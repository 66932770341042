import { flatten } from "@amcharts/amcharts4/.internal/core/utils/Iterator";

import axios from "axios";
import React, { useEffect, useState } from "react";
import "./filtrationPanelProjectDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
function FiltrationPanelProjectDetails({
  project,
  projectID,
  panelID,
  groupID,
  email,
  showPopup,
  onclose,
}) {
  const [Project, setProject] = useState({});
  const [teamMemberID, setteamMemberID] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [panelComments, setPanelComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [isMember, setIsMember] = useState(true);
  const [commentsCount, setcommentsCount] = useState(0);
  const [addMoreComment, setAddMoreComment] = useState(false);
  const [teamMember, setteamMember] = useState({});
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  useEffect(() => {
    setshow(showPopup);
  }, [showPopup]);
  useEffect(() => {
    setProject(project);
    if (show) {
      getProjectDetails(projectID);
    }
    getOtherData();
  }, [show]);

  const getOtherData = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcommentsbypanelproject`,
        {
          email: email,
          panelId: panelID,
          projectId: projectID,
        }
      )
      .then((res) => {
        setCommentsData(res.data);
        if (res.data.length >= 2) setcommentsCount(2);
        else if (res.data.length === 1) setcommentsCount(1);
        else if (res.data.length === 0) setcommentsCount(0);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/getpanelmemberbyemail`,
        {
          email: email,
          panelId: panelID,
          groupId: groupID,
        }
      )
      .then((res) => {
        if (res.data.length === 0) setIsMember(false);
        else setIsMember(true);
      });
    if (isMember == false) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcommentsbypanel`,
          {
            panelId: panelID,
            projectId: projectID,
          }
        )
        .then((res) => {
          setPanelComments(res.data);
        });
    }
  };
  const getProjectDetails = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectforpaneladmin`,
        {
          id: id,
        }
      )
      .then((res) => {
        console.log(res.data);
        setProject(res.data);
      });
  };

  const adminCommentsView = () => {
    return (
      <div className='details-main-container'>
        <span
          className='details-back-link m-2 mb-5'
          style={{ cursor: "pointer" }}
          onClick={() => {
            setteamMemberID("");
            setteamMember({});
            setShowComments(false);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
          Idea Details
        </span>
        <h5>Panel comments</h5>
        {panelComments.map((c) => (
          <div className='row mt-3 ml-2 mr-2'>
            <table className='text-center'>
              <tr>
                <th>Emails</th>
                <th>Comments</th>
              </tr>
              <tr>
                <td>{c.email}</td>
                <td>{c.comment}</td>
              </tr>
            </table>
          </div>
        ))}
      </div>
    );
  };

  const handleCommentsView = () => {
    setShowComments(true);
  };

  const addComment = () => {
    setAddMoreComment(true);
  };

  const removeComment = () => {
    setAddMoreComment(false);
  };

  let first = comments[0];
  let second = comments[1];
  const handleCommentChange = (value, text) => {
    switch (text) {
      case "first":
        first = value;
        break;
      case "second":
        second = value;
        break;

      default:
        break;
    }
    if (
      first !== undefined &&
      first !== "" &&
      second !== undefined &&
      second !== ""
    )
      setComments([first, second]);
    else {
      if (first !== undefined && first !== "") setComments([first]);
      else setComments([second]);
    }
  };

  const handleMoreCommentChange = (value) => {
    setComments([]);
    if (value !== undefined && value !== "") setComments([value]);
  };

  const saveComments = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcomments`,
        {
          email: email,
          panelId: panelID,
          groupId: groupID,
          projectId: projectID,
          comments: comments,
        }
      )
      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcommentsbypanelproject`,
            {
              email: email,
              panelId: panelID,
              projectId: projectID,
            }
          )
          .then((res) => {
            setCommentsData(res.data);
            if (res.data.length >= 2) setcommentsCount(2);
            else if (res.data.length === 1) setcommentsCount(1);
            else if (res.data.length === 0) setcommentsCount(0);
          });
      });
  };

  const deleteComment = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/deletepanelcomment`,
        {
          id: id,
        }
      )
      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcommentsbypanelproject`,
            {
              email: email,
              panelId: panelID,
              projectId: projectID,
            }
          )
          .then((res) => {
            setCommentsData(res.data);
            if (res.data.length >= 2) setcommentsCount(2);
            else if (res.data.length === 1) setcommentsCount(1);
            else if (res.data.length === 0) setcommentsCount(0);
          });
      });
  };

  const addComments = () => {
    return (
      <div className='details-main-container'>
        <span
          className='details-back-link m-2 mb-5'
          style={{ cursor: "pointer" }}
          onClick={() => {
            setteamMemberID("");
            setteamMember({});
            setShowComments(false);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
          Idea Details
        </span>
        <h6>This comments will be sent to this team in case of rejection</h6>
        <div className='row'>
          <div className='col-md-10'>
            <textarea
              className='form-control'
              placeholder='Please add a constructive comment'
              id='comment'
              name='comment'
              onChange={(e) => handleCommentChange(e.target.value, "first")}
            />
          </div>
          <div className='col-md-2'>
            <span onClick={() => addComment()} className='add-tech-btn'>
              +
            </span>
          </div>
        </div>
        {addMoreComment ? (
          <div className='row mt-3'>
            <div className='col-md-10'>
              <textarea
                className='form-control'
                placeholder='Please add a constructive comment'
                id='comment2'
                name='comment2'
                onChange={(e) => handleCommentChange(e.target.value, "second")}
              />
            </div>
            <div className='col-md-2'>
              <span onClick={() => removeComment()} className='add-tech-btn'>
                -
              </span>
            </div>
          </div>
        ) : null}
        <div className='row mt-3 mb-3'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <button
              className='btn btn-outline-light w-100'
              onClick={saveComments}
            >
              Save
            </button>
          </div>
          <div className='col-md-4'></div>
        </div>
      </div>
    );
  };
  const showAllComments = () => {
    return (
      <div className='details-main-container'>
        <span
          className='details-back-link m-2 mb-5'
          style={{ cursor: "pointer" }}
          onClick={() => {
            setteamMemberID("");
            setteamMember({});
            setShowComments(false);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
          Idea Details
        </span>
        <h6>Your comments</h6>
        {commentsData.map((c) => (
          <div className='row mt-3 ml-2 mr-2'>
            <ul className='col-md-10'>
              <li>{c.comment}</li>
            </ul>
            <div className='col-md-2'>
              <span
                className='fa fa-trash ml-2'
                style={{
                  cursor: "pointer",
                  color: "#e60000",
                  fontSize: "16px",
                }}
                onClick={() => deleteComment(c._id)}
              ></span>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const addAnotherComment = () => {
    return (
      <div className='details-main-container'>
        <span
          className='details-back-link m-2 mb-5'
          style={{ cursor: "pointer" }}
          onClick={() => {
            setteamMemberID("");
            setteamMember({});
            setShowComments(false);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
          Idea Details
        </span>
        <h6>This comments will be sent to this team in case of rejection</h6>
        {commentsData.map((c) => (
          <div className='row mt-3 ml-2 mr-2'>
            <ul className='col-md-10'>
              <li>{c.comment}</li>
            </ul>
            <div className='col-md-2'>
              <span
                className='fa fa-trash ml-2'
                style={{
                  cursor: "pointer",
                  color: "#e60000",
                  fontSize: "16px",
                }}
                onClick={() => deleteComment(c._id)}
              ></span>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='col-md-10'>
            <textarea
              className='form-control'
              placeholder='Please add a constructive comment'
              id='comment'
              name='comment'
              onChange={(e) => handleMoreCommentChange(e.target.value)}
            />
          </div>
        </div>

        <div className='row mt-3 mb-3'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <button
              className='btn btn-outline-light w-100'
              onClick={saveComments}
            >
              Save
            </button>
          </div>
          <div className='col-md-4'></div>
        </div>
      </div>
    );
  };

  return (
    <div className='modal-dialog modal-lg ' role='document'>
      <div className='modal-content add-groups-modal-body'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Project {Project.project?.name}
          </h5>

          <button
            href='javascript:void(0)'
            type='button'
            className='close'
            data-dismiss='modal'
            id='closeModal'
            aria-label='Close'
            onMouseDown={(e) => {
              e.preventDefault();
              setProject({});
              setteamMemberID("");
              setComments([]);
              setCommentsData([]);
              setPanelComments([]);
              setShowComments(false);

              setcommentsCount(0);
              setAddMoreComment(false);
              setteamMember({});
              setloading(true);
              setshow(false);

              onclose();
              return false;
            }}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        {show == false ? (
          <div className='modal-body row'>
            <div className='col-md-7 details-project-body-border'>
              {teamMemberID == "" ? (
                showComments ? (
                  isMember ? (
                    commentsCount === 0 ? (
                      addComments()
                    ) : commentsCount === 1 ? (
                      addAnotherComment()
                    ) : (
                      showAllComments()
                    )
                  ) : (
                    adminCommentsView()
                  )
                ) : (
                  <div className='details-main-container'>
                    <span className='details-main-header m-2'>
                      Idea Details
                    </span>
                    <span className='details-text-header m-2'>Idea Name</span>
                    <span className='details-text-value m-2'>
                      {Project.project?.name}
                    </span>
                    <span className='details-text-header m-2'>
                      Idea Description
                    </span>
                    <span className='details-text-value m-2'>
                      {Project.project?.description}
                    </span>
                    <span className='details-text-header m-2'>Technology</span>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {Project.project?.type.map((el) => (
                        <span className='details-tag mr-2'>{el}</span>
                      ))}
                    </div>

                    <span className='details-text-header m-2'>Attachments</span>
                    {Project.project?.videoLink ? (
                      <a
                        className='details-link mr-2'
                        href={Project.project?.videoLink}
                        style={{ cursor: "pointer" }}
                        target='_blank'
                      >
                        <span className=' fas fa-file-video mr-2'></span>
                        {Project.project?.videoLink}
                      </a>
                    ) : (
                      <span className='details-text-value ml-2'>N/A</span>
                    )}
                  </div>
                )
              ) : showComments ? (
                isMember ? (
                  commentsCount === 0 ? (
                    addComments()
                  ) : commentsCount === 1 ? (
                    addAnotherComment()
                  ) : (
                    showAllComments()
                  )
                ) : (
                  adminCommentsView()
                )
              ) : (
                <div className='details-main-container'>
                  <span
                    className='details-back-link m-2'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setteamMemberID("");
                      setteamMember({});
                      setShowComments(false);
                    }}
                  >
                    {/* <img src='/assets/back-arrow.svg' className='mr-2' ></img> */}
                    <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                    Idea Details
                  </span>
                  <span className='details-text-header m-2'>Full Name</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.name}
                  </span>
                  <span className='details-text-header m-2'>Email</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.email}
                  </span>
                  {/* <span className='details-text-header m-2'>Service Line</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.serviceLine}
                  </span> */}
                  <span className='details-text-header m-2'>Location</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.country}
                  </span>
                  {/* <span className='details-text-header m-2'>
                    Developer Type
                  </span>
                  <span className='details-text-value m-2'>
                    {teamMember?.participantType}
                  </span> */}
                </div>
              )}
            </div>
            <div className='col-md-5 '>
              <div className='pl-5'>
                {" "}
                <span className='details-popup-temmemberssection'>
                  Team Members
                </span>
                <ul style={{ padding: "0", margin: "0.25rem" }}>
                  {Project.registeredmembers?.map((el) => (
                    <li
                      onClick={() => {
                        setteamMemberID(el._id);
                        setteamMember(el);
                        setShowComments(false);
                      }}
                      style={
                        el.isTeamLeader
                          ? { fontWeight: "bold", cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                    >
                      {el.name}
                    </li>
                  ))}
                  {Project.nonregistered?.map((el) => (
                    <li style={{ color: "#e60000" }}>{el.email}</li>
                  ))}
                </ul>
                {isMember ? (
                  <>
                    {commentsCount < 2 ? (
                      <button
                        onClick={handleCommentsView}
                        className='btn btn-outline-dark mt-5'
                      >
                        Add comments
                      </button>
                    ) : (
                      <button
                        onClick={handleCommentsView}
                        className='btn btn-outline-dark mt-5'
                      >
                        View comments
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    onClick={handleCommentsView}
                    className='btn btn-outline-dark mt-5'
                  >
                    View comments
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='modal-body row'>
            <div className='col-md-7 details-project-body-border'>
              {teamMemberID == "" ? (
                showComments ? (
                  isMember ? (
                    commentsCount === 0 ? (
                      addComments()
                    ) : commentsCount === 1 ? (
                      addAnotherComment()
                    ) : (
                      showAllComments()
                    )
                  ) : (
                    adminCommentsView()
                  )
                ) : (
                  <div className='details-main-container'>
                    <span className='details-main-header m-2'>
                      Idea Details
                    </span>
                    <span className='details-text-header m-2'>Idea Name</span>
                    <span className='details-text-value m-2'>
                      {Project.project?.name}
                    </span>
                    <span className='details-text-header m-2'>
                      Idea Description
                    </span>
                    <span className='details-text-value m-2'>
                      {Project.project?.description}
                    </span>
                    <span className='details-text-header m-2'>Technology</span>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {Project.project?.type.map((el) => (
                        <span className='details-tag mr-2'>{el}</span>
                      ))}
                    </div>

                    <span className='details-text-header m-2'>Attachments</span>
                    {Project.project?.videoLink ? (
                      <a
                        className='details-link mr-2'
                        href={Project.project?.videoLink}
                        style={{ cursor: "pointer" }}
                        target='_blank'
                      >
                        <span className=' fas fa-file-video mr-2'></span>
                        {Project.project?.videoLink}
                      </a>
                    ) : (
                      <span className='details-text-value ml-2'>N/A</span>
                    )}
                  </div>
                )
              ) : showComments ? (
                isMember ? (
                  commentsCount === 0 ? (
                    addComments()
                  ) : commentsCount === 1 ? (
                    addAnotherComment()
                  ) : (
                    showAllComments()
                  )
                ) : (
                  adminCommentsView()
                )
              ) : (
                <div className='details-main-container'>
                  <span
                    className='details-back-link m-2'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setteamMemberID("");
                      setteamMember({});
                      setShowComments(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                    Idea Details
                  </span>
                  <span className='details-text-header m-2'>Full Name</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.name}
                  </span>
                  <span className='details-text-header m-2'>Email</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.email}
                  </span>
                  {/* <span className='details-text-header m-2'>Service Line</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.serviceLine}
                  </span> */}
                  <span className='details-text-header m-2'>Location</span>
                  <span className='details-text-value m-2'>
                    {teamMember?.country}
                  </span>
                  {/* <span className='details-text-header m-2'>
                    Developer Type
                  </span>
                  <span className='details-text-value m-2'>
                    {teamMember?.participantType}
                  </span> */}
                </div>
              )}
            </div>
            <div className='col-md-5 '>
              <div className='pl-5'>
                {" "}
                <span className='details-popup-temmemberssection'>
                  Team Members
                </span>
                <ul style={{ padding: "0", margin: "0.25rem" }}>
                  {Project.registeredmembers?.map((el) => (
                    <li
                      onClick={() => {
                        setteamMemberID(el._id);
                        setteamMember(el);
                        setShowComments(false);
                      }}
                      style={
                        el.isTeamLeader
                          ? { fontWeight: "bold", cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                    >
                      {el.name}
                    </li>
                  ))}
                  {Project.nonregistered?.map((el) => (
                    <li style={{ color: "#e60000" }}>{el.email}</li>
                  ))}
                </ul>
                {isMember ? (
                  <>
                    {commentsCount < 2 ? (
                      <button
                        onClick={handleCommentsView}
                        className='btn btn-outline-light mt-5'
                      >
                        Add comments
                      </button>
                    ) : (
                      <button
                        onClick={handleCommentsView}
                        className='btn btn-outline-light mt-5'
                      >
                        View comments
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    onClick={handleCommentsView}
                    className='btn btn-outline-light mt-5'
                  >
                    View comments
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      )
    </div>
  );
}
export default FiltrationPanelProjectDetails;
