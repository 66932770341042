import axios from "axios";
import React, { useEffect, useState } from "react";
import SuperAdminNavBar from "./SuperAdminNavBar";
import * as XLSX from "xlsx";
import styles from "./superadminProjectsinnerPage.module.css";
function MailGroups() {
  const [data, setdata] = useState([]);
  const [loadBytes, setloadBytes] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}templates/getsentTo`)
      .then((res) => {
        setdata(res.data);
        setloadBytes(true);
      });
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>

          <div className={" col-md-10"}>
            <div className={styles["table-main-container"]}>
              {loadBytes ? (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <table className='table '>
                    <thead>
                      <tr>
                        <th scope='col' style={{ width: "20%" }}>
                          Group Random ID
                        </th>
                        <th scope='col' style={{ width: "20%" }}>
                          SentTo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((el) => {
                        return (
                          <tr>
                            <td>{el.templateGroupID}</td>
                            <td>{el.SentTo}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailGroups;
