import axios from "axios";
import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import styles from "./superadminProjectsinnerPage.module.css";
function SessionConfig() {
  let location = useLocation();
  const [region, setregion] = useState("");

  const [sessions, setsessions] = useState([]);
  const [ID, setID] = useState("");
  const [sessionName, setsessionName] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [sessionLink, setsessionLink] = useState("");
  const [slidoLink, setslidoLink] = useState("");

  const getsession = () => {
    setsessions([]);
    if (region == undefined || region == null) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}session`, {})
        .then((res) => {
          setsessions(res.data);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}session/regional`, {
          region: region,
        })
        .then((res) => {
          setsessions(res.data);
        });
    }
  };
  useEffect(() => {
    setregion(
      localStorage.getItem("region") != "all" ? location.state.region : "all"
    );
    getsession();
  }, [region, location.state]);
  const Addsession = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}session/add`, {
        sessionName: sessionName,
        sessionLink: sessionLink,
        slidoLink: slidoLink,
        startDate: startDate,
        endDate: endDate,
        region: region,
      })
      .then((res) => {
        getsession();
        setsessionName("");
        setslidoLink("");
        setsessionLink("");
        setstartDate(new Date());
        setendDate(new Date());
      });
  };
  const Editsession = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}session/edit`, {
        id: ID,
        sessionName: sessionName,
        sessionLink: sessionLink,
        slidoLink: slidoLink,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        getsession();
        setID("");
        setsessionName("");
        setslidoLink("");
        setsessionLink("");
        setstartDate(new Date());
        setendDate(new Date());
        setregion(
          localStorage.getItem("region") != "all"
            ? location.state.region
            : "all"
        );
      });
  };
  const deletesession = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}session/delete`, {
        _id: id,
      })
      .then((res) => {
        getsession();
        setID("");
        setsessionName("");
        setslidoLink("");
        setsessionLink("");
        setstartDate(new Date());
        setendDate(new Date());
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Session
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Ssession Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setsessionName(el.target.value);
                  }}
                  value={sessionName}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Slido Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setslidoLink(el.target.value);
                  }}
                  value={slidoLink}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Session Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setsessionLink(el.target.value);
                  }}
                  value={sessionLink}
                ></input>
              </div>
              <div className='col-md-6'>
                <p>Event Start Date:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setstartDate}
                  value={startDate}
                ></DateTimePicker>
              </div>
              <div className='col-md-6'>
                <p> End Date:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setendDate}
                  value={endDate}
                ></DateTimePicker>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={Addsession}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Session
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Ssession Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setsessionName(el.target.value);
                  }}
                  value={sessionName}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Slido Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setslidoLink(el.target.value);
                  }}
                  value={slidoLink}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Session Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setsessionLink(el.target.value);
                  }}
                  value={sessionLink}
                ></input>
              </div>
              <div className='col-md-6'>
                <p>Event Start Date:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setstartDate}
                  value={startDate}
                ></DateTimePicker>
              </div>
              <div className='col-md-6'>
                <p> End Date:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setendDate}
                  value={endDate}
                ></DateTimePicker>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={Editsession}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div className={styles["main-container"] + " col-md-10"}>
              <button
                className='btn btn-outline-dark float-right m-2'
                data-toggle='modal'
                data-target='#exampleModal'
              >
                + Add Session
              </button>
              <div className={styles["main-container"] + " row mt-5"}>
                {sessions.map((r) => (
                  <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                    <div className={styles["region-box-header"]}>
                      <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                        {r.sessionName}
                      </p>
                      <div className='float-right'>
                        <span
                          className='fa fa-pencil'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          data-toggle='modal'
                          data-target={"#editModal"}
                          onClick={() => {
                            setID(r._id);
                            setregion(r.region);
                            setslidoLink(r.slidoLink);
                            setsessionLink(r.sessionLink);
                            setsessionName(r.sessionName);
                            setstartDate(
                              new Date(new Date(r.startDate).toLocaleString())
                            );
                            setendDate(
                              new Date(new Date(r.endDate).toLocaleString())
                            );
                          }}
                        ></span>
                        <span
                          className='fa fa-trash ml-2'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            deletesession(r._id);
                          }}
                        ></span>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ wordBreak: "break-all" }}>
                        <span
                          className='fa fa-link mr-2'
                          style={{ color: "#e60000", wordBreak: "break-all" }}
                        ></span>
                        {r.sessionLink}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>
                        <span
                          className='fa fa-link mr-2'
                          style={{ color: "#e60000" }}
                        ></span>
                        {r.slidoLink}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>
                        <span
                          className='fas fa-map-marker-alt mr-2'
                          style={{ color: "#e60000" }}
                        ></span>
                        {r.region}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p>
                        <span
                          className='fa fa-calendar mr-2'
                          style={{ color: "#e60000" }}
                        ></span>
                        {new Date(r.startDate).toLocaleString()}
                      </p>
                      <p style={{ marginLeft: "5.5rem" }}>
                        <span
                          className='fa fa-calendar mr-2'
                          style={{ color: "#e60000" }}
                        ></span>
                        {new Date(r.endDate).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SessionConfig;
