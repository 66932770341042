import React, { useState, useEffect } from "react";
import "./main.css";
import LandingNavBar from "../../components/home/navbar";
import NavBar from "../../components/common/navBar/navBar";

const TermsAndConditionMain = () => {
  return (
    <div className='terms-page-container container-fluid'>
      <LandingNavBar></LandingNavBar>
      <div className='row d-flex justify-content-center mt-4'>
        <div className='col-lg-12 col-md-6 col-xs-10 col-sm-10'>
          <span class='underline'>
            {" "}
            <p className='terms-page-title mt-3 '>GUIDELINES</p>
          </span>
        </div>
        <div className='col-lg-7 col-md-7 col-xs-12 col-sm-12 terms-card-container mt-5'>
          <ul>
            <li className='ml-3'>
              Registration will open from 9:00 AM GMT, January 26, 2024, to 6:00
              PM, February 24, 2024.
            </li>
            <li className='ml-3'>
              Participants can register and participate individually or in
              teams. Teams must be of 2 to 4 members.
            </li>
            <li className='ml-3'>
              Participation in the competition is free of charge, and the
              primary language of communication is English.
            </li>
            <li className='ml-3'>
              The solution pitched must align with the theme ‘Embracing AI in
              Business.’
            </li>
            <li className='ml-3'>
              Submission of the solution pitch shall be made by the team leader
              or by the individual competitor.​
            </li>
            <li className='ml-3'>
              The solution pitch should be submitted in PPT, PDF, or MP4 format,
              not exceeding 100 MB.
            </li>
            <li className='ml-3'>
              We encourage diversity and recommend that you create a diverse
              team from a gender mix perspective, different areas of expertise,
              etc.
            </li>
            <li className='ml-3'>
              A participant cannot be a member of more than one team.
            </li>
            <li className='ml-3'>
              Applications failing to meet the above guidelines will not be
              qualified to enter the hackathon competition.
            </li>

            <span
              className='ml-3 '
              style={{
                color: "white",
                display: "flex",

                font: "normal normal normal 24px/32px Vodafone Lt",
                flexDirection: "column",
              }}
            >
              For more details, check out our Terms and Conditions below:​
              <a
                href='/assets/IN_TermsAndConditions.pdf'
                target='_blank'
                style={{
                  color: "var(--text-secondary)",
                  display: "block",
                }}
              >
                India
              </a>
              <a
                href='/assets/terms_and_conditions_egypt.pdf'
                target='_blank'
                style={{
                  color: "var(--text-secondary)",
                  display: "block",
                }}
              >
                Egypt
              </a>
              <a
                href='/assets/terms_and_conditions_romania.pdf'
                target='_blank'
                style={{
                  color: "var(--text-secondary)",
                  display: "block",
                }}
              >
                Romania
              </a>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionMain;
