export const CARD_SIZES = { width: 174, height: 281 };
export const DECK_SIZE = 15;

export const GAME_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  CREATING: "CREATING",
  RESETTING: "RESETTING",
  FINISHED: "FINISHED",
};

export const CARD_STATUS = {
  SELECTED: "SELECTED",
  MATCHED: "MATCHED",
  HIDDEN: "HIDDEN",
};
