import React, { useContext } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { useNavigate } from "react-router-dom";
import { SuperAdminContext } from "../../contexts/superadminContext";
import { setRegionToken } from "../../services/authService";
import SuperAdminNavBar from "./SuperAdminNavBar";
import styles from "./superadminInnerHome.module.css";
function SuperAdminInnerHome() {
  const [superAdmin, changeDetails] = useContext(SuperAdminContext);
  const history = useNavigate();
  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className=' nav-button-padding'></div>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={" col-md-10"}>
            <div className={styles["main-container"] + " row mt-5"}>
              {/* <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history({
                      pathname: "/superadmin/sessions",
                      state: {
                        region: superAdmin.region,
                      },
                    });
                  }}
                >
                  Sessions
                </span>
              </div> */}
              {/* <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history({
                      pathname: "/superadmin/agenda",
                      state: {
                        region: superAdmin.region,
                      },
                    });
                  }}
                >
                  Agenda
                </span>
              </div> */}
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history("/superadmin/innerproject", {
                      state: {
                        region: superAdmin.region,
                      },
                    });
                  }}
                >
                  Projects
                </span>
              </div>
              {/* <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history({
                      pathname: "/superadmin/home",
                      state: {
                        region: superAdmin.region,
                      },
                    });
                  }}
                >
                  DashBoard
                </span>
              </div> */}
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history("/superadmin/announcements", {
                      state: {
                        region: superAdmin.region,
                      },
                    });
                  }}
                >
                  Announcements
                </span>
              </div>
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <span
                  className={styles["regionTitle"]}
                  onClick={() => {
                    history({
                      pathname: "/entrance",
                    });
                  }}
                >
                  Start Event
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminInnerHome;
