import { useNavigate, useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import axios from "axios";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import ReactPaginate from "react-paginate";
import "./projectspreview.css";
import styles from "./superadminProjectsinnerPage.module.css";
function FailedParticipants() {
  const history = useNavigate();
  let location = useLocation();
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [region, setregion] = useState(
    location.state.region != "all" ? location.state.region : "all"
  );
  const [searchText, setSearchText] = useState("");
  const [status, setstatus] = React.useState("all");
  const [projects, setprojects] = useState([]);
  const [tempProjects, setTempProjects] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const deleteProject = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/superadmindeleteparticipant`,
        {
          id,
        }
      )
      .then((res) => {
        getProjects();
      });
  };
  const getProjects = () => {
    setprojects([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getforsuperadminpaginatedfailedparticiapnts`,
        {
          region,
          searchText,
          status,
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setprojects(res.data.list);
      });
  };
  const getTempProjects = () => {
    setTempProjects([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getparticipantsTemppaginatedbyFilters`,
        {
          region,
          searchText,
          status,
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setTempProjects(res.data.list);
      });
  };

  useEffect(() => {
    setregion(location.state.region != "all" ? location.state.region : "all");
    getProjects();
    getTempProjects();
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, [region, location.state, status]);
  const deleteTempProject = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/superadmindeleteparticipantTemp`,
        {
          id,
        }
      )
      .then((res) => {
        getTempProjects();
      });
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={styles["main-container"] + " col-md-10"}>
            <div className={styles["main-container"] + " row mt-5"}>
              {loadBytes ? (
                <div>
                  <h2>Team Members</h2>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col' style={{ width: "20%" }}>
                          Name
                        </th>
                        <th scope='col' style={{ width: "20%" }}>
                          email
                        </th>

                        <th scope='col' style={{ width: "20%" }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((el) => {
                        return (
                          <tr>
                            <td>{el.name}</td>
                            <td>{el.email}</td>

                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                className='fa fa-trash'
                                onClick={() => {
                                  deleteProject(el._id);
                                }}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <h2>Non Registered Team Members</h2>
                  <table className='table '>
                    <thead>
                      <tr>
                        <th scope='col' style={{ width: "20%" }}>
                          email
                        </th>

                        <th scope='col' style={{ width: "20%" }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tempProjects.map((el) => {
                        return (
                          <tr>
                            <td>{el.email}</td>

                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                className='fa fa-trash'
                                onClick={() => {
                                  deleteTempProject(el._id);
                                }}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FailedParticipants;
