import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FiltrationPanelAddGroup from "../generalEvent/panelAdmin/filtrationPanel/filtrationPanelAddGroup";
import SuperAdminNavBar from "./SuperAdminNavBar";
import GroupedTable from "../superAdminTable/GroupedTable";
import NonGroupedTable from "../superAdminTable/NonGroupedTable";
import { getCurrentUser, setImpersonateJWT } from "../../services/authService";
import styles from "./superadminProjectsinnerPage.module.css";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function FiltrationPanel() {
  const forceUpdate = useForceUpdate();
  const [uploadErrors, setUploadErrors] = React.useState([]);
  const [groupsuploaded, setGroupsUploadedEmails] = React.useState([]);
  const [memberuploadedEmails, setMemberUploadedEmails] = React.useState([]);
  const [memberMail, setMemberMail] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [groupID, setGroupID] = React.useState("");
  const [oldMemberMail, setOldMemberMail] = React.useState("");
  const [memberid, setMemberid] = React.useState("");
  const history = useNavigate();
  const [loading, setloading] = useState(true);
  const [panelRanks, setpanelRanks] = useState([]);
  const location = useLocation();
  const [panelID, setpanelID] = useState(location.state.panelID);
  const [region, setregion] = useState(localStorage.getItem("region"));
  const [panelMainData, setpanelMainData] = useState({});
  const [panelCriterias, setpanelCriterias] = useState([]);
  const [panelMembers, setpanelMembers] = useState([]);
  const [panelMemberID, setpanelMemberID] = useState("");
  const [panelMemberemail, setpanelMemberemail] = useState("");

  const [admin, setpanelAdmin] = useState("");
  const [count, setthreshold] = useState(0);
  const [criteriaID, setcriteriaID] = useState("");
  const [criteriaName, setcriteriaName] = useState("");
  const [criteriaLimit, setcriteriaLimit] = useState("");
  const [groups, setgroups] = useState([]);

  const getPanelRanks = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}panelvalues/getRanksSuperAdmin`,
        {
          panelID,
        }
      )
      .then((res) => {
        setpanelRanks(res.data);
      });
  };

  const getWinners = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}panelvalues/getwinnerRanksSuperAdmin`,
        {
          panelID,
        }
      )
      .then((res) => {
        downloadExcel(res.data);
      });
  };
  const downloadExcel = (dataObj) => {
    const data = dataObj.map((record) => {
      let obj = {};
      for (let key in record) {
        if (key == "non_Registered") {
          obj["Non Registered"] = JSON.parse(record[key])

            .map((el) => el.email)
            .toString();
        } else if (key == "prefiltration_comments") {
          obj["Prefiltration Comments"] = JSON.parse(record[key])

            .map((el) => el.comment)
            .toString();
        } else if (key == "name") {
          obj["Participant Name"] = record[key];
        } else if (_.startCase(key) == "Created At") {
          obj["Member Created At"] = record[key].toString();
        } else if (_.startCase(key) == "Updated At") {
          obj["Member Updated At"] = record[key].toString();
        } else if (_.startCase(key) == "Status") {
          obj["Member Status"] = record[key].toString();
        } else if (key == "project") {
          for (let key2 in record[key]) {
            if (_.startCase(key2) != "Id" && _.startCase(key2) != "V") {
              if (_.startCase(key2) == "Name") {
                obj["Project Name"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Created At") {
                obj["Project Created At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Updated At") {
                obj["Project Updated At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Status") {
                obj["Project Status"] = record["project"][key2].toString();
              } else {
                obj[_.startCase(key2)] = record["project"][key2];
              }
            }

            obj[_.startCase(key2)] = record["project"][key2].toString();
          }
        } else {
          obj[_.startCase(key)] = record[key];
        }
      }

      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // var staffId = +localStorage.getItem('StaffId');

    /* save to file */
    const fileregion = region != undefined ? region : "all";
    XLSX.writeFile(
      wb,
      "Projects_winners_" + panelMainData.name + " " + new Date() + ".xlsx"
    );
  };
  const getlosers = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}panelvalues/getlossersRanksSuperAdmin`,
        {
          panelID,
        }
      )
      .then((res) => {
        downloadlosersExcel(res.data);
      });
  };
  const downloadlosersExcel = (dataObj) => {
    const data = dataObj.map((record) => {
      let obj = {};
      for (let key in record) {
        if (key == "non_Registered") {
          obj["Non Registered"] = JSON.parse(record[key])

            .map((el) => el.email)
            .toString();
        } else if (key == "prefiltration_comments") {
          obj["Prefiltration Comments"] = JSON.parse(record[key])

            .map((el) => el.comment)
            .toString();
        } else if (key == "name") {
          obj["Participant Name"] = record[key];
        } else if (_.startCase(key) == "Created At") {
          obj["Member Created At"] = record[key].toString();
        } else if (_.startCase(key) == "Updated At") {
          obj["Member Updated At"] = record[key].toString();
        } else if (_.startCase(key) == "Status") {
          obj["Member Status"] = record[key].toString();
        } else if (key == "project") {
          for (let key2 in record[key]) {
            if (_.startCase(key2) != "Id" && _.startCase(key2) != "V") {
              if (_.startCase(key2) == "Name") {
                obj["Project Name"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Created At") {
                obj["Project Created At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Updated At") {
                obj["Project Updated At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Status") {
                obj["Project Status"] = record["project"][key2].toString();
              } else {
                obj[_.startCase(key2)] = record["project"][key2];
              }
            }

            obj[_.startCase(key2)] = record["project"][key2].toString();
          }
        } else {
          obj[_.startCase(key)] = record[key];
        }
      }

      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // var staffId = +localStorage.getItem('StaffId');

    /* save to file */
    const fileregion = region != undefined ? region : "all";
    XLSX.writeFile(
      wb,
      "Projects_losers_" + panelMainData.name + " " + new Date() + ".xlsx"
    );
  };
  const getfiltrationPanel = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/panel`, {
        panelID,
      })
      .then((res) => {
        console.log(res.data);
        setpanelMainData(res.data);
      });
  };
  const getfiltrationPanelCriterias = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/criteria`, {
        id: panelMainData._id,
      })
      .then((res) => setpanelCriterias(res.data));
  };
  const getfiltrationPanelmembers = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/member`, {
        id: panelMainData._id,
      })
      .then((res) => {
        setpanelMembers(res.data);
      });
  };
  const getfiltrationPanelgroups = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/groups`, {
        panelID,
      })
      .then((res) => setgroups(res.data));
  };
  const submitdeleteMemberfromGroup = (emailID, groupID, panelID) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/deletememberfromgroup`,
        {
          panelID,
          emailID,
          groupID,
        }
      )
      .then((res) => window.location.reload());
  };

  useEffect(() => {
    setInterval(() => {
      setloading(false);
    }, 1000);
    setregion(
      localStorage.getItem("region") != "all" ? location.state.region : "all"
    );
    getfiltrationPanel();
    getPanelRanks();
    getfiltrationPanelgroups();
  }, []);
  useEffect(() => {
    getfiltrationPanelCriterias();
    getfiltrationPanelmembers();
  }, [panelMainData]);
  const submitEdit = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/editpanel`, {
        panelID,
        region,
        count,
        admin,
      })
      .then((res) => window.location.reload());
  };
  const submitEditCriteria = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/editcriteria`, {
        name: criteriaName,
        count: criteriaLimit,
        _id: criteriaID,
      })
      .then((res) => window.location.reload());
  };
  const submitAddCriteria = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/addcriteria`, {
        name: criteriaName,
        count: criteriaLimit,
        panelID: panelMainData._id,
      })
      .then((res) => window.location.reload());
  };
  const submitdeleteCriteria = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/deletecriteria`, {
        _id: id,
      })
      .then((res) => window.location.reload());
  };

  const getUserEmail = (email) => {
    setImpersonateJWT(email);
    history("/entrance");
  };

  const submitAddmember = () => {
    if (panelMemberemail.includes(".com")) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}filtration/addmember`, {
          email: panelMemberemail,

          panelID: panelMainData._id,
        })
        .then((res) => window.location.reload())
        .catch((ex) => {
          toast(ex.response.data, { autoClose: 5000 });
        });
    } else {
      toast("Please provide a valid email", { autoClose: 5000 });
    }
  };

  const submitAddMultipleMembers = (x) => {
    document.getElementById("multipleMembers").value = "";

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/addmultiplemember`, {
        emails: memberuploadedEmails,

        panelID: panelMainData._id,
      })
      .then((res) => window.location.reload())
      .catch((ex) => {
        setUploadErrors(ex.response.data);
      });
  };
  const addfiledataforMembers = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let x = [];
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        let x = memberuploadedEmails;
        x.push(element[0]);
        setMemberUploadedEmails(x);
        forceUpdate();
      }
      submitAddMultipleMembers(x);
    };
    reader.readAsBinaryString(f);
  };
  const sendGroupEmail = (groupID) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/sendGroupUpdateMail`,
        {
          group: groupID,
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((ex) => {
        setUploadErrors(ex.response.data);
      });
  };
  const submitgroupssheet = (data) => {
    document.getElementById("groupsupload").value = "";
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/addmultiplegroups`, {
        groups: data,
        panelID: panelMainData._id,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((ex) => {
        setUploadErrors(ex.response.data);
      });
  };
  const addfiledataforGroups = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      let x = groupsuploaded;
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        x.push({
          name: element[0],
          link: element[1],
          membersmails: element[2],
          projectName: element[3],
          from: strToDate(element[4]),
          to: strToDate(element[5]),
          display: element[6],
        });

        setGroupsUploadedEmails(x);

        forceUpdate();
      }
      submitgroupssheet(x);
    };
    reader.readAsBinaryString(f);
  };
  function strToDate(dtStr) {
    if (!dtStr) return null;
    let dateParts = dtStr.split("/");
    let timeParts = dateParts[2].split(" ")[1].split(":");
    dateParts[2] = dateParts[2].split(" ")[0];
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(
      +dateParts[2],
      dateParts[1] - 1,
      +dateParts[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    );
  }
  const submitEditmember = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/editmember`, {
        email: memberMail,
        panelID: panelMainData._id,
        oldEmail: oldMemberMail,
        _id: memberid,
      })
      .then((res) => getfiltrationPanelmembers());
  };
  const submitdeleteMember = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/deletemember`, {
        _id: id,
      })
      .then((res) => getfiltrationPanelmembers());
  };
  const calculatePanel = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}panelvalues/calculateRankssuperadmin`,
        {
          panelID: panelID,
        }
      )
      .then((res) => window.location.reload());
  };
  const changeRoomLink = (id, newstatus) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/setroomdiability`,
        {
          id: id,
          status: newstatus,
        }
      )
      .then((res) => window.location.reload());
  };

  return (
    <>
      {uploadErrors.length > 0 ? (
        <div
          class='alert alert-danger'
          role='alert'
          style={{ position: "fixed", zIndex: "100000" }}
        >
          <button
            type='button'
            class='close'
            data-dismiss='alert'
            aria-label='Close'
            onClick={(e) => {
              setUploadErrors([]);
              setMemberUploadedEmails([]);
            }}
          >
            <span aria-hidden='true' style={{ color: "#721c24" }}>
              &times;
            </span>
          </button>
          <div>
            <span>Please resolve these errors in the sheet and Reupload </span>
            {uploadErrors.map((el) => (
              <li>{el}</li>
            ))}
          </div>
        </div>
      ) : null}
      <div
        className='modal fade'
        id='addGroup'
        tabindex='-1'
        role='dialog'
        aria-labelledby='addGroup'
        aria-hidden='true'
      >
        <FiltrationPanelAddGroup
          panel={panelID}
          type={panelMainData.panelType}
          isEdit={isEdit}
          group={groupID}
        ></FiltrationPanelAddGroup>
      </div>

      <div
        className='modal fade'
        id='editMainDetails'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Panel Details
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Admin Email:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setpanelAdmin(el.target.value);
                  }}
                  value={admin}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Max number of Projects:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setthreshold(el.target.value);
                  }}
                  value={count}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitEdit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editCriteriaModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Panel Criteria
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Criteria Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setcriteriaName(el.target.value);
                  }}
                  value={criteriaName}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Criteria Max Weight:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setcriteriaLimit(el.target.value);
                  }}
                  value={criteriaLimit}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitEditCriteria}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='addCriteriaModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Panel Criteria
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Criteria Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setcriteriaName(el.target.value);
                  }}
                  value={criteriaName}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Criteria Max Weight:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setcriteriaLimit(el.target.value);
                  }}
                  value={criteriaLimit}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitAddCriteria}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='addMemberModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Panel Member
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Member Email:</p>

                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setpanelMemberemail(el.target.value);
                  }}
                  value={panelMemberemail}
                ></input>

                <div className='row' id='textBoxes'></div>
              </div>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitAddmember}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editMemberModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Panel Member
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Member Email:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setMemberMail(el.target.value);
                  }}
                  value={memberMail}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitEditmember}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ overflow: "hidden" }}>
          <div className='container-fluid p-0'>
            <div className=' nav-button-padding'></div>
            <div className='row' style={{ minHeight: "50vh" }}>
              <div className='col-md-2'>
                <SuperAdminNavBar></SuperAdminNavBar>
              </div>
              <div className={" col-md-10"}>
                <div className={styles["main-container"] + " row mt-5"}>
                  {loading ? (
                    <div
                      className='main-postKeynotes-page-body row'
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <div className='text-center'>
                        <div
                          className='spinner-grow text-primary'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-secondary'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-success'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-danger' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-warning'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-info' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-light' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-dark' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=''>
                      <div
                        className={
                          styles["panel-page-main-box"] + " col-md-12 ml-2 mb-2"
                        }
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span className={styles["regionTitle"]}>Region</span>
                          <p>{region}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "2rem",
                          }}
                        >
                          <div>
                            <span className={styles["regionTitle"]}>
                              Panel Admin
                            </span>
                          </div>

                          <p>{panelMainData.panelAdmin}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "2rem",
                          }}
                        >
                          <span className={styles["regionTitle"]}>
                            Max number of Projects
                          </span>
                          <p>{panelMainData.maxCount}</p>
                        </div>
                        <div className='float-right'>
                          <span
                            className='fa fa-pencil mr-5'
                            data-toggle='modal'
                            data-target={"#editMainDetails"}
                            onClick={() => {
                              setpanelAdmin(panelMainData.panelAdmin);
                              setthreshold(panelMainData.maxCount);
                              setpanelID(panelMainData._id);
                            }}
                          ></span>
                        </div>
                      </div>{" "}
                      <div className='col-md-12' style={{ minHeight: "15rem" }}>
                        <span className={styles["regionTitle2"]}>
                          Criterias
                        </span>

                        {panelMainData._id ? (
                          <button
                            className='btn btn-outline-dark float-right m-2'
                            data-toggle='modal'
                            data-target='#addCriteriaModal'
                            onClick={() => {
                              setcriteriaID("");
                              setcriteriaLimit("");
                              setcriteriaName("");
                            }}
                          >
                            + Add Criteria
                          </button>
                        ) : null}
                        <div
                          className={
                            styles["main-container-section"] + " row mt-5"
                          }
                        >
                          {panelCriterias.map((r) => {
                            return (
                              <div
                                className={
                                  styles["region-box"] + " col-md-3 mr-3 mb-2"
                                }
                              >
                                <div className={styles["region-box-header"]}>
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      wordBreak: "break-word",
                                      maxWidth: "50%",
                                    }}
                                  >
                                    {r.name}
                                  </p>
                                  <div className='float-right'>
                                    <span
                                      className='fa fa-pencil'
                                      style={{
                                        cursor: "pointer",
                                        color: "#e60000",
                                        fontSize: "16px",
                                      }}
                                      data-toggle='modal'
                                      data-target={"#editCriteriaModal"}
                                      onClick={() => {
                                        setcriteriaID(r._id);
                                        setcriteriaLimit(r.maxWeight);
                                        setcriteriaName(r.name);
                                      }}
                                    ></span>
                                    <span
                                      className='fa fa-trash ml-2'
                                      style={{
                                        cursor: "pointer",
                                        color: "#e60000",
                                        fontSize: "16px",
                                      }}
                                      onClick={() => {
                                        submitdeleteCriteria(r._id);
                                      }}
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p>{r.maxWeight}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='col-md-12' style={{ minHeight: "15rem" }}>
                        <span className={styles["regionTitle2"]}>Members</span>
                        {panelMainData._id ? (
                          <>
                            <input
                              type='file'
                              style={{ visibility: "hidden" }}
                              id='multipleMembers'
                              onChange={addfiledataforMembers}
                            ></input>
                            <a
                              href='/hackathon upload panel emails.xlsx'
                              download='hackathon upload panel emails'
                              target='_blank'
                            >
                              <button className='btn btn-outline-dark float-right m-2'>
                                Download Members Template
                              </button>
                            </a>
                            <button
                              type='button'
                              className='btn btn-outline-dark float-right m-2'
                              onClick={() => {
                                document
                                  .getElementById("multipleMembers")
                                  .click();
                              }}
                            >
                              Upload
                            </button>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              data-toggle='modal'
                              data-target='#addMemberModal'
                              onClick={() => {
                                setpanelMemberemail("");
                              }}
                            >
                              + Add Member
                            </button>
                          </>
                        ) : null}
                        <div
                          className={
                            styles["main-container-section"] + " row mt-5"
                          }
                        >
                          {panelMembers.map((r) => {
                            return (
                              <div
                                className={
                                  styles["region-box"] + " col-md-4 mr-3 mb-2"
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {r.email}
                                  </p>
                                  <div className='float-right'>
                                    <span
                                      className='fa fa-pencil'
                                      style={{
                                        cursor: "pointer",
                                        color: "#e60000",
                                        fontSize: "16px",
                                      }}
                                      data-toggle='modal'
                                      data-target={"#editMemberModal"}
                                      onClick={() => {
                                        setMemberid(r._id);
                                        setMemberMail(r.email);
                                        setOldMemberMail(r.email);
                                      }}
                                    ></span>
                                    <span
                                      className='fa fa-trash ml-2'
                                      style={{
                                        cursor: "pointer",
                                        color: "#e60000",
                                        fontSize: "16px",
                                      }}
                                      onClick={() => {
                                        submitdeleteMember(r._id);
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='col-md-12' style={{ minHeight: "15rem" }}>
                        <span className={styles["regionTitle2"]}>Groups</span>
                        {panelMainData.isCalculated == false ? (
                          <>
                            <input
                              type='file'
                              onChange={addfiledataforGroups}
                              id='groupsupload'
                              style={{ display: "none" }}
                            ></input>
                            <a
                              href='/hackathon groups_upload.xlsx'
                              download='hackathon groups_upload'
                              target='_blank'
                            >
                              <button className='btn btn-outline-dark float-right m-2'>
                                Download Groups Template
                              </button>
                            </a>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              onClick={() => {
                                document.getElementById("groupsupload").click();
                              }}
                            >
                              Upload Groups
                            </button>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              data-toggle='modal'
                              data-target='#addGroup'
                              onClick={() => {
                                setIsEdit(false);
                                setGroupID("");
                              }}
                            >
                              Add Group
                            </button>
                          </>
                        ) : null}
                        <div
                          className={
                            styles["main-container-section"] + " row mt-5"
                          }
                        >
                          {groups.map((r) => {
                            return (
                              <div
                                className={
                                  styles["region-box"] + " col-md-4 mr-3 mb-2"
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() => {
                                        history(
                                          "/superadmin/filtrationpanelgroup",
                                          {
                                            state: {
                                              groupID: r.group._id,
                                              panelID: panelID,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      {r.group.name}
                                    </span>
                                    {panelMainData.panelType ==
                                    "evaluation-panel" ? (
                                      <span style={{ float: "right" }}>
                                        <span
                                          className='fa fa-pencil pr-2'
                                          style={{
                                            cursor: "pointer",
                                            color: "#e60000",
                                            fontSize: "16px",
                                          }}
                                          data-toggle='modal'
                                          data-target={"#addGroup"}
                                          onClick={() => {
                                            setIsEdit(true);
                                            setGroupID(r.group._id);
                                          }}
                                        ></span>
                                        <span
                                          className='fa fa-envelope'
                                          style={{
                                            cursor: "pointer",
                                            color: "#e60000",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            sendGroupEmail(r.group._id);
                                          }}
                                        ></span>
                                      </span>
                                    ) : null}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {r.group.Link}
                                    {r.group.Link != "" &&
                                    r.group.Link != undefined ? (
                                      <div className='float-right'>
                                        {r.group.IsDisabled ? (
                                          <span
                                            className='ml-2'
                                            style={{
                                              cursor: "pointer",
                                              color: "#e60000",
                                              fontSize: "16px",
                                            }}
                                            onClick={() => {
                                              changeRoomLink(
                                                r.group._id,
                                                !r.group.IsDisabled
                                              );
                                            }}
                                          >
                                            x
                                          </span>
                                        ) : (
                                          <span
                                            className='fa fa-check ml-2'
                                            style={{
                                              cursor: "pointer",
                                              color: "#e60000",
                                              fontSize: "16px",
                                            }}
                                            onClick={() => {
                                              changeRoomLink(
                                                r.group._id,
                                                !r.group.IsDisabled
                                              );
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                    ) : null}
                                  </p>
                                  <p style={{}}>
                                    {r.members.map((l) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        {l.email}

                                        <span
                                          className='fa fa-user ml-2'
                                          style={{
                                            cursor: "pointer",
                                            color: "#e60000",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            getUserEmail(l.email);
                                          }}
                                        ></span>

                                        {panelMainData.isCalculated == false ? (
                                          <div className='float-right'>
                                            <span
                                              className='fa fa-trash ml-2'
                                              style={{
                                                cursor: "pointer",
                                                color: "#e60000",
                                                fontSize: "16px",
                                              }}
                                              onClick={() => {
                                                submitdeleteMemberfromGroup(
                                                  l._id,
                                                  r._id,
                                                  r.filtrationPanel
                                                );
                                              }}
                                            ></span>
                                          </div>
                                        ) : null}
                                      </div>
                                    ))}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {panelMainData.isCalculated ? (
                          <div
                            className='col-md-12'
                            style={{ minHeight: "15rem" }}
                          >
                            <span className={styles["regionTitle2"]}>
                              Ranks
                            </span>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              onClick={getWinners}
                            >
                              Export Winners
                            </button>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              onClick={getlosers}
                            >
                              Export Rejected
                            </button>
                            {panelRanks.map((e) => (
                              <div
                                className={
                                  styles["region-box"] + " col-md-4 mr-3 mb-2"
                                }
                              >
                                <div className=' card-success-panel-text-div'>
                                  <span className='card-success-panel-headertext'>
                                    Rank {e.rank}
                                  </span>
                                  <span className='card-success-panel-innertext'>
                                    {e.grade}
                                  </span>
                                  <span
                                    className='card-success-panel-innertext'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history("/superadmin/projectDetails", {
                                        state: { id: e.project },
                                      });
                                    }}
                                  >
                                    {e.projectName}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}{" "}
                      </div>
                    </div>
                  )}
                  <NonGroupedTable panelID={panelID} />
                  {/* <GroupedTable panelID={panelID} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FiltrationPanel;
