import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUser,
  returnSuperAdmin,
  setRegionToken,
  ssoJWTCheck,
} from "../../services/authService";
import SideMenu from "../common/sideMenu/sideMenu";
import { Navbar, Container, Nav } from "react-bootstrap";
function Hall2() {
  const [backDrop, setbackDrop] = useState(true);
  const soundRef = React.useRef();
  const history = useNavigate();
  const [IsAdmin, setIsAdmin] = useState();
  const [regionButtons, setRegionButtons] = useState(true);
  const [region, setRegion] = useState("");
  const [announcementsCount, setAnnouncementsCount] = useState(0);
  const [loadAgain, setloadAgain] = useState("");
  function playVideos() {
    // console.log("kjjkjkkj");
    var videos = document.querySelectorAll("video");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element.paused === true) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
  function playAudios() {
    // console.log("kjjkjkkj");
    var videos = document.querySelectorAll("audio");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element.paused === true) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
  useEffect(() => {
    const currentUser = getCurrentUser();
    setRegionButtons(
      currentUser.roles.includes("superAdmin") ||
        currentUser.roles.includes("panelAdmin") ||
        currentUser.roles.includes("panelMember") ||
        currentUser.roles.includes("coach") ||
        (currentUser.roles.includes("guestUser") &&
          !currentUser.roles.includes("projectMember"))
        ? true
        : false
    );
    setIsAdmin(
      currentUser.roles.includes("superAdmin") ||
        localStorage.getItem("superAdminTempJWT") != undefined
        ? "true"
        : "false"
    );
  });
  useEffect(() => {
    const currentUser = getCurrentUser();
    ssoJWTCheck().then((r) => {
      setIsAdmin(
        currentUser.roles.includes("superAdmin") ||
          localStorage.getItem("superAdminTempJWT") != undefined
          ? "true"
          : "false"
      );
      setRegion(getCurrentUser().region);
    });
  }, [IsAdmin, region, announcementsCount, loadAgain]);
  useEffect(() => {
    var heatpoints = document.getElementsByClassName("heatpoint");
    for (let index = 0; index < heatpoints.length; index++) {
      const element = heatpoints[index];
      element.setAttribute("visible", true);
    }
    playVideos();
  }, []);

  return (
    <>
      <div
        class='modal fade'
        id='infoModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-lg' role='document'>
          <div class='modal-content modal-content-info'>
            <div class='modal-body modal-info-body'>
              <img className='logo' src='/assets/hackLogo.svg'></img>
              <span className='info-header'>INSTRUCTIONS</span>
              <div className='round-div'>
                <img
                  src='/assets/instructions/1.svg'
                  className='round-img'
                ></img>
                <span className='round-text'>
                  Click and drag to explore the environment
                </span>
              </div>
              <div className='instructions-div'>
                <div className='round-div'>
                  <img
                    src='/assets/instructions/2.svg'
                    className='round-img'
                  ></img>
                  <span className='round-text'>
                    Click this icon to view the page in new tab
                  </span>
                </div>
                <div className='round-div'>
                  <img src='/assets/newpage.svg' className='round-img'></img>
                  <span className='round-text'>
                    Click this icon to enter the room
                  </span>
                </div>
              </div>
              <button
                data-dismiss='modal'
                aria-label='Close'
                className='close-btn'
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='menu'>
        <img
          src='/assets/muted.svg'
          ref={soundRef}
          style={{ cursor: "pointer" }}
          className='mr-2'
          onClick={() => {
            playAudios();

            if (!soundRef.current.src.includes("/assets/unmuted.svg"))
              soundRef.current.src = "/assets/unmuted.svg";
            else soundRef.current.src = "/assets/muted.svg";
          }}
        ></img>
        <img
          src='/assets/notifications.svg'
          style={{ cursor: "pointer" }}
          className='mr-2'
          onClick={() => {
            history("/announcments");
          }}
        ></img>
        <img
          src='/assets/info.svg'
          style={{ cursor: "pointer" }}
          className='mr-2'
          data-toggle='modal'
          data-target='#infoModal'
        ></img>
        {regionButtons === true ? (
          <div class='btn-group dropup mr-2'>
            {/* <button
              type='button'
              class=' dropdown-toggle dropdown-hall'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src='/assets/region.svg'></img>
            </button> */}

            <div class='dropdown-hall-menu dropdown-menu'>
              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("egypt");
                  window.location.reload();
                }}
              >
                {" "}
                Egypt
              </Nav.Link>

              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("india");
                  window.location.reload();
                }}
              >
                {" "}
                India
              </Nav.Link>
              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("romania");
                  window.location.reload();
                }}
              >
                Romania
              </Nav.Link>
            </div>
          </div>
        ) : null}
        {IsAdmin === "true" ? (
          <img
            src='/assets/back.svg'
            className='mr-2'
            style={{ cursor: "pointer" }}
            onClick={() => {
              returnSuperAdmin();
              history("/superadmin/home");
            }}
          ></img>
        ) : null}
      </div>
      <a-scene
        vr-mode-ui='enabled: false'
        deallocate
        renderer='gammaOutput: true;antialias: true; colorManagement: true;
        sortObjects: true; '
        cursor='rayOrigin: mouse'
        loading-screen='dotsColor: white; backgroundColor: darkred'
        gltf-model='/draco-decoder'
        foo
      >
        <a-assets timeout='10000'>
          <img id='heatPoint' src='/assets/newpage.svg' />
          <video
            id='video1'
            autoPlay
            loop='loop'
            src='/assets/video.mp4'
            muted
            preload='auto'
          ></video>
          <audio id='audio' preload='auto' loop>
            <source
              src='/assets/glb/newmodel/hallsound.mp3'
              type='audio/mp3'
            ></source>
          </audio>
          <img id='arrow' src='/assets/right-arrow.svg' />
          <img id='theme' src='assets/Hero_Image.png' />
          <img id='skyimg' src='/assets/glb/thirdroom/secondroom.png' />
        </a-assets>
        {/* <a-entity light='type: ambient; color: #efefa3;groundColor:#92928b;intensity:0.2'></a-entity> */}
        {/* <a-entity
          light='type:directional; 
                   shadowCameraVisible: false; '
          position='1 35 1'
        ></a-entity> */}
        <a-sound src='#audio' loop='true' position='0 0 0'></a-sound>
        {/* <a-entity
          id='Agenda2'
          position='-140 -7.94 55.216 -0.354'
          rotation='0 90 0'
          scale='10.5 7.5 1'
        >
          <a-image
            width='9'
            height='6.5'
            src='#theme'
            position='5.3 0 0'
          ></a-image>
        </a-entity> */}

        <a-entity
          class='listenonkey'
          id='rig'
          movement-controls='controls: touch;speed:0.5'
          position='0 2.35 0'
          rotation='0 -10 0'
        >
          <a-camera
            id='camera'
            wasd-controls-enabled='false'
            position='0 0 0'
            twoway-motion='speed: 35;nonMobileLoad:false'
          ></a-camera>
        </a-entity>

        <a-sky src='#skyimg'></a-sky>
        <a-image
          class='heatpoint'
          src='#heatPoint'
          position='-13.2 -1.7 22.455'
          rotation='0 -45  0'
          scale='3 3 1'
          cursor-inside-url='url:./hall'
        ></a-image>
        <a-image
          class='heatpoint'
          src='#heatPoint'
          position='-12.6 -2.72 -23.99'
          rotation='0 35 0'
          scale='3 3 1'
          cursor-inside-url='url:./hall4'
        ></a-image>

        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='23.77 8.447 -100'
          rotation='0 0  0'
          scale='5 5 1'
          cursor-url='url:./video1'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='96.71 9.447 -100'
          rotation='0 -45  0'
          scale='7.5 7.5 1'
          cursor-url='url:./video2'
        ></a-entity>

        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          src='#heatPoint'
          position='150 9.447 -43.907'
          rotation='0 -90 0'
          scale='7.5 7.5 1'
          cursor-url='url:./video3'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='209 15.447 58.907'
          rotation='0 -90 0'
          scale='9.5 9.5 1'
          cursor-url='url:./video4'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='150 12.447 157'
          rotation='0 -135 0'
          scale='12 12 1'
          cursor-url='url:./video5'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='23.77 8.447 100'
          rotation='0 0  0'
          scale='5 5 1'
          cursor-url='url:./video6'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-13.2 -1.7 22.455'
          rotation='0 -45  0'
          scale='4 4 1'
          cursor-inside-url='url:./hall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-12.6 -2.72 -23.99'
          rotation='0 35 0'
          scale='4.5 4 1'
          cursor-inside-url='url:./hall4'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='23.77 8.447 -100'
          rotation='0 0  0'
          scale='10 11 1'
          cursor-url='url:./video1'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          // material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='96.71 9.447 -100'
          rotation='0 -45  0'
          scale='15 15 1'
          cursor-url='url:./video2'
        ></a-entity>

        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          // material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='150 9.447 -43.907'
          rotation='0 -90 0'
          scale='15 17.5 1'
          cursor-url='url:./video3'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='209 15.447 58.907'
          rotation='0 -90 0'
          scale='20 25 1'
          cursor-url='url:./video4'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='150 12.447 157'
          rotation='0 -135 0'
          scale='20 25 1'
          cursor-url='url:./video5'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='23.77 8.447 100'
          rotation='0 0  0'
          scale='20 25 1'
          cursor-url='url:./video6'
        ></a-entity>
      </a-scene>
    </>
  );
}

export default Hall2;
