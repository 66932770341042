import React from "react";
import styles from "./Cookies.module.css";
function Cookies({
  onclickAcceptOrReject,
  onclickAccept,
  onclickManageCookies,
}) {
  return (
    <div className={styles["main-component"]}>
      <div className={styles["submain-component"]}>
        <div className={styles["submain2-component"]}>
          <span className={styles["cookies-header"]}>YOUR COOKIES!</span>
          <span className={styles["cookies-body"]}>
            We use cookies, including from our partners, to enhance and
            personalize your experience. Accept all cookies below, or select
            "Manage Cookies" to choose which cookies we can use. Reject all
            means you are rejecting all non essential cookies. Please see our{" "}
            <a
              href='/cookienotice'
              style={{ color: "#e60000", fontWeight: "bold" }}
            >
              Cookie Notice
            </a>{" "}
            for more information.
          </span>
        </div>
        <div className={styles["cookies-btn-div"]}>
          <span
            className={styles["cookie-Manage-all-btn"]}
            onClick={onclickManageCookies}
          >
            Manage Cookies
          </span>
          <div className={styles["cookies-btn-subdiv"]}>
            <span
              className={styles["cookie-reject-all-btn"]}
              onClick={onclickAccept}
            >
              Accept All
            </span>
            <span
              className={styles["cookie-reject-all-btn"] + " m-0"}
              onClick={onclickAcceptOrReject}
            >
              Reject All
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
