import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { useNavigate } from "react-router-dom";
import { SuperAdminContext } from "../../contexts/superadminContext";
import SuperAdminNavBar from "./SuperAdminNavBar";
import $ from "jquery";
import styles from "./superadminProjectsinnerPage.module.css";
function SuperAdminInnerProjectHome() {
  const [superAdmin, changeDetails] = useContext(SuperAdminContext);
  const history = useNavigate();

  const [admin, setpanelAdmin] = useState("");
  const [count, setthreshold] = useState(0);
  const [region, setregion] = useState(localStorage.getItem("region"));
  const [paneldisplayOrder, setpaneldisplayOrder] = useState(0);
  const [panelType, setpanelType] = useState("evaluation-panel");
  const [panelName, setpanelName] = useState("");
  const [panels, setPanels] = useState([]);
  useEffect(() => {
    getfiltrationPanel();
  }, []);
  const getfiltrationPanel = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/panels`, {
        region,
      })
      .then((res) => setPanels(res.data));
  };
  const submitAdd = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/addpanel`, {
        region,
        count,
        admin,
        displayOrder: paneldisplayOrder,
        panelType,
        panelName,
      })
      .then((res) => window.location.reload());
  };
  const deletePanel = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/deletePanel`, {
        _id: id,
      })
      .then((res) => window.location.reload());
  };

  return (
    <>
      <div
        className='modal fade'
        id='AddMainDetails'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add new Panel
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Panel Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setpanelName(el.target.value);
                  }}
                  value={panelName}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Admin Email:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setpanelAdmin(el.target.value);
                  }}
                  value={admin}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Max number of Projects:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setthreshold(el.target.value);
                  }}
                  value={count}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Panel Round:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setpaneldisplayOrder(el.target.value);
                  }}
                  value={paneldisplayOrder}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Panel Type:</p>
                <select
                  style={{ borderRadius: "50px", width: "100%" }}
                  id='sel1'
                  onChange={(el) => {
                    setpanelType(el.target.value);
                  }}
                >
                  <option>evaluation-panel</option>
                  <option>filtration-panel</option>
                </select>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={submitAdd}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className=' nav-button-padding'></div>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div className={" col-md-10"}>
              <div className={styles["main-container"] + " row mt-5"}>
                <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                  <span
                    className={styles["regionTitle"]}
                    onClick={() => {
                      history("/superadmin/project", {
                        state: {
                          region: superAdmin.region,
                        },
                      });
                    }}
                  >
                    Projects
                  </span>
                </div>
                {panels.length > 0
                  ? panels.map((el) => (
                      <div
                        className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                      >
                        <span
                          className={styles["regionTitle"]}
                          onClick={() => {
                            history("/superadmin/filtrationPanel", {
                              state: {
                                region: superAdmin.region,
                                panelID: el._id,
                              },
                            });
                          }}
                        >
                          {el.name}
                        </span>
                        <span
                          className={styles["regionTitle"] + " fa fa-trash"}
                          style={{
                            color: "#e60000",
                            cursor: "pointer",
                            fontSize: "24px",
                          }}
                          onClick={() => {
                            deletePanel(el._id);
                          }}
                        ></span>
                      </div>
                    ))
                  : null}{" "}
                <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                  <span
                    className={styles["regionTitle"]}
                    data-toggle='modal'
                    data-target={"#AddMainDetails"}
                    onClick={() => {
                      setpanelAdmin("");
                      setthreshold("");
                      setpaneldisplayOrder("0");
                      setpanelType("evaluation-panel");
                      setpanelName("");
                    }}
                  >
                    Add New Panel
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminInnerProjectHome;
