import React, { useState } from "react";

const GuardianContext = React.createContext([() => {}]);

const GuardianProvider = ({ children }) => {
  const [guardianDetails, setGuardianDetails] = useState({
    guardianName: "",
    guardianAddress: "",
    guardianId: "",
    guardianIdIssuance: "",
    nameid: "",
    address: "",
    id: "",
    idIssuance: "",
    isAdult: true,
  });

  const changeGuardianDetails = (data) => {
    setGuardianDetails(data);
  };
  return (
    <GuardianContext.Provider value={[guardianDetails, changeGuardianDetails]}>
      {children}
    </GuardianContext.Provider>
  );
};

export { GuardianProvider, GuardianContext };
