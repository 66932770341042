import Axios from "axios";

const BASEURL = `${process.env.REACT_APP_SERVER_URL}panelvalues`;

export const getNonGrouped = (panelId) => {
  return Axios.post(`${BASEURL}/getNonGropedGrade`, { panelId });
};
export const getNonGroupedbycriteria = (panelId, criteria) => {
  return Axios.post(`${BASEURL}/getNonGroupedbycriteria`, {
    panelId,
    criteria,
  });
};
export const getGrouped = (panelId) => {
  return Axios.post(`${BASEURL}/getGropedGrade`, { panelId });
};
export const getNonGroupedGroup = (groupId) => {
  return Axios.post(`${BASEURL}/getNonGroupedGroupGrades`, { groupId });
};

export const getGroupedGroup = (groupId) => {
  return Axios.post(`${BASEURL}/getGroupedGroupGrades`, { groupId });
};
