import "./index.css";
import React from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import TableToExcel from "@linways/table-to-excel";
import styles from "../../superadmin/superadminProjectsinnerPage.module.css";
import axios from "axios";
import { useState } from "react";
import { getNonGroupedbycriteria } from "../../../apis/adminPanel";
function NonGrouped({ data, final, panelID }) {
  function compare(a, b) {
    var nameA = a.criteriaName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.criteriaName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const [criterias, setCriterias] = useState([]);
  async function getdata() {
    let crit = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}filtration/criteria`,
      {
        id: panelID,
      }
    );
    let wb = XLSX.utils.book_new();
    var data = [];
    var ws = [];
    for (let index = 0; index < crit.data.length; index++) {
      const element = crit.data[index];
      let d = await getNonGroupedbycriteria(panelID, element._id);
      data = d.data.map((r) => {
        return {
          projectName: r.projectName,
          judge: r.email,
          value: r.value,
        };
      });
      let ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        element.name.slice(0, 31).replace("/", " ")
      );
      data = [];
    }

    return wb;
  }
  function exportTable() {
    let w = getdata().then((r) => {
      XLSX.writeFile(r, "SuperAdmin-NonGrouped-Table.xlsx");
    });

    // var ws_data = criterias.map((item, index) => {
    //   getNonGroupedbycriteria(panelID, item._id).then((l) => {
    //     l.data
    //       .map((r) => {
    //         let x = data.push({
    //           projectName: r.projectName,
    //           judge: r.email,
    //           value: r.value,
    //         });
    //         return x;
    //       })
    //       .then((r) => {
    //         ws = XLSX.utils.json_to_sheet(r);
    //       });
    //   });
    //   XLSX.utils.boo.book_append_sheet(wb, ws, item.name.slice(0, 31));
    //   data = [];
    // });

    // .then((r) => {
    //   XLSX.writeFile(wb, "SuperAdmin-NonGrouped-Table.xlsx");
    // });
    // console.log(document.getElementsByClassName("exportedTable")[0]);
    //

    // TableToExcel.convert(document.getElementsByClassName("exportedTable")[0], {
    //   name: "SuperAdmin-NonGrouped-Table.xlsx",
    //   sheet: {
    //     name: "Sheet 1",
    //   },
    // });
  }
  return (
    <div className='col-md-12'>
      <span className={styles["regionTitle2"]}>Panel Results/Judge Email</span>
      <button
        type='button'
        className='btn btn-outline-dark float-right'
        onClick={exportTable}
      >
        Export
      </button>
      {/* <div
        className='divToConvert'
        style={{ position: "absolute", zIndex: "-1" }}
      >
        <table
          className='table exportedTable'
          style={{ width: "0", height: "0", overflow: "hidden" }}
        >
          <tbody className='col-md-12'>
            <tr
              style={{
                display: "flex",
                backgroundColor: "red",
                color: "white",
              }}
            >
              {criterias.map((item, index) => {
                return (
                  <td
                    className='col-1'
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      padding: "20px",
                    }}
                    key={index}
                  >
                    {item}
                  </td>
                );
              })}
            </tr>
            {final.map(function (row, i) {
              return Object.values(row).map((l) => {
                let temp = l.sort(compare);
                return (
                  <tr style={{ display: "flex" }}>
                    {temp.map((cell) => {
                      return (
                        <td className='col-1'>
                          <span>
                            name : {cell.projectName}|Judge:{cell.email}|value :
                            {cell.value}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}
export default NonGrouped;
