import Axios from "axios";

const BASEURL = `${process.env.REACT_APP_SERVER_URL}project`;

export const submitProject = (obj, RecaptchaToken) => {
  return Axios.post(`${BASEURL}/submitproject`, obj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const submitParticipant = (obj, RecaptchaToken) => {
  return Axios.post(`${BASEURL}/submitpart`, obj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// export const submitProject = (obj) =>
//   Axios.post(`${BASEURL}/submitproject`, obj)

// export const submitParticipant = (obj) =>
//   Axios.post(`${BASEURL}/submitpart`, obj);

export const getProject = (id) => Axios.post(`${BASEURL}/getbyid`, { id });
