import React, { useState, useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import "./Accordion.css";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccordionComponent = ({ title, description, list, index }) => {
  const [expand, setExpansion] = useState(-1);

  const handleExpansion = (expandedItem) => {
    if (expand === expandedItem) {
      setExpansion(-1);
    } else {
      setExpansion(expandedItem);
    }
  };
  function makeUrlsClickable(text) {
    const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
    const transformedText = text.replace(
      urlRegex,
      '<a href="$&" target="_blank"  style="color:var(--text-secondary);">$&</a>'
    );
    return transformedText;
  }
  return (
    <Accordion>
      <Accordion.Item
        eventKey={index}
        className=' mb-4'
        onClick={() => handleExpansion(index)}
      >
        <Accordion.Header className='row'>
          <span className='pull-left ml-3 question-content'>{title}</span>
          <span>
            {expand === index ? (
              <FontAwesomeIcon icon={faAngleUp} className='pull-right mr-4' />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} className='pull-right mr-4' />
            )}
          </span>
        </Accordion.Header>
        <Accordion.Body>
          {list ? (
            <ul>
              {list.map((r) => (
                <li className=' ml-4 mt-1'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: makeUrlsClickable(r),
                    }}
                    style={{ wordBreak: "normal" }}
                  ></div>
                </li>
              ))}
            </ul>
          ) : (
            <p className=' ml-4' style={{ wordBreak: "normal" }}>
              {description}
            </p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionComponent;
