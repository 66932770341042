import axios from "axios";
import React, { useEffect, useState } from "react";
import SuperAdminNavBar from "./SuperAdminNavBar";
import * as XLSX from "xlsx";
import styles from "./superadminProjectsinnerPage.module.css";
function RevokeRequests() {
  const [data, setdata] = useState([]);
  const [loadBytes, setloadBytes] = useState(false);
  useEffect(() => {
    axios.put(`${process.env.REACT_APP_SERVER_URL}user/revoke`).then((res) => {
      setdata(res.data);
      setloadBytes(true);
    });
  }, []);
  const exportrevokeRequestsToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      data.map((el) => {
        return { email: el.email, date: new Date(el.date).toString() };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Revoke_Requests_Reports" + new Date() + ".xlsx");
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>

          <div className={" col-md-10"}>
            <button
              className='btn btn-outline-dark float-right '
              style={{
                marginRight: "6%",
                marginTop: "2rem",
                marginBottom: "1.5rem",
              }}
              onClick={() => {
                exportrevokeRequestsToExcel();
              }}
            >
              <span className='fas fa-file-download mr-2'></span> Export Report
            </button>

            <div className={styles["table-main-container"]}>
              {loadBytes ? (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <table className='table '>
                    <thead>
                      <tr>
                        <th scope='col' style={{ width: "20%" }}>
                          Email
                        </th>
                        <th scope='col' style={{ width: "20%" }}>
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((el) => {
                        return (
                          <tr>
                            <td>{el.email}</td>
                            <td>{new Date(el.date).toString()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevokeRequests;
