import React from "react";
import styles from "./ThirdSection.module.css";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
const judges = [
  {
    img: "/jury/Gary.webp",
    name: "Gary Adey",
    title: "_VO<b>IS</b> Director",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    img: "/jury/Willie.webp",
    name: "Willie Stegmann",
    title: "CIO Corporate IT Services",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    img: "/jury/Sara.webp",
    name: "Sara Heuer",
    title: "Head of Corporate Functions",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    img: "/jury/Emma-D.webp",
    name: "Emma Derby",
    title: "Head of HR _VO<b>IS</b>",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    img: "/jury/Viji.webp",
    name: "Vijayalaxmi Narayan",
    title: "Head of Finance _VO<b>IS</b>",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    img: "/jury/Mohamed_Sami.webp",
    name: "Mohamed Sami",
    title: "Head of Technology _VO<b>IS</b>",
    bioShortened:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    fullBio:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];
function ThirdSection() {
  const [page, setPage] = React.useState(0);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 50,
  };
  return (
    <div className={styles["third-section-div"]}>
      <div className={styles["frame-top-design"]}>
        <div className={styles["lefttopframe"]}></div>
        <div className={styles["righttopframe"]}></div>
      </div>

      <div className={styles["third-page-main-content"]}>
        <span className={styles["third-section-main-title"] + " col-md-12"}>
          Your hackathon judges
        </span>
        <div className={styles["main-d"] + " row"}>
          {isMobile ? (
            <>
              <div
                className={styles["third-section-details-box"]}
                style={{ margin: "24px 5vw 0px 0px" }}
              >
                <img
                  src={judges[page * 2].img}
                  className={styles["third-section-details-box-img"]}
                ></img>

                <div className={styles["naming-tags"]}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    className={styles["third-section-details-beforehover"]}
                    id='background'
                  >
                    <path
                      d='M0.5 0.5H167.5V124C167.5 126.485 165.485 128.5 163 128.5H5C2.51472 128.5 0.5 126.485 0.5 124V0.5Z'
                      fill='#E60000'
                      stroke='#E60000'
                    />
                  </svg>

                  <div className={styles["third-section-details-box-details"]}>
                    <div
                      className={
                        styles["third-section-details-box-name"] + " mb-2"
                      }
                    >
                      {judges[page * 2].name}
                    </div>
                    <div
                      className={
                        styles["third-section-details-box-title"] + " mb-2"
                      }
                      dangerouslySetInnerHTML={{
                        __html: judges[page * 2].title,
                      }}
                    ></div>
                    <div
                      className={styles["third-section-details-box-title"]}
                      dangerouslySetInnerHTML={{
                        __html: judges[page * 2].bioShortened,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className={styles["third-section-details-box"]}
                style={{ margin: "24px 0 0 0" }}
              >
                <img
                  src={judges[page * 2 + 1].img}
                  className={styles["third-section-details-box-img"]}
                ></img>

                <div className={styles["naming-tags"]}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    className={styles["third-section-details-beforehover"]}
                    id='background'
                  >
                    <path
                      d='M0.5 0.5H167.5V124C167.5 126.485 165.485 128.5 163 128.5H5C2.51472 128.5 0.5 126.485 0.5 124V0.5Z'
                      fill='#E60000'
                      stroke='#E60000'
                    />
                  </svg>

                  <div className={styles["third-section-details-box-details"]}>
                    <div
                      className={
                        styles["third-section-details-box-name"] + " mb-2"
                      }
                    >
                      {judges[page * 2 + 1].name}
                    </div>
                    <div
                      className={
                        styles["third-section-details-box-title"] + " mb-2"
                      }
                      dangerouslySetInnerHTML={{
                        __html: judges[page * 2 + 1].title,
                      }}
                    ></div>
                    <div
                      className={styles["third-section-details-box-title"]}
                      dangerouslySetInnerHTML={{
                        __html: judges[page * 2 + 1].bioShortened,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            judges.map((el, i) => {
              return (
                <div
                  className={
                    (i + 1) % 4 == 0
                      ? styles["third-section-details-boxby4"]
                      : styles["third-section-details-box"]
                  }
                >
                  <img
                    src={el.img}
                    className={styles["third-section-details-box-img"]}
                  ></img>

                  <div className={styles["naming-tags"]}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      // width='280'
                      // height='120'
                      viewBox='0 0 280 75'
                      fill='none'
                      className={styles["third-section-details-beforehover"]}
                      id='background'
                    >
                      <path
                        d='M0.5 90V0.586031L279.5 45.0679V90C279.5 92.4853 277.485 94.5 275 94.5H5C2.51472 94.5 0.5 92.4853 0.5 90Z'
                        fill='#E60000'
                        stroke='#E60000'
                      />
                    </svg>
                    <div
                      className={styles["third-section-details-box-details"]}
                    >
                      <div className={styles["third-section-details-box-name"]}>
                        {el.name}
                      </div>
                      <div
                        className={styles["third-section-details-box-title"]}
                        dangerouslySetInnerHTML={{ __html: el.title }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className={styles["frame-bottom-design"]}>
          <div className={styles["leftbottomframe"]}></div>

          <div className={styles["slick-ss"]}>
            {isMobile ? (
              <div
                className={styles["third-section-main-title"] + " col-md-12"}
              >
                {page == 0 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(0);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#E60000'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(0);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#8F1818'
                    />
                  </svg>
                )}

                {page == 1 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(1);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#E60000'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(1);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#8F1818'
                    />
                  </svg>
                )}
                {page == 2 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(2);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#E60000'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    className='mr-2'
                    onClick={() => {
                      setPage(2);
                    }}
                  >
                    <path
                      d='M3.87868 1.12132C5.05025 -0.0502538 6.94975 -0.0502526 8.12132 1.12132L10.8787 3.87868C12.0503 5.05025 12.0503 6.94975 10.8787 8.12132L8.12132 10.8787C6.94975 12.0503 5.05025 12.0503 3.87868 10.8787L1.12132 8.12132C-0.0502538 6.94975 -0.0502526 5.05025 1.12132 3.87868L3.87868 1.12132Z'
                      fill='#8F1818'
                    />
                  </svg>
                )}
              </div>
            ) : null}
            {isMobile ? (
              <div className={styles["sloganone-div"]}>
                ///// 01011111 01010110 01001111 01001110 /////
              </div>
            ) : (
              <div className={styles["sloganone-div"]}>
                ///// 01011111 01010110 01001111 01001001 01010011 00100000
                01001000 01000001 01000011 01001011 01000001 01010100 01001000
                01001111 01001110 /////
              </div>
            )}
          </div>
          <div className={styles["rightbottomframe"]}></div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
