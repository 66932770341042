import React, { useState } from "react";

const MainUserContext = React.createContext([() => {}]);

const MainUserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",

    email: "",
    phoneNumber: "",

    uploadCv: "",
    password: "",
    passwordConfirm: "",
    region: "",
    CV: "",

    isAdmin: false,
    birthDate: "2004-01-01",
    participatedBefore: false,
    sameIdea: false,
    won: false,
    place: "",
    hackathonName: "",
    ideaPreviousName: "",
  });

  const changeDetails = (data) => {
    setUserDetails(data);
  };
  return (
    <MainUserContext.Provider value={[userDetails, changeDetails]}>
      {children}
    </MainUserContext.Provider>
  );
};

export { MainUserContext, MainUserProvider };
