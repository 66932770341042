import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import queryString from "query-string";
import "./chatLogin.css";
import Select from "react-select";
import Axios from "axios";
import LiveChat from "../liveChat/liveChat";
import auth from "../../../../services/authService";
import LoggedNavBar from "../../../common/navBar/loggedNav";
import SideMenu from "../../../common/sideMenu/sideMenu";
// import { getCurrentUser } from "../../../services/authService";
export default function SignIn({ location }) {
  const [rooms, setRooms] = useState("");
  const [room, setRoom] = useState("");
  const [joinOldRoom, setjoinOldRoom] = useState("");
  const [btnClicked, setbtnClicked] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(true);
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    const { joinroom } = queryString.parse(location.search);
    setjoinOldRoom(joinroom);
    Axios.get(`${process.env.REACT_APP_SERVER_URL}chat/`).then((res) => {
      setRooms(
        res.data.map((el) => {
          // console.log(el.roomCount > 2);
          return {
            value: el.roomName,
            label: el.roomName,
          };
        })
      );
    });
  }, []);
  function handleRoomNameChange(option) {
    setRoom(option.value);
    setbtnDisabled(false);
  }
  function handleRoomNameintB(e) {
    setRoom(e.target.value);
    setbtnDisabled(false);
  }

  return btnClicked ? (
    <LiveChat
      nickname={auth.getCurrentUser().preferred_username.split(".")[0]}
      chatroom={room}
      event={localStorage.getItem("selectedEventName")}
    />
  ) : (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0", justifyContent: "center" }}
          >
            <div className='asc-joinOuterContainer'>
              <div className='asc-joinInnerContainer'>
                <span className='chat-login-header'>
                  {joinOldRoom === "false"
                    ? "CREATE CHAT ROOM"
                    : "JOIN CHAT ROOM"}
                </span>
                <div
                  className='btn-width'
                  style={{
                    width: "25.5rem",
                    margin: "0 1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className='chat-login-room-name'>
                    {joinOldRoom === "false" ? "Room name" : "Select room"}
                  </span>
                  {joinOldRoom === "false" ? (
                    <input
                      className='asc-joinInput mt-20 form-control'
                      type='text'
                      onChange={handleRoomNameintB}
                    />
                  ) : (
                    <Select
                      className='asc-ddl bounce-in-top ddl-SL-z-index mt-3 w-30 h-20'
                      onChange={handleRoomNameChange}
                      options={rooms}
                    />
                  )}{" "}
                  <button
                    className='btn   asc-knowwhats-going-on-btn mt-4 mb-4'
                    disabled={btnDisabled}
                    onClick={() => {
                      setbtnClicked(true);
                    }}
                  >
                    Know what's going on !!
                  </button>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
