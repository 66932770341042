import "./projectDetailsPreview.css";
import React, { useContext, useEffect } from "react";
import { ProjectContext } from "../../../../contexts/projectContext";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import { ConsentContext } from "../../../../contexts/consentContext";
import Stepper from "../stepper/stepper";
import { useNavigate } from "react-router-dom";
import { verifyParticipant } from "../../../../apis/register";
import { getProject } from "../../../../apis/project";
import ReCAPTCHA from "react-google-recaptcha";
import { GuardianContext } from "../../../../contexts/guardianContext";

export default function ProjectDetailsPreview({ setRecabtcha, handleSubmit }) {
  const [projectDetails, changeDetails] = React.useContext(ProjectContext);
  const [user, setUser] = React.useContext(MainUserContext);
  const [guardianDetails, changeGuardianContext] = useContext(GuardianContext);

  const [consentDetails, consentChangeDetails] =
    React.useContext(ConsentContext);
  const [goBack, setGoBack] = React.useState(false);
  const [verifyStep, setVerifyStep] = React.useState("recaptcha");
  const [alreadyPart, setAlreadyPart] = React.useState();
  const [sameIdea, setSameIdea] = React.useState();
  const [winning, setWinning] = React.useState();
  const [place, setPlace] = React.useState("Not Applicable");
  const [hackathonName, setHackathonName] = React.useState();
  const [ideaBeforeName, setIdeaBeforeName] = React.useState();
  const onChangeRecaptcha = (value) => {
    //
    setRecabtcha(value);
    setVerifyStep("submit");
  };

  useEffect(() => {
    convertDocument();
  });
  function convertDocument() {
    verifyParticipant(user.email).then((res2) => {
      getProject(res2.data).then((res) => {
        let temp = {
          projectName: res.data.name,
          projectDesc: res.data.description,
          ideaTechs: res.data.technology,
          ideaTypes: res.data.type,
          projectVideo: res.data.videoLink,
          teamEmails: res.data.teamEmails,
          region: res.data.country,
        };
        changeDetails(temp);
      });
    });
  }
  const handleSubmitFunction = (values) => {
    let partObj = {
      name: user.name,
      // nameNationalId: guardianDetails.nameid,
      // address: guardianDetails.address,
      email: user.email,
      phoneNumber: user.phoneNumber,
      // nationalId: guardianDetails.id,
      // nationalIdIssuance: guardianDetails.idIssuance,
      password: user.password,
      CV: user.CV,
      isAdmin: user.isAdmin,
      birthDate: user.birthDate,
      participatedBefore: alreadyPart,
      sameIdea: sameIdea,
      won: winning,
      place: place,
      hackathonName: hackathonName,
      ideaPreviousName: ideaBeforeName,
    };

    setUser({
      ...user,
      participatedBefore: alreadyPart,
      sameIdea: sameIdea,
      won: winning,
      place: place,
    });
    handleSubmit({
      part: partObj,
    });
  };
  return (
    <div className='user-details-container row m-0'>
      <div className='col-md-3 mr-5 p-0'>
        <Stepper
          BackStep={goBack}
          next={handleSubmitFunction}
          setRecabtcha={setRecabtcha}
        />
      </div>
      <div className='col-md-8 user-details-alignment scrolled'>
        <h3 className='title'>Submit Your Pitch</h3>

        <div className=' main-user-form-body-container project-details-form '>
          <div className='row'>
            <div className='form-group col-md-6  '>
              <label
                htmlFor='projectName'
                className='label-format project-form-label'
              >
                Idea Name
              </label>
              <p className='projectDetails-data'>
                {projectDetails.projectName}
              </p>
            </div>
            <div className='form-group col-md-6 '>
              <label
                htmlFor='projectDesc'
                className='label-format project-form-label'
              >
                Description
              </label>
              <p className='projectDetails-data'>
                {projectDetails.projectDesc}
              </p>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='form-group col-md-6'>
              <label
                htmlFor='ideaType'
                className='label-format project-form-label'
              >
                Sector
              </label>
              <div className='container-center'>
                <div className='tags-input-container'>
                  <div className='idea-types-tags'>
                    {projectDetails.ideaTypes &&
                      projectDetails.ideaTypes.map((idea, index) => {
                        return (
                          <span className='tag' key={index}>
                            <span className='content'>{idea}</span>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className='form-group col-md-6'>
              <label
                htmlFor='ideaTech'
                className='label-format project-form-label'
              >
                Technologies
              </label>
              <div className='container-center'>
                <div className='tags-input-container'>
                  <div className='idea-techs-tags'>
                    {projectDetails.ideaTechs &&
                      projectDetails.ideaTechs.map((tech, index) => {
                        return (
                          <span className='tag' key={index}>
                            <span className='content'>{tech}</span>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='form-group col-md-12'>
              <div className='col-md-12 col-sm-12  pl-0'>
                <form>
                  <div className='form-group  '>
                    <h3 htmlFor='projectname' className='label-format'>
                      Presentation Video
                    </h3>
                    {projectDetails.projectVideo ? (
                      <div className=''>
                        <a
                          href={projectDetails.projectVideo}
                          class='text-danger'
                          download
                        >
                          <i className='fa fa-file text-danger pr-1'></i>{" "}
                          {projectDetails.projectVideo.substring(
                            projectDetails.projectVideo.lastIndexOf("/") + 1
                          )}
                        </a>
                      </div>
                    ) : (
                      <p className='text-danger'>
                        There is no video provided with this project
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='form-group col-md-12'>
              <label
                htmlFor='emails'
                className='label-format project-form-label'
              >
                Team Members
              </label>
              <span className='hint '>
                {projectDetails.teamEmails
                  ? projectDetails.teamEmails.map((el, i) => (
                      <li className='col-12'>
                        <i class='far fa-user pr-1'></i>
                        {el}
                      </li>
                    ))
                  : ""}
              </span>
              <br />

              <div className='container-center'></div>
            </div>
          </div>
          <div className='form-group col-md-12 mt-5 pl-0'>
            <label htmlFor='name' className='full-name label-format'>
              Have you participated in a previous Hackathon?
              <span className='required'> *</span>
              <span className='required ml-1'> (required)</span>
            </label>
            <div className='boolean-div'>
              <div
                class='form-check '
                onChange={(e) => {
                  setAlreadyPart(true);
                }}
              >
                <label className='full-name label-format'>
                  <input
                    type='radio'
                    class='form-check-input'
                    name='optradio'
                    value={true}
                  />
                  Yes
                </label>
              </div>
              <div
                class='form-check ml-5'
                onChange={(e) => {
                  setAlreadyPart(false);
                  setSameIdea(undefined);
                  setWinning(undefined);
                  setPlace("Not Applicable");

                  setIdeaBeforeName(undefined);
                  setHackathonName(undefined);
                }}
              >
                <label className='full-name label-format'>
                  <input
                    type='radio'
                    class='form-check-input'
                    name='optradio'
                    value={false}
                    style={{ color: "#e60000" }}
                  />
                  No
                </label>
              </div>
            </div>
          </div>

          {alreadyPart ? (
            <>
              <div className='row mt-5 pl-0'>
                <div className='form-group col-md-6 '>
                  <label
                    htmlFor='hackathonName'
                    className='label-format project-form-label'
                  >
                    Please write the Hackathon's name
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    value={hackathonName}
                    type='text'
                    className='text-box'
                    id='hackathonName'
                    name='hackathonName'
                    onChange={(e) => setHackathonName(e.target.value)}
                  />
                </div>
                <div className='form-group col-md-6 '>
                  <label htmlFor='name' className='full-name label-format'>
                    Have you submitted the same idea?
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <div className='boolean-div'>
                    <div
                      class='form-check '
                      onChange={(e) => {
                        setSameIdea(true);
                        setIdeaBeforeName(undefined);
                        setWinning(undefined);
                        setPlace("Not Applicable");
                      }}
                    >
                      <label className='full-name label-format'>
                        <input
                          type='radio'
                          class='form-check-input'
                          name='optradio2'
                          value={true}
                        />
                        Yes
                      </label>
                    </div>
                    <div
                      class='form-check ml-5'
                      onChange={(e) => {
                        setSameIdea(false);
                      }}
                    >
                      <label className='full-name label-format'>
                        <input
                          type='radio'
                          class='form-check-input'
                          name='optradio2'
                          value={false}
                          style={{ color: "#e60000" }}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {sameIdea == false ? (
                <>
                  <div className='row mt-5 pl-0'>
                    <div className='form-group col-md-6  '>
                      <label
                        htmlFor='ideaBeforeName'
                        className='label-format project-form-label'
                      >
                        Please write the idea's name
                        <span className='required'> *</span>
                        <span className='required ml-1'> (required)</span>
                      </label>
                      <input
                        value={ideaBeforeName}
                        type='text'
                        className='text-box'
                        id='ideaBeforeName'
                        name='ideaBeforeName'
                        onChange={(e) => setIdeaBeforeName(e.target.value)}
                      />
                    </div>
                    <div className='form-group col-md-6 '>
                      <label htmlFor='name' className='full-name label-format'>
                        Have you won a previous Hackathon?
                        <span className='required'> *</span>
                        <span className='required ml-1'> (required)</span>
                      </label>
                      <div className='boolean-div'>
                        <div
                          class='form-check '
                          onChange={(e) => {
                            setWinning(true);
                          }}
                        >
                          <label className='full-name label-format'>
                            <input
                              type='radio'
                              class='form-check-input'
                              name='optradio3'
                              value={true}
                            />
                            Yes
                          </label>
                        </div>
                        <div
                          class='form-check ml-5'
                          onChange={(e) => {
                            setWinning(false);
                          }}
                        >
                          <label className='full-name label-format'>
                            <input
                              type='radio'
                              class='form-check-input'
                              name='optradio3'
                              value={false}
                              style={{ color: "#e60000" }}
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group input-width-100 col-md-6 pl-0 mt-5'>
                    <label htmlFor='name' className='full-name label-format'>
                      If you have won a previous Hackathon, which place did you
                      get?
                      <span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <select
                      class='form-control'
                      id='sel1'
                      value={place}
                      onChange={(e) => {
                        setPlace(e.target.value);
                      }}
                    >
                      <option>Not Applicable</option>
                      <option>1st Place</option>
                      <option>2nd Place</option>
                      <option>3rd Place</option>
                      <option>4th Place</option>
                      <option>5th Place</option>
                      <option>6th Place</option>
                      <option>7th Place</option>
                      <option>8th Place</option>
                      <option>9th Place</option>

                      <option>10th Place</option>
                    </select>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          <div className='btn-next-container'>
            <div
              className='back-btn-container'
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              style={{ visibility: "visible" }}
            >
              <img src='/assets/back-arrow.svg'></img>
              <button
                type='button'
                className='back-btn'
                onClick={() => setGoBack(true)}
              >
                BACK
              </button>
            </div>
            {verifyStep === "recaptcha" ? (
              <div
                className='reCaptcha-container'
                style={{ height: "55px", width: "144px" }}
              >
                <ReCAPTCHA
                  sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                  theme='dark'
                  onChange={(value) => onChangeRecaptcha(value)}
                />
              </div>
            ) : (
              <button
                // type='submit'
                disabled={
                  alreadyPart == undefined ||
                  (alreadyPart == true &&
                    (hackathonName == undefined ||
                      sameIdea == undefined ||
                      (sameIdea == false &&
                        (ideaBeforeName == undefined || winning == undefined))))
                }
                onClick={handleSubmitFunction}
                type='button'
                className={
                  alreadyPart == undefined ||
                  (alreadyPart == true &&
                    (hackathonName == undefined ||
                      sameIdea == undefined ||
                      (sameIdea == false &&
                        (ideaBeforeName == undefined || winning == undefined))))
                    ? "next-btn-disabled"
                    : "next-btn-egypt"
                }
              >
                SUBMIT
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
