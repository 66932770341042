import { useEffect, useCallback, useState, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Modal from "../Modal/Modal";
import classes from "./RevokeUser.module.css";

const apiUri = process.env.REACT_APP_SERVER_URL + "usermanagement/";

const RevokeUser = (props) => {
  const [userToDeleteDetails, setUserToDeleteDetails] = useState();
  const [newAdmin, setNewAdmin] = useState();
  const [confirmNewAdmin, setConfirmNewAdmin] = useState(false);
  const [confirmDeleteProject, setConfirmDeleteProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectedUserEmailToDelete = props.selectedUser;

  const fetchSelectedUser = useCallback(async () => {
    const response = await axios.post(apiUri + "GetUser", {
      email: selectedUserEmailToDelete,
    });
    return response.data;
  }, [selectedUserEmailToDelete]);

  const pickNewTeamAdminHandler = (event) => {
    const newAdmin = event.target.value;
    if (newAdmin) {
      setNewAdmin(newAdmin);
      setConfirmNewAdmin(true);
    } else {
      setConfirmNewAdmin(false);
    }
  };

  const confirmDeleteProjectHandler = (event) => {
    setConfirmDeleteProject(event.target.checked);
  };

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const fetchedUser = await fetchSelectedUser();
      setUserToDeleteDetails(fetchedUser);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [fetchSelectedUser]);

  const sendRevokeUser = async (revokeObject) => {
    setIsLoading(true);

    try {
      const request = await axios.post(apiUri + "revokeUser", revokeObject);
      const deletedUser = await request.data;
      setIsLoading(false);
      if (deletedUser.deletedUser) {
        toast("User " + deletedUser.deletedUser + " Revoked Successfully!", {
          autoClose: 3000,
        });
      }
      if (deletedUser.oldAdmin) {
        toast(
          "User " +
            deletedUser.oldAdmin +
            " Revoked Successfully and the new Admin Is " +
            deletedUser.newAdmin,
          {
            autoClose: 3000,
          }
        );
      }
      props.showDeleteModalHandler();
      props.fetchUsers();
    } catch (error) {
      toast("Error while revoke user!");
    }
  };

  const revokeUserObject = async () => {
    let revokeObjectRequest = {};
    if (userToDeleteDetails.existing === true) {
      revokeObjectRequest = {
        isOnlyUser: true,
        email: userToDeleteDetails.user.email,
      };
    }

    if (userToDeleteDetails.isAdmin === false) {
      revokeObjectRequest = {
        isAdmin: false,
        email: userToDeleteDetails.user.email,
      };
    }

    if (userToDeleteDetails.isAdmin === true) {
      if (userToDeleteDetails.projectTeamMembers.length > 0) {
        revokeObjectRequest = {
          isAdmin: true,
          teamMembers: userToDeleteDetails.projectTeamMembers,
          oldAdminEmail: selectedUserEmailToDelete,
          newAdminEmail: newAdmin,
        };
      } else {
        revokeObjectRequest = {
          isAdmin: true,
          teamMembers: userToDeleteDetails.projectTeamMembers,
          adminEmail: selectedUserEmailToDelete,
          projectId: userToDeleteDetails.project._id,
        };
      }
    }
    await sendRevokeUser(revokeObjectRequest);
    // console.log(revokeObjectRequest);
  };

  let content = "";

  if (isLoading) {
    content = <div className="col-md-12">Is Loading...</div>;
  }

  if (!isLoading && userToDeleteDetails) {
    if (
      userToDeleteDetails.existing === true ||
      userToDeleteDetails.isAdmin === false
    ) {
      content = (
        <Fragment>
          <span>
            Revoke{" "}
            <span className="text-danger font-weight-bold">
              {userToDeleteDetails.user.email}?
            </span>
          </span>
          <div className="clearfix"></div>
          <div className="float-right mt-3">
            <button className="btn btn-info" onClick={revokeUserObject}>
              Revoke
            </button>
            <button
              className="btn btn-danger ml-2"
              onClick={props.showDeleteModalHandler}
            >
              Close
            </button>
          </div>
        </Fragment>
      );
    }

    if (userToDeleteDetails.isAdmin === true) {
      if (userToDeleteDetails.projectTeamMembers.length > 0) {
        content = (
          <Fragment>
            <div>
              <span>
                Revoke{" "}
                <span className="text-danger font-weight-bold">
                  {selectedUserEmailToDelete}{" "}
                </span>{" "}
                and select new team leader for project{" "}
                <span className="text-danger font-weight-bold">
                  {userToDeleteDetails.project.name}
                </span>
              </span>
            </div>
            <label className="text-info">Team Members : </label>{" "}
            <select
              onChange={pickNewTeamAdminHandler}
              className={"mt-2 mb-2 " + classes.selectTeamMembers}
              aria-label=".form-select-sm example"
            >
              <option value="">Select New Team Leader</option>
              {userToDeleteDetails.projectTeamMembers.map((teamMember) => (
                <option key={teamMember.id} value={teamMember.email}>
                  {teamMember.email}
                </option>
              ))}
            </select>
            {confirmNewAdmin && (
              <div>
                <strong>
                  Are you sure to pick{" "}
                  <span className="text-danger">{newAdmin}</span> as a team
                  leader ?
                </strong>
              </div>
            )}
            <div className="clearfix"></div>
            <div className="float-right mt-3">
              <button
                className="btn btn-info"
                disabled={!confirmNewAdmin}
                onClick={revokeUserObject}
              >
                Revoke
              </button>
              <button
                className="btn btn-danger ml-2"
                onClick={props.showDeleteModalHandler}
              >
                Close
              </button>
            </div>
          </Fragment>
        );
      } else {
        content = (
          <Fragment>
            <div>
              <span>
                Revoke{" "}
                <span className="text-danger font-weight-bold">
                  {selectedUserEmailToDelete}{" "}
                </span>{" "}
                and Revoke the project :{" "}
                <span className="text-danger font-weight-bold">
                  {userToDeleteDetails.project.name}
                </span>
              </span>
            </div>
            <div className="clearfix"></div>
            <div className="float-right mt-3">
              <div className="form-check mb-3">
                <input
                  className="form-check-input mt-2"
                  type="checkbox"
                  name="confirmDeleteProject"
                  id="confirmDeleteProject"
                  onChange={confirmDeleteProjectHandler}
                />
                <label
                  className="form-check-label"
                  htmlFor="confirmDeleteProject"
                >
                  I agree
                </label>
              </div>
              <button
                className="btn btn-info"
                disabled={!confirmDeleteProject}
                onClick={revokeUserObject}
              >
                Revoke
              </button>
              <button
                className="btn btn-danger ml-2"
                onClick={props.showDeleteModalHandler}
              >
                Close
              </button>
            </div>
          </Fragment>
        );
      }
    }
  }

  return (
    <Modal showDeleteModalHandler={props.showDeleteModalHandler}>
      <div className="row">
        <h5 className="ml-3 mt-2">Confirmation For Revoke ?</h5>
        <div className="col-md-12 pt-2 pb-2">{content}</div>
      </div>
    </Modal>
  );
};

export default RevokeUser;
