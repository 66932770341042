import Axios from "axios";

const BASEURL = `${process.env.REACT_APP_SERVER_URL}registerverification`;

export const getCodebyEmail = (email) =>
  Axios.post(`${BASEURL}/getvermail`, { email });

export const getCodebyMobile = (mobile, region) =>
  Axios.post(`${BASEURL}/getvermobile`, { mobile, region });

export const verifyMobileCode = (mobile, code) =>
  Axios.post(`${BASEURL}/verifymobile`, { mobile, code });

export const verifyEmailCode = (mail, code) =>
  Axios.post(`${BASEURL}/verifymail`, { mail, code });

export const verifyParticipant = (mail) =>
  Axios.post(`${BASEURL}/verifyparticipant `, { mail });

export const editVerifyMobile = (mobile) =>
  Axios.post(`${BASEURL}/editVerifyMobile`, { mobile });

export const editVerifyEmail = (mail) =>
  Axios.post(`${BASEURL}/editVerifyMail`, { mail });
