import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logService";
import CryptoJS from "crypto-js";
axios.interceptors.request.use(async (request) => {
  request.headers.setAuthorization("Bearer " + localStorage.getItem("token"));
  debugger;
  if (
    request.url.includes("/api/mosaicWall") ||
    request.url.includes("/api/project/submitproject") ||
    request.url.includes("/api/project/submitpart")
  )
    return request;
  else {
    request.data = {
      payload: CryptoJS.AES.encrypt(
        JSON.stringify(request.data),
        "secret key 123"
      ).toString(),
    };
    return request;
  }
});

axios.interceptors.response.use(
  (res) => {
    if (
      res.config.url.includes("/api/mosaicWall") ||
      res.config.url.includes("/api/project/submitproject") ||
      res.config.url.includes("/api/project/submitpart") ||
      res.config.url.includes("/api/cleardata") ||
      res.config.url.includes("/api/auth/getToken")
    ) {
      debugger;
      return Promise.resolve(res);
    }
    let decrypted = CryptoJS.AES.decrypt(res.data, "secret key 123");

    let decryptedItem = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    res.data = decryptedItem;
    return Promise.resolve(res);
  },
  (error) => {
    debugger;
    const expectedErrors =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (!expectedErrors) {
      logger.log(error);
    }
    let decrypted = CryptoJS.AES.decrypt(error.response.data, "secret key 123");

    let decryptedItem = decrypted.toString(CryptoJS.enc.Utf8);
    console.log(decryptedItem);
    error.response.data = decryptedItem;
    if (decryptedItem == JSON.stringify("Concurrent User")) {
      document.getElementById("concurrentuserbtn").click();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export function setJwt(jwt) {}
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
