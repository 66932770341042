import Spinner from "react-bootstrap/Spinner";
import "./index.css";
function Loader({ show }) {
  return (
    <div style={{ display: show ? "flex" : "none" }} className="loader-back">
      <Spinner animation="border" role="status" variant="danger" />
    </div>
  );
}

export default Loader;
