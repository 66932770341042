import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import LoggedNavBar from "../../common/navBar/loggedNav";
import { getCurrentUser } from "../../../services/authService";

import SideMenu from "../../common/sideMenu/sideMenu";
import { getAllByRegion } from "../../../apis/announcments";
import { useNavigate } from "react-router-dom";

const Announcments = () => {
  const [annoncments, setAnnouncmnets] = useState([]);
  const [sorted, setSort] = useState("A");
  useEffect(() => {
    const region = getCurrentUser().region;
    getAllByRegion({ region: region }).then((res) => {
      console.log(res.data);
      setAnnouncmnets(res.data.sort((a, b) => a.title.localeCompare(b.title)));
    });
  }, []);
  const searchref = useRef();
  const history = useNavigate();
  const search = () => {
    console.log(searchref.current.value);
    if (searchref.current.value == "") {
      getAllByRegion({ region: getCurrentUser().region }).then((res) => {
        setAnnouncmnets(res.data);
      });
    } else {
      getAllByRegion({ region: getCurrentUser().region }).then((res) => {
        const panels = res.data.filter((r) =>
          r.title
            .toLowerCase()
            .startsWith(searchref.current.value.toLowerCase())
        );
        console.log(panels);
        setAnnouncmnets(panels);
      });
    }
  };
  const sort = () => {
    const pan = Announcments;
    if (pan.length > 0)
      if (sorted == "A") {
        setAnnouncmnets(pan.sort((a, b) => b.title.localeCompare(a.title)));
        console.log(pan.sort((a, b) => b.title - a.title));
        setSort("D");
      } else if (sorted == "D") {
        setAnnouncmnets(pan.sort((a, b) => a.title.localeCompare(b.title)));
        setSort("A");
      }
  };
  const deleteAnnouncement = (Annid) => {
    setAnnouncmnets(annoncments.filter((r) => r._id != Annid));
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar />
        <div className='container'>
          <p className='header-support'>NOTIFICATIONS</p>
          <div className='coaches-redLine'></div>
          <div className='room-container-subheader'>
            <input
              type='text'
              className='searchbox-room'
              ref={searchref}
              onKeyUp={search}
            ></input>
            <button className='searchbox-room-btn' onClick={search}>
              <i className='fa fa-search searchbox-room-icon-btn'></i>{" "}
            </button>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <button className='room-sort-btn' onClick={sort}>
                SORT
              </button>
            </div>
          </div>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            {annoncments.map((ann) => {
              return (
                <div className='announ ' key={ann._id}>
                  <div className='announ-container-header'>
                    <span className='announ-title'>{ann.title}</span>
                  </div>
                  <div className='announ-container-body'>
                    <span className='announ-body'>{ann.body}</span>
                    <div className='card-border'></div>
                    <div className='card-footer'>
                      <span className='footer-date'>
                        {new Date(ann.createdAt).toDateString() +
                          "-" +
                          new Date(ann.createdAt).toLocaleTimeString()}
                      </span>
                      <span
                        className='footer-Delete'
                        onClick={() => {
                          deleteAnnouncement(ann._id);
                        }}
                      >
                        DELETE
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Announcments;
