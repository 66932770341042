import React, { useState, useEffect } from "react";
import {
  getCurrentUser,
  returnSuperAdmin,
  setUserJWT,
  ssoJWTCheck,
  setRegionToken,
  getJwt,
  getCurrentSSOUser,
  getToken,
} from "../../services/authService";
import Platform from "react-platform-js";
import { WithContext as ReactTags } from "react-tag-input";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { useNavigate } from "react-router-dom";
import SideMenu from "../common/sideMenu/sideMenu";
import { setJwt } from "../../services/httpService";
import styles from "./entrancePage.module.css";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import {
  getProject,
  submitParticipant,
  submitProject,
} from "../../apis/project";
import FailSubmission from "../registration/Egypt/failSubmission";
import SuccessSubmission from "../registration/Egypt/successSubmission";
import { verifyParticipant } from "../../apis/register";
import SubmissionLoader from "../common/submissionLoader/submissionLoader";
import { Spinner } from "react-bootstrap";
import { Input } from "@mui/material";
const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    height: "100%",
    color: "#08699B",
    paddingTop: "3px",
  }),
};
const locs = [
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },

  // {
  //   value: "Other",
  //   label: "Other – Type in your country",
  // },
];

const serviceLs = [
  { value: "Business _VOIS", label: "Business _VOIS" },
  { value: "CARE _VOIS", label: "CARE _VOIS" },
  {
    value: "CFSL _VOIS",
    label: "CFSL _VOIS",
  },
  {
    value: "Enabling Functions _VOIS",
    label: "Enabling Functions _VOIS",
  },
  {
    value: "Intelligent Operations _VOIS",
    label: "Intelligent Operations _VOIS",
  },
  {
    value: "NSE",
    label: "NSE",
  },
  {
    value: "T _VOIS",
    label: "T _VOIS",
  },
  {
    value: "VNO",
    label: "VNO",
  },
];
const KeyCodes = {
  space: 32,
  enter: 13,
  tab: 9,
};
const delimiters = [KeyCodes.space, KeyCodes.enter, KeyCodes.tab];
const ideaDomainSuggestions = [
  {
    value: "Simplicity",
    label: "Simplicity",
  },
  {
    value: "Customer",
    label: "Customer",
  },
  {
    value: "Growth",
    label: "Growth",
  },
];
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function EntranceNonRegistered() {
  const forceUpdate = useForceUpdate();
  const formRef = React.useRef();
  const [agree, setAgree] = React.useState(false);
  const [agreePrivacy, setAgreePrivacy] = React.useState(false);
  const [descriptionCounter, setDiscriptionCounter] = React.useState(1024);
  const [project, setProject] = React.useState({
    projectName: "",
    projectDesc: "",
    ideaTypes: [],
    projectVideo: "",
    teamEmails: [],
  });
  const [ideaTypes, setIdeaTypes] = useState(project.ideaTypes);
  const [ideaTechs, setIdeaTechs] = useState(project.ideaTechs);
  const [emails, setemails] = useState(project.teamEmails);
  const addIdeaType = (values) => {
    if (values.ideaType != "") {
      setIdeaTypes((arr) => [...arr, values.ideaType]);
      values.ideaType = "";
    }
  };

  const removeIdeaType = (index) => {
    setIdeaTypes((arr) => arr.filter((item, itemIndex) => itemIndex !== index));
  };
  const [emailError, setEmailError] = useState("");
  const addemails = (values) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.teamEmails != "") {
      if (
        values.teamEmails.match(mailformat) &&
        (values.teamEmails.toLowerCase().includes("@vodafone.com") ||
          values.teamEmails.toLowerCase().includes("@vodafone-itc.com"))
      ) {
        if (
          getCurrentUser().preferred_username.toLowerCase() !=
          values.teamEmails.toLowerCase()
        ) {
          setemails((arr) => [...arr, values.teamEmails]);
          values.teamEmails = "";
          setEmailError("");
        } else {
          setEmailError("Please use only other team member email.");
        }
      } else {
        setEmailError("Please provide a correct Vodafone email.");
      }
    }
  };

  const removeemails = (index) => {
    setemails((arr) => arr.filter((item, itemIndex) => itemIndex !== index));
  };
  //accordion Handler
  const [expand, setExpansion] = useState(-1);

  const handleExpansion = (expandedItem) => {
    if (expand === expandedItem) {
      setExpansion(-1);
    } else {
      setExpansion(expandedItem);
    }
  };
  const ProjectVideoRef = React.useRef();
  const [showVideo, setshowVideo] = useState(false);
  const [videoError, setVideoError] = useState("");
  const [file, setfile] = useState({});
  const [videobytes, setvideobytes] = useState();
  function Upload(element) {
    var reader = new FileReader();
    let file = element.files[0];
    reader.onload = function () {
      var arrayBuffer = this.result;
    };
    reader.readAsArrayBuffer(file);
  }

  function readFileDataAsBase64(e) {
    const file = e.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  }

  const uploadFile = (e) => {
    if (
      e.target.files[0].type !== "video/mp4" &&
      e.target.files[0].type !==
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
      e.target.files[0].type !== "application/pdf" &&
      e.target.files[0].type !== "application/zip"
    ) {
      setVideoError(
        "The uploaded Document should be in mp4,pptx,pdf or zip format"
      );
      return;
    } else if (e.target.files[0].size > 20 * 1024 * 1024) {
      setVideoError("The uploaded video should be less than 20 MB");
      return;
    } else {
      setVideoError("");
    }
    setshowVideo(true);
    localStorage.setItem(
      "projectVideo",
      JSON.stringify(URL.createObjectURL(e.target.files[0]))
    );

    Upload(e.target);

    readFileDataAsBase64(e).then((val) => {
      setvideobytes(val);
    });
    setfile(e.target.files[0]);
  };

  const removePresentation = () => {
    setshowVideo(false);
    setfile({});
    setvideobytes("");
  };

  //statsData
  const [submitted, setSubmitted] = React.useState(false);
  const [isNotAdmin, setisNotAdmin] = React.useState(true);
  const [location, setLocation] = React.useState("");
  const [groupFunction, setGroupFunction] = React.useState("");
  const [serviceLine, setServiceLine] = React.useState("");
  const [team, setTeam] = React.useState("");
  const [noOfApps, setNoOfApps] = React.useState("");
  const [participantType, setParticipantType] = React.useState("");
  const [toggle2, setToggle2] = React.useState(false);
  const [privacyURL, setprivacyURL] = useState("");
  //user Data
  const [IsAdmin, setIsAdmin] = useState();
  const [regionButtons, setRegionButtons] = useState(true);
  const [loading, setLoding] = useState(true);
  // const [region, setRegion] = useState({});
  // const [regionToken, setRegionToken] = useState(true);

  const history = useNavigate();
  async function pdffile(url, name, defaultType = "*/*") {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });

    return URL.createObjectURL(data);
  }

  useEffect(() => {
    if (loading) {
      pdffile(
        "assets/Participants_Acknowledgment.pdf",
        "Participants_Acknowledgment.pdf"
      ).then((res) => {
        setprivacyURL(res);
      });
      if (getCurrentUser() == undefined) {
        ssoJWTCheck().then((r) => {
          if (getCurrentUser() != undefined) {
            setIsAdmin(getCurrentUser().roles.includes("superAdmin"));
            if (
              getCurrentUser().roles.includes("panelMember") ||
              getCurrentUser().roles.includes("coach") ||
              getCurrentUser().roles.includes("projectMember") ||
              getCurrentUser().roles.includes("superAdmin")
            ) {
              forceUpdate();
              history("/hall");
            }
            setLoding(!loading);
            forceUpdate();
          }
        });
      } else {
        getCurrentSSOUser().then((r) => {
          if (r != null) {
            getToken(r.preferred_username).then((r) => {
              localStorage.setItem("token", r);
              console.log(r);

              ssoJWTCheck().then((r) => {
                if (getCurrentUser() != undefined) {
                  verifyParticipant(getCurrentUser().preferred_username)
                    .then((res2) => {
                      setisNotAdmin(true);
                      getProject(res2.data).then((res) => {
                        let temp = {
                          projectName: res.data.name,
                          projectDesc: res.data.description,

                          ideaTypes: res.data.type,
                          projectVideo: res.data.videoLink,
                          teamEmails: res.data.teamEmails,
                        };
                        setProject(temp);
                        forceUpdate();
                      });
                    })
                    .catch((ex) => {
                      setisNotAdmin(false);
                    });
                  setIsAdmin(getCurrentUser().roles.includes("superAdmin"));
                  if (
                    getCurrentUser().roles.includes("panelMember") ||
                    getCurrentUser().roles.includes("coach") ||
                    getCurrentUser().roles.includes("projectMember") ||
                    getCurrentUser().roles.includes("superAdmin")
                  ) {
                    history("/hall");
                    forceUpdate();
                  }
                  setLoding(!loading);
                  forceUpdate();
                }
              });
            });
          } else {
            ssoJWTCheck().then((r) => {
              setLoding(!loading);
              if (getCurrentUser() != undefined) {
                setIsAdmin(getCurrentUser().roles.includes("superAdmin"));
                if (
                  getCurrentUser().roles.includes("panelMember") ||
                  getCurrentUser().roles.includes("coach") ||
                  getCurrentUser().roles.includes("projectMember") ||
                  getCurrentUser().roles.includes("superAdmin")
                ) {
                  history("/hall");
                  forceUpdate();
                }
                setLoding(!loading);
                forceUpdate();
              }
            });
          }
        });
      }
    } else {
    }
  });
  const [success, setSuccess] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, seterrors] = useState([]);
  const getprivacyNotice = async () => {
    window.open(
      "https://vodafone.sharepoint.com/sites/MAKEITHAPPEN/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMAKEITHAPPEN%2FShared%20Documents%2FPrivacy%20notice%2FPrivacy%20Notice%20Hackathon%2Epdf&parent=%2Fsites%2FMAKEITHAPPEN%2FShared%20Documents%2FPrivacy%20notice",
      "_blank"
    );
  };
  const getT_CNotice = async () => {
    window.open(
      "https://vodafone.sharepoint.com/:f:/r/sites/MAKEITHAPPEN/Shared%20Documents/Hackathon%20-%20T%26C?csf=1&web=1&e=9R69d9",
      "_blank"
    );
  };
  const handleSubmitFunctionForParticipant = () => {
    getCurrentSSOUser().then((r) => {
      let finalObj = {
        part: {
          name: r.name.split(",")[0],
          nameNationalId: "",
          address: "",
          email: r.preferred_username,
          phoneNumber: "",
          nationalId: "",
          nationalIdIssuance: "",
          CV: "",
          password: "",
          isAdmin: false,
          participatedBefore: false,
          sameIdea: false,
          won: false,
          place: "",
          hackathonName: "",
          ideaPreviousName: "",
          region: location,
          serviceLine,
          participantType,
          noOfApps,
          team,
        },
        consent: {
          consentForm: "",
          consentCheck: true,
        },
      };

      setShow(true);

      submitParticipant(finalObj)
        .then((res) => {
          setSubmit(true);
          setSuccess(true);
          setShow(false);
        })
        .catch((error) => {
          setSuccess(false);
          setSubmit(true);
          seterrors(error.response.data);
        });
    });
  };
  const handleSubmitFunction = () => {
    getCurrentSSOUser().then((r) => {
      let finalObj = {
        project: {
          ...project,
          projectName: formRef.current.values.projectName,
          projectDesc: formRef.current.values.projectDesc,
          ideaType:
            Platform.OS != "Android" ? ideaTypes.map((e) => e) : ideaTypes,

          region: location,
          video: videobytes,
          teamEmails:
            Platform.OS != "Android" ? emails.map((e) => e.text) : emails,
        },
        admin: {
          name: r.name.split(",")[0],
          nameNationalId: "",
          address: "",
          email: r.preferred_username,
          phoneNumber: "",
          nationalId: "",
          nationalIdIssuance: "",
          CV: "",
          password: "",
          isAdmin: true,
          participatedBefore: false,
          sameIdea: false,
          won: false,
          place: "",
          hackathonName: "",
          ideaPreviousName: "",
          serviceLine,
          participantType,
          noOfApps,
          team,
        },
        consent: {
          consentForm: "",
          consentCheck: true,
        },
      };
      setShow(true);
      submitProject(finalObj)
        .then((res) => {
          setSubmit(true);
          setSuccess(true);
          setShow(false);
        })
        .catch((error) => {
          setSubmit(true);
          setSuccess(false);
          seterrors(error.response.data);
        });
    });
  };

  if (success === true && submit)
    return <SuccessSubmission></SuccessSubmission>;
  if (success === false && submit && errors.length != 0)
    return <FailSubmission errors={errors}></FailSubmission>;
  return (
    <>
      <div className={styles["summit-joinOuterContainer"]}>
        {loading ? (
          <div
            style={{
              display: loading ? "flex" : "none",
              flexDirection: "column",
            }}
            className='submission-loader-back'
          >
            <Spinner animation='border' role='status' variant='danger' />

            <span className='submissionLoaderTextnote'>
              Don't close this screen
            </span>
          </div>
        ) : (
          <>
            <SubmissionLoader show={show} />
            <div className={styles["login-container"]}>
              <div className={styles["title-div"]}>
                <span className={styles["summit-heading"]}>
                  _VOIS DevOps Hackathon{" "}
                </span>
              </div>
              {IsAdmin && (
                <div>
                  <button
                    className='login-body-actionBtn1 mt-3'
                    type='button'
                    onClick={() => {
                      returnSuperAdmin();
                      history("/superadmin/home");
                    }}
                  >
                    <span>Back To Super Admin</span>
                  </button>
                </div>
              )}

              <Accordion style={{ width: "100%" }} id='accordionExample'>
                <Accordion.Item
                  eventKey={1}
                  className=' mb-4'
                  onClick={() => handleExpansion(1)}
                >
                  <Accordion.Header
                    className='row'
                    data-toggle='collapse'
                    data-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'
                  >
                    <span className='pull-left ml-3 question-content'>
                      Participant Data
                    </span>
                    <span>
                      {expand === 1 ? (
                        <FontAwesomeIcon
                          icon={faAngleUp}
                          className='pull-right mr-4'
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className='pull-right mr-4'
                        />
                      )}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body
                    className={
                      styles["summit-joinInnerContainer"] + " ml-4 mb-3"
                    }
                    id='collapseOne'
                    aria-labelledby='headingOne'
                    data-parent='#accordionExample'
                  >
                    <div>
                      <div className=''>
                        <div className='form-group col-md-9 '>
                          <label
                            htmlFor='projectName'
                            className='label-format project-form-label'
                          >
                            Location
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <Select
                            className='bounce-in-top ddl-SL-z-index mt-1 mb-2  w-30 h-20'
                            onChange={(e) => {
                              setLocation(e.value);
                            }}
                            placeholder={
                              isMobile
                                ? "Location"
                                : " Select your country location"
                            }
                            options={locs}
                            classNamePrefix='an-simple-select'
                          />
                        </div>

                        <div className='form-group col-md-9'>
                          <label
                            htmlFor='projectDesc'
                            className='label-format project-form-label'
                          >
                            Service line
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <Select
                            className='bounce-in-top ddl-SL-z-index mt-1 mb-2  w-30 h-20'
                            onChange={(e) => {
                              setServiceLine(e.value);
                            }}
                            placeholder={
                              isMobile
                                ? "Service line"
                                : "Select your service line / enabling function​"
                            }
                            classNamePrefix='an-simple-select'
                            options={serviceLs}
                          />
                        </div>
                        <div className='form-group col-md-9'>
                          <label
                            htmlFor='projectDesc'
                            className={"label-format project-form-label"}
                          >
                            Developer Type
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <div
                            className='boolean-div mt-1 mb-2'
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              class='form-check '
                              onChange={(e) => {
                                setParticipantType("Professional Developer");
                                // setAlreadyPart(true);
                              }}
                            >
                              <label
                                className={
                                  styles["label-format"] + " project-form-label"
                                }
                              >
                                <input
                                  type='radio'
                                  class='form-check-input'
                                  name='optradio'
                                  value={true}
                                />
                                <span className={styles["radio-text"]}>
                                  Professional Developer
                                </span>
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      Anyone who has Power Platform solution
                                      development as a core role &
                                      responsibility in their job profile or has
                                      been working on Power Platform for more
                                      than 1 year - the year of experience
                                      assumes a level of proficiency and
                                      understanding of the end to end lifecycle
                                      of developing solution including complex
                                      solutions involving more than 1 capability
                                      set of Power Platform.
                                    </Tooltip>
                                  )}
                                  placement='bottom'
                                >
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                    className='fa fa-info-circle ml-2'
                                    style={{ fontSize: "16px" }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                            <div
                              class='form-check '
                              onChange={(e) => {
                                setParticipantType("Citizen Developer");
                              }}
                            >
                              <label
                                className={
                                  styles["label-format"] + " project-form-label"
                                }
                              >
                                <input
                                  type='radio'
                                  class='form-check-input'
                                  name='optradio'
                                  value={false}
                                  style={{ color: "#e60000" }}
                                />
                                <span className={styles["radio-text"]}>
                                  Citizen Developer
                                </span>
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      Anyone who has picked up learning or
                                      developing Power Platform solutions in the
                                      last year and the development of these
                                      solutions is not a core role &
                                      responsibility in their job profile.
                                    </Tooltip>
                                  )}
                                  placement='bottom'
                                >
                                  <span
                                    className='fa fa-info-circle ml-2'
                                    style={{ fontSize: "16px" }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='form-group col-md-9'>
                          <label
                            htmlFor='projectDesc'
                            className={
                              styles["label-format"] + " project-form-label"
                            }
                          >
                            Service Delivery Team Name
                            {/* <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  Mention your sub-function name.
                                </Tooltip>
                              )}
                              placement='bottom'
                            >
                              <span
                                className='fa fa-info-circle ml-2'
                                style={{ fontSize: "16px" }}
                              ></span>
                            </OverlayTrigger> */}
                          </label>
                          <Input
                            type='text'
                            value={team}
                            onChange={(e) => {
                              setTeam(e.target.value);
                            }}
                            className='text-box mt-1 mb-2'
                          />
                        </div>
                        {/* <div className='form-group col-md-9'>
                          <label
                            htmlFor='projectDesc'
                            className='label-format project-form-label'
                          >
                            How many apps / flows have you created?
                          </label>
                          <Input
                            type='text'
                            value={noOfApps}
                            onChange={(e) => {
                              setNoOfApps(e.target.value);
                            }}
                            className='text-box mt-1 mb-2'
                          />
                        </div> */}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <>
                  <Accordion.Item
                    eventKey={2}
                    className=' mb-4'
                    onClick={() => handleExpansion(2)}
                  >
                    <Accordion.Header
                      className='row'
                      data-toggle='collapse'
                      data-target='#collapseTwo'
                      aria-expanded='true'
                      aria-controls='collapseTwo'
                    >
                      <span className='pull-left ml-3 question-content'>
                        Review Your Pitch
                      </span>
                      <span>
                        {expand === 2 ? (
                          <FontAwesomeIcon
                            icon={faAngleUp}
                            className='pull-right mr-4'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            className='pull-right mr-4'
                          />
                        )}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body
                      className={
                        styles["summit-joinInnerContainer"] + " ml-4 mb-3"
                      }
                      id='collapseTwo'
                      aria-labelledby='headingTwo'
                      data-parent='#accordionExample'
                    >
                      <div
                        style={{
                          width: "100%",
                          // maxHeight: "25vh",
                          // overflowY: "scroll",
                          // overflowX: "hidden",
                        }}
                      >
                        <div>
                          <div className='form-group col-md-9  '>
                            <label
                              htmlFor='projectName'
                              className='label-format project-form-label'
                            >
                              Idea Name
                              <span className='required'> *</span>
                              <span className='required ml-1'> (required)</span>
                            </label>
                            <p className='projectDetails-data mt-1 mb-2'>
                              {project.projectName}
                            </p>
                          </div>

                          <div className='form-group col-md-9'>
                            <label
                              htmlFor='projectDesc'
                              className='label-format project-form-label'
                            >
                              Description
                              <span className='required'> *</span>
                              <span className='required ml-1'> (required)</span>
                            </label>
                            <p className='projectDetails-data mt-1 mb-2'>
                              {project.projectDesc}
                            </p>
                          </div>
                          <div className='form-group col-md-9 '>
                            <label
                              htmlFor='ideaType'
                              className='label-format project-form-label'
                            >
                              Theme
                              <span className='required'> *</span>
                              <span className='required ml-1'> (required)</span>
                            </label>
                            <span className='hint pl-0 mt-1 mb-2'>
                              {project.ideaTypes
                                ? project.ideaTypes.map((el, i) => (
                                    <li className='col-12 pl-0'>
                                      <i class='fa fa-lightbulb-o pr-1'></i>
                                      {el}
                                    </li>
                                  ))
                                : ""}
                            </span>
                            {/* <div className='container-center'>
                            <div className='tags-input-container'>
                              <div className='idea-types-tags'>
                                {project.ideaTypes &&
                                  project.ideaTypes.map((idea, index) => {
                                    return (
                                      <span
                                        className='tag tag ml-0 mt-1 mb-2'
                                        key={index}
                                      >
                                        <span className='content'>{idea}</span>
                                      </span>
                                    );
                                  })}
                              </div>
                            </div>
                          </div> */}
                          </div>
                          <div className='form-group col-md-9 mt-1 mb-2'>
                            <div className='col-md-12 col-sm-12  pl-0'>
                              <form>
                                <div className='form-group  '>
                                  <h3
                                    htmlFor='projectname'
                                    className='label-format'
                                  >
                                    Presentation Document
                                  </h3>
                                  {project.projectVideo ? (
                                    <div className=''>
                                      <a
                                        href={project.projectVideo}
                                        class='text-danger'
                                        download
                                      >
                                        <i className='fa fa-file text-danger pr-1'></i>{" "}
                                        {project.projectVideo.substring(
                                          project.projectVideo.lastIndexOf(
                                            "/"
                                          ) + 1
                                        )}
                                      </a>
                                    </div>
                                  ) : (
                                    <p className='text-danger'>
                                      There is no video provided with this
                                      project
                                    </p>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className='form-group col-md-9'>
                            <label
                              htmlFor='emails'
                              className='label-format project-form-label'
                            >
                              Team Members
                            </label>

                            <span className='hint pl-0 mt-1 mb-2'>
                              {project.teamEmails
                                ? project.teamEmails.map((el, i) => (
                                    <li className='col-12 pl-0'>
                                      <i class='far fa-user pr-1'></i>
                                      {el}
                                    </li>
                                  ))
                                : ""}
                            </span>
                          </div>
                          <div
                            className='form-group col-md-9'
                            style={{
                              paddingLeft: "2rem",
                            }}
                          >
                            <div
                              className='form-check-box mt-2'
                              style={{ width: "100%" }}
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={agreePrivacy}
                                id='flexCheckDefault'
                                onChange={(e) =>
                                  setAgreePrivacy(e.target.checked)
                                }
                              />

                              <label
                                className='form-check-label'
                                style={{
                                  color: "white",
                                  paddingLeft: "0",
                                }}
                              >
                                By registering to this Hackathon contest , I
                                acknowledge and agree to all data processing
                                activities performed for organizing and managing
                                the Hackathon, evaluating and awarding the
                                results of the contest.{" "}
                                <a
                                  href=''
                                  onClick={getprivacyNotice}
                                  style={{
                                    color: "var(--text-secondary)",
                                    display: "inline-block",
                                  }}
                                >
                                  Privacy Notice{" "}
                                </a>{" "}
                                is available here.
                              </label>
                            </div>
                            <div
                              className='form-check-box mt-2'
                              style={{ width: "100%" }}
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={agree}
                                id='flexCheckDefault'
                                onChange={(e) => setAgree(e.target.checked)}
                              />

                              <label
                                className='form-check-label'
                                style={{
                                  color: "white",
                                  paddingLeft: "0",
                                }}
                              >
                                By registering to this Hackathon contest, I
                                acknowledge and agree to all the Terms &
                                Conditions available{" "}
                                <a
                                  href=''
                                  onClick={getT_CNotice}
                                  style={{
                                    color: "var(--text-secondary)",
                                    display: "inline-block",
                                  }}
                                >
                                  here{" "}
                                </a>{" "}
                                for the _VOIS entity that I belong to.
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='row '></div>
                        <div className=' '></div>

                        <div
                          className='btn-next-container'
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "0.5rem",
                          }}
                        >
                          <button
                            // type='submit'
                            onClick={() => {
                              handleSubmitFunctionForParticipant();
                            }}
                            type='button'
                            disabled={
                              agree == false ||
                              agreePrivacy == false ||
                              location == "" ||
                              serviceLine == "" ||
                              participantType == ""
                            }
                            className={
                              agree == false ||
                              agreePrivacy == false ||
                              location == "" ||
                              serviceLine == "" ||
                              participantType == ""
                                ? "next-btn-disabled"
                                : "next-btn-egypt"
                            }
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              </Accordion>
            </div>
            <div className={styles["footer"]}>
              {/* <label
                className={styles["hyperLinks"] + " mr-3"}
                onClick={() => {
                  localStorage.removeItem("acceptedCookies");
                  window.location.reload();
                }}
              >
                Manage Cookies
              </label>
              &nbsp;—&nbsp; */}
              <label
                className={styles["hyperLinks"] + " ml-3 mr-3"}
                onClick={() => {
                  window.open(
                    "https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/Employee-Privacy-Statement.aspx?csf=1&e=PahNAV",
                    "_blank"
                  );
                }}
              >
                Employees privacy policy
              </label>
              &nbsp;—&nbsp;
              <label
                className={styles["hyperLinks"] + " ml-3"}
                onClick={() => {
                  window.open(
                    "https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/External-Workforce.aspx?csf=1&e=hMss8f",
                    "_blank"
                  );
                }}
              >
                Contractors privacy policy
              </label>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EntranceNonRegistered;
