import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUser,
  returnSuperAdmin,
  setRegionToken,
  ssoJWTCheck,
} from "../../services/authService";
import SideMenu from "../common/sideMenu/sideMenu";
import { Navbar, Container, Nav } from "react-bootstrap";
function Hall4() {
  const [backDrop, setbackDrop] = useState(true);
  const soundRef = React.useRef();
  const history = useNavigate();
  const [IsAdmin, setIsAdmin] = useState();
  const [regionButtons, setRegionButtons] = useState(true);
  const [region, setRegion] = useState("");
  const [announcementsCount, setAnnouncementsCount] = useState(0);
  const [loadAgain, setloadAgain] = useState("");
  function playVideos() {
    // console.log("kjjkjkkj");
    var videos = document.querySelectorAll("video");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element.paused === true) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
  function playAudios() {
    // console.log("kjjkjkkj");
    var videos = document.querySelectorAll("audio");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element.paused === true) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
  useEffect(() => {
    const currentUser = getCurrentUser();
    setRegionButtons(
      currentUser.roles.includes("superAdmin") ||
        currentUser.roles.includes("panelAdmin") ||
        currentUser.roles.includes("panelMember") ||
        currentUser.roles.includes("coach") ||
        (currentUser.roles.includes("guestUser") &&
          !currentUser.roles.includes("projectMember"))
        ? true
        : false
    );
    setIsAdmin(
      currentUser.roles.includes("superAdmin") ||
        localStorage.getItem("superAdminTempJWT") != undefined
        ? "true"
        : "false"
    );
  });
  useEffect(() => {
    const currentUser = getCurrentUser();
    ssoJWTCheck().then((r) => {
      setIsAdmin(
        currentUser.roles.includes("superAdmin") ||
          localStorage.getItem("superAdminTempJWT") != undefined
          ? "true"
          : "false"
      );
      setRegion(getCurrentUser().region);
    });
  }, [IsAdmin, region, announcementsCount, loadAgain]);

  useEffect(() => {
    var heatpoints = document.getElementsByClassName("heatpoint");
    for (let index = 0; index < heatpoints.length; index++) {
      const element = heatpoints[index];
      element.setAttribute("visible", true);
    }
    playVideos();
  }, []);
  async function getLocalfile(url, name, defaultType = "*/*") {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });

    return URL.createObjectURL(data);
  }
  return (
    <>
      <div
        class='modal fade'
        id='infoModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-lg' role='document'>
          <div class='modal-content modal-content-info'>
            <div class='modal-body modal-info-body'>
              <img className='logo' src='/assets/hackLogo.svg'></img>
              <span className='info-header'>INSTRUCTIONS</span>
              <div className='round-div'>
                <img
                  src='/assets/instructions/1.svg'
                  className='round-img'
                ></img>
                <span className='round-text'>
                  Click and drag to explore the environment
                </span>
              </div>
              <div className='instructions-div'>
                <div className='round-div'>
                  <img
                    src='/assets/instructions/2.svg'
                    className='round-img'
                  ></img>
                  <span className='round-text'>
                    Click this icon to view the page in new tab
                  </span>
                </div>
                <div className='round-div'>
                  <img src='/assets/newpage.svg' className='round-img'></img>
                  <span className='round-text'>
                    Click this icon to enter the room
                  </span>
                </div>
              </div>
              <button
                data-dismiss='modal'
                aria-label='Close'
                className='close-btn'
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='menu'>
        <img
          src='/assets/muted.svg'
          ref={soundRef}
          style={{ cursor: "pointer" }}
          className='mr-2'
          onClick={() => {
            playAudios();

            if (!soundRef.current.src.includes("/assets/unmuted.svg"))
              soundRef.current.src = "/assets/unmuted.svg";
            else soundRef.current.src = "/assets/muted.svg";
          }}
        ></img>
        <img
          src='/assets/notifications.svg'
          style={{ cursor: "pointer" }}
          className='mr-2'
          onClick={() => {
            history("/announcments");
          }}
        ></img>
        <img
          src='/assets/info.svg'
          style={{ cursor: "pointer" }}
          className='mr-2'
          data-toggle='modal'
          data-target='#infoModal'
        ></img>
        {regionButtons === true ? (
          <div class='btn-group dropup mr-2'>
            {/* <button
              type='button'
              class=' dropdown-toggle dropdown-hall'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img src='/assets/region.svg'></img>
            </button> */}

            <div class='dropdown-hall-menu dropdown-menu'>
              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("egypt");
                  window.location.reload();
                }}
              >
                {" "}
                Egypt
              </Nav.Link>

              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("india");
                  window.location.reload();
                }}
              >
                {" "}
                India
              </Nav.Link>
              <Nav.Link
                className='item'
                onClick={() => {
                  setRegionToken("romania");
                  window.location.reload();
                }}
              >
                Romania
              </Nav.Link>
            </div>
          </div>
        ) : null}
        {IsAdmin === "true" ? (
          <img
            src='/assets/back.svg'
            className='mr-2'
            style={{ cursor: "pointer" }}
            onClick={() => {
              returnSuperAdmin();
              history("/superadmin/home");
            }}
          ></img>
        ) : null}
      </div>
      <a-scene
        vr-mode-ui='enabled: false'
        deallocate
        renderer='gammaOutput: true;antialias: true; colorManagement: true;
        sortObjects: true; '
        cursor='rayOrigin: mouse'
        loading-screen='dotsColor: white; backgroundColor: darkred'
        gltf-model='/draco-decoder'
        foo
      >
        <a-assets timeout='10000'>
          <img id='heatPoint' src='/assets/newpage.svg' />
          <video
            id='video1'
            autoPlay
            loop='loop'
            src='/assets/video.mp4'
            muted
            preload='auto'
          ></video>
          <audio id='audio' preload='auto' loop>
            <source
              src='/assets/glb/newmodel/hallsound.mp3'
              type='audio/mp3'
            ></source>
          </audio>
          <img id='arrow' src='/assets/right-arrow.svg' />
          <img id='agenda' />
          <img id='skyimg' src='/assets/glb/secondroom/second_room.png' />
          <img id='theme' src='assets/Hero_Image.png' />
        </a-assets>
        {/* <a-entity light='type: ambient; color: #efefa3;groundColor:#92928b;intensity:0.2'></a-entity> */}
        {/* <a-entity
          light='type:hemisphere; 
                   shadowCameraVisible: false;color:#ece8da ;groundColor:#9f9c93 ;intensity:0.8'
          position='1 15 1'
        ></a-entity> */}
        <a-sound src='#audio' loop='true' position='0 0 0'></a-sound>
        <a-entity
          id='Agenda2'
          position='-393 20.54 261.45'
          rotation='0 90 0'
          scale='49 20 1'
        >
          <a-image
            width='9'
            height='6.5'
            src='#theme'
            position='5.3 0 0'
          ></a-image>
        </a-entity>
        {/* <a-simple-sun-sky
          visible={false}
          id='background'
          sun-position='0.7 0.4 -1'
        ></a-simple-sun-sky>
        <a-entity
          environment='preset: poison;active:false;fog:0;shadow:true;lighting:point ;flatShading:true;color: #c6c6b8;groundColor:#92928b'
          id='env'
        ></a-entity> */}
        <a-entity
          class='listenonkey'
          id='rig'
          movement-controls='controls: touch;speed:0.5'
          position='0 6 2'
          rotation='0 0 0'
        >
          <a-camera
            id='camera'
            wasd-controls-enabled='false'
            position='0 0 0'
            twoway-motion='speed: 35;nonMobileLoad:false'
          ></a-camera>
        </a-entity>
        {/* <!--<a-sky src="../tests4.png" rotation="0 -90 0">

        <a-entity id='texttest'
                  geometry='primitive: box'
                  material=''
                  position='4 4 -1'
                  rotation="0 -90 0"
                  scale='0.9 0.5 0.12'
                  cursor-url='url:../class1.html'></a-entity>


    </a-sky>--> */}
        {/* <a-entity light='type: ambient;intensity:0.4'></a-entity>
         */}
        {/* <a-entity
          light='type: spot; angle: 45;intensity:1;decay:1;distance:20'
          position='17.49 14.51 7.339'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:50'
          position='9.876 14.51 15.114'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:50'
          position='0.170 14.51 18.351'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:50'
          position='17.02 14.51 -7.034'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:50'
          position='10.42 14.51 -15.52'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:50'
          position='0.098 14.51 -18.6'
          rotation='-90 0 -90'
        ></a-entity> */}
        <a-sky src='#skyimg'></a-sky>
        {/* <a-entity
          id='model'
          visible={false}
          position='0 0.60 0'
          gltf-model='url(/assets/glb/secondroom/RoomsHall.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity> */}
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='-5 5.365 -90 '
          rotation='0 180 0'
          scale='7.5 7.5 0.12'
          cursor-url='url:./coaches'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='-5 5.365 -150 '
          rotation='0 180 0'
          scale='20 25 1'
          cursor-url='url:./coaches'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='35.28 5.365 -58 '
          rotation='0 140 0'
          scale='5 5 0.12'
          cursor-url='url:./judges'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='100.28 5.365 -200 '
          rotation='0 140 0'
          scale='35 40 1'
          cursor-url='url:./judges'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='49.48 5.365 -20.68 '
          rotation='0 115 0'
          scale='5 5 0.12'
          cursor-url='url:./agenda'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='200.48 5.365 -100.68 '
          rotation='0 115 0'
          scale='35 40 1'
          cursor-url='url:./agenda'
        ></a-entity>
        <a-image
          class='heatpoint'
          src='#heatPoint'
          position='-20.3 4.581 0.390'
          rotation='0 -90 0'
          scale='2 2 0.12'
          cursor-inside-url='url:./hall2'
        ></a-image>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='-350.3 4.581 0'
          rotation='0 90 0'
          scale='150 75 1'
          cursor-inside-url='url:./hall2'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='64.97 5.365 30 '
          rotation='0 65 0'
          scale='5 5 0.12'
          cursor-url='url:./session'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          // material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='200.97 5.365 100 '
          rotation='0 65 0'
          scale='35 40 1'
          cursor-url='url:./session'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='38.84 5.365 67.628 '
          rotation='0 35 0'
          scale='5 5 0.12'
          cursor-url='url:./photoboothlisting'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='200.97 5.365 350 '
          rotation='0 35 0'
          scale='60 60 1'
          cursor-url='url:./photoboothlisting'
        ></a-entity>
        <a-entity
          geometry='primitive:box;'
          material='shader:gif;src:url(/assets/popupCTA.gif);transparent:true;opacity:1;autoplay:1'
          class='heatpoint'
          position='-5 5.365 96 '
          rotation='0 35 0'
          scale='6.5 6.5 0.12'
          cursor-url='url:./breakroomlisting'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          position='-26.6 9.365 400 '
          rotation='0 0 0'
          scale='60 60 1'
          cursor-url='url:./breakroomlisting'
        ></a-entity>
      </a-scene>
    </>
  );
}

export default Hall4;
