import "./index.css";
import React from "react";
import HiddenNonGrouped from "./NonGroupedHidden";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

function NonGrouped({ data, final, panelID }) {
  const [criterias, setCriterias] = useState([]);
  const [Loading, setLoading] = useState(false);

  const getfiltrationPanelCriterias = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/criteria`, {
        id: panelID,
      })
      .then((res) => {
        console.log(res.data);
        res.data.map((l) => {
          let x = criterias;
          x.push(l.name);
          setCriterias(x);
          criterias.sort();
        });
        setLoading(true);
      });
  };
  useEffect(() => {
    getfiltrationPanelCriterias();
    console.log("cc", criterias);
  }, []);
  function compare(a, b) {
    var nameA = a.criteriaName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.criteriaName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  // let criterias = Object.keys(data).sort((a, b) => {
  //   var nameA = a.toUpperCase(); // ignore upper and lowercase
  //   var nameB = b.toUpperCase(); // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // });

  return (
    <div className=' super-admin-container'>
      <HiddenNonGrouped data={data} final={final} panelID={panelID} />
      {/* {Loading ? (
        <table className='table table-fixed-super'>
          <tbody style={{ width: "100%" }}>
            {console.log("s", criterias)}
            <tr
              style={{
                display: "flex",
                position: "sticky",
                top: "0",
                zIndex: "999",
                backgroundColor: "white",
              }}
            >
              {criterias.map((item, index) => {
                return (
                  <td
                    className='col-2'
                    style={{ backgroundColor: "white", padding: "20px" }}
                    key={index}
                  >
                    {item}
                  </td>
                );
              })}
            </tr>
            {final.map(function (row, i) {
              return Object.values(row).map((l) => {
                console.log("ff", l);
                let temp = l.sort(compare);
                return (
                  <tr style={{ display: "flex" }}>
                    {temp.map((cell) => {
                      if (
                        criterias.filter((r) => r == cell.criteriaName).length >
                        0
                      ) {
                        return (
                          <td className='col-2'>
                            <div>
                              <div>
                                <span>Project {cell.projectName}</span>
                              </div>
                              <span>Judge {cell.email}</span>
                              <div>
                                <span>{cell.value}</span>
                              </div>
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td className='col-2'>
                            <div>No Value</div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      ) : null} */}
    </div>
  );
}
export default NonGrouped;
