import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import img from "../../assets/check.svg";
import ReCAPTCHA from "react-google-recaptcha";

import httpService from "./../../services/httpService";
import NavBar from "../../components/common/navBar/navBar";
import { setUserJWT, getCurrentUser } from "./../../services/authService";
import axios from "axios";
import LandingNavBar from "../home/navbar";
export default function LoginForget2() {
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const history = useNavigate();
  const [successPassword, setSuccessPassword] = useState(false);
  const [binCode, setBinCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  const [RecaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
  }, []);
  const ResetSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    password: Yup.string()
      .min(5, "Minimum 5 characters")
      .max(255, "Maximum 5 characters")
      .required("Password is required"),
    binCode: Yup.string().required("bin code is required"),
  });
  function SubmitRecaptcha(value) {
    // console.log("Captcha value:", value);
    setRecaptchaToken(value);
  }

  const showForgetPassword = () => {
    setBinCode(false);
    setShowPassword(false);
    setSuccessPassword(false);
  };

  const showBinCode = ({ email }) => {
    validateEmail(email);
    setRecaptchaToken("");
  };

  const showPasswordHandle = ({ binCode, email }) => {
    validateBinCode(binCode, email);
    setRecaptchaToken("");
  };

  const showSuccessPasswordHandle = ({ password, email }) => {
    updatePassword(password, email).then(setSuccessPassword(true));
    setRecaptchaToken("");
  };

  const validateEmail = async (email) => {
    try {
      const { data } = await httpService.post(
        `${process.env.REACT_APP_SERVER_URL}user/validateEmail`,
        {
          email: email,
        },
        { headers: { RecaptchaHeader: RecaptchaToken } }
      );
      if (data) {
        setServerErrorMessage("");
        setBinCode(true);
      }
    } catch (err) {
      if (err.response.status === 403) {
        setServerErrorMessage(
          "Please refresh the page and verify you are not a robot."
        );
      } else {
        setServerErrorMessage("not registered user");
      }
    }
  };

  const validateBinCode = async (binCode, email) => {
    try {
      const { data } = await httpService.post(
        `${process.env.REACT_APP_SERVER_URL}user/validateBinCode`,
        {
          binCode: binCode,
          email: email,
        },
        { headers: { RecaptchaHeader: RecaptchaToken } }
      );
      if (data) {
        setServerErrorMessage("");
        setShowPassword(true);
      }
    } catch (err) {
      if (err.response.status === 403) {
        setServerErrorMessage(
          "Please refresh the page and verify you are not a robot."
        );
      } else {
        setServerErrorMessage("PinCode is Incorrect.");
      }
    }
  };

  const updatePassword = async (password, email) => {
    try {
      const { data } = await httpService.post(
        `${process.env.REACT_APP_SERVER_URL}user/updatePassword`,
        {
          email: email,
          password: password,
        },
        { headers: { RecaptchaHeader: RecaptchaToken } }
      );
      if (data) {
        setServerErrorMessage("");
        setSuccessPassword(true);
      }
    } catch (err) {
      if (err.response.status === 403) {
        setServerErrorMessage(
          "Please refresh the page and verify you are not a robot."
        );
      } else {
        setServerErrorMessage("something went wrong");
        setSuccessPassword(false);
      }
    }
  };
  return (
    <div className='login-pageContainer container-fluid'>
      <LandingNavBar />
      <div className='row'>
        <div className='col-lg-4 col-md-4 col-sm-8 col-xs-8'></div>
        <div className='login-container col-lg-4 col-md-4 col-sm-8 col-xs-8'>
          <div className='login-body-title'> _VOIS Global Hackathon</div>
          <div className='success-message-note'>Forget Password</div>
          {serverErrorMessage ? (
            <div
              class='alert alert-danger login-form-alert-customize'
              role='alert'
            >
              {serverErrorMessage}
            </div>
          ) : null}
          <div>
            <Formik
              initialValues={{
                email: "",
                isChecked: false,
                binCode: "",
                password: "",
              }}
              validationSchema={ResetSchema}
              onSubmit={(values) => {}}
            >
              {({
                handleSubmit,
                getFieldProps,
                touched,
                errors,
                isValid,
                handleChange,
                values,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    {binCode === true ? (
                      <>
                        {successPassword === false ? (
                          <>
                            <div className='form-group login-form-input-text'>
                              <div htmlFor='email'>
                                Email
                                <div>
                                  {values.email}
                                  <button
                                    component={Link}
                                    onClick={showForgetPassword}
                                    className='verify-btn ml-5'
                                  >
                                    Change Account
                                  </button>
                                </div>
                              </div>
                            </div>
                            {showPassword === false ? (
                              <>
                                <div className='form-group'>
                                  <p
                                    htmlFor='email'
                                    className='login-form-input-text'
                                  >
                                    Enter the bin code sent to your Email
                                  </p>
                                  <input
                                    type='text'
                                    className='form-control login-form-input'
                                    id='binCode'
                                    name='binCode'
                                    value={values.binCode}
                                    onChange={handleChange}
                                    required
                                    {...getFieldProps("binCode")}
                                  />
                                  {touched.binCode && errors.binCode ? (
                                    <div className='alert alert-danger login-form-alert-customize'>
                                      {errors.binCode}
                                    </div>
                                  ) : null}
                                </div>
                                {/* <div className='login-form-checkBox-2'>
                                  <input
                                    type='checkbox'
                                    onChange={handleChange}
                                    id='exampleCheck1'
                                    name='isChecked'
                                  />
                                  {values.isChecked}

                                  <label
                                    className='login-form-checkBox-2 mx-2'
                                    htmlFor='exampleCheck1'
                                  >
                                    Remember My Credentials
                                  </label>
                                </div> */}
                                {RecaptchaToken !== "" ? (
                                  <button
                                    type='submit'
                                    className='login-form-actionBtn'
                                    onClick={() => {
                                      showPasswordHandle(values);
                                    }}
                                    disabled={
                                      !(
                                        !errors.hasOwnProperty("binCode") &&
                                        dirty
                                      )
                                    }
                                  >
                                    <span>NEXT</span>
                                  </button>
                                ) : (
                                  <span className='d-flex justify-content-center align-items-center'>
                                    <ReCAPTCHA
                                      sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                                      onChange={SubmitRecaptcha}
                                      theme='dark'
                                    />
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                <div className='form-group'>
                                  <p
                                    htmlFor='password'
                                    className='login-form-input-text'
                                  >
                                    Insert New Password
                                  </p>
                                  <input
                                    type='password'
                                    className='form-control login-form-input'
                                    id='password'
                                    name='password'
                                    {...getFieldProps("password")}
                                  />
                                  {touched.password && errors.password ? (
                                    <div className='alert alert-danger login-form-alert-customize '>
                                      {errors.password}
                                    </div>
                                  ) : null}
                                </div>

                                {/* <div className='login-form-checkBox-2'>
                                  <input
                                    type='checkbox'
                                    onChange={handleChange}
                                    id='exampleCheck1'
                                    name='isChecked'
                                  />
                                  {values.isChecked}

                                  <label
                                    className='login-form-checkBox-1 mx-2'
                                    htmlFor='exampleCheck1'
                                  >
                                    Remember My Credentials
                                  </label>
                                
                                </div> */}
                                {RecaptchaToken !== "" ? (
                                  <button
                                    type='button'
                                    className='login-form-actionBtn'
                                    onClick={() => {
                                      showSuccessPasswordHandle(values);
                                    }}
                                  >
                                    <span>Save</span>
                                  </button>
                                ) : (
                                  <span className='d-flex justify-content-center align-items-center'>
                                    <ReCAPTCHA
                                      sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                                      onChange={SubmitRecaptcha}
                                      theme='dark'
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <img src={img} />

                            <p className='success-message-note'>
                              Password Changed Successfully
                            </p>

                            <button
                              type='submit'
                              className='login-form-actionBtn'
                              onClick={() => {
                                history("/login");
                              }}
                            >
                              <span>Go to the Login Page</span>
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className='form-group'>
                          <p htmlFor='email' className='login-form-input-text'>
                            Email
                          </p>
                          <input
                            type='email'
                            className='form-control login-form-input'
                            id='email'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            {...getFieldProps("email")}
                          />
                          {touched.email && errors.email ? (
                            <div className='alert alert-danger login-form-alert-customize'>
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                        {/* <div className='login-form-checkBox-3'>
                          <input
                            type='checkbox'
                            onChange={handleChange}
                            id='exampleCheck1'
                            name='isChecked'
                          />
                          {values.isChecked}

                          <label
                            className='login-form-checkBox-1 mx-2'
                            htmlFor='exampleCheck1'
                          >
                            Remember My Credentials
                          </label>
                        </div> */}
                        {RecaptchaToken !== "" ? (
                          <button
                            type='submit'
                            className='login-form-actionBtn'
                            onClick={() => {
                              showBinCode(values);
                            }}
                            onChange={handleChange}
                            disabled={
                              !(!errors.hasOwnProperty("email") && dirty)
                            }
                          >
                            <span>NEXT</span>
                          </button>
                        ) : (
                          <span className='d-flex justify-content-center align-items-center'>
                            <ReCAPTCHA
                              sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                              onChange={SubmitRecaptcha}
                              theme='dark'
                            />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
