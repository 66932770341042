import React from "react";
import { Spinner } from "react-bootstrap";
import "./index.css";
function GeneralLoader() {
  return (
    <div
      style={{ display: "none", flexDirection: "column" }}
      className='submission-loader-back2'
      id='loader-screen'
    >
      <Spinner animation='border' role='status' variant='danger' />
    </div>
  );
}

export default GeneralLoader;
