import React, { useState, useEffect, useContext } from "react";
import { GuardianContext } from "../../../contexts/guardianContext";
import { MainUserContext } from "../../../contexts/mainUserContext";
import { StepsContext } from "../../../contexts/StepsContext";
import GuardianForm from "./legalForm/guardianForm";
import MainUserDetails from "./userMainDetails/mainUserDetails";
import { ConsentContext } from "../../../contexts/consentContext";
import ConsentFormAdult from "./consent/consentFormAdult";
import ConsentFormGuard from "./consent/consentFormGuard";
import jsPDF from "jspdf";
import Loader from "../../common/Loader";
import SuccessSubmission from "./successSubmission";
import { submitParticipant, submitProject } from "../../../apis/project";
import * as htmlToImage from "html-to-image";
import ProjectDetailsForm from "./project/projectDetailsForm";
import ProjectDetailsPreview from "./project/projectDetailsPreview";
import NavBar from "../../common/navBar/navBar";
import FailSubmission from "./failSubmission";
import { setUserJWT, getCurrentUser } from "../../../services/authService";
import axios from "axios";
import "./egyptRegister.css";
import LandingNavBar from "../../home/navbar";
import SubmissionLoader from "../../common/submissionLoader/submissionLoader";

function EgyptRegister() {
  const [success, setSuccess] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
  }, []);

  const [RecaptchaToken, setRecaptchaToken] = useState(null);
  const [show, setShow] = useState(false);
  const [errors, seterrors] = useState([]);
  const [currentStep, setCurrentStep] = useContext(StepsContext);

  const setRecabtcha = (value) => {
    setRecaptchaToken(value);
  };

  //step 1
  const [mainuser, setmainuser] = useContext(MainUserContext);

  //step 2
  const [guardianDetails, changeGuardianContext] = useContext(GuardianContext);

  //step 3
  const [consentDetails, consentChangeDetails] =
    React.useContext(ConsentContext);

  //step 4
  const submitparticiapnt = (value) => {
    setShow(true);

    submitParticipant(value, RecaptchaToken)
      .then((res) => {
        setSubmit(true);
        setSuccess(true);
        setShow(false);
      })
      .catch((error) => {
        setSuccess(false);
        setSubmit(true);
        seterrors(error.response.data);
      });
  };

  const handlesubmit = (value) => {
    console.log(value);
    setShow(true);
    submitProject(value, RecaptchaToken)
      .then((res) => {
        setSubmit(true);
        setSuccess(true);
        setShow(false);
      })
      .catch((error) => {
        setSubmit(true);
        setSuccess(false);
        seterrors(error.response.data);
      });
  };
  const changeSteps = () => {
    setCurrentStep(4);
    setSubmit(false);
    setSuccess(false);
    seterrors([]);
    setShow(false);
  };
  if (success === true && submit)
    return <SuccessSubmission></SuccessSubmission>;
  if (success === false && submit && errors.length != 0)
    return (
      <FailSubmission
        changeSteps={changeSteps}
        errors={errors}
      ></FailSubmission>
    );
  return (
    <div className='faq-page-wrapper container-fluid no-gutters'>
      <div className='col-lg-12 '></div>
      {/* <NavBar user={user} navColor={navColor} setNavColor={setNavColor} /> */}
      <div className='navbar-container'>
        <LandingNavBar />
      </div>
      <div
        className='register-page-container  p-0 '
        style={{
          display: "flex",
          justifyContent: "center",
          overflowX: "hidden",
        }}
      >
        <div className='row' style={{ width: "100%" }}>
          <SubmissionLoader show={show} />
          <div style={{ width: "100%" }}>
            {currentStep == 1 ? (
              <MainUserDetails />
            ) : currentStep == 2 ? (
              <ConsentFormAdult />
            ) : currentStep == 3 ? (
              mainuser.isAdmin ? (
                <ProjectDetailsForm
                  handleSubmit={handlesubmit}
                  setRecabtcha={setRecabtcha}
                ></ProjectDetailsForm>
              ) : (
                <ProjectDetailsPreview
                  handleSubmit={submitparticiapnt}
                  setRecabtcha={setRecabtcha}
                ></ProjectDetailsPreview>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EgyptRegister;
