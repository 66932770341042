import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import { getCurrentUser, setImpersonateJWT } from "../../services/authService";
import styles from "./superadminProjectsinnerPage.module.css";
function AddSuperAdmin() {
  const [email, setEmail] = useState("");
  const [superadmins, setsuperadmins] = useState([]);
  const history = useNavigate();

  const getUserEmail = (email) => {
    setImpersonateJWT(email);
    history("/entrance");
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}superadmin`, {
        email: email,
      })
      .then((res) => {
        setsuperadmins(res.data);
      });
  }, []);
  function validateEmail(email) {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = () => {
    if (validateEmail(email)) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}superadmin/add`, {
          email: email,
        })
        .then((res) => {
          setEmail("");
          axios
            .get(`${process.env.REACT_APP_SERVER_URL}superadmin`, {
              email: email,
            })
            .then((res) => {
              setsuperadmins(res.data);
            });
        });
    }
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={" col-md-10"}>
            <div className={styles["main-container"] + " row mt-5"}>
              {superadmins.map((r) => (
                <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                  <span className={styles["regionTitle"]}>{r.email} </span>
                  <span
                    className={styles["regionTitle"] + " fa fa-trash"}
                    style={{
                      color: "#e60000",
                      cursor: "pointer",
                      fontSize: "24px",
                    }}
                    onClick={() => {
                      axios
                        .post(
                          `${process.env.REACT_APP_SERVER_URL}superadmin/delete`,
                          {
                            _id: r._id,
                          }
                        )
                        .then((res) => {
                          setEmail("");
                          axios
                            .get(
                              `${process.env.REACT_APP_SERVER_URL}superadmin`,
                              {
                                email: email,
                              }
                            )
                            .then((res) => {
                              setsuperadmins(res.data);
                            });
                        });
                    }}
                  ></span>
                </div>
              ))}
              <div
                className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Email: </label>

                <input
                  type='email'
                  value={email}
                  onChange={emailHandler}
                ></input>

                <button
                  type='button'
                  className='btn  btn-outline-dark button-login mt-4  '
                  onClick={onSubmit}
                >
                  <span className='btnText'>Add</span>
                </button>
              </div>
            </div>
            <div
              className='col-md-4 '
              style={{
                display: "flex",
                alignItems: "center",

                flexDirection: "column",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSuperAdmin;
