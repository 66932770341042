import React, { useState } from "react";

const ReservedPathContext = React.createContext([() => {}]);

const ReservedPathProvider = ({ children }) => {
  const [reservedPath, setReservedPath] = useState({
    path: "",
  });

  const changeReservedPath = (data) => {
    setReservedPath(data);
  };
  return (
    <ReservedPathContext.Provider value={[reservedPath, changeReservedPath]}>
      {children}
    </ReservedPathContext.Provider>
  );
};

export { ReservedPathProvider, ReservedPathContext };
