import React from "react";
import styles from "./HomeNav.module.css";
import { Link, useNavigate } from "react-router-dom";
function HomeNav() {
  const history = useNavigate();
  return (
    <div className={styles["nav-main-container"]}>
      <div className={styles["nav-main-container-left"]}>
        <span
          className={styles["nav-main-container-left-link"]}
          onClick={() => {
            history("/");
          }}
        >
          HOME
        </span>
        {/* <span
          className={styles["nav-main-container-left-link"]}
          onClick={() => {
            history("/guidelines");
          }}
        >
          Guidelines
        </span>
        <span
          className={styles["nav-main-container-left-link"]}
          onClick={() => {
            history("/faq");
          }}
        >
          FAQ
        </span> */}
        <span
          className={styles["nav-main-container-left-link"]}
          onClick={() => {
            history("/login");
          }}
        >
          Login
        </span>
      </div>{" "}
      <div className={styles["nav-main-container-mid"]}>
        <div className={styles["logo"]}></div>
      </div>
      <div className={styles["nav-main-container-right"]}>
        <div className={styles["nav-main-container-right-first"]}>
          <div className={styles["nav-main-container-right-first-text"]}>
            17 May - 07 Jul
          </div>
          <div className={styles["nav-main-container-right-first-text2"]}>
            Online virtual event
          </div>
        </div>
        <span
          className={styles["nav-main-container-right-btn"]}
          onClick={() => {
            history("/region");
          }}
        >
          Register
        </span>
      </div>
    </div>
  );
}

export default HomeNav;
