import React, { useState, useEffect } from "react";
import "./successSubmission.css";
import { useNavigate } from "react-router-dom";
import { setUserJWT, getCurrentUser } from "../../../services/authService";
import LandingNavBar from "./../../home/navbar";

const SuccessSubmission = () => {
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
  }, []);
  const history = useNavigate();

  return (
    <div className='container-fluid success-submission-container '>
      <LandingNavBar />
      <main className='success-main row '>
        <div className='success-main-content  col-sm-12 text-center'>
          <div className='success-message'>
            <h1 className='success-message-header'>Congratulations!</h1>

            <p className='success-message-note'>
              Please check your email, confirmation will be received soon.
            </p>

            {/* <button
              type='submit'
              className='login-btn d-flex justify align-content-center'
              onClick={() => {
                history("/login");
              }}
            >
              <span>Login</span>
            </button> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default SuccessSubmission;
