import React, { useState } from "react";

const ProjectContext = React.createContext([() => {}]);

const ProjectProvider = ({ children }) => {
  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    projectDesc: "",
    ideaTypes: [],
    ideaTechs: [],
    region: "",
    projectVideo: "",
    teamEmails: [],
  });

  const changeProjectDetails = (data) => {
    setProjectDetails(data);
  };
  return (
    <ProjectContext.Provider value={[projectDetails, changeProjectDetails]}>
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectProvider, ProjectContext };
