import axios from "axios";
import React, { useEffect, Fragment, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import classes from "./Modal.module.css";

const Backdrop = (props) => {
  return (
    <div
      className={classes.superAdminRevokeUsersBackdrop}
      onClick={props.onClose}
    />
  );
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.superAdminRevokeUsersModal}>{props.children}</div>
  );
};

const portalElement = document.getElementById("superAdminRevokeUsersOverlay");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.showDeleteModalHandler} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay> {props.children} </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
