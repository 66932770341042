import React, { useState } from "react";

const ConsentContext = React.createContext([() => {}]);

const ConsentProvider = ({ children }) => {
  const [consentDetails, setConsentDetails] = useState({
    consentFormAdult: "",
    consentFormGuard: "",
    consentCheck: "",
    privacyCheck: "",
  });

  const changeConsentDetails = (data) => {
    setConsentDetails(data);
  };

  return (
    <ConsentContext.Provider value={[consentDetails, changeConsentDetails]}>
      {children}
    </ConsentContext.Provider>
  );
};

export { ConsentProvider, ConsentContext };
