import React, { useContext, useState, useEffect } from "react";
import { ProjectContext } from "../../../../contexts/projectContext";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import "./projectDetailsForm.css";
import "./projectVideo.css";
import Stepper from "../stepper/stepper";

import { useNavigate } from "react-router-dom";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import { ConsentContext } from "../../../../contexts/consentContext";
import { GuardianContext } from "../../../../contexts/guardianContext";
// import { TagsInput } from "react-tag-input-component";
import ReCAPTCHA from "react-google-recaptcha";
import { WithContext as ReactTags } from "react-tag-input";
import Platform from "react-platform-js";
import { useRef } from "react";

const ideaDomainSuggestions = [
  ,
  "Planet",
  "Inclusion for All",
  "Digital Society",
].map((r) => {
  return {
    id: r,
    text: r,
  };
});
const ideaTechnologiesSuggestions = [
  "HTML5/CSS3",
  "NodeJs",
  "MongoDB",
  "MySQL",
  "PostgreSQL",
  "Python",
  "PHP",
  ".Net",
  "Java",
  "C/C++",
  "Ruby on Rails",
  "Unity",
  "R",
  "JavaScript",
  "AngularJs",
  "Angular",
  "TypeScript",
  "ReactJs",
  "VueJs",
  "React Native",
  "Kotlin",
  "Flutter",
  "Xamarin",
  "Ionic",
  "PhoneGap",
  "iOS",
  "Objective C",
  "Swift",
  "Android",
  "Docker",
  "Electron",
  "Rust",
  "Scala",
  "Go",
  "Sketch",
  "InVision Studio",
  "Proto.io",
  "Adobe XD",
  "Marvel",
  "Figma",
].map((r) => {
  return {
    id: r,
    text: r,
  };
});
const KeyCodes = {
  space: 32,
  enter: 13,
  tab: 9,
};

const delimiters = [KeyCodes.space, KeyCodes.enter, KeyCodes.tab];
const ProjectDetailsForm = ({ handleSubmit, setRecabtcha }) => {
  const formRef = React.useRef();

  const [project, changeDetails] = useContext(ProjectContext);
  const [videobytes, setvideobytes] = useState();
  const [user, setUser] = useContext(MainUserContext);
  const [consent, setConsent] = useContext(ConsentContext);
  const [legal, setLegal] = useContext(GuardianContext);
  const [ideaTypes, setIdeaTypes] = useState(project.ideaTypes);
  const [ideaTechs, setIdeaTechs] = useState(project.ideaTechs);
  const [emails, setemails] = useState(project.teamEmails);
  const [alreadyPart, setAlreadyPart] = React.useState();
  const [sameIdea, setSameIdea] = React.useState();
  const [winning, setWinning] = React.useState();
  const [place, setPlace] = React.useState("Not Applicable");
  const [hackathonName, setHackathonName] = React.useState();
  const [ideaBeforeName, setIdeaBeforeName] = React.useState();
  const history = useNavigate();

  const [show, setShow] = useState(false);
  const [verifyStep, setVerifyStep] = useState("recaptcha");

  const onChangeRecaptcha = (value) => {
    //
    setRecabtcha(value);
    setVerifyStep("submit");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addIdeaType = (values) => {
    if (values.ideaType != "") {
      setIdeaTypes((arr) => [...arr, values.ideaType]);
      values.ideaType = "";
    }
  };

  const removeIdeaType = (index) => {
    setIdeaTypes((arr) => arr.filter((item, itemIndex) => itemIndex !== index));
  };
  const [emailError, setEmailError] = useState("");
  const addemails = (values) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.teamEmails != "") {
      if (values.teamEmails.match(mailformat)) {
        setemails((arr) => [...arr, values.teamEmails]);
        values.teamEmails = "";
        setEmailError("");
      } else {
        setEmailError(
          "please provide a correct emails to be able to send them for registration."
        );
      }
    }
  };

  const removeemails = (index) => {
    setemails((arr) => arr.filter((item, itemIndex) => itemIndex !== index));
  };
  const addIdeaTech = (values) => {
    if (values.ideaTech != "") {
      setIdeaTechs((arr) => [...arr, values.ideaTech]);
      values.ideaTech = "";
    }
  };

  const removeIdeaTech = (index) => {
    setIdeaTechs((arr) => arr.filter((item, itemIndex) => itemIndex !== index));
  };
  const ProjectVideoRef = React.useRef();
  const [showVideo, setshowVideo] = useState(false);
  const [videoError, setVideoError] = useState("");
  const [file, setfile] = useState({});
  const [goBack, setGoBack] = useState(false);
  function Upload(element) {
    var reader = new FileReader();
    let file = element.files[0];
    reader.onload = function () {
      var arrayBuffer = this.result;
    };
    reader.readAsArrayBuffer(file);
  }

  function readFileDataAsBase64(e) {
    const file = e.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  }

  const uploadFile = (e) => {
    if (
      e.target.files[0].type !== "video/mp4" &&
      e.target.files[0].type !==
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
      e.target.files[0].type !== "application/pdf" &&
      e.target.files[0].type !== "application/zip"
    ) {
      setVideoError(
        "The uploaded Document should be in mp4,pptx,pdf or zip format"
      );
      return;
    } else if (e.target.files[0].size > 100 * 1024 * 1024) {
      setVideoError("The uploaded video should be less than 100MB");
      return;
    } else {
      setVideoError("");
    }
    setshowVideo(true);
    localStorage.setItem(
      "projectVideo",
      JSON.stringify(URL.createObjectURL(e.target.files[0]))
    );

    Upload(e.target);

    readFileDataAsBase64(e).then((val) => {
      setvideobytes(val);
    });
    setfile(e.target.files[0]);
  };

  const removePresentation = () => {
    setshowVideo(false);
    setfile({});
    setvideobytes("");
  };

  const handleSubmitFunction = (values) => {
    let projectObj = {
      projectName: values.projectName,
      projectDesc: values.projectDesc,
      ideaType:
        Platform.OS != "Android" ? ideaTypes.map((e) => e.text) : ideaTypes,
      ideaTech:
        Platform.OS != "Android" ? ideaTechs.map((e) => e.text) : ideaTechs,
      region: user.region,
      video: videobytes,
      teamEmails: Platform.OS != "Android" ? emails.map((e) => e.text) : emails,
    };

    let adminObj = {
      name: user.name,
      nameNationalId: legal.nameid,
      address: legal.address,
      email: user.email,
      birthDate: user.birthDate,
      phoneNumber: user.phoneNumber,
      nationalId: legal.id,
      nationalIdIssuance: legal.idIssuance,
      CV: user.CV,
      password: user.password,
      isAdmin: user.isAdmin,
      participatedBefore: alreadyPart,
      sameIdea: sameIdea,
      won: winning,
      place: place,
      hackathonName: hackathonName,
      ideaPreviousName: ideaBeforeName,
    };
    let consentObj = {
      consentForm: consent.consentFormAdult
        ? consent.consentFormAdult
        : consent.consentFormGuard,
      consentCheck: consent.consentCheck,
    };
    changeDetails({
      ...project,
      projectName: formRef.current.values.projectName,
      projectDesc: formRef.current.values.projectDesc,
      ideaTypes:
        Platform.OS != "Android" ? ideaTypes.map((e) => e.text) : ideaTypes,
      ideaTechs:
        Platform.OS != "Android" ? ideaTechs.map((e) => e.text) : ideaTechs,
      region: user.region,
      projectVideo: project.projectVideo,
      teamEmails: Platform.OS != "Android" ? emails.map((e) => e.text) : emails,
    });
    setUser({
      ...user,
      participatedBefore: alreadyPart,
      sameIdea: sameIdea,
      won: winning,
      place: place,
    });
    handleSubmit({
      project: projectObj,
      admin: adminObj,
      consent: consentObj,
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        projectName: project.projectName,
        projectDesc: project.projectDesc,
        ideaType: project.ideaTypes,
        ideaTech: project.ideaTechs,
        projectVideo: project.projectVideo,
        teamEmails: project.teamEmails,
      }}
      validationSchema={Yup.object().shape({
        projectName: Yup.string()
          .min(5, "Minimum 5 characters")
          .max(50, "Maximum 50 characters")
          .required("Project name is required"),
        projectDesc: Yup.string()
          .min(8, "Minimum 8 characters")
          .max(5000, "Maximum 5000 characters")
          .required("Project Description is required"),
        ideaType: Yup.string().required("Idea type is required"),
        ideaTech: Yup.string().required("technology is required"),
      })}
      render={({ values, errors, touched, isValid }) => (
        <div className='user-details-container row m-0'>
          <div className='col-md-3 mr-5 p-0'>
            <Stepper
              BackStep={goBack}
              next={() => handleSubmitFunction(values)}
              setRecabtcha={setRecabtcha}
              disabled={
                values.projectName == "" ||
                values.projectDesc == "" ||
                ideaTypes.length == 0 ||
                ideaTechs.length == 0 ||
                (Object.keys(touched).length === 0 &&
                  touched.constructor === Object)
              }
            />
          </div>
          <div className='col-md-8 user-details-alignment scrolled'>
            <h3 className='title'>Submit Your Pitch</h3>

            <div className=' main-user-form-body-container project-details-form '>
              <div className='row'>
                <div className='form-group col-md-6  '>
                  <label
                    htmlFor='projectName'
                    className='label-format project-form-label'
                  >
                    Idea Name
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <Field
                    value={values.projectName}
                    type='text'
                    className={
                      touched.projectName && errors.projectName
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='projectName'
                    name='projectName'
                  />
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.projectName && errors.projectName
                        ? {
                            display: "block",
                            position: "absolute",
                            top: "36%",
                            right: "9%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  {touched.projectName && errors.projectName ? (
                    <div className='alert alert-danger'>
                      {errors.projectName}
                    </div>
                  ) : null}
                </div>

                <div className='form-group col-md-6'>
                  <label
                    htmlFor='projectDesc'
                    className='label-format project-form-label'
                  >
                    Description
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <Field
                    value={values.projectDesc}
                    type='textarea'
                    className={
                      touched.projectDesc && errors.projectDesc
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='projectDesc'
                    name='projectDesc'
                  />
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.projectDesc && errors.projectDesc
                        ? {
                            display: "block",
                            position: "absolute",
                            top: "36%",
                            right: "9%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  {touched.projectDesc && errors.projectDesc ? (
                    <div className='alert alert-danger'>
                      {errors.projectDesc}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* make component */}
              <div className='row mt-5'>
                <div className='form-group col-md-6 '>
                  <label
                    htmlFor='ideaType'
                    className='label-format project-form-label'
                  >
                    Sector
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  {Platform.OS != "Android" ? (
                    <>
                      <ReactTags
                        autofocus={false}
                        className={
                          ideaTypes.length == 0 &&
                          touched.ideaType &&
                          errors.ideaType
                            ? "text-box-error"
                            : "text-box"
                        }
                        tags={ideaTypes}
                        suggestions={ideaDomainSuggestions}
                        delimiters={delimiters}
                        handleDelete={(i) => {
                          setIdeaTypes(
                            ideaTypes.filter((tag, index) => index !== i)
                          );
                          values.ideaType = ideaTypes;
                          project.ideaTypes = ideaTypes;
                        }}
                        handleAddition={(tag) => {
                          setIdeaTypes([...ideaTypes, tag]);
                          values.ideaType = [...ideaTypes, tag];
                          project.ideaTypes = [...ideaTypes, tag];
                        }}
                        autocomplete
                      />
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        style={
                          ideaTypes.length == 0 &&
                          touched.ideaType &&
                          errors.ideaType
                            ? {
                                display: "block",
                                position: "relative",
                                bottom: "2rem",
                                left: "95%",
                              }
                            : { display: "none" }
                        }
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                          fill='#B3261E'
                        />
                      </svg>
                    </>
                  ) : (
                    <div className='container-center'>
                      <div className='tags-input-container'>
                        <div className='add-input-field'>
                          <Field
                            value={values.ideaType}
                            type='text'
                            className='form-control'
                            id='ideaType'
                            name='ideaType'
                          />
                          <span
                            className='add-type-btn'
                            onClick={() => addIdeaType(values)}
                          >
                            +
                          </span>
                        </div>
                        <div className='idea-types-tags'>
                          {ideaTypes &&
                            ideaTypes.map((idea, index) => {
                              return (
                                <span className='tag' key={index}>
                                  <span className='content'>{idea}</span>
                                  <span
                                    onClick={() => removeIdeaType(index)}
                                    className='rm-lnk'
                                  >
                                    x
                                  </span>
                                </span>
                              );
                            })}
                        </div>
                      </div>
                      {ideaTypes.length == 0 &&
                      touched.ideaType &&
                      errors.ideaType ? (
                        <div className='alert alert-danger idea'>
                          {errors.ideaType}
                        </div>
                      ) : null}
                    </div>
                  )}

                  {/* <TagsInput
                    value={values.ideaType}
                    onChange={setIdeaTypes}
                    name='Sector'
                    required
                  /> */}

                  <span className='hint row'>
                    <li className='col-12'>e.g. Health Care, Education, ...</li>
                    {Platform.OS != "Android" ? (
                      <li className='col-12'>
                        Press enter, tab or space to add sector
                      </li>
                    ) : null}
                  </span>
                </div>
                <div className='form-group col-md-6'>
                  <label
                    htmlFor='ideaTech'
                    className='label-format project-form-label'
                  >
                    Technologies
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  {Platform.OS != "Android" ? (
                    <>
                      <ReactTags
                        autofocus={false}
                        className={
                          ideaTechs.length == 0 &&
                          touched.ideaTech &&
                          errors.ideaTech
                            ? "text-box-error"
                            : "text-box"
                        }
                        tags={ideaTechs}
                        suggestions={ideaTechnologiesSuggestions}
                        delimiters={delimiters}
                        handleDelete={(i) => {
                          setIdeaTechs(
                            ideaTechs.filter((tag, index) => index !== i)
                          );
                          values.ideaTech = ideaTechs;
                          project.ideaTechs = ideaTechs;
                        }}
                        handleAddition={(tag) => {
                          setIdeaTechs([...ideaTechs, tag]);
                          values.ideaTech = [...ideaTechs, tag];
                          project.ideaTechs = [...ideaTechs, tag];
                        }}
                        autocomplete
                      />
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        style={
                          ideaTechs.length == 0 &&
                          touched.ideaTech &&
                          errors.ideaTech
                            ? {
                                display: "block",
                                position: "relative",
                                bottom: "2rem",
                                left: "95%",
                              }
                            : { display: "none" }
                        }
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                          fill='#B3261E'
                        />
                      </svg>
                    </>
                  ) : (
                    <div className='container-center'>
                      <div className='tags-input-container'>
                        <div className='add-input-field'>
                          <Field
                            // onKeyDown={(event) => addIdeaTech(values, event)}
                            value={values.ideaTech}
                            type='text'
                            className='form-control'
                            id='ideaTech'
                            name='ideaTech'
                          />
                          <span
                            onClick={() => addIdeaTech(values)}
                            className='add-tech-btn'
                          >
                            +
                          </span>
                        </div>
                        <div className='idea-techs-tags'>
                          {ideaTechs &&
                            ideaTechs.map((tech, index) => {
                              return (
                                <span className='tag' key={index}>
                                  <span className='content'>{tech}</span>
                                  <span
                                    onClick={() => removeIdeaTech(index)}
                                    className='rm-lnk'
                                  >
                                    x
                                  </span>
                                </span>
                              );
                            })}
                        </div>
                      </div>
                      {ideaTechs.length == 0 &&
                      touched.ideaTech &&
                      errors.ideaTech ? (
                        <div className='alert alert-danger idea'>
                          {errors.ideaTech}
                        </div>
                      ) : null}
                    </div>
                  )}
                  {/* <TagInput
                    value={values.ideaTech}
                    onChange={setIdeaTechs}
                    placeholder='Idea Technology'
                    options={["Planet", "Inclusion for All", "Digital Society"]}
                  /> */}
                  {/* <TagsInput
                    value={values.ideaTech}
                    onChange={setIdeaTechs}
                    name='Idea Technology'
                    required
                  /> */}
                  <span className='hint row'>
                    <li className='col-12'>e.g. Java, .Net, ...</li>
                    {Platform.OS != "Android" ? (
                      <li className='col-12'>
                        Press enter, tab or space to add technology
                      </li>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className=' mt-5'>
                <div className='form-group input-width-100'>
                  <div className='col-md-12 col-sm-12  pl-0'>
                    <form>
                      <div className='form-group  '>
                        <h3 htmlFor='projectname' className='label-format'>
                          Presentation Document
                        </h3>
                        {showVideo === false ? (
                          <>
                            {showVideo}
                            <div className='image-upload'>
                              <label for='file-input'>
                                <div className='file-input-container'>
                                  <img
                                    src='/assets/upload.svg'
                                    style={{ cursor: "pointer" }}
                                  ></img>

                                  <span className='file-input-container-text'>
                                    Browse File
                                  </span>
                                </div>
                              </label>
                              <input
                                style={{ display: "none" }}
                                id='file-input'
                                ref={ProjectVideoRef}
                                type='file'
                                className='CVUploader'
                                accept='video/mp4,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/zip'
                                size={100 * 1024 * 1024}
                                onChange={uploadFile}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='image-upload'>
                              <label for='file-input'>
                                <div className='file-input-container'>
                                  <img
                                    src='/assets/uploaded.svg'
                                    style={{ cursor: "pointer" }}
                                  ></img>
                                </div>
                              </label>
                              <input
                                style={{ display: "none" }}
                                id='file-input'
                                ref={ProjectVideoRef}
                                type='file'
                                className='CVUploader'
                                accept='video/mp4,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/zip'
                                size={100 * 1024 * 1024}
                                onChange={uploadFile}
                              />
                              <button
                                onClick={removePresentation}
                                className='mycvgrodeypdf  ml-2 pl-0'
                              >
                                <span className='change-file-btn-text'>
                                  Change File
                                </span>
                              </button>
                            </div>
                          </>
                        )}
                        {videoError !== "" ? (
                          <div className='alert alert-danger'>{videoError}</div>
                        ) : null}
                        <span className='hint row'>
                          <li className='col-12'>Must be Less Than 100 MB.</li>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='form-group mt-5'>
                <label
                  htmlFor='emails'
                  className='label-format project-form-label'
                >
                  Team Members
                </label>
                <br />

                <div className='container-center'>
                  <div className='tags-input-container'>
                    {Platform.OS != "Android" ? (
                      <>
                        <ReactTags
                          autofocus={false}
                          className={emailError ? "text-box-error" : "text-box"}
                          tags={emails}
                          delimiters={delimiters}
                          handleDelete={(i) => {
                            setemails(
                              emails.filter((tag, index) => index !== i)
                            );
                            values.teamEmails = emails;
                            project.teamEmails = emails;
                          }}
                          handleAddition={(tag) => {
                            var mailformat =
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            if (emails.length < 3) {
                              setEmailError("");

                              if (tag.text.match(mailformat)) {
                                setemails([...emails, tag]);
                                values.teamEmails = [...emails, tag];
                                project.teamEmails = [...emails, tag];
                                setEmailError("");
                              } else {
                                setEmailError(
                                  "Please enter a valid email address to invite them fill in their data"
                                );
                              }
                            }
                          }}
                        />
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          style={
                            emailError
                              ? {
                                  display: "block",
                                  position: "relative",
                                  bottom: "2rem",
                                  left: "95%",
                                }
                              : { display: "none" }
                          }
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                            fill='#B3261E'
                          />
                        </svg>
                      </>
                    ) : (
                      <div className='container-center'>
                        <div className='tags-input-container'>
                          {emails.length < 3 ? (
                            <div
                              className='add-input-field'
                              style={
                                emails.length == 0
                                  ? {}
                                  : { marginBottom: ".4rem" }
                              }
                            >
                              <Field
                                value={values.teamEmails}
                                type='text'
                                className='form-control'
                                id='teamEmails'
                                name='teamEmails'
                              />
                              <span
                                className='add-type-btn'
                                onClick={() => addemails(values)}
                              >
                                +
                              </span>
                            </div>
                          ) : null}
                          <div className='idea-types-tags'>
                            {emails &&
                              emails.map((idea, index) => {
                                return (
                                  <span className='tag' key={index}>
                                    <span className='content'>{idea}</span>
                                    <span
                                      onClick={() => removeemails(index)}
                                      className='rm-lnk'
                                    >
                                      x
                                    </span>
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {emailError ? (
                  <div className='alert alert-danger idea'>{emailError}</div>
                ) : null}
                <span className='hint '>
                  <li className='col-12'>
                    {" "}
                    Add up to 3 team members by using members emails
                  </li>
                  {Platform.OS != "Android" ? (
                    <li className='col-12'>
                      Press enter, tab or space to add team members emails
                    </li>
                  ) : null}
                </span>
              </div>
              <div className='form-group col-md-12 mt-5 pl-0'>
                <label htmlFor='name' className='full-name label-format'>
                  Have you participated in a previous Hackathon?
                  <span className='required'> *</span>
                  <span className='required ml-1'> (required)</span>
                </label>
                <div className='boolean-div'>
                  <div
                    class='form-check '
                    onChange={(e) => {
                      setAlreadyPart(true);
                    }}
                  >
                    <label className='full-name label-format'>
                      <input
                        type='radio'
                        class='form-check-input'
                        name='optradio'
                        value={true}
                      />
                      Yes
                    </label>
                  </div>
                  <div
                    class='form-check ml-5'
                    onChange={(e) => {
                      setAlreadyPart(false);
                      setSameIdea(undefined);
                      setWinning(undefined);
                      setPlace("Not Applicable");

                      setIdeaBeforeName(undefined);
                      setHackathonName(undefined);
                    }}
                  >
                    <label className='full-name label-format'>
                      <input
                        type='radio'
                        class='form-check-input'
                        name='optradio'
                        value={false}
                        style={{ color: "#e60000" }}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {alreadyPart ? (
                <>
                  <div className='row mt-5 pl-0'>
                    <div className='form-group col-md-6 '>
                      <label
                        htmlFor='hackathonName'
                        className='label-format project-form-label'
                      >
                        Please write the Hackathon's name
                        <span className='required'> *</span>
                        <span className='required ml-1'> (required)</span>
                      </label>
                      <Field
                        value={hackathonName}
                        type='text'
                        className='text-box'
                        id='hackathonName'
                        name='hackathonName'
                        onChange={(e) => setHackathonName(e.target.value)}
                      />
                    </div>
                    <div className='form-group col-md-6 '>
                      <label htmlFor='name' className='full-name label-format'>
                        Have you submitted the same idea?
                        <span className='required'> *</span>
                        <span className='required ml-1'> (required)</span>
                      </label>
                      <div className='boolean-div'>
                        <div
                          class='form-check '
                          onChange={(e) => {
                            setSameIdea(true);
                            setIdeaBeforeName(undefined);
                            setWinning(undefined);
                            setPlace("Not Applicable");
                          }}
                        >
                          <label className='full-name label-format'>
                            <input
                              type='radio'
                              class='form-check-input'
                              name='optradio2'
                              value={true}
                            />
                            Yes
                          </label>
                        </div>
                        <div
                          class='form-check ml-5'
                          onChange={(e) => {
                            setSameIdea(false);
                          }}
                        >
                          <label className='full-name label-format'>
                            <input
                              type='radio'
                              class='form-check-input'
                              name='optradio2'
                              value={false}
                              style={{ color: "#e60000" }}
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {sameIdea == false ? (
                    <>
                      <div className='row mt-5 pl-0'>
                        <div className='form-group col-md-6  '>
                          <label
                            htmlFor='ideaBeforeName'
                            className='label-format project-form-label'
                          >
                            Please write the idea's name
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <Field
                            value={ideaBeforeName}
                            type='text'
                            className='text-box'
                            id='ideaBeforeName'
                            name='ideaBeforeName'
                            onChange={(e) => setIdeaBeforeName(e.target.value)}
                          />
                        </div>
                        <div className='form-group col-md-6 '>
                          <label
                            htmlFor='name'
                            className='full-name label-format'
                          >
                            Have you won a previous Hackathon?
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <div className='boolean-div'>
                            <div
                              class='form-check '
                              onChange={(e) => {
                                setWinning(true);
                              }}
                            >
                              <label className='full-name label-format'>
                                <input
                                  type='radio'
                                  class='form-check-input'
                                  name='optradio3'
                                  value={true}
                                />
                                Yes
                              </label>
                            </div>
                            <div
                              class='form-check ml-5'
                              onChange={(e) => {
                                setWinning(false);
                              }}
                            >
                              <label className='full-name label-format'>
                                <input
                                  type='radio'
                                  class='form-check-input'
                                  name='optradio3'
                                  value={false}
                                  style={{ color: "#e60000" }}
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='form-group input-width-100 col-md-6 pl-0 mt-5'>
                        <label
                          htmlFor='name'
                          className='full-name label-format'
                        >
                          If you have won a previous Hackathon, which place did
                          you get?
                          <span className='required'> *</span>
                          <span className='required ml-1'> (required)</span>
                        </label>
                        <select
                          class='form-control'
                          id='sel1'
                          value={place}
                          onChange={(e) => {
                            setPlace(e.target.value);
                          }}
                        >
                          <option>Not Applicable</option>
                          <option>1st Place</option>
                          <option>2nd Place</option>
                          <option>3rd Place</option>
                          <option>4th Place</option>
                          <option>5th Place</option>
                          <option>6th Place</option>
                          <option>7th Place</option>
                          <option>8th Place</option>
                          <option>9th Place</option>
                          <option>10th Place</option>
                        </select>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              <div className='btn-next-container'>
                <div
                  className='back-btn-container'
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  style={{ visibility: "visible" }}
                >
                  <img src='/assets/back-arrow.svg'></img>
                  <button
                    type='button'
                    className='back-btn'
                    onClick={() => setGoBack(true)}
                  >
                    BACK
                  </button>
                </div>
                {verifyStep === "recaptcha" ? (
                  <div
                    className='reCaptcha-container'
                    style={{ height: "55px", width: "144px" }}
                  >
                    <ReCAPTCHA
                      sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                      theme='dark'
                      onChange={(value) => onChangeRecaptcha(value)}
                    />
                  </div>
                ) : (
                  <button
                    // type='submit'
                    onClick={() => {
                      handleSubmitFunction(values);
                    }}
                    type='button'
                    disabled={
                      values.projectName == "" ||
                      values.projectName.length < 5 ||
                      values.projectName.length >= 50 ||
                      values.projectDesc == "" ||
                      values.projectDesc.length < 8 ||
                      values.projectDesc.length >= 5000 ||
                      ideaTypes.length == 0 ||
                      ideaTechs.length == 0 ||
                      emailError != "" ||
                      alreadyPart == undefined ||
                      (alreadyPart == true &&
                        (hackathonName == undefined ||
                          sameIdea == undefined ||
                          (sameIdea == false &&
                            (ideaBeforeName == undefined ||
                              winning == undefined)))) ||
                      (Object.keys(touched).length === 0 &&
                        touched.constructor === Object)
                    }
                    className={
                      values.projectName == "" ||
                      values.projectName.length < 5 ||
                      values.projectName.length >= 50 ||
                      values.projectDesc == "" ||
                      values.projectDesc.length < 8 ||
                      values.projectDesc.length >= 5000 ||
                      ideaTypes.length == 0 ||
                      ideaTechs.length == 0 ||
                      emailError != "" ||
                      alreadyPart == undefined ||
                      (alreadyPart == true &&
                        (hackathonName == undefined ||
                          sameIdea == undefined ||
                          (sameIdea == false &&
                            (ideaBeforeName == undefined ||
                              winning == undefined)))) ||
                      (Object.keys(touched).length === 0 &&
                        touched.constructor === Object)
                        ? "next-btn-disabled"
                        : "next-btn-egypt"
                    }
                  >
                    SUBMIT
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};
export default ProjectDetailsForm;
