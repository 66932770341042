import "./consentFormAdult.css";

import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ConsentContext } from "../../../../contexts/consentContext";
import jsPDF from "jspdf";
import Loader from "../../../common/Loader";
import * as htmlToImage from "html-to-image";
import { ProjectContext } from "../../../../contexts/projectContext";

import Stepper from "../stepper/stepper";
import { GuardianContext } from "../../../../contexts/guardianContext";

export default function ConsentFormAdult() {
  const [consentDetails, consentChangeDetails] =
    React.useContext(ConsentContext);
  const [guardianDetails, guardianChangeDetails] =
    React.useContext(GuardianContext);
  const [toggle, setToggle] = React.useState(consentDetails.consentCheck);
  const [toggle2, setToggle2] = React.useState(consentDetails.privacyCheck);
  const [show, setShow] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [privacyURL, setprivacyURL] = useState("");
  async function pdffile(url, name, defaultType = "*/*") {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });

    return URL.createObjectURL(data);
  }
  useEffect(() => {
    pdffile("/assets/egypt_privacy.pdf", "egypt_privacy.pdf").then((res) => {
      setprivacyURL(res);
    });
  }, []);
  const getConsent = () => {
    if (consentDetails.consentCheck) {
      setShow(true);
      htmlToImage
        .toPng(document.getElementById("divToConvert"), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-image-name.jpeg";
          const pdf = new jsPDF();
          const imgProps = pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
          // pdf.save("ConsentPDF.pdf");
          let f = new Blob([pdf.output("blob")], {
            type: "application/pdf",
          });
          var reader = new FileReader();
          reader.readAsDataURL(f);
          reader.onload = function () {
            consentDetails.consentFormAdult = reader.result;
            consentChangeDetails(consentDetails);
            setShow(false);
          };
        });
    }
  };

  function toggleCheck(checked) {
    if (checked) {
      consentDetails.consentCheck = checked;
      setToggle(checked);
      getConsent();
    } else {
      consentDetails.consentCheck = checked;
      setToggle(checked);
    }
  }
  function toggleCheck2(checked) {
    if (checked) {
      consentDetails.privacyCheck = checked;
      setToggle2(checked);
    } else {
      consentDetails.privacyCheck = checked;
      setToggle2(checked);
    }
  }
  return (
    <div className='row user-details-container'>
      <div className='col-md-3 mr-5 p-0'>
        {" "}
        <Stepper
          next={() => {}}
          disabled={!toggle}
          NextStep={goNext}
          BackStep={goBack}
        />
      </div>
      <div className='col-md-8 scrolled user-details-alignment'>
        <Loader show={show} />
        <div className='consent-card-header-container grey-3 col-lg-6  col-md-6 col-sm-6 col-xs-6 mb-4 p-0'>
          <h4 className='consent-card-header-content'>Consent </h4>
          {/* <p className="card-text blockquote-footer">
                (above 21 years old)
              </p> */}
        </div>
        {/* {privacyURL != "" ? (
          <div className='consent-privacy mb-4'>
            <embed
              className='consent-privacy-content'
              src={privacyURL}
              width='100%'
              height='200vh'
            />
          </div>
        ) : null} */}
        <div id='divToConvert' className='card-body'>
          <h5 className='card-title'>
            إقرار بالموافقة لإستخدام البيانات الشخصية
          </h5>
          <p className='card-text'>
            أقر أنا {guardianDetails.nameid} بطاقة رقم قومي {guardianDetails.id}{" "}
            بأنني وبكامل ارادتى الحرة أوافق موافقة صريحة على تقديم بياناتى
            الشخصية لشركة فودافون انترناشيونال سيرفيزيس ش.ذ.م.م أو من ينوب عنها،
            و استخدامها من قبل الشركه التى تشمل على سبيل المثال لا الحصر الاسم و
            العنوان و رقم التليفون و السيرة الذاتية و صور شخصية (اختيارية) و
            فكرة المشروع المطروحة و ملف فيديو يشمل الفكرة المطروحة و كذلك ملفات
            الفيديو المصورة لدى شركة فودافون انترناشيونال سيرفيزيس ش.ذ.م.م خلال
            احداث و فاعليلات الهاكاثون ، كما أقر بعلمى التام بأن بيناتى الشخصية
            سيتم التعامل معها بس من خلال شركة فودافون انترناشيونال سيرفيزيس
            ش.ذ.م.م أو من ينوب خلال فترة الهاكاثون و لفترة لاحقة كلما هو موضح
            تفصيلا فى الفقرات السابقة من هذا الاقرار. ويعتبر هذا الإقرار منتجا
            لكافة آثاره القانونية ما لم أتقدم بطلب إلى الشركة بطلب سحب هذه
            الموافقة وذلك من خلال هذا{" "}
            <NavLink to='/revoke' style={{ color: "#e60000 !important" }}>
              الرابط
            </NavLink>{" "}
            . ولا يخل هذا الإقرار بأية حقوق أخرى مقررة للشركة.
            <br /> الاسم: {guardianDetails.nameid}
            <br />
            التاريخ: {new Date().toLocaleDateString()}
            <br />
            التوقيع: {guardianDetails.nameid}
          </p>
        </div>

        <div className='mb-3 input-width-100 custom-padding-left mt-4'>
          <div>
            <input
              className='form-check1-input'
              type='checkbox'
              checked={toggle}
              id='defaultCheck1'
              onChange={(e) => toggleCheck(e.target.checked)}
            />

            <label className='team-leader  white-1 ml-2'>
              I have read and agree to the Consent.
            </label>
          </div>
          {/* <div>
            <input
              className='form-check1-input'
              type='checkbox'
              checked={toggle2}
              id='defaultCheck2'
              onChange={(e) => toggleCheck2(e.target.checked)}
            />

            <label className='team-leader  white-1 ml-2'>
              I have read and agree to the{" "}
              <a
                href={privacyURL}
                target={"_blank"}
                style={{ color: "#e60000 !important" }}
              >
                Privacy Policy
              </a>
              .
            </label>
          </div> */}
        </div>
        <div className='btn-next-container'>
          <div
            className='back-btn-container'
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            style={{ visibility: "visible" }}
          >
            <img src='/assets/back-arrow.svg'></img>
            <button
              type='button'
              className='back-btn'
              onClick={() => setGoBack(true)}
            >
              BACK
            </button>
          </div>
          <button
            // type='submit'
            onClick={() => {
              setGoNext(true);
            }}
            type='button'
            disabled={!toggle}
            className={!toggle ? "next-btn-disabled" : "next-btn-egypt"}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
}
