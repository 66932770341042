import React from "react";

const SearchUser = (props) => {
  return (
    <div className="col-md-11 ml-5 text-center input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Search For User"
        aria-label="Search For User"
        aria-describedby="basic-addon2"
        value={props.searchKey}
        onChange={props.searchKeyChangeHandler}
      />
      <div className="input-group-append">
        <button
          onClick={async () => await props.searchButtonClickHandler()}
          className="btn btn-outline-primary"
        >
          Search For User
        </button>
      </div>
    </div>
  );
};

export default SearchUser;
