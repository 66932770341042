import axios from "axios";
import React, { useState, useEffect } from "react";
import SuperAdminNavBar from "./SuperAdminNavBar";
import { getCurrentUser, setImpersonateJWT } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import styles from "./superadminProjectsinnerPage.module.css";
function CoachRoom() {
  const [coaches, setcoaches] = useState([]);
  const [roomOwner, setroomOwner] = useState("");

  const [roomID, setroomID] = useState("");
  const [linkID, setlinkID] = useState("");
  const [imageLink, setimageLink] = useState("");
  const [Link, setLink] = useState("");
  const [Name, setName] = useState("");

  const history = useNavigate();
  useEffect(() => {
    getCoachrooms();
  }, []);
  const getCoachrooms = () => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}coach`, {}).then((res) => {
      setcoaches(res.data);
    });
  };

  const getUserEmail = (email) => {
    setImpersonateJWT(email);
    history("/entrance");
  };

  const addRoom = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/add`, {
        name: Name,
        imageLink: imageLink,
      })
      .then((res) => {
        getCoachrooms();
        setName("");
        setimageLink("");
      });
  };
  const editRoom = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/edit`, {
        id: roomID,
        name: Name,
        imageLink: imageLink,
      })
      .then((res) => {
        getCoachrooms();
        setName("");
        setimageLink("");
      });
  };
  const deleteRoom = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/delete`, {
        _id: id,
      })
      .then((res) => {
        getCoachrooms();
      });
  };
  const addLink = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/addlink`, {
        Link: Link,
        coachRoom: roomID,
        email: roomOwner.toLowerCase(),
      })
      .then((res) => {
        getCoachrooms();
        setLink("");
        setroomID("");
        setroomOwner("");
      });
  };
  const deleteRoomLink = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/deletelink`, {
        _id: id,
      })
      .then((res) => {
        getCoachrooms();
      });
  };
  const changeRoomLink = (id, newstatus) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}coach/setroomdiability`, {
        id: id,
        status: newstatus,
      })
      .then((res) => {
        getCoachrooms();
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='addRoomLink'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Room Link
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Owner Email:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setroomOwner(el.target.value);
                  }}
                  value={roomOwner}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Room Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setLink(el.target.value);
                  }}
                  value={Link}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={addLink}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='addRoom'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Room
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Room Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setName(el.target.value);
                  }}
                  value={Name}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Image Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setimageLink(el.target.value);
                  }}
                  value={imageLink}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={addRoom}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editRoom'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Room
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Room Name:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setName(el.target.value);
                  }}
                  value={Name}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Image Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setimageLink(el.target.value);
                  }}
                  value={imageLink}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={editRoom}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div className={" col-md-10"}>
              <button
                className='btn btn-outline-dark float-right m-2'
                data-toggle='modal'
                data-target='#addRoom'
              >
                + Add Room
              </button>
              <div className={styles["main-container"] + " row mt-5"}>
                {coaches.map((r) => (
                  <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                    <div className={styles["region-box-header"]}>
                      <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                        {r.name}
                      </p>
                      <div className='float-right'>
                        <span
                          className='fa fa-pencil'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          data-toggle='modal'
                          data-target={"#editRoom"}
                          onClick={() => {
                            setroomID(r._id);
                            setName(r.name);
                            setimageLink(r.imageLink);
                          }}
                        ></span>
                        <span
                          className='fa fa-trash ml-2'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            deleteRoom(r._id);
                          }}
                        ></span>
                        <span
                          className='ml-2'
                          data-toggle='modal'
                          data-target={"#addRoomLink"}
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            setroomID(r._id);
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    {r.Links.length != 0 ? (
                      r.Links.map((x) => {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                              <a href={x.Link} target='_blank'>
                                {x.email}
                              </a>
                              <div className='float-right'>
                                {x.isDisabled ? (
                                  <span
                                    className='ml-2'
                                    style={{
                                      cursor: "pointer",
                                      color: "#e60000",
                                      fontSize: "16px",
                                    }}
                                    onClick={() => {
                                      changeRoomLink(x._id, !x.isDisabled);
                                    }}
                                  >
                                    x
                                  </span>
                                ) : (
                                  <span
                                    className='fa fa-check ml-2'
                                    style={{
                                      cursor: "pointer",
                                      color: "#e60000",
                                      fontSize: "16px",
                                    }}
                                    onClick={() => {
                                      changeRoomLink(x._id, !x.isDisabled);
                                    }}
                                  ></span>
                                )}
                                <span
                                  className='fa fa-trash ml-2'
                                  style={{
                                    cursor: "pointer",
                                    color: "#e60000",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    deleteRoomLink(x._id);
                                  }}
                                ></span>

                                <span
                                  className='fa fa-user ml-2'
                                  style={{
                                    cursor: "pointer",
                                    color: "#e60000",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    getUserEmail(x.email);
                                  }}
                                ></span>
                              </div>
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <h4>No Room Links were added</h4>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachRoom;
