import React, { useEffect } from "react";
import "./mosaicWall.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import LoggedNavBar from "../../common/navBar/loggedNav";
import SideMenu from "../../common/sideMenu/sideMenu";
function Mosaicwall() {
  const [imageLinks, setImageLinks] = React.useState([]);
  const [imageBytes, setImageBytes] = React.useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}mosaicWall`).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          imageLinks.push(res.data[i]);

          axios.get(`${res.data[i]}`).then((res) => {
            imageBytes.push(res.data);
            if (imageLinks.length === imageBytes.length) {
              setLoadBytes(true);
            }
          });
        }
      }
    });
  }, []);

  const imageContainer = () => {
    return imageBytes.map((img) => {
      console.log(img);
      return (
        <div className='asc-mainframe asc-photobooth'>
          <img
            src='/assets/glb/newmodel/BACKGROUND_ONLY.svg'
            className='asc-frame-bg'
          ></img>
          <img
            src='/assets/photoboothFrame2.png'
            className='asc-frame'
            style={{ width: "16rem" }}
          ></img>
          <img src={img} width='225' height='225' className='asc-video'></img>
        </div>
      );
    });
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
              className='col-md-12 col-xs-12  mt-5'
            >
              <div
                // className='asc-mosaic-background '
                style={{
                  position: "relative",
                  left: "4px",
                  overflow: "hidden",
                  width: "95%",
                }}
              >
                {loadBytes === true ? (
                  <>
                    <div className='row' id='mosaicContainer'>
                      {imageContainer()}
                    </div>
                  </>
                ) : (
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mosaicwall;
