// import Mosaic from "react-photo-mosaic";
import React, { useEffect, useState } from "react";
const MosaicExample = (props) => {
  const props2 = {
    images: props.images,
    mainImagePath: props.source,

    width: 1219,
    height: 560,
    maxBlocksInRow: 30,
    spaceBetween: 0,
    mainImageStyle: { opacity: 0.6 },

    onClick: (e, url) => {
      window.open(url, "_blank");
    },
  };
  return <div></div>;
};
export default MosaicExample;
