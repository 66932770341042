import { useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import axios from "axios";
import React, { useState, useEffect } from "react";
import styles from "./agendaConfig.module.css";
import DateTimePicker from "react-datetime-picker";
import * as XLSX from "xlsx";
import * as _ from "lodash";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function AgendaConfig() {
  const forceUpdate = useForceUpdate();
  let location = useLocation();
  const [region, setregion] = useState("");

  const [agendas, setagendas] = useState([]);
  const [ID, setID] = useState("");
  const [addingregion, setaddingregion] = useState("egypt");
  const [host, setHost] = useState("");
  const [hostTitle, setHosttitle] = useState("");
  const [title, setTitle] = useState("");
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [groupsuploaded, setGroupsUploadedEmails] = React.useState([]);
  const [uploadErrors, setUploadErrors] = React.useState([]);
  const getagenda = () => {
    setagendas([]);

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}newagenda`, {})
      .then((res) => {
        setagendas(res.data);
      });
  };
  useEffect(() => {
    setregion(
      localStorage.getItem("region") != "all" ? location.state.region : "all"
    );
    getagenda();
  }, [region, location.state]);
  const Addagenda = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}newagenda/add`, {
        startDate: startDateTime,
        hostTitle,
        title,
        host,
      })
      .then((res) => {
        getagenda();
        setStartDateTime(new Date());
        setHost("");
        setHosttitle("");
        setTitle("");
      });
  };
  const Editagenda = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}newagenda/edit`, {
        id: ID,
        startDate: startDateTime,
        hostTitle,
        title,
        host,
      })
      .then((res) => {
        getagenda();
        setID("");
        setStartDateTime(new Date());
        setHost("");
        setHosttitle("");
        setTitle("");
      });
  };
  const deleteagenda = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}newagenda/delete`, {
        _id: id,
      })
      .then((res) => {
        getagenda();
        setID("");
      });
  };
  const addfiledataforAgenda = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      let x = groupsuploaded;
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        x.push({
          host: element[0],
          hostTitle: element[1],
          title: element[2],

          startDate: strToDate(element[3]),
        });
        setGroupsUploadedEmails(x);
        forceUpdate();
      }
      submitAgendasheet(x);
    };
    reader.readAsBinaryString(f);
  };
  const submitAgendasheet = (data) => {
    document.getElementById("groupsupload").value = "";
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}newagenda/addmany`, {
        agendas: data,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((ex) => {
        setUploadErrors(ex.response.data);
      });
  };
  function strToDate(dtStr) {
    if (!dtStr) return null;
    let dateParts = dtStr.split("/");
    let timeParts = dateParts[2].split(" ")[1].split(":");
    dateParts[2] = dateParts[2].split(" ")[0];
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(
      +dateParts[2],
      dateParts[1] - 1,
      +dateParts[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    );
  }
  return (
    <>
      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Agenda
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Host:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setHost(el.target.value);
                  }}
                  value={host}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Host Title:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setHosttitle(el.target.value);
                  }}
                  value={hostTitle}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Title:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setTitle(el.target.value);
                  }}
                  value={title}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Start Date Time:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setStartDateTime}
                  value={startDateTime}
                ></DateTimePicker>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={Addagenda}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='editModal'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Agenda
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Host:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setHost(el.target.value);
                  }}
                  value={host}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Host Title:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setHosttitle(el.target.value);
                  }}
                  value={hostTitle}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Title:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setTitle(el.target.value);
                  }}
                  value={title}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Start Date Time:</p>
                <DateTimePicker
                  style={{ borderRadius: "10px", width: "100%" }}
                  rows='10'
                  onChange={setStartDateTime}
                  value={startDateTime}
                ></DateTimePicker>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={Editagenda}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='nav-button-padding col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>

            <div className='col-md-10'>
              <div style={{ position: "absolute", right: "2rem" }}>
                <input
                  type='file'
                  onChange={addfiledataforAgenda}
                  id='groupsupload'
                  style={{ display: "none" }}
                ></input>
                <a
                  href='/hackathon Agenda_upload.xlsx'
                  download='hackathon Agenda_upload'
                  target='_blank'
                >
                  <button className='btn btn-outline-dark float-right m-2'>
                    Download Agenda Template
                  </button>
                </a>
                <button
                  className='btn btn-outline-dark float-right m-2'
                  onClick={() => {
                    document.getElementById("groupsupload").click();
                  }}
                >
                  Upload Agenda
                </button>
                <button
                  className='btn btn-outline-dark float-right m-2'
                  data-toggle='modal'
                  data-target='#exampleModal'
                >
                  + Add Agenda
                </button>
              </div>
              <div
                className='row mt-4 mb-4'
                style={{
                  padding: "2rem 0",
                  maxHeight: "100vh",
                  overflow: "auto",
                }}
              >
                {agendas.map((r) => (
                  <div
                    className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div className={styles["region-box-header"]}>
                      <div className='float-right'>
                        <span
                          className='fa fa-pencil mr-2'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          data-toggle='modal'
                          data-target={"#editModal"}
                          onClick={() => {
                            setStartDateTime(new Date(r.startDateTime));
                            setHost(r.host);
                            setTitle(r.title);
                            setID(r._id);
                            setHosttitle(r.hostTitle);
                          }}
                        ></span>
                        <span
                          className='fa fa-trash'
                          style={{
                            cursor: "pointer",
                            color: "#e60000",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            deleteagenda(r._id);
                          }}
                        ></span>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >
                        {r.host}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >
                        {r.title}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >
                        {new Date(r.startDateTime).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgendaConfig;
