import React from "react";

const UserPagination = ({ totalUsers, usersPerPage, paginate, currPage }) => {
  const pageNumbers = [];

  if (Math.ceil(totalUsers / usersPerPage) >= 24) {
    for (let i = 1; i <= 24; i++) {
      pageNumbers.push(i);
    }
  } else {
    for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <nav className='col-md-11 ml-5 text-center mt-2'>
      <ul className='pagination'>
        <li className='page-item'>
          <button className='page-link' onClick={() => paginate("-")}>
            Previous
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={currPage === number ? "page-item active" : "page-item"}
          >
            <button onClick={() => paginate(number)} className='page-link'>
              {number}
            </button>
          </li>
        ))}
        <li className='page-item'>
          <button className='page-link' onClick={() => paginate("+")}>
            NEXT
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default UserPagination;
