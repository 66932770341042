import React from "react";
import { useNavigate } from "react-router-dom";
import LoggedNavBar from "../common/navBar/loggedNav";
import "./breakroomListing.css";
function BreakroomListing() {
  const history = useNavigate();
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='breakroomLising-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className='col-md-6 col-xs-12 breakroom-separating-line '
            >
              <img
                src='/assets/Online games addiction-pana.svg'
                className='breakroom-img'
              ></img>
              <h2 className='breakroom-title'>Games</h2>

              <span
                style={{
                  color: "white",
                  font: "normal normal normal 24px/28px Vodafone Rg",
                }}
              >
                Have A Break And Try Our
              </span>
              <span
                style={{
                  color: "white",
                  font: "normal normal normal 24px/28px Vodafone Rg",
                }}
              >
                Games
              </span>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className='mt-3'
              >
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    font: "normal normal normal 24px/28px Vodafone Rg",
                    borderRadius: "10px",
                    padding: "0.5rem 3.5rem",
                    border: "2px solid #FFFFFF",
                  }}
                  onClick={() => {
                    history("/game/2");
                  }}
                >
                  Remember Cards
                </button>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    font: "normal normal normal 24px/28px Vodafone Rg",
                    borderRadius: "10px",
                    padding: "0.5rem 3.5rem",

                    border: "2px solid #FFFFFF",
                    marginTop: ".5rem",
                  }}
                  onClick={() => {
                    history("/game/1");
                  }}
                >
                  Sudoku
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className='col-md-6 col-xs-12 '
            >
              <img src='/assets/Chat-pana.svg' className='breakroom-img'></img>
              <h2 className='breakroom-title'>Chat Rooms</h2>
              <span
                style={{
                  color: "white",
                  font: "normal normal normal 24px/28px Vodafone Rg",
                }}
              >
                Join Different Chat Rooms To Gain
              </span>
              <span
                style={{
                  color: "white",
                  font: "normal normal normal 24px/28px Vodafone Rg",
                }}
              >
                Experience With Your Field
              </span>

              <div
                style={{ display: "flex", flexDirection: "column" }}
                className='mt-3'
              >
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    font: "normal normal normal 24px/28px Vodafone Rg",
                    borderRadius: "10px",
                    padding: "0.5rem 3.5rem",
                    border: "2px solid #FFFFFF",
                  }}
                  onClick={() => {
                    history("/chatLogin?joinroom=true");
                  }}
                >
                  Join Room
                </button>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    font: "normal normal normal 24px/28px Vodafone Rg",
                    borderRadius: "10px",
                    padding: "0.5rem 3.5rem",
                    border: "2px solid #FFFFFF",
                    marginTop: "0.5rem",
                  }}
                  onClick={() => {
                    history("/chatLogin?joinroom=false");
                  }}
                >
                  Create Room
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreakroomListing;
