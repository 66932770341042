import "./style.css";
import LandingNavBar from "../../components/home/navbar";
import AccordionComponent from "./Accordion";
const faqs = [
  {
    title: "Who can participate in the Hackathon?",
    descrition:
      "We welcome all professionals of technical, academic and/or professional backgrounds, with no age limit. If you are a student, preferably to be a senior in the last two years of your studies. ​",
  },
  {
    title: "Do I have to pay any registration fee?",
    descrition: "Registration is completely free of charge.​",
  },
  {
    title: "How will you conduct the Hackathon – online or offline?",
    descrition: "Our 2024 Hackathon will take place online.​",
  },
  {
    title: "How many rounds are there in the competition?",
    descrition: "There will be one application stage and 3 Hackathon rounds.​",
  },
  {
    title: "Can I participate if I do not have a team?",
    descrition: "Yes, you can compete individually as well.",
  },
  {
    title: "Can I form my own team? What is the maximum size of a team?",
    descrition: "The team size should be 2 and up to 4 members maximum.​",
  },
  {
    title: "Is this an international competition?",
    descrition:
      "Yes, the competition will have teams from Egypt, India, and Romania participating internationally.​",
  },
  {
    title: "How do I register?",
    list: [
      `Please use the registration form on our webpage: https://${window.location.host} .`,
      `Please note that, in order to complete the registration process, you must submit your pitch.<br/>Once submitted, you cannot edit it later.`,
    ],
  },
  {
    title: "What are the submission requirements?",
    descrition: `Please refer to the competition guidelines and the terms and conditions.​`,
  },
  {
    title: "What is the deadline for submissions?",
    descrition: `February 24, 2024​`,
  },
  {
    title: "Can I submit more than one solution?",
    descrition: `No, you can only submit one solution.`,
  },
];
const FAQMain = () => {
  return (
    <div className='faq-page-wrapper container-fluid'>
      <LandingNavBar></LandingNavBar>
      <div className='row d-flex justify-content-center mt-4'>
        <div className='col-lg-12 col-md-6 col-xs-6 col-sm-6'>
          <span class='underline'>
            {" "}
            <p className='faq-page-title '>FREQUENTLY ASKED QUESTIONS</p>
          </span>
          <div className='faq-page-subtitle mt-4'>
            Have a question? We’re here to help
          </div>
        </div>
        <div className='col-lg-6 col-md-8 col-xs-6 col-sm-6 faq-accodrion-container  mt-5 '>
          {faqs.map((e, i) => (
            <AccordionComponent
              title={e.title}
              description={e.descrition}
              list={e.list}
              index={i}
            ></AccordionComponent>
          ))}{" "}
        </div>
      </div>
    </div>
  );
};

export default FAQMain;
