import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import * as XLSX from "xlsx";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import styles from "./dashboard.module.css";
function Dashboard() {
  let location = useLocation();
  const [region, setregion] = useState("");
  const [counters, setcounters] = useState({});
  const [registrationData, setregistrationData] = useState();
  const [generalData, setgeneralData] = useState();
  const [generatedLinksData, setgeneratedLinksData] = useState();
  const [egyptCounter, setEgyptCounter] = useState(0);
  const [indiaCounter, setIndiaCounter] = useState(0);
  const [romaniaCounter, setRomaniaCounter] = useState(0);
  useEffect(() => {
    setregion(
      localStorage.getItem("region") != "all" ? location.state.region : "all"
    );
    getdashBoard();
    getdashBoardRegister();
    getdashBoardgeneral();
    getdashBoardLink();
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "egypt",
        }
      )
      .then((res) => {
        setEgyptCounter(res.data.result);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "india",
        }
      )
      .then((res) => {
        setIndiaCounter(res.data.result);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "romania",
        }
      )
      .then((res) => {
        setRomaniaCounter(res.data.result);
      });
  }, [region, location.state]);
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart3D);
    let chart2 = am4core.create("generalchartdiv", am4charts.PieChart3D);
    let chart3 = am4core.create("generatedLinkschartdiv", am4charts.PieChart3D);
    return () => {
      if (chart) {
        chart.dispose();
      }
      if (chart2) {
        chart2.dispose();
      }
      if (chart3) {
        chart3.dispose();
      }
    };
  }, []);
  const getdashBoard = () => {
    setcounters({});
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard`, {
        region: region,
      })
      .then((res) => {
        setcounters(res.data);
      });
  };
  const history = useNavigate();
  const getdashBoardRegister = () => {
    setregistrationData([]);
    let chart = am4core.create("chartdiv", am4charts.PieChart3D);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/register`, {
        region: region,
      })
      .then((res) => {
        setregistrationData(res.data);

        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el.region,
            litres: el.count,
            color: getRandomItem(),
          };
        });

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;

        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.value}";
        pieSeries.slices.template.events.on(
          "hit",
          function (ev) {
            history("/superadmin/project", {
              state: {
                region: ev.target.dataItem.category,
              },
            });
          },
          this
        );
      });
  };
  function getRandomItem() {
    let arr = [
      "#171717",
      "#2E2E2E",
      "#454545",
      "#5C5C5C",
      "#737373",
      "#8A8A8A",
      "#A1A1A1",
      "#B8B8B8",
      "#CFCFCF",
      "#DEDEDE",
    ];
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
  }
  const getdashBoardgeneral = () => {
    setgeneralData([]);

    let chart = am4core.create("generalchartdiv", am4charts.PieChart);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/general`)
      .then((res) => {
        setgeneralData(res.data.sort((a, b) => b.count - a.count));

        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el.link,
            litres: el.count,
            color: getRandomItem(),
          };
        });
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;
        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.value}";
      });
  };
  const getdashBoardLink = () => {
    setgeneratedLinksData([]);
    let chart = am4core.create("generatedLinkschartdiv", am4charts.PieChart3D);

    axios.get(`${process.env.REACT_APP_SERVER_URL}invetations/`).then((res) => {
      setgeneratedLinksData(res.data);

      chart.paddingRight = 20;
      chart.data = res.data.map((el) => {
        return {
          country: el.email,
          litres: el.count,
          color: getRandomItem(),
        };
      });

      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable tooltips
      pieSeries.slices.template.tooltipText = "";
      pieSeries.slices.template.propertyFields.fill = "color";
      chart.legend = new am4charts.Legend();
      chart.legend.maxHeight = 150;
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.text = "{value.value}";
    });
  };
  const exportgeneralToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      generalData.map((el) => {
        return { page: el.link, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Hits-Stats" + new Date() + ".xlsx");
  };
  const exportgeneratedLinks = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      generatedLinksData.map((el) => {
        return { email: el.email, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Influencing-Stats" + new Date() + ".xlsx");
  };
  const exportRegistrationToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      registrationData.map((el) => {
        return { region: el.region, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Registration-Stats" + new Date() + ".xlsx");
  };
  return (
    <div>
      <div
        className={styles["background"] + " container-fluid p-0"}
        style={{ overflowX: "hidden" }}
      >
        <div className='  nav-button-padding'>
          <SuperAdminNavBar></SuperAdminNavBar>
        </div>
        <div className={styles["line"]}></div>
        <div className={styles["main-page"] + " row"}>
          <div className={styles["card1"]}>
            <div className={styles["card1-header"]}>
              <span className={styles["card1-header-title"]}>
                REGISTRATION BY COUNTRY
              </span>
              <span className={styles["card1-header-subtitle"]}>
                Statistics according to every country
              </span>
            </div>
            <div className={styles["card1-body"] + " row"}>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["card1-boxes-title"]}>EGYPT</span>
                <img
                  className={styles["card1-boxes-img"]}
                  src='/assets/egypt.png'
                ></img>
                <span className={styles["card1-boxes-count"]}>
                  {egyptCounter}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["card1-boxes-title"]}>INDIA</span>
                <img
                  className={styles["card1-boxes-img"]}
                  src='/assets/india.png'
                ></img>
                <span className={styles["card1-boxes-count"]}>
                  {indiaCounter}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12"}>
                <span className={styles["card1-boxes-title"]}>ROMANIA</span>
                <img
                  className={styles["card1-boxes-img"]}
                  src='/assets/romania.png'
                ></img>
                <span className={styles["card1-boxes-count"]}>
                  {romaniaCounter}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["card1"]}>
            <div className={styles["card1-header"]}>
              <span className={styles["card1-header-title"]}>
                BEFORE FIRST PANEL
              </span>
              <span className={styles["card1-header-subtitle"]}>
                Statistics after opening the registration
              </span>
            </div>
            <div className={styles["card1-body"] + " row"}>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["card1-boxes-title"]}>PROJECTS</span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.totalProjects}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12"}>
                <span className={styles["card1-boxes-title"]}>
                  TEAM MEMBERS
                </span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.totalRegisteredTeammembers}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12"}>
                <span className={styles["card1-boxes-title"]}>
                  NON-SUBMITTED TEAM MEMBERS
                </span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.totalnonRegisteredTeammembers}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["card1"]}>
            <div className={styles["card1-header"]}>
              <span className={styles["card1-header-title"]}>
                AFTER FILTRATION PANEL
              </span>
              <span className={styles["card1-header-subtitle"]}>
                Statistics after the first filtration panel
              </span>
            </div>
            <div className={styles["card1-body"] + " row"}>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["approved-ribon"]}>Approved</span>
                <span className={styles["card1-boxes-title"]}>PROJECTS</span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.approvedProjects}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["pending-ribon"]}>Pending</span>
                <span className={styles["card1-boxes-title"]}>PROJECTS</span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.pendingProjects}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["rejected-ribon"]}>Rejected</span>
                <span className={styles["card1-boxes-title"]}>PROJECTS</span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.rejectedProjects}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["approved-ribon"]}>Approved</span>
                <span className={styles["card1-boxes-title"]}>
                  TEAM MEMBERS
                </span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.approvedTeammembers}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["pending-ribon"]}>Pending</span>
                <span className={styles["card1-boxes-title"]}>
                  TEAM MEMBERS
                </span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.pendingTeammembers}
                </span>
              </div>
              <div className={styles["card1-boxes"] + " col-md-3 col-xs-12 "}>
                <span className={styles["rejected-ribon"]}>Rejected</span>
                <span className={styles["card1-boxes-title"]}>
                  TEAM MEMBERS
                </span>

                <span className={styles["card1-boxes-count"]}>
                  {counters.rejectedTeammembers}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["card2"]}>
            <div className={styles["card1-header"]}>
              <span className={styles["card1-header-title"]}>HITS</span>
              <span className={styles["card1-header-subtitle"]}>
                Visitors statistics
              </span>
            </div>
            <div className={styles["card2-body"]}>
              <div className={styles["card2-boxes"]}>
                <span className={styles["card2-boxes-title"]}>
                  INFLUENCING LINKS
                </span>
                <div
                  id='generatedLinkschartdiv'
                  className={styles["chart"]}
                ></div>
                <button
                  className={styles["button-style"]}
                  onClick={() => {
                    exportgeneratedLinks();
                  }}
                >
                  Export to excel
                </button>
              </div>
              <div className={styles["card2-boxes"]}>
                <span className={styles["card2-boxes-title"]}>HITS/PAGE</span>
                <div id='generalchartdiv' className={styles["chart"]}></div>
                <button
                  className={styles["button-style"]}
                  onClick={() => {
                    exportgeneralToExcel();
                  }}
                >
                  Export to excel
                </button>
              </div>
            </div>
          </div>
          <div className={styles["card1"]}>
            <div className={styles["card1-header"]}>
              <span className={styles["card1-header-title"]}>
                ACTUAL REGISTRATION
              </span>
              <span className={styles["card1-header-subtitle"]}>
                Registration by region
              </span>
            </div>
            <div className={styles["card3-body"] + " row"}>
              <div id='chartdiv' className={styles["chart"]}></div>

              <button
                className={styles["button-style"]}
                onClick={() => {
                  exportRegistrationToExcel();
                }}
              >
                Export to excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
