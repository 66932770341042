import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import "./index.css";
import React from "react";

function Toaster({ variant, msg }) {
  
  const [show, setShow] = React.useState(true);
    
  return (
      <div className="back" >
    <ToastContainer className="p-3">
    <Toast bg={variant.toLowerCase()} onClose={() => setShow(false)} show={show} delay={3000} autohide> 
    <Toast.Body className= 'text-white'>
      {msg}
    </Toast.Body>
  </Toast>
  </ToastContainer>
  </div>
  );
}

export default Toaster;