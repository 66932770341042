import React from 'react';
import './prizeCard.css';
const PrizeCard = ({ project, rankAbbreviation, prizeIcon, theme, scale }) => {
  if (theme === 'vertical') {
    return (
      <div
        class={`prizes-card prizes-card--vertical ${
          scale
            ? 'prizes-card--scale-' + scale
            : project.rank == 1
            ? 'prizes-card--scale-one'
            : 'prizes-card--scale-two'
        }`}
      >
        <img src={prizeIcon} alt='prize' class='prizes-card__image'></img>
        <p class='prizes-card__ranking'>
          {project.rank}
          <sup>{rankAbbreviation}</sup> PLACE
        </p>
        <p class='prizes-card__name font-weight-bold'>{project.projectName}</p>
      </div>
    );
  }
  //horizontal card
  else {
    return (
      <div
        className={`prizes-card prizes-card--horizontal ${
          scale ? 'prizes-card--scale-' + scale : 'prizes-card--scale-two'
        }`}
      >
        <img src={prizeIcon} alt='prize' className='prizes-card__image'></img>
        <div className='  prizes-card__text'>
          <p class='prizes-card__ranking'>
            {project.rank}
            <sup>TH</sup> PLACE
          </p>
          <p class='prizes-card__name font-weight-bold'>
            {project.projectName}
          </p>
        </div>
      </div>
    );
  }
};

export default PrizeCard;
