import React, { useEffect, useState } from "react";
import "./regionPage.css";
import $ from "jquery";
import { MainUserContext } from "../../../contexts/mainUserContext";
import { useNavigate } from "react-router-dom";
import LandingNavBar from "../../home/navbar";
import axios from "axios";

const RegionPage = () => {
  const history = useNavigate();
  const [region, setRegion] = React.useState(-1);
  const [data, setData] = React.useContext(MainUserContext);
  const [privacyURL, setprivacyURL] = useState("");
  const [checkedPrivacy, setcheckedPrivacy] = useState(false);
  const [TermsURL, setTermsURL] = useState("");
  const [checkedTerms, setcheckedTerms] = useState(false);
  const [checkedGreaterThan18, setCheckedGreaterThan18] = useState(false);
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
  }, []);
  const handleInputChange = () => {
    data.region = region.toLowerCase();

    setData(data);
    switch (region) {
      case "egypt":
        history("/egyptregistration");
        break;
      case "india":
        history("/indiaregistration");
        break;
      case "romania":
        history("/romaniaregistration");
        break;

      default:
        break;
    }
  };

  function changeMap() {
    $("#dropdownList a").on("click", function () {
      $(".regionBtn:first-child").text($(this).text());
      $(".regionBtntn:first-child").val($(this).text());
    });
    // $(".mainDiv").css({
    //   background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
    //         padding-box`,
    // });

    $("#dropdownList a").on("click", function () {
      setprivacyURL("");
      setTermsURL("");
      var txt = $(this).text().toLowerCase();
      setRegion(txt);
      switch (txt) {
        case "egypt":
          // $("#dropdownList a").addClass("disabled");

          //loading privacy for egypt
          pdffile("/assets/egypt_privacy.pdf", "egypt_privacy.pdf").then(
            (res) => {
              setprivacyURL(res);
            }
          );
          //loading terms for egypt
          pdffile(
            "/assets/terms_and_conditions_egypt.pdf",
            "terms_and_conditions_egypt.pdf"
          ).then((res) => {
            setTermsURL(res);
          });

          break;
        case "india":
          // $("#dropdownList a").addClass("disabled");

          //loading privacy for egypt
          pdffile("/assets/IN_Privacy_Statement.pdf", "india_privacy.pdf").then(
            (res) => {
              setprivacyURL(res);
            }
          );
          //loading terms for egypt
          pdffile(
            "/assets/IN_TermsAndConditions.pdf",
            "terms_and_conditions_india.pdf"
          ).then((res) => {
            setTermsURL(res);
          });
          break;
        case "romania":
          //loading terms for egypt
          pdffile(
            "/assets/terms_and_conditions_romania.pdf",
            "Regulament hackathon.pdf"
          ).then((res) => {
            setTermsURL(res);
          });
          pdffile(
            "/assets/Nota de informare privind protecția datelor.pdf",
            "Nota de informare privind protecția datelor.pdf"
          ).then((res) => {
            setprivacyURL(res);
          });
          break;
        default:
          break;
      }
    });
  }
  async function pdffile(url, name, defaultType = "*/*") {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });

    return URL.createObjectURL(data);
  }
  const chack = () => {
    if (region !== -1) {
      if (region.toLowerCase() == "india" || region.toLowerCase() == "romania")
        return checkedGreaterThan18 && checkedPrivacy && checkedTerms;
      else return checkedPrivacy && checkedTerms;
    } else return false;
  };
  return (
    <div className='region-pageContainer'>
      <div className='container-fluid '>
        <LandingNavBar />

        <main className='row region-body-container'>
          {/* <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8"></div> */}
          <div className='region-body col-12'>
            <h1 className='region-text-1'>Select Your Country</h1>
            <label htmlFor='dropdown' className='region-text-2'>
              Select your country to start the registration process
            </label>

            <div className='dropdown' id='dropdown'>
              <button
                className='regionBtn dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={changeMap}
              >
                Country
              </button>
              <div
                id='dropdownList'
                className='dropdown-menu'
                aria-labelledby='dropdownMenuButton'
              >
                <a className='dropdown-item' href='#'>
                  Egypt
                </a>
                <a className='dropdown-item' href='#'>
                  India
                </a>
                {/* <a className='dropdown-item' href='#'>
                  hungary
                </a> */}
                <a className='dropdown-item' href='#'>
                  Romania
                </a>
              </div>
            </div>
            {region !== -1 && (
              <div className='form-check-container  row'>
                <div className=''>
                  <div className='col-md-12 col-sm-12'>
                    <div className='form-check-box mt-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={checkedTerms}
                        id='flexCheckDefault'
                        onChange={(e) => setcheckedTerms(e.target.checked)}
                      />
                      {region == "romania" ? (
                        <label className='form-check-label'>
                          Am citit și sunt de acord cu termenii și condițiile
                          Regulamentului evenimentului “_VOIS International
                          Hackathon” și declar că am vârsta de 18 ani împliniți.
                          ​
                          <br />
                          <a href={TermsURL} target='_blank'>
                            Regulament hackathon
                          </a>
                        </label>
                      ) : (
                        <label className='form-check-label'>
                          I accept the{" "}
                          <a href={TermsURL} target='_blank'>
                            terms and conditions
                          </a>
                        </label>
                      )}
                    </div>
                  </div>

                  {region == "romania" ? (
                    <div className='col-md-12 col-sm-12'>
                      <div className='form-check-box mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={checkedPrivacy}
                          id='flexCheckDefault'
                          onChange={(e) => setcheckedPrivacy(e.target.checked)}
                        />

                        <label className='form-check-label'>
                          Sunt de acord cu prelucrarea datelor cu caracter
                          personal în scopul desfășurării evenimentului, conform
                          termenilor din Nota de informare privind protecția
                          datelor.
                          <a href={privacyURL} target='_blank'>
                            Notă de informare privind protecția datelor
                          </a>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className='col-md-12 col-sm-12'>
                      <div className='form-check-box mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={checkedPrivacy}
                          id='flexCheckDefault'
                          onChange={(e) => setcheckedPrivacy(e.target.checked)}
                        />

                        <label className='form-check-label'>
                          I accept the{" "}
                          <a href={privacyURL} target='_blank'>
                            {" "}
                            privacy notice
                          </a>
                        </label>
                      </div>
                    </div>
                  )}
                  {region !== -1 ? (
                    region.toLowerCase() == "india" ? (
                      <div className='col-md-12 col-sm-12'>
                        <div className='form-check-box mt-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={checkedGreaterThan18}
                            id='flexCheckDefault'
                            onChange={(e) =>
                              setCheckedGreaterThan18(e.target.checked)
                            }
                          />

                          <label className='form-check-label'>
                            I hereby confirm that I am above 18 years of age
                          </label>
                        </div>
                      </div>
                    ) : region.toLowerCase() == "romania" ? (
                      <div className='col-md-12 col-sm-12'>
                        <div className='form-check-box mt-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={checkedGreaterThan18}
                            id='flexCheckDefault'
                            onChange={(e) =>
                              setCheckedGreaterThan18(e.target.checked)
                            }
                          />

                          <label className='form-check-label'>
                            Sunt de acord cu realizarea de fotografii și
                            înregistrări video în timpul prezentărilor în scopul
                            promovării evenimentului pe paginile de social media
                            ale _VOIS, conform termenilor din Nota de informare
                            privind protecția datelor.
                          </label>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>
            )}
            {chack() ? (
              <button
                type='button'
                className='next-btn-disabled'
                onClick={handleInputChange}
              >
                NEXT
              </button>
            ) : null}
          </div>
        </main>
        <div className='col-lg-4 col-md-4 col-sm-10 col-xs-10'></div>
      </div>
    </div>
  );
};

export default RegionPage;
// import React, { useEffect, useState } from "react";
// import "./regionPage.css";
// import $ from "jquery";

// import { MainUserContext } from "../../../contexts/mainUserContext";
// import { useNavigate } from "react-router-dom";
// import NavBar from "../../common/navBar/navBar";

// const RegionPage = () => {
//   const history = useNavigate();
//   const [region, setRegion] = React.useState();
//   const [data, setData] = React.useContext(MainUserContext);
//   const [privacyURL, setprivacyURL] = useState("");
//   const [checkedPrivacy, setcheckedPrivacy] = useState(false);
//   const [TermsURL, setTermsURL] = useState("");
//   const [checkedTerms, setcheckedTerms] = useState(false);
//   useEffect(() => {
//     $(".region-pageContainer").css({
//       background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
//             padding-box`,
//     });
//   }, []);
//   const handleInputChange = () => {
//     data.region = region.toLowerCase();

//     setData(data);
//     switch (region) {
//       case "egypt":
//         history("/egyptregistration");
//         break;
//       case "india":
//         history("/indiaregistration");
//         break;
//       case "romania":
//         history("/romaniaregistration");
//         break;

//       default:
//         break;
//     }
//   };

//   function changeMap() {
//     $("#dropdownList a").on("click", function () {
//       $(".regionBtn:first-child").text($(this).text());
//       $(".regionBtntn:first-child").val($(this).text());
//     });
//     // $(".mainDiv").css({
//     //   background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
//     //         padding-box`,
//     // });
//     $("#dropdownList a").on("click", function () {
//       setprivacyURL("");
//       setTermsURL("");
//       var txt = $(this).text().toLowerCase();
//       setRegion(txt);
//       switch (txt) {
//         case "egypt":
//           $(".region-pageContainer").css({
//             background: `transparent url("/assets/ic-bg.png") 0% 0% no-repeat
//             padding-box`,
//           });
//           //loading privacy for egypt
//           pdffile("/assets/egypt_privacy.pdf", "egypt_privacy.pdf").then(
//             (res) => {
//               setprivacyURL(res);
//             }
//           );
//           //loading terms for egypt
//           pdffile(
//             "/assets/terms_and_conditions_egypt.pdf",
//             "terms_and_conditions_egypt.pdf"
//           ).then((res) => {
//             setTermsURL(res);
//           });

//           break;
//         case "india":
//           $(".region-pageContainer").css({
//             background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
//               padding-box`,
//           });
//           //loading privacy for egypt
//           pdffile("/assets/egypt_privacy.pdf", "egypt_privacy.pdf").then(
//             (res) => {
//               setprivacyURL(res);
//             }
//           );
//           //loading terms for egypt
//           pdffile(
//             "/assets/terms_and_conditions_egypt.pdf",
//             "terms_and_conditions_egypt.pdf"
//           ).then((res) => {
//             setTermsURL(res);
//           });
//           break;
//         case "romania":
//           $(".region-pageContainer").css({
//             background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
//               padding-box`,
//           });
//           //loading privacy for egypt
//           pdffile("/assets/egypt_privacy.pdf", "egypt_privacy.pdf").then(
//             (res) => {
//               setprivacyURL(res);
//             }
//           );
//           //loading terms for egypt
//           pdffile(
//             "/assets/terms_and_conditions_egypt.pdf",
//             "terms_and_conditions_egypt.pdf"
//           ).then((res) => {
//             setTermsURL(res);
//           });
//           break;
//         default:
//           $(".region-pageContainer").css({
//             background: `transparent url("/assets/GlobalMap.svg") 0% 0% no-repeat
//             padding-box`,
//           });
//           break;
//       }
//     });
//   }
//   async function pdffile(url, name, defaultType = "*/*") {
//     const response = await fetch(url);
//     const data = await response.blob();
//     const file = new File([data], name, {
//       type: response.headers.get("content-type") || defaultType,
//     });

//     return URL.createObjectURL(data);
//   }
//   return (
//     <div className='container-fluid'>
//       <NavBar />
//       <div className='row region-pageContainer'>
//         <div className='col-lg-4 col-md-4 col-sm-8 col-xs-8'></div>
//         <div className='region-body'>
//           <div className='region-text-1'>Select Your Country</div>
//           <div className='region-text-2'>
//             Select your Country to start the registration process
//           </div>
//           <div>
//             <div className='dropdown'>
//               <button
//                 className='regionBtn dropdown-toggle'
//                 type='button'
//                 id='dropdownMenuButton'
//                 data-toggle='dropdown'
//                 aria-haspopup='true'
//                 aria-expanded='false'
//                 onClick={changeMap}
//               >
//                 Country
//               </button>
//               <div
//                 id='dropdownList'
//                 className='dropdown-menu'
//                 aria-labelledby='dropdownMenuButton'
//               >
//                 <a className='dropdown-item' href='#'>
//                   Egypt
//                 </a>
//                 <a className='dropdown-item' href='#'>
//                   India
//                 </a>
//                 {/* <a className='dropdown-item' href='#'>
//                   hungary
//                 </a> */}
//                 <a className='dropdown-item' href='#'>
//                   Romania
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className='row mt-5'>
//             {privacyURL != "" ? (
//               <>
//                 <div className='col-md-6 col-sm-12'>
//                   <embed src={privacyURL} width='100%' height='200vh' />
//                   <div className='form-check mt-2'>
//                     <input
//                       className='form-check-input'
//                       type='checkbox'
//                       value={checkedPrivacy}
//                       id='flexCheckDefault'
//                       onChange={(e) => setcheckedPrivacy(e.target.checked)}
//                     />
//                     {region == "romania" ? (
//                       <label
//                         className='form-check-label'
//                         htmlFor='flexCheckDefault'
//                         style={{ color: "#e60000" }}
//                       >
//                         Sunt de acord cu prelucrarea datelor mele cu caracter
//                         personal de catre Vodafone Romania Technologies S.R.L.
//                         in conformitate cu termenii si conditiile mentionate in
//                         Nota de Informare de mai sus
//                       </label>
//                     ) : (
//                       <label
//                         className='form-check-label'
//                         htmlFor='flexCheckDefault'
//                         style={{ color: "#e60000" }}
//                       >
//                         I have read and agree on privacy notice
//                       </label>
//                     )}
//                   </div>
//                 </div>
//               </>
//             ) : null}
//             {TermsURL != "" ? (
//               <>
//                 <div className='col-md-6 col-sm-12'>
//                   <embed src={TermsURL} width='100%' height='200vh' />
//                   <div className='form-check mt-2'>
//                     <input
//                       className='form-check-input'
//                       type='checkbox'
//                       value={checkedTerms}
//                       id='flexCheckDefault'
//                       onChange={(e) => setcheckedTerms(e.target.checked)}
//                     />
//                     {region == "romania" ? (
//                       <label
//                         className='form-check-label'
//                         for='flexCheckDefault'
//                         style={{ color: "#e60000" }}
//                       >
//                         Am citit si sunt de acord cu termenii si conditiile
//                         Regulamentului evenimentului “_VOIS Global Hackathon” si
//                         declar ca am varsta de 18 ani impliniti
//                       </label>
//                     ) : (
//                       <label
//                         className='form-check-label'
//                         for='flexCheckDefault'
//                         style={{ color: "#e60000" }}
//                       >
//                         I have read and agree on terms and conditions
//                       </label>
//                     )}
//                   </div>
//                 </div>
//               </>
//             ) : null}
//           </div>
//           {checkedTerms && checkedPrivacy ? (
//             <button
//               type='button'
//               className='nextBtn'
//               onClick={handleInputChange}
//             >
//               NEXT
//             </button>
//           ) : null}
//         </div>
//       </div>
//       <div className='col-lg-4 col-md-4 col-sm-10 col-xs-10'></div>
//     </div>
//   );
// };

// export default RegionPage;
