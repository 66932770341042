import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PrizeCard from "../../../common/cards/prizeCard";
import InternalEventNavbar from "../../../common/internalEventNavbar/navBar/navBar";
import LoggedNavBar from "../../../common/navBar/loggedNav";
import SideMenu from "../../../common/sideMenu/sideMenu";

import "./filtrationPanelCalculation.css";

function FiltrationPanelCalculation() {
  const location = useLocation();
  const [panel, setpanel] = useState(location.state?.panelID);
  const [panelRanks, setpanelRanks] = useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [data, setData] = useState({ topThree: [], others: [] });
  useEffect(() => {
    if (loadBytes == false) {
      getPanelRanks();
    }
  });
  const sortData = (data) => {
    data.sort((a, b) => a.rank - b.rank);
    return {
      topThree: data.slice(0, 3),
      others: data.slice(3, data.length),
    };
  };
  const getPanelRanks = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}panelvalues/getRanks`, {
        panelID: panel,
      })
      .then((res) => {
        setpanelRanks(res.data);
        setData(sortData([...res.data]));
        setLoadBytes(true);
        console.log(res.data);
      });
  };

  const getPrizeIcon = (rank) => {
    switch (rank) {
      case 1:
        return "/assets/ic-first-prize.svg";
      case 2:
        return "/assets/ic-second-prize.svg";
      case 3:
        return "/assets/ic-third-prize.svg";
      default:
        return "/assets/ic-fourth-prize.svg";
    }
  };
  const getRankAbbreviation = (rank) => {
    switch (rank) {
      case 1:
        return "ST";
      case 2:
        return "ND";
      case 3:
        return "RD";
      default:
        return "TH";
    }
  };
  const getRankOrder = (rank) => {
    switch (rank) {
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 1;
      default:
        return 1;
    }
  };

  return (
    <div style={{ minHeight: "100%", width: "100%" }}>
      <div className='prizes-page-container '>
        <LoggedNavBar to='judges' />
        {loadBytes == false ? (
          <div className='text-center'>
            <div className='spinner-grow text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-secondary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-danger' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-warning' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-info' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-light' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div className='spinner-grow text-dark' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='container ' style={{ marginBottom: "4.7rem" }}>
            <section className='row   justify-content-between top-three-prizes-container'>
              {data.topThree.map((project) => (
                <div
                  className={` prizes-card m-0 ${
                    project.rank == 1
                      ? "prizes-card-border col-lg-6"
                      : "col-lg-3"
                  }`}
                  style={{ order: getRankOrder(project.rank) }}
                >
                  <PrizeCard
                    project={project}
                    rankAbbreviation={getRankAbbreviation(project.rank)}
                    prizeIcon={getPrizeIcon(project.rank)}
                    theme={"vertical"}
                  />
                </div>
              ))}
            </section>
            <section className='row other-prizes-container'>
              {data.others.map((project) => (
                <div
                  className='col-md-6 prizes-card__wrapper p-0'
                  style={{ order: project.rank }}
                >
                  <div className='prizes-card__container'>
                    <PrizeCard
                      project={project}
                      rankAbbreviation='TH'
                      prizeIcon='/assets/ic-fourth-prize.svg'
                      theme={"horizontal"}
                    />
                  </div>
                </div>
              ))}
            </section>
          </div>
        )}
      </div>
    </div>
  );
}

export default FiltrationPanelCalculation;
