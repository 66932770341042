import React, { useEffect, useRef } from "react";
import styles from "../superadminProjectsinnerPage.module.css";
import styles2 from "./MailTemplates.module.css";
import SuperAdminNavBar from "../SuperAdminNavBar";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { useNavigate } from "react-router-dom";

Quill.register("modules/imageResize", ImageResize);
var BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];

function MailTemplates() {
  const history = useNavigate();
  const [templates, setTemplates] = React.useState([]);

  const [templateData, setTemplateData] = React.useState("");
  const [templateDataBody, setTemplateDataBody] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [id, setID] = React.useState("");
  const containerRef = useRef();

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html;
  };
  async function fetchHtml() {
    const fetchHtmlFile = async () => {
      try {
        const response = await fetch("/x.html");
        const htmlText = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlText, "text/html");
        containerRef.current.innerHTML = "";

        // Append the HTML document to the container
        containerRef.current.appendChild(doc.documentElement.cloneNode(true));
        setTemplateData(doc.getElementById("bodytochange").innerHTML);
      } catch (error) {
        console.error("Error fetching HTML file:", error);
      }
    };
    fetchHtmlFile();
  }
  useEffect(() => {
    getMailTemplates();
    fetchHtml();
  }, []);

  const getMailTemplates = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}templates`, {})
      .then((res) => {
        console.log(res.data);
        setTemplates(res.data);
      });
  };
  const addTemplate = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templates/add`, {
        templateData: containerRef.current.innerHTML,
        subject,
      })
      .then((res) => {
        getMailTemplates();
        fetchHtml();
      });
  };
  return (
    <>
      <div id='templateviewer' ref={containerRef} style={{ display: "none" }} />

      <div
        className='modal fade'
        id='AddModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Template
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Mail Body:</p>
                <ReactQuill
                  theme='snow'
                  value={templateData}
                  onChange={(e) => {
                    console.log(e);
                    document.getElementById("bodytochange").innerHTML = e;
                    setTemplateData(e);
                    // if (temp != "") {
                    //   document.getElementById("bodytochange").innerHTML = e;
                    //   setTemplateDataBody(e);
                    //   var rootElement =
                    //     document.getElementById("templateviewer");

                    //   // Convert DOM to HTML string
                    //   var htmlString = rootElement.outerHTML;

                    //   setTemplateData(htmlString);
                    // }
                  }}
                  modules={modules}
                  formats={formats}
                  bounds={"#root"}
                />
              </div>
              <div className='col-md-12'>
                <p>Mail Subject:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setSubject(el.target.value);
                  }}
                  value={subject}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                disabled={templateData == "" || subject == ""}
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={addTemplate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div
              className={" col-md-10 pt-4"}
              style={{ maxHeight: "100vh", overflow: "auto" }}
            >
              <span className={styles["regionTitle2"]}>Default Templates</span>
              <div className='row mt-4 mb-4'>
                {templates.map((r) => {
                  if (r.MailType == "system") {
                    return (
                      <div
                        className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className={styles["region-box-header"]}>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              display: "inline",
                            }}
                          >
                            {r.MailSubject}
                          </p>
                          <div className='float-right'>
                            <span
                              className='fa fa-pencil'
                              style={{
                                cursor: "pointer",
                                color: "#e60000",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                history("/superadmin/viewmail", {
                                  state: {
                                    subject: r.MailSubject,
                                    templateData: r.Template,
                                    id: r._id,
                                  },
                                });
                              }}
                            ></span>
                          </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{ wordBreak: "break-all" }}
                          dangerouslySetInnerHTML={{ __html: r.Template }}
                        ></span>
                      </div> */}
                      </div>
                    );
                  }
                })}
              </div>
              <span className={styles["regionTitle2"]}>Certificate</span>
              <div className='row mt-4 mb-4'>
                {templates.map((r) => {
                  if (r.MailType == "cert") {
                    return (
                      <div
                        className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className={styles["region-box-header"]}>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              display: "inline",
                            }}
                          >
                            {r.MailSubject}
                          </p>
                          <div className='float-right'>
                            <span
                              className='fa fa-pencil'
                              style={{
                                cursor: "pointer",
                                color: "#e60000",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                history("/superadmin/viewmail", {
                                  state: {
                                    subject: r.MailSubject,
                                    templateData: r.Template,
                                    id: r._id,
                                  },
                                });
                              }}
                            ></span>
                          </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{ wordBreak: "break-all" }}
                          dangerouslySetInnerHTML={{ __html: r.Template }}
                        ></span>
                      </div> */}
                      </div>
                    );
                  }
                })}
              </div>
              <span className={styles["regionTitle2"]}>Custom Emails</span>
              <span
                className=' mr-2 float-right'
                style={{
                  cursor: "pointer",
                  color: "#e60000",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                data-toggle='modal'
                data-target={"#AddModal"}
              >
                +
              </span>
              <div className='row mt-4 mb-4'>
                {templates.map((r) => {
                  if (r.MailType == "custom") {
                    return (
                      <div
                        className={styles["region-box"] + " col-md-3 ml-2 mb-2"}
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className={styles["region-box-header"]}>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              display: "inline",
                            }}
                          >
                            {r.MailSubject}
                          </p>
                          <div className='float-right'>
                            <span
                              className='fa fa-pencil'
                              style={{
                                cursor: "pointer",
                                color: "#e60000",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                history("/superadmin/viewmail", {
                                  state: {
                                    subject: r.MailSubject,
                                    templateData: r.Template,
                                    id: r._id,
                                  },
                                });
                              }}
                            ></span>
                          </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{ wordBreak: "break-all" }}
                          dangerouslySetInnerHTML={{ __html: r.Template }}
                        ></span>
                      </div> */}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MailTemplates;
