import React, { useEffect } from "react";
import axios from "axios";
const Reception = () => {
  return (
    <>
      <a-scene cursor='rayOrigin: mouse' raycaster='objects: .intersectable'>
        <a-entity position='0 2 -3' rotation='0 180 0'>
          <a-camera wasd-controls-enabled='false'></a-camera>
        </a-entity>

        <>
          <a-text
            id='texttest'
            value='Register'
            position='-1.72 2.85 0'
            rotation='0 160 0'
            color='white'
            scale='0.85 0.5 0.12'
            width='3.6'
          ></a-text>

          <a-entity
            id='texttest'
            geometry='primitive: box'
            material='transparency:true;opacity:0.0'
            // material='color:white'
            rotation='0 160 0'
            position='-2.02 2.80 0'
            scale='0.5 0.85 0.12'
            cursor-url='url:./region'
            class='intersectable'
          ></a-entity>
        </>

        <>
          <a-text
            id='texttest'
            value='Log in'
            position='2.15 2.9 0'
            rotation='0 200 0'
            color='white'
            scale='0.9 0.5 0.12'
            width='4'
          ></a-text>

          <a-entity
            id='texttest'
            geometry='primitive: box'
            material='transparency:true;opacity:0.0'
            // material='color:white'
            position='1.9 2.9 0'
            scale='0.45 0.7 0.12'
            cursor-url='url:./login'
            class='intersectable'
          ></a-entity>
        </>

        <a-scene>
          <a-sky src='/assets/reception22.png' rotation='0 90 0'></a-sky>
        </a-scene>
      </a-scene>
    </>
  );
};

export default Reception;
