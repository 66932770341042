import React from "react";

import Users from "./Users/Users";

function Generalusermanagement() {
  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className=' pl-5 nav-button-padding'>
          {/* <SuperAdminNavBar></SuperAdminNavBar> */}
        </div>

        <Users />
      </div>
    </div>
  );
}

export default Generalusermanagement;
