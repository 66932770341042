import { useNavigate, useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import ProjectPanelsPreview from "../common/projectPanelspreview/projectPanelsPreview";
import styles from "./superadminProjectsinnerPage.module.css";
import * as XLSX from "xlsx";
import * as _ from "lodash";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function ProjectDetails() {
  const forceUpdate = useForceUpdate();
  const [data, setdata] = useState({});
  const [Ranks, setRanks] = useState([]);
  const location = useLocation();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [Editemail, seteditEmail] = useState("");
  const [BeforeEmailChange, setBeforeEmailChange] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState("");
  const textArearef = React.useRef();
  const textboxref = React.useRef();
  const getProject = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectforsuperadmin`,
        {
          id: location.state.id,
        }
      )
      .then((res) => {
        setdata(res.data);
        setLoading(false);
      });
  };
  const getProjectRanks = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}panelvalues/getranksforproject`,
        {
          id: location.state.id,
        }
      )
      .then((res) => {
        setRanks(res.data);
      });
  };
  const [Comments, setComments] = useState([]);
  const getProjectComments = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcommentsbyproject`,
        {
          projectId: location.state.id,
        }
      )
      .then((res) => {
        setComments(res.data);
      });
  };
  const [preComments, setPreComments] = useState([]);
  const getProjectPreComments = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}prefiltration/`, {
        id: location.state.id,
      })
      .then((res) => {
        setPreComments(res.data);
      });
  };
  const addComment = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}prefiltration/add`, {
        _id: location.state.id,
        commentstring: textArearef.current.value,
      })
      .then((res) => {
        textArearef.current.defaultValue = "";
        textArearef.current.value = "";
        setComment("");
        getProjectPreComments();
        forceUpdate();
      });
  };
  const editComment = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}prefiltration/edit`, {
        id: editID,
        commentstring: textArearef.current.value,
      })
      .then((res) => {
        textArearef.current.defaultValue = "";
        textArearef.current.value = "";
        setComment("");
        setIsEdit(false);
        setEditID("");
        getProjectPreComments();
        forceUpdate();
      });
  };
  const addemail = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}project/addnonregistered`, {
        _id: location.state.id,
        email: textboxref.current.value,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  const deleteemail = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}project/deletenonregistered`, {
        _id: id,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  useEffect(() => {
    getProject();
    getProjectRanks();
    getProjectComments();
    getProjectPreComments();
  }, []);
  const editMemberEmail = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}project/editemailregistered`, {
        _id: location.state.id,
        email: Editemail,
        before: BeforeEmailChange,
      })
      .then((res) => {
        getProject();
        seteditEmail("");
      });
  };
  const [EditLink, seteditLink] = useState("");
  const editLink = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}project/editlinkregistered`, {
        _id: location.state.id,
        link: EditLink,
      })
      .then((res) => {
        seteditLink("");
        getProject();
      });
  };
  const getComments = () => {
    const d = Comments.map((record) => {
      let obj = {};
      for (let key in record) {
        if (key == "filtrationPanel") {
          for (let key2 in record[key]) {
            if (_.startCase(key2) != "Id" && _.startCase(key2) != "V") {
              if (_.startCase(key2) == "Name") {
                obj["Panel Name"] = record["filtrationPanel"][key2].toString();
              }
            }
          }
        } else {
          if (
            _.startCase(key) != "Id" &&
            _.startCase(key) != "V" &&
            _.startCase(key) != "Filtration Group" &&
            _.startCase(key) != "Project"
          ) {
            obj[_.startCase(key)] = record[key];
          }
        }
      }

      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(d);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // var staffId = +localStorage.getItem('StaffId');

    /* save to file */

    XLSX.writeFile(
      wb,
      "Project_Comments_" + data.project.name + " " + new Date() + ".xlsx"
    );
  };
  return (
    <>
      <div
        className='modal fade'
        id='editlink'
        tabindex='-1'
        role='dialog'
        aria-labelledby='editlink'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-md' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Presentation Link
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              {" "}
              <div
                className='col-md-12 mb-2'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor='members'>Presentation Link</label>
                {/* <p>Members:</p> */}
                <input
                  type='text'
                  value={EditLink}
                  onChange={(e) => {
                    seteditLink(e.target.value);
                    console.log(EditLink);
                  }}
                ></input>{" "}
                <div
                  className='modal-footer'
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type='button'
                    className='add-groups-modal-save-btn'
                    data-dismiss='modal'
                    onClick={editLink}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='editMail'
        tabindex='-1'
        role='dialog'
        aria-labelledby='editMail'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-md' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Email
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              {" "}
              <div
                className='col-md-12 mb-2'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor='members'>Email</label>
                {/* <p>Members:</p> */}
                <input
                  type='text'
                  value={Editemail}
                  onChange={(e) => {
                    seteditEmail(e.target.value);
                    console.log(Editemail);
                  }}
                ></input>{" "}
                <div
                  className='modal-footer'
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type='button'
                    className='add-groups-modal-save-btn'
                    data-dismiss='modal'
                    onClick={editMemberEmail}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className=' nav-button-padding'></div>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div
              className={" col-md-10"}
              style={{ maxHeight: "100vh", overflowY: "scroll" }}
            >
              <div className={" row mt-5"}>
                {loading ? (
                  <div
                    className='main-postKeynotes-page-body row'
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <div className='text-center'>
                      <div className='spinner-grow text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div
                        className='spinner-grow text-secondary'
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-success' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-danger' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-warning' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-info' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-light' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-dark' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    <div className=' '>
                      {/* <button
                className='btn btn-outline-dark float-right '
                style={{
                  marginRight: "6%",
                  marginTop: "2rem",
                  marginBottom: "1.5rem",
                }}
              >
                <span className='fas fa-file-download mr-2'></span> Export
                Document
              </button> */}

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <div
                          className='mt-2 col-md-6 col-sm-12'
                          style={{ paddingLeft: "4%" }}
                        >
                          <h2> Project Details</h2>

                          <h6 className='mt-2'>
                            Project Name{" "}
                            <span
                              className='btn-selcted '
                              style={{ marginLeft: "5%" }}
                            >
                              {data.project.status}
                            </span>
                            <span
                              className='btn-selcted '
                              style={{ marginLeft: "5%" }}
                            >
                              {data.project.country}
                            </span>
                          </h6>
                          <p>{data.project.name}</p>
                          <h6 className='mt-2'>Project Description</h6>
                          <p>{data.project.description}</p>
                          <h6 className='mt-2'>Project Technologies</h6>
                          {data.project.technology.map((el) => (
                            <span className='btn-selcted '>{el}</span>
                          ))}
                          <h6 className='mt-2'>Project Type</h6>
                          {data.project.type.map((el) => (
                            <span className='btn-selcted '>{el}</span>
                          ))}
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <h6 className='mt-2'>Presentation Link</h6>
                          <span
                            className='float-right fa fa-pencil'
                            data-toggle='modal'
                            data-target='#editlink'
                            onClick={() => {
                              seteditLink(data.project.videoLink);
                            }}
                          ></span>
                          <a
                            className='btn-selcted '
                            href={data.project.videoLink}
                          >
                            {data.project.videoLink}
                          </a>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",

                              marginTop: "1rem",
                            }}
                          >
                            <h2> PreFiltration Comments</h2>
                            <ul>
                              {preComments.map((el) => (
                                <li
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {el.comment}{" "}
                                  <span
                                    className='fas fa-edit'
                                    onClick={() => {
                                      textArearef.current.defaultValue =
                                        el.comment;
                                      textArearef.current.value = el.comment;
                                      setComment(el.comment);
                                      setIsEdit(true);
                                      setEditID(el._id);
                                      forceUpdate();
                                    }}
                                  ></span>
                                </li>
                              ))}
                            </ul>
                            <div
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "1rem",
                              }}
                            >
                              <textarea
                                cols='80'
                                rows='3'
                                ref={textArearef}
                                onKeyUp={(e) => {
                                  setComment(e.target.value);
                                }}
                              ></textarea>
                              <button
                                className='btn btn-outline-dark float-right '
                                onClick={isEdit ? editComment : addComment}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <hr></hr>
                        <div
                          className='mt-2 col-md-6 col-sm-12'
                          style={{ paddingLeft: "4%" }}
                        >
                          <h2> Users</h2>
                          <h4>Finished Registration</h4>
                          {data.registeredmembers.map((el) => (
                            <>
                              <h6 className='mt-2'>
                                Name{" "}
                                {el.isTeamLeader ? (
                                  <span
                                    className='btn-selcted '
                                    style={{ marginLeft: "5%" }}
                                  >
                                    Team Leader
                                  </span>
                                ) : (
                                  <span
                                    className='btn-selcted '
                                    style={{ marginLeft: "5%" }}
                                  >
                                    Team Member
                                  </span>
                                )}
                              </h6>
                              <p>{el.name}</p>

                              <h6 className='mt-2'>Email</h6>
                              <span
                                className='float-right fa fa-pencil'
                                data-toggle='modal'
                                data-target='#editMail'
                                onClick={() => {
                                  seteditEmail(el.email);
                                  setBeforeEmailChange(el.email);
                                }}
                              ></span>

                              <p>{el.email}</p>
                              <h6 className='mt-2'>Phone Number</h6>
                              {el.phoneNumber}
                              {el.cvLink != "" ? (
                                <>
                                  <h6 className='mt-2'>CV</h6>
                                  <a href={el.cvLink} target='_blank'>
                                    Link
                                  </a>
                                </>
                              ) : null}
                            </>
                          ))}
                          <hr></hr>
                          <h4>Didn't Finish Rgistration</h4>
                          {data.nonregistered.map((el) => (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>{el.email}</p>
                                <span
                                  style={{ cursor: "pointer" }}
                                  className='fa fa-trash'
                                  onClick={() => {
                                    deleteemail(el._id);
                                  }}
                                ></span>
                              </div>
                            </>
                          ))}
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: "1rem",
                            }}
                          >
                            <input
                              type='text'
                              style={{ borderRadius: "50px", width: "80%" }}
                              ref={textboxref}
                              onKeyUp={(e) => {
                                setEmail(e.target.value);
                              }}
                            ></input>
                            <button
                              className='btn btn-outline-dark float-right '
                              onClick={addemail}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <hr></hr>
                          {/* <h4>Panels</h4>
                          <ProjectPanelsPreview
                            projectID={location.state.id}
                            projectName={data.project.name}
                          ></ProjectPanelsPreview>
                          <hr></hr> */}
                          <div className='d-flex'>
                            <h4>Comments/Panel Rounds</h4>
                            <button
                              className='btn btn-outline-dark float-right m-2'
                              onClick={getComments}
                            >
                              Export Comments
                            </button>
                          </div>
                          <hr></hr>
                          <h4>Ranks</h4>
                          <ul>
                            {Ranks.map((el) => (
                              <li>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h6 className='mt-2'>
                                      {el.filtrationPanel.name}
                                    </h6>
                                    <p>{el.grade}</p>
                                  </span>
                                  <span
                                    class='badge badge-dark '
                                    style={{
                                      height: "3.5rem",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    Rank <h3>#{el.rank}</h3>
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetails;
