import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import GroupedTable from "../superAdminTable/GroupedTable";
import NonGroupedTable from "../superAdminTable/NonGroupedTable";
import axios from "axios";
import FiltrationPanelAddGroup from "../generalEvent/panelAdmin/filtrationPanel/filtrationPanelAddGroup";
import Select from "react-select";
import styles from "./superadminProjectsinnerPage.module.css";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function FiltrationPanelGroup() {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [groupID, setGroupId] = React.useState(location.state.groupID);
  const [panelID, setGPanelId] = React.useState(location.state.panelID);
  const [group, setGroup] = React.useState({});
  const [members, setMembers] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [panelMainData, setpanelMainData] = useState({});
  useEffect(() => {
    getgroup();
    getfiltrationPanel();
    if (membersDDL.length == 0) getMembers();
    if (projectsDDL.length == 0) getProjects();
  }, []);
  const submitdeleteMemberfromGroup = (emailID) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/deletememberfromgroup`,
        {
          panelID,
          emailID,
          groupID,
        }
      )
      .then((res) => {
        getgroup();
        getMembers();
      });
  };
  const submitdeleteProjectfromGroup = (projectID) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/deleteprojectfromgroup`,
        {
          panelID,
          projectID,
          groupID,
        }
      )
      .then((res) => {
        getgroup();
        getProjects();
      });
  };
  const getgroup = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/getgroup`, {
        groupID,
      })
      .then((res) => {
        setMembers(res.data.members);
        setProjects(res.data.projects);
      });
  };
  const getfiltrationPanel = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}filtration/panel`, {
        panelID,
      })
      .then((res) => setpanelMainData(res.data));
  };
  const [membersDDL, setmembersDDL] = useState([]);
  const [addedMembersDDL, setAddedMembersDDL] = useState([]);
  const [projectsDDL, setprojectsDDL] = useState([]);
  const [addedprojectsDDL, setAddedprojectsDDL] = useState([]);
  const getProjects = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/projectsddl`, {
        panelID: panelID,
      })
      .then((res) => {
        setprojectsDDL(
          res.data.map((response) => ({
            value: response._id,
            label: response.name,
          }))
        );
      });
  };

  const getMembers = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/membersddl`, {
        panelID: panelID,
      })
      .then((res) => {
        res.data.map((response) => {
          let x = membersDDL;
          x.push({ value: response._id, label: response.email });
          setmembersDDL(x);
        });

        console.log(membersDDL);
      });
  };
  const addMemberToGroup = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/addmembertogroup`,
        {
          panelID: panelID,
          groupID: groupID,
          emails: addedMembersDDL,
        }
      )
      .then((res) => {
        getgroup();
        getMembers();
      });
  };
  const addprojectsToGroup = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/addprojecttogroup`,
        {
          panelID: panelID,
          groupID: groupID,
          projects: addedprojectsDDL,
        }
      )
      .then((res) => {
        getgroup();
        getProjects();
        forceUpdate();
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='addMembers'
        tabindex='-1'
        role='dialog'
        aria-labelledby='addMembers'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-md' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Member
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              {" "}
              <div
                className='col-md-12 mb-2'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor='members'>Members</label>
                {/* <p>Members:</p> */}
                <Select
                  isMulti
                  isSearchable
                  options={membersDDL}
                  className='options'
                  onChange={(e) => {
                    setAddedMembersDDL(e.map((el) => el.label));
                  }}
                />
                <div
                  className='modal-footer'
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type='button'
                    className='add-groups-modal-save-btn'
                    data-dismiss='modal'
                    onClick={addMemberToGroup}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='addProjects'
        tabindex='-1'
        role='dialog'
        aria-labelledby='addProjects'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-md' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Projects
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              {" "}
              <div
                className='col-md-12 mb-2'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor='members'>Projects</label>
                {/* <p>Members:</p> */}
                <Select
                  isMulti
                  isSearchable
                  options={projectsDDL}
                  className='options'
                  onChange={(e) => {
                    setAddedprojectsDDL(e.map((el) => el.value));
                  }}
                />
                <div
                  className='modal-footer'
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type='button'
                    className='add-groups-modal-save-btn'
                    data-dismiss='modal'
                    onClick={addprojectsToGroup}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ overflow: "hidden" }}>
          <div className='container-fluid p-0'>
            <div className=' nav-button-padding'></div>
            <div className='row' style={{ minHeight: "50vh" }}>
              <div className='col-md-2'>
                <SuperAdminNavBar></SuperAdminNavBar>
              </div>
              <div className={" col-md-10"}>
                <div className={styles["main-container"] + " row mt-5"}>
                  <div className='col-md-12' style={{ minHeight: "15rem" }}>
                    <span className={styles["regionTitle2"]}>Members</span>{" "}
                    <button
                      className='btn btn-outline-dark float-right '
                      data-toggle='modal'
                      data-target='#addMembers'
                    >
                      Add Member
                    </button>
                    <div
                      className={styles["main-container-section"] + " row mt-5"}
                    >
                      {members.map((el) => {
                        return (
                          <div
                            className={
                              styles["region-box"] + " col-md-3 mr-3 mb-2"
                            }
                          >
                            {el.email}
                            <div className='float-right'>
                              <span
                                className='fa fa-trash ml-2'
                                style={{
                                  cursor: "pointer",
                                  color: "#e60000",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  submitdeleteMemberfromGroup(el._id);
                                }}
                              ></span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className='col-md-12' style={{ minHeight: "15rem" }}>
                    <span className={styles["regionTitle2"]}>Projects</span>{" "}
                    <button
                      className='btn btn-outline-dark float-right m-2'
                      data-toggle='modal'
                      data-target='#addProjects'
                    >
                      Add Projects
                    </button>
                    <div
                      className={styles["main-container-section"] + " row mt-5"}
                    >
                      {projects.map((el) => {
                        return (
                          <div
                            className={
                              styles["region-box"] + " col-md-3 mr-3 mb-2"
                            }
                          >
                            {el.project.name}
                            <div className='float-right'>
                              <span
                                className='fa fa-trash ml-2'
                                style={{
                                  cursor: "pointer",
                                  color: "#e60000",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  submitdeleteProjectfromGroup(el._id);
                                }}
                              ></span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FiltrationPanelGroup;
