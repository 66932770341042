import React, { useState, useEffect } from "react";
import {
  getCurrentUser,
  returnSuperAdmin,
  setUserJWT,
  ssoJWTCheck,
  setRegionToken,
  getJwt,
  getCurrentSSOUser,
  getToken,
} from "../../services/authService";
import { useNavigate } from "react-router-dom";
import SideMenu from "../common/sideMenu/sideMenu";
import { setJwt } from "../../services/httpService";

export default function EntrancePage() {
  const [IsAdmin, setIsAdmin] = useState(false);
  const [regionButtons, setRegionButtons] = useState(true);
  const [loading, setLoding] = useState(true);
  // const [region, setRegion] = useState({});
  // const [regionToken, setRegionToken] = useState(true);
  const reservedPath = localStorage.getItem("from");
  const history = useNavigate();
  useEffect(() => {
    localStorage.setItem("firstLoad", "true");
    if (getCurrentUser()?.roles.includes("projectMember")) {
      history("/hall");
    } else {
      ssoJWTCheck().then((r) => {
        if (getCurrentUser()?.roles.includes("superAdmin")) {
          debugger;
          setIsAdmin(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    getCurrentSSOUser().then((r) => {
      if (getCurrentUser() != undefined && r != undefined) {
        setIsAdmin("false");
      } else {
        setIsAdmin("true");
      }
      if (
        localStorage.getItem("from") != "" &&
        localStorage.getItem("from") != null &&
        localStorage.getItem("from") != undefined
      ) {
        localStorage.removeItem("from");
        history(reservedPath);
      }
    });
  }, [loading]);

  return (
    <>
      {/* {console.log(region)} */}
      {/* <button className="btn btn-primary">Back To Super Admin</button> */}
      {IsAdmin ? (
        <div className=' login-pageContainer' style={{ overflow: "hidden" }}>
          {/* <SideMenu/> */}
          <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-8 col-xs-8'></div>
            <div className='login-container col-lg-4 col-md-4 col-sm-8 col-xs-8'>
              <div className='login-body-title'> _VOIS Global Hackathon</div>
              <div className='login-body-subtitle'>Welcome </div>
              {IsAdmin === "true" && (
                <div>
                  <button
                    className='login-body-actionBtn1'
                    type='button'
                    onClick={() => {
                      returnSuperAdmin();
                      history("/superadmin/home");
                    }}
                  >
                    <span>Back To Super Admin</span>
                  </button>
                </div>
              )}
              {regionButtons === true && (
                <div className='entrance-Title'>
                  <p className='entrance-SubTitle'>View Event From </p>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'egypt'})
                      setRegionToken("egypt");
                      history("/hall");
                    }}
                  >
                    <span>Egypt</span>
                  </button>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'india'})
                      setRegionToken("india");
                      history("/hall");
                    }}
                  >
                    <span>India</span>
                  </button>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'romnia'})
                      setRegionToken("romania");
                      history("/hall");
                    }}
                  >
                    <span>Romania</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-10 col-xs-10'></div>
        </div>
      ) : (
        <div className=' login-pageContainer'>
          {/* <SideMenu/> */}
          <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-8 col-xs-8'></div>
            <div className='login-container col-lg-4 col-md-4 col-sm-8 col-xs-8'>
              <div className='login-body-title'> _VOIS Global Hackathon</div>
              <div className='login-body-subtitle'>Welcome </div>
              {IsAdmin === "true" && (
                <div>
                  <button
                    className='login-body-actionBtn1'
                    type='button'
                    onClick={() => {
                      returnSuperAdmin();
                      history("/superadmin/home");
                    }}
                  >
                    <span>Back To Super Admin</span>
                  </button>
                </div>
              )}
              {regionButtons === true && (
                <div className='entrance-Title'>
                  <p className='entrance-SubTitle'>View Event From </p>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'egypt'})
                      setRegionToken("egypt");
                      history("/hall");
                    }}
                  >
                    <span>Egypt</span>
                  </button>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'india'})
                      setRegionToken("india");
                      history("/hall");
                    }}
                  >
                    <span>India</span>
                  </button>
                  <button
                    className='entrance-Btn'
                    type='button'
                    onClick={() => {
                      // setRegion({...region,'region':'romnia'})
                      setRegionToken("romnia");
                      history("/hall");
                    }}
                  >
                    <span>Romnia</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-10 col-xs-10'></div>
        </div>
      )}{" "}
    </>
  );
}
