import Table from "../common/superAdminTable/NonGrouped";
import "./superAdminTable.css";
import { getNonGrouped, getNonGroupedGroup } from "../../apis/adminPanel";
import { useEffect, useState } from "react";
import axios from "axios";

const SuperAdminNonGroupedTable = ({ panelID, groupID }) => {
  return (
    <div
      className='super-admin-back-table-responsive'
      style={{
        backgroundColor: "white",
      }}
    >
      <Table panelID={panelID} />
    </div>
  );
};

export default SuperAdminNonGroupedTable;
