import React from "react";
import { Fragment } from "react";
import styles from "../superadminProjectsinnerPage.module.css";
const Announcement = ({
  id,
  title,
  body,
  deleteAnnouncementHandler,
  updateAnnouncementHandler,
}) => {
  return (
    <Fragment>
      <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
        <h4 className='card-title'>{title}</h4>
        <p>{body}</p>
        <button
          className='btn btn-primary mr-3'
          onClick={() => updateAnnouncementHandler({ id, title, body })}
        >
          Edit Announcement
        </button>
        <button
          className='btn btn-danger'
          onClick={() => deleteAnnouncementHandler(id)}
        >
          Delete Announcement
        </button>
      </div>
    </Fragment>
  );
};

export default Announcement;
