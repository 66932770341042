import styles from "./CookieNotice.module.css";
import LandingNavBar from "../../components/home/navbar";

import React, { useState, useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CookieAccordion from "./CookieAccordion";

function CookieNotice() {
  const [expand, setExpansion] = useState(-1);
  const cookies = [
    {
      title: "Strictly Necessary Cookies",
      usetoggle: false,
      descrition: "These are used to help make our website work efficiently. ​",
      ifany: false,
    },
    {
      title: "Targeting Cookies",
      usetoggle: false,
      ifany: true,
      descrition:
        "These are used to share some information with third parties who we advertise with, so we know how you've reached our website. We can also use cookies to identify the parts of the website you are interested in. We then use this information to show you adverts and pages we think may also be of interest to you, to tailor how we communicate with you, or to tailor the contents of the communications we send to you. If you prefer, you can opt out of these. Tailoring of content means it includes information reflecting the interest you’ve shown in the content of our web pages, or offers or promotions that we think may interest you and to improve how we respond to your needs.​",
    },
    {
      title: "Performance Cookies",
      usetoggle: false,
      ifany: true,
      descrition:
        "These are used to analyse the way our website works and how we can improve it for example which pages you visit the most often and if you get any error messages from web pages.​",
    },
  ];
  const handleExpansion = (expandedItem) => {
    if (expand === expandedItem) {
      setExpansion(-1);
    } else {
      setExpansion(expandedItem);
    }
  };
  const OpenModal = () => {
    document.getElementById("managecookiesbtn").click();
  };
  return (
    <div className={styles["faq-page-wrapper"] + " container-fluid"}>
      <LandingNavBar></LandingNavBar>
      <div className='row d-flex justify-content-center mt-4'>
        <div className='col-lg-12 col-md-6 col-xs-6 col-sm-6'>
          <span class={styles["underline"]}>
            {" "}
            <p className='faq-page-title '>Cookie Notice</p>
          </span>
        </div>
        <div
          className={
            styles["faq-accodrion-container"] +
            " col-lg-6 col-md-8 col-xs-6 col-sm-6   mt-5 "
          }
        >
          <Accordion>
            <Accordion.Item
              eventKey={1}
              className=' mb-4'
              onClick={() => handleExpansion(1)}
            >
              <Accordion.Header className='row'>
                <span
                  className={
                    styles["pull-left"] + " ml-3" + styles["question-content"]
                  }
                >
                  Our cookie notice
                </span>
                <span>
                  {expand === 1 ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  )}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <p className=' ml-4'>
                  This cookie notice applies for cookies described below which
                  may be placed when you visit different pages on the{" "}
                  <a
                    href='/'
                    style={{ color: "#e60000", fontWeight: "bold" }}
                    target='_blank'
                  >
                    www.
                    {window.location.host}
                  </a>{" "}
                  domain for example some may be set when you first visit{" "}
                  <a
                    href='/'
                    style={{ color: "#e60000", fontWeight: "bold" }}
                    target='_blank'
                  >
                    www.
                    {window.location.host}
                  </a>{" "}
                  and then additional cookies may be placed when you move around
                  the website to subdomains for example{" "}
                  <a
                    href='/faq'
                    style={{ color: "#e60000", fontWeight: "bold" }}
                    target='_blank'
                  >
                    www.
                    {window.location.host}/faq
                  </a>{" "}
                  .
                </p>{" "}
                <p className=' ml-4'>
                  {" "}
                  In this cookie notice we explain what cookies are, how we use
                  them and how you can manage them for both our site and other
                  websites you might use.
                </p>{" "}
                <p className=' ml-4'>
                  {" "}
                  What are cookies Cookies are tiny text files that are stored
                  on your computer, tablet or mobile phone when you visit a
                  website.
                </p>{" "}
                <p className=' ml-4'>
                  {" "}
                  The cookies mean that the website will remember you and how
                  you've used the site every time you come back. If you want to
                  know more about cookies, head to{" "}
                  <a
                    href='https://www.aboutcookies.org/'
                    style={{ color: "#e60000", fontWeight: "bold" }}
                    target='_blank'
                  >
                    {" "}
                    aboutcookies.org
                  </a>{" "}
                  (Please note: This link opens a new window or browser tab)
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey={2}
              className=' mb-4'
              onClick={() => handleExpansion(2)}
            >
              <Accordion.Header className='row'>
                <span
                  className={
                    styles["pull-left"] + " ml-3" + styles["question-content"]
                  }
                >
                  How we use cookies
                </span>
                <span>
                  {expand === 2 ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  )}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <p className=' ml-4'>
                  We use cookies to make our website work, or work more
                  efficiently. We also use cookies to track what you've done on
                  the site so we can understand what you like and use or don't
                  like and don't use to provide a more enjoyable, customised
                  service and experience, as well as to help us develop and
                  deliver better content, products and services tailored to our
                  customers' interests and needs.
                </p>{" "}
                <p className=' ml-4'>
                  {" "}
                  Our cookies don't hold personal information such as your name.
                  They simply let us find information once you're logged in or
                  help link your browsing information to you and your personal
                  information when you choose to register for a service, white
                  paper or newsletter.
                </p>{" "}
                <p className=' ml-4'>
                  {" "}
                  To do this we sometimes use persistent cookies. We take
                  looking after your personal information seriously – we're
                  always monitoring and improving how we protect it.
                </p>{" "}
                <p className=' ml-4'>
                  <bold>
                    We've put our cookies into the following categories, to make
                    it easier for you to understand why we need them:
                  </bold>
                  <ul>
                    <li
                      className=' mt-1'
                      style={{ color: "#e60000", fontWeight: "bold" }}
                    >
                      First Party Cookies:
                    </li>
                    <p className=' '>
                      First party cookies originate from the same domain as the
                      website you’re currently visiting (in this case,
                      {window.location.host}).
                    </p>
                    <li
                      className=' mt-1'
                      style={{ color: "#e60000", fontWeight: "bold" }}
                    >
                      Third Party Cookies:
                    </li>
                    <p className=' '>
                      Third party cookies originate from a domain that’s
                      different to the website being visited. For example, when
                      you visit our website, we may link to another company's
                      website – like our Facebook or Twitter account, or a video
                      from our YouTube page.
                    </p>
                    <p className=' '>
                      So, when you 'Like' or 'Tweet' an item from our website,
                      these sites may sometimes put cookies on your computer. We
                      don't control how they use their cookies, so we suggest
                      you check their website to see how they're using them and
                      how you can manage them.
                    </p>
                    <p className=' '>
                      Affiliates of the Vodafone Group with a different domain
                      name may also place cookies on our website, to show you
                      adverts or pages of other Vodafone Group companies that
                      may be of interest to you. Details of these affiliates –
                      and how to opt-out – are included in the table.
                      <div className={styles["accordion-div"]}>
                        {cookies.map((e, i) => (
                          <CookieAccordion
                            title={e.title}
                            description={e.descrition}
                            usetoggle={e.usetoggle}
                            showDetails={true}
                            ifany={e.ifany}
                          ></CookieAccordion>
                        ))}
                      </div>
                    </p>
                  </ul>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey={3}
              className=' mb-4'
              onClick={() => handleExpansion(3)}
            >
              <Accordion.Header className='row'>
                <span
                  className={
                    styles["pull-left"] + " ml-3" + styles["question-content"]
                  }
                >
                  Controlling your cookies
                </span>
                <span>
                  {expand === 3 ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className={styles["pull-right"] + " mr-4"}
                    />
                  )}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <p className=' ml-4'>
                  <p style={{ color: "#e60000", fontWeight: "bold" }}>
                    You can manage your cookie preferences by accessing the
                    preference centre below:
                  </p>
                  <span
                    className={styles["cookie2-btn"]}
                    onClick={() => OpenModal()}
                  >
                    Consent Pereferences
                  </span>
                </p>{" "}
                <p className=' ml-4'>
                  <p style={{ color: "#e60000", fontWeight: "bold" }}>
                    You can also manage your cookie preferences in your browser:
                  </p>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    You can control how you use cookies in your browser. If you
                    don’t know what browser you’re using, find out by:
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      PC users: Go to ‘Help’ in your browser and choosing
                      ‘About’.
                    </li>
                    <li className=' mt-1'>
                      Mac users: Click on the Apple menu and choose ‘About’.
                    </li>
                  </ul>
                </p>{" "}
                <p className=' ml-4'>
                  <p style={{ color: "#e60000", fontWeight: "bold" }}>
                    How to check cookies are enabled for PCs
                  </p>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Google Chrome
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      Click the menu button (three horizontal bars) at the top
                      right of your browser window and select ’Settings’ then
                      ‘Show advanced settings…
                    </li>
                    <li className=' mt-1'>
                      Locate the 'Privacy' heading, and select 'Content
                      settings'
                    </li>
                    <li className=' mt-1'>
                      Now select 'Allow local data to be set'
                    </li>
                  </ul>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Microsoft Internet Explorer 11
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      Click on the ‘Settings’ icon (looks like a cog) at the top
                      right of your browser window and select 'Internet
                      options', then click on the 'Privacy' tab
                    </li>
                    <li className=' mt-1'>
                      Locate the 'Privacy' heading, and select 'Content
                      settings'
                    </li>
                    <li className=' mt-1'>
                      Ensure that your Privacy level is set to Medium or below,
                      which will enable cookies in your browser
                    </li>
                    <li className=' mt-1'>
                      Settings above Medium will disable cookies
                    </li>
                  </ul>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Mozilla Firefox
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      Click the menu button (three horizontal bars) at the top
                      right of your browser window and select Options
                    </li>
                    <li className=' mt-1'>Then select the Privacy icon</li>
                    <li className=' mt-1'>
                      Click on Cookies, then select 'allow sites to set cookies'
                    </li>
                  </ul>
                </p>{" "}
                <p className=' ml-4'>
                  <p style={{ color: "#e60000", fontWeight: "bold" }}>
                    How to check cookies are enabled for Macs:
                  </p>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Safari on OX X
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      Click on 'Safari' at the top of your browser window and
                      select the 'Preferences' option.
                    </li>
                    <li className=' mt-1'>Click on 'Privacy'.</li>
                    <li className=' mt-1'>
                      Select 'Allow from websites I visit'.
                    </li>
                    <li className=' mt-1'>
                      Select 'Allow from websites I visit'.
                    </li>
                  </ul>
                </p>
                <p className=' ml-4'>
                  <p style={{ color: "#e60000", fontWeight: "bold" }}>
                    Block cookies:
                  </p>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    If you'd like to find out how to stop websites using cookies
                    to remember what sites you visit, choose your browser from
                    the list. This will take you to the browser's respective
                    page where you can manage your cookies and site data:
                  </p>
                  <ul>
                    <li className=' mt-1'>
                      <a
                        href='https://support.google.com/chrome/answer/95647?hl=en-GB'
                        style={{ color: "#e60000", fontWeight: "bold" }}
                        target='_blank'
                      >
                        Chrome
                      </a>
                    </li>
                    <li className=' mt-1'>
                      <a
                        href='https://support.apple.com/en-sg/guide/safari/sfri11471/mac'
                        style={{ color: "#e60000", fontWeight: "bold" }}
                        target='_blank'
                      >
                        Safari
                      </a>
                    </li>
                    <li className=' mt-1'>
                      <a
                        href='https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature'
                        style={{ color: "#e60000", fontWeight: "bold" }}
                        target='_blank'
                      >
                        Firefox
                      </a>
                    </li>
                    <li className=' mt-1'>
                      <a
                        href='https://support.microsoft.com/en-us/windows/internet-explorer-help-23360e49-9cd3-4dda-ba52-705336cc0de2#ie=ie-11'
                        style={{ color: "#e60000", fontWeight: "bold" }}
                        target='_blank'
                      >
                        Internet Explorer
                      </a>
                    </li>
                  </ul>
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    If you change your cookie settings in your browser, your
                    experience with us won't be tracked. Information on
                    controlling and deleting cookies is also available at{" "}
                    <a
                      href=' http://allaboutcookies.org'
                      style={{ color: "#e60000", fontWeight: "bold" }}
                      target='_blank'
                    >
                      http://allaboutcookies.org
                    </a>
                    .
                  </p>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default CookieNotice;
