import React, { useState, useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import styles from "./CookieAcoordion.module.css";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Category } from "@mui/icons-material";
function CookiesDetails({
  title,
  description,
  Duration,
  host,
  category,
  type,
  index,
}) {
  const [expand, setExpansion] = useState(-1);
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const handleExpansion = (expandedItem) => {
    if (expand === expandedItem) {
      setExpansion(-1);
    } else {
      setExpansion(expandedItem);
    }
  };
  return (
    <Accordion>
      <Accordion.Item
        eventKey={index}
        className=' mb-4'
        onClick={() => handleExpansion(index)}
      >
        <Accordion.Header className='row'>
          <span
            className={
              styles["pull-left"] + " ml-3 " + styles["question-content"]
            }
          >
            {title}
          </span>
          <span>
            {expand === index ? (
              <FontAwesomeIcon
                icon={faAngleUp}
                className={styles["pull-right"] + " mr-4"}
              />
            ) : (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={styles["pull-right"] + " mr-4"}
              />
            )}
          </span>
        </Accordion.Header>
        <Accordion.Body className={styles["accordion-body"]}>
          <p className=' ml-4'>Description : {description}</p>
          <p className=' ml-4'>Host : {host}</p>
          <p className=' ml-4'>Category : {category}</p>
          <p className=' ml-4'>Type : {type}</p>
          <p className=' ml-4'>Duration : {Duration}</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CookiesDetails;
