import React, { useState } from "react";

const SuperAdminContext = React.createContext([() => {}]);

const SuperAdminProvider = ({ children }) => {
  const [superAdmin, setSuperAdmin] = useState({
    region: "all",
  });

  const changeDetails = (data) => {
    setSuperAdmin(data);
  };

  return (
    <SuperAdminContext.Provider value={[superAdmin, changeDetails]}>
      {children}
    </SuperAdminContext.Provider>
  );
};

export { SuperAdminProvider, SuperAdminContext };
