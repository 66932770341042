import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import img from "../../assets/hackLogo.svg";
function LandingNavBar({ show }) {
  const location = useLocation();

  return (
    <div class='nav-space'>
      <Navbar
        collapseOnSelect
        expand='lg'
        className='home-nav-size navbar-home-class '
        variant='light'
        color='light'
        style={{ width: "100%" }}
      >
        <Navbar.Brand>
          <div
            className='d-inline-block align-top home_logo_size'
            alt=''
            style={
              location.pathname === "/"
                ? { animation: "changeBG  1.2s 3s forwards" }
                : { animation: "" }
            }
          />
          <img
            className=' align-top Vois_logo'
            alt='Brand logo'
            loading='lazy'
            src='../../assets/_vois_white.png'
          />
          <Link to='/' className='logo-container'></Link>
        </Navbar.Brand>
        {show == false ? null : (
          <>
            <Navbar.Toggle aria-controls='responsive-navbar-nav ' />
            <Navbar.Collapse
              id='responsive-navbar-nav'
              className='justify-content-end'
            >
              <Nav>
                {/* <Nav.Link
                  className={`home-navitem ${
                    location.pathname === "/guidelines" && "highlight"
                  }`}
                >
                  <Link to='/guidelines'>GUIDELINES</Link>
                </Nav.Link>
                <Nav.Link
                  className={`home-navitem ${
                    location.pathname === "/FAQ" && "highlight"
                  }`}
                >
                  <Link to='/FAQ'>FAQ</Link>
                </Nav.Link> */}

                <Nav.Link
                  eventKey={2}
                  href='/login'
                  className={`home-navitem ${
                    location.pathname === "/login" && "highlight"
                  }`}
                >
                  <Link to='/login'>LOGIN</Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </div>
  );
}

export default LandingNavBar;
