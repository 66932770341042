import React from "react";

import "./Input.css";

const Input = ({ setMessage, sendMessage, message }) => (
  <form className='form'>
    <input
      className='asc-input '
      type='text'
      placeholder='Type a message...'
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={(event) =>
        event.key === "Enter" ? sendMessage(event) : null
      }
    />
    <button className='btn  asc-send-btn  ' onClick={(e) => sendMessage(e)}>
      Send
    </button>
  </form>
);

export default Input;
