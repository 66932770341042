import React from "react";

import onlineIcon from "../../../../assets/online.png";

import "./TextContainer.css";

const TextContainer = ({ users }) => (
  <div className=''>
    {users ? (
      <div>
        {/* {console.log(users)} */}
        <div className='asc-infoBar asc-active-now-infobar'>
          <span className='mt-3 asc-active-now'>Active now</span>
        </div>
        <div className='activeContainer'>
          <h5 style={{ maxHeight: "32.5rem" }}>
            {users.map(({ name }) => (
              <div key={Math.random()} className='activeItem'>
                <img className='mr-2' alt='Online Icon' src={onlineIcon} />
                {name}
              </div>
            ))}
          </h5>
        </div>
      </div>
    ) : null}
  </div>
);

export default TextContainer;
