import React, { useEffect, useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";

import SuperAdminNavBar from "./../SuperAdminNavBar";
import Announcement from "./Announcement";
import styles from "../superadminProjectsinnerPage.module.css";
import "react-toastify/dist/ReactToastify.css";
import AnnouncementModal from "./AnnouncementModal";

const apiUri = process.env.REACT_APP_SERVER_URL + "announcement/";

const socketUri = process.env.REACT_APP_SOCKET_IO_URL;

let socket;
let socketId;

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [showModalHandler, setShowModalHandler] = useState(false);
  const [announcementObject, setAnnouncementObject] = useState({});

  const showOrHideModalHandler = (announcement) => {
    setAnnouncementObject(announcement);
    setShowModalHandler(!showModalHandler);
  };

  const fetchAnnouncements = async () => {
    const response = await axios.post(apiUri + "getAllByRegion", {
      region: localStorage.getItem("region"),
    });

    const announcementsData = response.data;
    setAnnouncements(announcementsData);
  };

  const addNewAnnouncementHandler = async (newAnnouncementObject) => {
    const request = await axios.post(apiUri, newAnnouncementObject);

    const addedAnnouncement = await request.data;

    toast.success("New Announcement Added Successfully!", { autoClose: 3000 });

    await fetchAnnouncements();

    socket = io(socketUri, { path: "/socket.io" });

    socket.on("connect", () => {
      socketId = socket.id;
    });

    // socket.emit("test", () => {
    //   console.log(socket);
    // });

    socket.emit(
      "count",
      { socketId, region: localStorage.getItem("region") },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );
  };

  const updateAnnouncementHandler = async (existingAnnouncement) => {
    const request = await axios.post(apiUri + "update", existingAnnouncement);

    const updatedAnnouncement = await request.data;

    toast.success("Announcement Modification Successfully!", {
      autoClose: 3000,
    });

    await fetchAnnouncements();
  };

  const deleteAnnouncementHandler = async (announcementId) => {
    const deletedAnnouncement = await axios.post(apiUri + "delete", {
      id: announcementId,
    });

    const checkDeleted = deletedAnnouncement.data;

    if (checkDeleted.deletedCount > 0) {
      toast.success("Deleted", { autoClose: 3000 });
      await fetchAnnouncements();
    }
  };

  useEffect(() => {
    socket = io(socketUri, { path: "/socket.io" });

    socket.on("connect", () => {
      socketId = socket.id;
    });
    socket.emit(
      "count",
      { socketId, region: localStorage.getItem("region") },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );
    // socket.emit("test", () => {
    //   console.log(socket);
    // });

    // socket.emit(
    //   "count",
    //   { socketId, region: localStorage.getItem("region") },
    //   (error) => {
    //     if (error) {
    //       alert(error);
    //     }
    //   }
    // );

    socket.on("counted", (result) => {});

    // socket.on("counted");

    const fetchData = async () => {
      await fetchAnnouncements();
    };

    fetchData();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={" col-md-10"}>
            <button
              className='btn btn-outline-dark float-right m-2'
              data-toggle='modal'
              data-target='#exampleModal'
              onClick={() => showOrHideModalHandler({})}
            >
              + Add Announcement
            </button>

            <div className={styles["main-container"] + " row mt-5"}>
              {announcements.map((announcement) => (
                <Announcement
                  key={announcement._id}
                  title={announcement.title}
                  body={announcement.body}
                  id={announcement._id}
                  deleteAnnouncementHandler={deleteAnnouncementHandler}
                  updateAnnouncementHandler={() =>
                    showOrHideModalHandler(announcement)
                  }
                />
              ))}
            </div>

            {showModalHandler && (
              <AnnouncementModal
                showOrHideModalHandler={showOrHideModalHandler}
                announcementObject={announcementObject}
                addNewAnnouncementHandler={addNewAnnouncementHandler}
                updateAnnouncementHandler={updateAnnouncementHandler}
              />
            )}
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcements;
