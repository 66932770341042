import React, { useState, useEffect } from "react";
import "./failSubmission.css";
import { useNavigate } from "react-router-dom";
import { setUserJWT, getCurrentUser } from "../../../services/authService";
import NavBar from "../../common/navBar/navBar";
import LandingNavBar from "../../home/navbar";

const FailSubmission = (props) => {
  const history = useNavigate();
  const [user, setUser] = useState({});
  const [navColor, setNavColor] = useState(false);
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
  }, []);
  return (
    <div className='failSubmission-container  container-fluid'>
      <div className='row d-flex justify-content-center mt-4'>
        <main className='col-lg-12 col-md-12 col-xs-10 col-sm-10 failsubmission-main'>
          <div>
            <p className='failsubmission-page-title mt-3 col-12'>
              Something Went Wrong
            </p>
            <div className='project-submission-errors-container col-12'>
              <ul className='errors-list'>
                {props.errors
                  ? props.errors.map((el) => <li className='error'>{el}</li>)
                  : null}
              </ul>
              <button
                type='submit'
                className='loginBtn '
                onClick={() => {
                  window.location.reload();
                }}
              >
                <span>Back to registration</span>
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default FailSubmission;

{
  /* <div className="container-fluid m-0 p-0">
<NavBar user={user} navColor={navColor} setNavColor={setNavColor} />
<div className="maindiv row">
  <div className="col-md-4"></div>
  <div className="subParentDiv col-md-4 col-xs-12">
    <div className="parent text-center">
      <h1>_VOIS Global Hackathon</h1>
      <p style={{ fontSize: "1.7rem", marginBottom: "2rem" }}>Sorry!</p>
      <img className="failSubmission-img-size" src="/assets/error.jpg" />

      <p>Something Went Wrong</p>
      <ul>
        {props.errors.map((el) => (
          <li>{el}</li>
        ))}
      </ul>
      <button
        type="submit"
        className="loginBtn col-md-12"
        onClick={() => {
          props.changeSteps();
        }}
      >
        <span>Back to my registration</span>
      </button>
    </div>
  </div>
</div>
</div> */
}
