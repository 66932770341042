import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import GameBoard from "./components/GameBoard";
import Banner from "./components/Banner";
import { Link } from "react-router-dom";
import { GAME_STATUS } from "./constants";
import axios from "axios";
import LoggedNavBar from "../../../common/navBar/loggedNav";
import SideMenu from "../../../common/sideMenu/sideMenu";
const bg = "../../../../assets/glb/newmodel/ascentionBG.png";
const useStyles = createUseStyles({
  header: {
    textAlign: "center",
    color: "white",
  },
  footer: {
    textAlign: "center",
  },
  bg: {
    position: "fixed",
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    overflowX: "scroll",
    backgroundImage: `url(${bg})`,
  },
});

const RememberCards = () => {
  const [gameStatus, setGameStatus] = useState(GAME_STATUS.CREATING);
  const classes = useStyles();

  const gameFinished = () => {
    setGameStatus(GAME_STATUS.FINISHED);
  };

  const resetGame = () => {
    setGameStatus(GAME_STATUS.RESETTING);
  };

  const setGameToInProgress = () => {
    setGameStatus(GAME_STATUS.IN_PROGRESS);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container' style={{ padding: "0" }}>
          <div
            className=' asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div>
              <GameBoard
                gameStatus={gameStatus}
                handleStart={setGameToInProgress}
                handleFinish={gameFinished}
              />
              {gameStatus === GAME_STATUS.FINISHED && (
                <Banner handleClick={resetGame} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RememberCards;
