import React, { useEffect, useState, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

import AddUser from "./AddUser";
import SearchUser from "./SearchUser";
import User from "./User";
import UserPagination from "./UserPagination";
import SuperAdminNavBar from "../SuperAdminNavBar";
import styles from "../superadminInnerHome.module.css";
const apiUri = process.env.REACT_APP_SERVER_URL + "usermanagement/";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [numberOfUsers, setNumberOfUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    const request = await axios.post(apiUri + "GetUsers/", {
      skip: currPage - 1,
      searchKeyWord: searchKey.toLowerCase(),
      limitUsers: usersPerPage,
    });
    const fetchedUsers = await request.data;
    setUsers(fetchedUsers);
  }, [searchKey, currPage, usersPerPage]);

  const getNumberOfUsers = useCallback(async () => {
    const response = await axios.post(apiUri + "count", {
      searchKeyWord: searchKey,
    });
    let numberOfUsers = await response.data.numberOfUsers;
    setNumberOfUsers(numberOfUsers);
  }, [searchKey]);

  const paginate = (number) => {
    let newCurrPage = 0;
    switch (number) {
      case "+":
        newCurrPage = currPage + 1;
        if (newCurrPage <= Math.ceil(numberOfUsers / usersPerPage)) {
          setCurrPage(newCurrPage);
        }
        break;
      case "-":
        newCurrPage = currPage - 1;
        if (newCurrPage >= 1) {
          setCurrPage(newCurrPage);
        }
        break;
      default:
        setCurrPage(number);
        break;
    }
  };

  const searchKeyChangeHandler = async (event) => {
    let enteredValue = event.target.value;
    setCurrPage(1);
    setSearchKey(enteredValue);
  };

  const searchUsers = async (updatedUsers) => {
    if (updatedUsers.length > 0) {
      setUsers(updatedUsers);
    } else {
      await fetchUsers();
    }
  };

  useEffect(() => {
    const loadAllUsers = async () => {
      await getNumberOfUsers();
      await fetchUsers();
    };
    loadAllUsers();
  }, [fetchUsers, getNumberOfUsers]);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div className={styles["main-container"] + " col-md-10"}>
              <div className='row'>
                <AddUser
                  updatedUsers={async () => {
                    await fetchUsers();
                    await getNumberOfUsers();
                  }}
                />

                <SearchUser
                  searchKey={searchKey}
                  searchKeyChangeHandler={searchKeyChangeHandler}
                  searchButtonClickHandler={fetchUsers}
                  searchUsers={searchUsers}
                />

                {users.length > 0 && (
                  <table className='col-md-11 ml-5 text-center table table-striped table-bordered'>
                    <thead>
                      <tr>
                        <th>Users</th>
                        <th>Reset Password</th>
                        <th>Revoke User</th>
                        <th>Impersonate User</th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {users.map((user) => (
                          <User
                            fetchUsers={fetchUsers}
                            user={user.email}
                            key={user.id}
                          />
                        ))}
                      </tbody>
                    }
                  </table>
                )}
                {users.length <= 0 && (
                  <div className='col-md-11 ml-5 text-center'>
                    No Users Found!
                  </div>
                )}
                <div className='col-md-11 ml-5 text-center'>
                  Page {currPage}
                </div>
                <UserPagination
                  paginate={paginate}
                  usersPerPage={usersPerPage}
                  totalUsers={numberOfUsers}
                  currPage={currPage}
                />
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Users;
