import React from "react";
import { Spinner } from "react-bootstrap";
import "./index.css";
function SubmissionLoader({ show }) {
  return (
    <div
      style={{ display: show ? "flex" : "none", flexDirection: "column" }}
      className='submission-loader-back'
    >
      <Spinner animation='border' role='status' variant='danger' />
      <span className='submissionLoaderTextHeader'>
        Please wait while we upload your information
      </span>
      <span className='submissionLoaderTextnote'>Don't close this screen</span>
    </div>
  );
}

export default SubmissionLoader;
