import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InternalEventNavbar from "../../../common/internalEventNavbar/navBar/navBar.js";
import FiltrationPanelAddGroup from "./filtrationPanelAddGroup.js";
import "./filtrationPanelAdmin.css";
import FiltrationPanelProjectDetails from "./filtrationPanelProjectDetails.js";
import io from "socket.io-client";
import SideMenu from "../../../common/sideMenu/sideMenu.js";
import LoggedNavBar from "../../../common/navBar/loggedNav.js";
import * as _ from "lodash";
import * as XLSX from "xlsx";
function FiltrationPanelAdmin() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;
  const id = 1;
  let socket;
  let socketID;

  const [searchText, setSearchText] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [searchResult, setSearchResult] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const searchProjects = () => {
    let searchResult = projects.filter((project) =>
      project.project.name.includes(searchText)
    );
    setPageNumbersForPaging(searchResult);
    setSearchResult(searchResult);
  };

  const projectsToBeShown = () => {
    let result;
    result =
      searchResult.length > 0 && searchText !== "" ? searchResult : projects;
    const indexOfLastUser = currentPage * 10;
    const indexOfFirstUser = indexOfLastUser - 10;
    const currentProjects = result.slice(indexOfFirstUser, indexOfLastUser);
    return currentProjects;
  };

  useEffect(() => {
    searchProjects();
  }, [searchText]);

  useEffect(() => {
    socket = io(ENDPOINT, { path: "/socket.io" });
    socket.on("connect", function () {
      socketID = socket.id;
    });
    socket.emit("joinDefaultRoom", { panelID }, (error) => {
      if (error) {
        alert(error);
      }
    });
  }, [ENDPOINT, socketID]);

  const location = useLocation();
  const [projects, setprojects] = useState([]);
  const [ProjectID, setProjectID] = useState("");
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [panelID, setpanelID] = useState(location.state.panelID);
  const [panelType, setpanelType] = useState();
  const [panelStatus, setpanelStatus] = useState(false);
  const [projectDetails, setprojectDetails] = useState({});

  function compareASC(a, b) {
    if (a.group.grade < b.group.grade) {
      return -1;
    }
    if (a.group.grade > b.group.grade) {
      return 1;
    }
    return 0;
  }

  function compareDESC(a, b) {
    if (a.group.grade > b.group.grade) {
      return -1;
    }
    if (a.group.grade < b.group.grade) {
      return 1;
    }
    return 0;
  }

  const sortHandler = () => {
    if (searchResult.length > 0 && searchText !== "") {
      sortType === "asc"
        ? searchResult.sort(compareDESC)
        : searchResult.sort(compareASC);
    } else {
      sortType === "asc"
        ? projects.sort(compareDESC)
        : projects.sort(compareASC);
    }
    sortType === "desc" ? setSortType("asc") : setSortType("desc");
  };

  const setPageNumbersForPaging = (source) => {
    let pageNumbersTemp = [];
    for (let i = 1; i <= Math.ceil(source.length / 10); i++) {
      pageNumbersTemp.push(i);
    }
    setPageNumbers(pageNumbersTemp);
  };

  const nextHandler = () => {
    if (currentPage !== pageNumbers[pageNumbers.length - 1]) {
      setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage((oldCurrentPage) => oldCurrentPage - 1);
    }
  };

  useEffect(() => {
    getData();
    getPanelType();

    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, [ProjectID]);

  const getPanelStatus = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/panelStatusAdmin`,
        {
          panelID: panelID,
        }
      )
      .then((res) => {
        history("/judgesCalculated", {
          state: { panelID: panelID },
        });
        setpanelStatus(true);
      });
  };

  const getPanelType = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/panelType`, {
        panelID: panelID,
      })
      .then((res) => {
        setpanelType(res.data);
      });
  };

  const getData = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/adminProjects`,
        {
          panelID: panelID,
        }
      )
      .then((res) => {
        setprojects(res.data);
        setPageNumbersForPaging(res.data);
      });
  };

  const history = useNavigate();
  const [error, setError] = React.useState([]);
  function getDifference() {
    let errors = [];
    for (let index = 0; index < projects.length; index++) {
      const r = projects[index];
      for (let index2 = 0; index2 < projects.length; index2++) {
        const n = projects[index2];
        if (
          r["group"]["grade"] == n["group"]["grade"] &&
          index != index2 &&
          !errors.includes((l) => l.name == n["project"]["name"])
        ) {
          errors.push({
            first: { name: r["project"]["name"], grade: r["group"]["grade"] },
            second: {
              name: n["project"]["name"],
              grade: n["group"]["grade"],
            },
          });

          break;
        } else continue;
      }
    }
    return errors;
  }
  const [sendSuccess, setSendSuccess] = React.useState(true);
  const [sendFailure, setSendFailiure] = React.useState(true);
  const calculatePanel = () => {
    // let diff = getDifference();
    // if (diff.length > 0) {
    //   setError(diff);
    //   document.getElementById("calculateError").click();
    // } else {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}panelvalues/calculateRanks`, {
        panelID: panelID,
        sendSuccess,
        sendFailed: sendFailure,
      })
      .then((res) => {
        socket = io(ENDPOINT, { path: "/socket.io" });
        socket.emit("calculate", { socketID, panelID }, () => {});
        history("/judgesCalculated", {
          state: { panelID: panelID },
        });
      })
      .catch((ex) => {
        console.log(ex);
      });
  };
  const [showmodal, setshowmodal] = useState(false);
  const exportPanel = () => {
    var ws = XLSX.utils.json_to_sheet(
      projectsToBeShown().map((el) => {
        return {
          grade: el.group._id ? el.group.grade : el.group.grade,
          ProjectName: el.project.name,
        };
      })
    );
    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "panel");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Panel-Summary" + new Date() + ".xlsx");
  };
  return (
    <>
      <button
        id='calculateError'
        style={{ display: "none" }}
        data-toggle='modal'
        data-target='#ErrorCalculate'
      >
        Add Group
      </button>
      <div
        className='modal fade'
        id='ErrorCalculate'
        tabindex='-1'
        role='dialog'
        aria-labelledby='addGroup'
        aria-hidden='true'
        data-backdrop='static'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Error Message
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <p className='modal-title p-5'>
                Some similarities in scores need to be resolved first :
                <div className='row'>
                  {error.map((e, i) => {
                    return (
                      <span
                        className='col-md-6'
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <p className='modal-title '>{i + 1}</p>
                        <p className='modal-title '>{e["first"]["name"]}</p>
                        <p className='modal-title '>{e["second"]["name"]}</p>
                        <p className='modal-title '>{e["first"]["grade"]}</p>
                      </span>
                    );
                  })}
                </div>
              </p>
            </div>
            <div className='modal-footer' style={{ justifyContent: "center" }}>
              <button
                type='button'
                className='add-groups-modal-save-btn'
                data-dismiss='modal'
                aria-label='Close'
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='CheckMails'
        tabindex='-1'
        role='dialog'
        aria-labelledby='CheckMails'
        aria-hidden='true'
        data-backdrop='static'
      >
        <div className='modal-dialog ' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Calculation Confirmation
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <p className='modal-title pr-5 pl-5 pb-2 '>
                Proceed the calcultion taking in consideration :
              </p>
              <div className='row mt-5 pr-5 pl-5'>
                <div className='col-md-12'>
                  <p> send email to successful participants?</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem",
                    }}
                  >
                    <label class='switch2'>
                      <input
                        type='checkbox'
                        checked={sendSuccess}
                        onChange={(checked) => {
                          setSendSuccess(!sendSuccess);
                        }}
                      />
                      <span class='slider2 round'></span>
                    </label>
                    <label style={{ color: "white", marginLeft: "0.5rem" }}>
                      {sendSuccess == true ? "on" : "off"}
                    </label>
                  </div>
                </div>
                <div className='col-md-12'>
                  <p> send email to failed participants ?</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem",
                    }}
                  >
                    <label class='switch2'>
                      <input
                        type='checkbox'
                        checked={sendFailure}
                        onChange={(checked) => {
                          setSendFailiure(!sendFailure);
                        }}
                      />
                      <span class='slider2 round'></span>
                    </label>
                    <label style={{ color: "white", marginLeft: "0.5rem" }}>
                      {sendFailure == true ? "on" : "off"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer' style={{ justifyContent: "center" }}>
              <button
                type='button'
                className='add-groups-modal-save-btn'
                data-dismiss='modal'
                aria-label='Close'
                onClick={calculatePanel}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='addGroup'
        tabindex='-1'
        role='dialog'
        aria-labelledby='addGroup'
        aria-hidden='true'
        data-backdrop='static'
      >
        <FiltrationPanelAddGroup
          panel={panelID}
          type={panelType}
        ></FiltrationPanelAddGroup>
      </div>
      <div
        className='modal fade'
        id='projectDetails'
        tabindex='-1'
        role='dialog'
        aria-labelledby='projectDetails'
        aria-hidden='true'
      >
        <FiltrationPanelProjectDetails
          project={projectDetails}
          projectID={ProjectID}
          panelID={location.state.panelID}
          groupID={location.state.groupID}
          email={location.state.email}
          showPopup={showmodal}
          onclose={() => setshowmodal(false)}
        ></FiltrationPanelProjectDetails>
      </div>

      <div className='filtraion-panel-admin-container'>
        <LoggedNavBar to='judges' />

        <div className='coaches-pageContainer '>
          <div className='container'>
            {getPanelStatus()}
            <div
              className='row asc-cards-flip-container '
              style={{ margin: "0", padding: "0" }}
            >
              <div className=' mt-5 filtration-panel-group-btn-dev'>
                <span className='dev-label'>
                  Showing {projects.length < 10 ? projects.length : "10"} of{" "}
                  {projects.length}
                </span>
                <div className='dev-btns'>
                  <button
                    className=' add-group-btn float-right m-2'
                    onClick={exportPanel}
                  >
                    Export
                  </button>
                  <button
                    className=' add-group-btn float-right m-2'
                    // onClick={calculatePanel}
                    data-toggle='modal'
                    data-target='#CheckMails'
                  >
                    Calculate
                  </button>
                  <button
                    className=' add-group-btn float-right m-2'
                    data-toggle='modal'
                    data-target='#addGroup'
                  >
                    Add Group
                  </button>
                </div>
              </div>
              <div className='search-sort-container'>
                <div className='search-container'>
                  <input
                    className='search-input mr-2'
                    type='text'
                    style={{ borderRadius: "10px" }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder='SEARCH'
                    value={searchText}
                  />
                  <i class='fa fa-search-panel fa-search'></i>
                </div>
                <button className='sort-btn' onClick={() => sortHandler()}>
                  SORT
                </button>
              </div>
              <div className='col-md-12 mt-1'>
                <div className='col-md-12 col-sm-12'>
                  <div className=''>
                    {loadBytes ? (
                      <React.Fragment>
                        <div
                          className='filtration-panel-admin-table-width '
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <table class='table mb-0'>
                            <thead>
                              <tr>
                                <th
                                  scope='col'
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  GRADE
                                </th>
                                <th
                                  scope='col'
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  Country
                                </th>
                                <th scope='col' style={{ width: "40%" }}>
                                  PROJECT NAME
                                </th>
                                <th scope='col' style={{ width: "30%" }}>
                                  PANEL MEMBERS
                                </th>
                                <th
                                  scope='col'
                                  style={{ width: "10%" }}
                                  classname=''
                                >
                                  ACTIONS
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectsToBeShown().map((el) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        color: "#4A4D4E",
                                      }}
                                    >
                                      {el.group._id ? (
                                        <a> {el.group.grade}</a>
                                      ) : (
                                        el.group.grade
                                      )}
                                    </td>
                                    <td style={{ textTransform: "uppercase" }}>
                                      {el.project.country}
                                    </td>
                                    <td>{el.project.name}</td>
                                    <td>
                                      {el.group.panelMembers.length != 0 ? (
                                        <ul style={{ padding: "0" }}>
                                          {el.group.panelMembers.map((e) => (
                                            <li>{e}</li>
                                          ))}
                                        </ul>
                                      ) : (
                                        "No Assigned Panel"
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <button
                                        className=' add-group-btn  m-2'
                                        data-toggle='modal'
                                        data-target='#projectDetails'
                                        onClick={() => {
                                          setProjectID(el.project._id);
                                          setshowmodal(true);
                                        }}
                                      >
                                        <span className='fa fa-eye'></span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className='paging'>
                            <span
                              onClick={() => prevHandler()}
                              className='paging-item previous'
                            >
                              &lt;&lt; Previous
                            </span>
                            {pageNumbers.map((number) => {
                              return (
                                <span
                                  className={
                                    number === currentPage
                                      ? "paging-item active"
                                      : "paging-item"
                                  }
                                  onClick={() => setCurrentPage(number)}
                                >
                                  {number}
                                </span>
                              );
                            })}
                            <span
                              onClick={() => nextHandler()}
                              className='paging-item next'
                            >
                              NEXT &gt;&gt;
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div
                        className='main-postKeynotes-page-body row'
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className='text-center'>
                          <div
                            className='spinner-grow text-primary'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div
                            className='spinner-grow text-secondary'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div
                            className='spinner-grow text-success'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div
                            className='spinner-grow text-danger'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div
                            className='spinner-grow text-warning'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div className='spinner-grow text-info' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div
                            className='spinner-grow text-light'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div className='spinner-grow text-dark' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FiltrationPanelAdmin;
