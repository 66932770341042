import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";

import TextContainer from "../TextContainer/TextContainer";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import auth from "../../../../services/authService";
import "./liveChat.css";
import SideMenu from "../../../common/sideMenu/sideMenu";
import LoggedNavBar from "../../../common/navBar/loggedNav";
// import { getCurrentUser } from "../../../services/authService";
const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;

let socket;
let socketID;
const LiveChat = ({ nickname, chatroom, event }) => {
  const [name, setName] = useState(nickname);
  // const [userName, setUserName] = useState(
  //   getCurrentUser().email !== null
  //     ? getCurrentUser().email
  //     : getCurrentUser().preferred_username
  // );
  const [userName, setUserName] = useState(
    auth.getCurrentUser().preferred_username
  );
  const [room, setRoom] = useState(chatroom);
  const [eventName, setEventName] = useState(event);
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    socket = io(ENDPOINT, {
      path: "/socket.io/",
    });

    socket.on("connect", function () {
      // console.log("Connected! ID: " + socket.id);

      socketID = socket.id;
    });

    setRoom(room);
    setName(name);
    setEventName(event);
    // setUserName(
    //   getCurrentUser().email !== null
    //     ? getCurrentUser().email
    //     : getCurrentUser().preferred_username
    // );
    setUserName(auth.getCurrentUser().preferred_username);
    socket.emit("join", { name, userName, room, eventName }, (error) => {
      // console.log("join error", error);
      localStorage.setItem("chatSocket", socketID);
      if (error) {
        alert(error);
      }
    });
    return function () {
      socket.close();
    };
  }, [ENDPOINT, chatroom, chatroom]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages(message);
    });
    socket.on("singlemessage", (message) => {
      // console.log("sending", message);

      setMessages(message);
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();

    socketID = localStorage.getItem("chatSocket");
    if (message) {
      // console.log();
      socket.emit("sendMessage", { message, socketID }, () => setMessage(""));
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <div className='container'>
          <LoggedNavBar to='hall' />
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div className='col-sm-8 asc-chat-container p-0'>
              <InfoBar room={room} />

              <Messages messages={messages} name={name} />
              <Input
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
              />
            </div>
            <div className='col-sm-3 asc-online-container'>
              <TextContainer users={users} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChat;
