import React from "react";

import "./Message.css";

import ReactEmoji from "react-emoji";

const Message = ({ message: { text, user }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if (user === trimmedName) {
    isSentByCurrentUser = true;
  }

  return isSentByCurrentUser ? (
    <div className='asc-messageContainer justifyEnd'>
      {/* <p className='sentText pr-10'>{trimmedName}</p> */}
      <div className='asc-messageBox asc-backgroundBlue'>
        <p className='messageText '>{text}</p>
      </div>
    </div>
  ) : (
    <div className='asc-messageContainer asc-justifyStart'>
      {/* <p className='asc-sentText pl-10 '>{user}</p> */}
      <div className='asc-messageBox asc-backgroundLight '>
        <p className='messageText colorDark'>{text}</p>
      </div>
    </div>
  );
};

export default Message;
