import "./index.css";
import React, { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/Path 6998.svg";
import * as _ from "lodash";

function Table({ evaluations, criterias, setValues, openModal }) {
  const changeValue = (evaluation, e) => {
    evaluation.value = e.target.value;
    setValues(evaluation);
  };

  const createDDL = (criteria) => {
    const criteriaMaxWeight =
      criterias.find((r) => r.criteriaId == criteria).weight + 1;
    return [...Array(criteriaMaxWeight).keys()];
  };

  const getCriteriaWeight = (criteriaId) => {
    return criterias.find((singleCri) => singleCri.criteriaId === criteriaId)
      .weight;
  };

  return (
    <div className='table-responsive project-table'>
      {Object.keys(_.groupBy(evaluations, "projectId")).map((item, i) => {
        console.log(_.groupBy(evaluations, "projectId")[item][0]);
        return (
          <div className='scrollIt' key={i}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginBottom: "1rem",
              }}
            >
              <span className='project-name'>
                {_.groupBy(evaluations, "projectId")[item][0].projectName}
                {_.groupBy(evaluations, "projectId")[item][0].fromMixedSL ? (
                  <span class='badge badge-info ml-3'>
                    Cross Functional Team
                  </span>
                ) : null}
              </span>
              <span
                className='project-detail'
                onClick={() => {
                  openModal(item);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                VIEW PROJECT DETAILS
              </span>
              <span style={{ color: "#e60000" }}>
                Please add score to all criteria and business justification.
              </span>
            </div>
            <table className='table table-fixed'>
              <thead>
                <tr className='table-head-row'>
                  {criterias.map((item, index) => {
                    return (
                      <th
                        scope='col'
                        className='col-md-2 col-sm-12 table-head-row-cell'
                        key={index}
                      >
                        {item.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr className='table-body-row' key={i}>
                  {_.groupBy(evaluations, "projectId")[item].map(
                    (evaluation, i) => {
                      return (
                        <td
                          key={i}
                          className='col-md-2 col-sm-12 table-body-row-cell'
                        >
                          <div className='project-container'>
                            <div className='rail-select'>
                              <div className='select-side'>
                                <Arrow />
                              </div>
                              {evaluation.value != 0 ? (
                                <img
                                  src='/assets/tick.png'
                                  style={{
                                    width: "1rem",
                                    position: "absolute",
                                    top: "-5px",
                                    left: "-5px",
                                  }}
                                ></img>
                              ) : null}
                              <select
                                onChange={(e) => changeValue(evaluation, e)}
                                value={evaluation.value}
                                className='form-control'
                                style={{ padding: "0rem 1rem .1rem 1rem" }}
                                id='sel1'
                              >
                                {createDDL(evaluation.criteriaId).map((el) => (
                                  <option>{el}</option>
                                ))}
                              </select>
                            </div>
                            <span className='criteria-weight'>
                              /{getCriteriaWeight(evaluation.criteriaId)}
                            </span>
                          </div>
                        </td>
                      );
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}
export default Table;
