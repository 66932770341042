import { Navbar, Container, Nav } from "react-bootstrap";
import img from "../../../assets/vodafoneLogo.svg";
import arrow from "../../../assets/Icon feather-arrow-left.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUser,
  returnSuperAdmin,
  setRegionToken,
  ssoJWTCheck,
} from "../../../services/authService";
import { useState, useEffect } from "react";
import { getAnnouncementsCountByRegion } from "../../../apis/announcments";
import io from "socket.io-client";
function LoggedNavBar(props) {
  const socketUri = process.env.REACT_APP_SOCKET_IO_URL;
  let socket;
  let socketId;
  const [IsAdmin, setIsAdmin] = useState();
  const [regionButtons, setRegionButtons] = useState(true);
  const [region, setRegion] = useState("");
  const [announcementsCount, setAnnouncementsCount] = useState(0);
  const [loadAgain, setloadAgain] = useState("");
  const location = useLocation();
  const history = useNavigate();
  const [user, setUser] = useState();
  useEffect(() => {
    const currentUser = getCurrentUser();
    setUser(currentUser);
    setRegionButtons(
      currentUser.roles.includes("superAdmin") ||
        currentUser.roles.includes("panelAdmin") ||
        currentUser.roles.includes("panelMember") ||
        currentUser.roles.includes("coach") ||
        (currentUser.roles.includes("guestUser") &&
          !currentUser.roles.includes("projectMember"))
        ? true
        : false
    );
    setIsAdmin(
      currentUser.roles.includes("superAdmin") ||
        localStorage.getItem("superAdminTempJWT") != undefined
        ? "true"
        : "false"
    );
  });

  useEffect(() => {
    const currentUser = getCurrentUser();
    ssoJWTCheck().then((r) => {
      setIsAdmin(
        currentUser.roles.includes("superAdmin") ||
          localStorage.getItem("superAdminTempJWT") != undefined
          ? "true"
          : "false"
      );
      setRegion(getCurrentUser().region);
      getAnnouncementsCountByRegion({ region: region }).then((res) =>
        setAnnouncementsCount(res.data.announcementsCount)
      );

      socket = io(socketUri, { path: "/socket.io" });
      socket.on("connect", () => {
        socketId = socket.id;
      });
      socket.emit("count", { socketId, region: region }, (error) => {
        if (error) {
          alert(error);
        }
      });

      socket.on("counted", (result) => {
        setAnnouncementsCount(result.count);
      });
    });
  }, [IsAdmin, region, announcementsCount, socket, loadAgain]);

  const getFileURL = async (url, name, defaultType = "*/*") => {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });
    return URL.createObjectURL(data);
  };
  const getprivacyNotice = async () => {
    let currentUser = getCurrentUser();
    if (currentUser != undefined && currentUser != null) {
      switch (currentUser.region) {
        case "egypt":
          window.open(
            await getFileURL("/assets/egypt_privacy.pdf", "egypt_privacy.pdf"),
            "_blank"
          );
          break;
        case "india":
          window.open(
            await getFileURL(
              "/assets/IN_Privacy_Statement.pdf",
              "india_privacy.pdf"
            ),
            "_blank"
          );
          break;

        default:
          break;
      }
    }
  };
  const getTermsAndConditions = async () => {
    let currentUser = getCurrentUser();
    if (currentUser != undefined && currentUser != null) {
      switch (currentUser.region) {
        case "egypt":
          window.open(
            await getFileURL(
              "/assets/terms_and_conditions_egypt.pdf",
              "terms_and_conditions_egypt.pdf"
            ),
            "_blank"
          );
          break;
        case "india":
          window.open(
            await getFileURL(
              "/assets/IN_TermsAndConditions.pdf",
              "terms_and_conditions_india.pdf"
            ),
            "_blank"
          );
          break;
        case "romania":
          window.open(
            await getFileURL(
              "/assets/terms_and_conditions_romania.pdf",
              "RO_Termenii si Conditiile Regulamentui concursului.pdf"
            ),
            "_blank"
          );
        default:
          break;
      }
    }
  };
  return (
    <Navbar
      collapseOnSelect
      style={{ width: "100%" }}
      expand='lg'
      className='Container-NavBar'
      variant='light'
    >
      <Container className='border-bottom'>
        <Navbar.Collapse className='justify-content-start'></Navbar.Collapse>
        <Navbar.Brand className='logo' href='/'>
          {/* <div
            className='d-inline-block align-top home_logo_size'
            alt=''
            style={
              location.pathname === '/'
                ? { animation: 'changeBG  1.2s 3s forwards' }
                : { animation: '' }
            }
          />
          <img
            className='d-inline-block align-center Vois_logo'
            alt='Brand logo'
            loading='lazy'
            src='../../assets/_vois_white.png'
          />
           <Link to='/' className='logo-container'></Link> */}
          {/* <img
            src='../../assets/_vois_white.png'
            width='100'
            height='30'
            className='d-inline-block align-center'
            alt=''
            loading='lazy'
          /> */}
          <img
            src='/assets/hackLogo.svg'
            className='d-inline-block align-center '
            alt=''
            width='300'
            height='30'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav>
            <div className='nav-dropdown' style={{ float: "left" }}>
              <button
                className='dropbtn'
                onClick={() => {
                  history("/announcments");
                }}
              >
                {" "}
                <i class='fa fa-bell' style={{ color: "white" }}>
                  {" "}
                </i>
                <span class='badge badge-danger  bell-icon-badge'>
                  {announcementsCount}
                </span>
              </button>{" "}
            </div>
            {IsAdmin == "true" ? (
              <div className='nav-dropdown' style={{ float: "left" }}>
                <button
                  className='dropbtn'
                  onClick={() => {
                    returnSuperAdmin();
                    history("/superadmin/home");
                  }}
                >
                  {" "}
                  <i
                    className='fas fa-users-cog '
                    style={{ color: "white" }}
                    title='Go To SuperAdmin'
                  ></i>
                </button>{" "}
              </div>
            ) : null}
            {/* {regionButtons ? (
              <div className='nav-dropdown' style={{ float: "left" }}>
                <button className='dropbtn'>
                  {" "}
                  <i class='fas fa-globe' style={{ color: "white" }}></i>
                </button>
                <div className='dropdown-content-globe'>
                  <Nav.Link
                    className='redBg'
                    onClick={() => {
                      setRegionToken("romania");
                      window.location.reload();
                    }}
                  >
                    Romania
                  </Nav.Link>
                  <Nav.Link
                    className='redBg'
                    onClick={() => {
                      setRegionToken("india");
                      window.location.reload();
                    }}
                  >
                    {" "}
                    India
                  </Nav.Link>
                  <Nav.Link
                    className='redBg'
                    onClick={() => {
                      setRegionToken("egypt");
                      window.location.reload();
                    }}
                  >
                    {" "}
                    Egypt
                  </Nav.Link>
                </div>
              </div>
            ) : (
              <div className='nav-dropdown' style={{ float: "left" }}>
                <button
                  className='dropbtn'
                  onClick={() => {
                    history("/revoke");
                  }}
                >
                  {" "}
                  <i class='fas fa-user-times' title='Request Revoke'></i>
                </button>{" "}
              </div>
            )}

            <div className='nav-dropdown' style={{ float: "left" }}>
              <button className='dropbtn'>
                {" "}
                <i
                  className='fas fa-user-circle '
                  style={{ color: "white" }}
                ></i>
              </button>

              <div className='dropdown-content'>
                {user ? (
                  user.region != "romania" ? (
                    <Nav.Link className='redBg' onClick={getprivacyNotice}>
                      Privacy Notice
                    </Nav.Link>
                  ) : null
                ) : null}
                <Nav.Link className='redBg' onClick={getTermsAndConditions}>
                  {" "}
                  Terms and conditions
                </Nav.Link>
              </div>
            </div> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default LoggedNavBar;
