import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InternalEventNavbar from "../common/internalEventNavbar/navBar/navBar";
import "./judgementPanel.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { jwtDecode } from "jwt-decode";
import SideMenu from "../common/sideMenu/sideMenu";
import LoggedNavBar from "../common/navBar/loggedNav";
import { getCurrentUser } from "../../services/authService";
import LandingNavBar from "../../components/home/navbar";

function JudgementPanels() {
  const [approved, setAproved] = React.useState(false);
  const history = useNavigate();
  const [email, setemail] = useState();
  const [panelAdmin, setpanelAdmin] = useState([]);
  const [panelJudge, setpanelpanelJudge] = useState([]);
  const changeRoomLink = (id, newstatus) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/setroomdiability`,
        {
          id: id,
          status: newstatus,
        }
      )
      .then((res) => window.location.reload());
  };

  useEffect(() => {
    getAdminPanels();
    const decodedSSOToken = jwtDecode(localStorage.getItem("token"));
    setemail(decodedSSOToken.preferred_username);
  }, [email]);
  const getAdminPanels = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}adminfiltration/panelasadminbymail`,
        {
          email,
          region: getCurrentUser().region,
        }
      )
      .then((res) => {
        console.log(res.data);
        setpanelAdmin(res.data);
      });
  };
  const getprivacyNotice = async () => {
    //ading new link
    window.open(
      "https://vodafone.sharepoint.com/:f:/s/a_VOISInternationalHackathon2024/Epd_uoRJQtlEuE2xhdNmE1MBU8q0h6Dgbfjmdjd_u79CSw?e=mavxTR",
      "_blank"
    );
  };
  const ApproveTerms = (groupID) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/approve`, {
        email,
        groupID,
        region: getCurrentUser().region,
      })
      .then((res) => {
        getAdminPanels();
      });
  };
  // const gettobejudgedPanels = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_SERVER_URL}adminfiltration/panelasjudgebymail`,
  //       {
  //         email,
  //       }
  //     )
  //     .then((res) => {
  //       setpanelpanelJudge(res.data);
  //       console.log(res.data);
  //     });
  // };
  return (
    <div
      style={{
        overflow: "hidden",
        minHeight: "100vh",
      }}
      className='judgementPanel-wrapper container-fluid p-0'
    >
      <div className='  nav-button-padding'>
        <LoggedNavBar to='hall2' />
        {/* <LandingNavBar></LandingNavBar> */}
      </div>
      <div className='container'>
        <p className='judges-header'>JUDGES ROOM</p>
        <div className='judges-redLine'></div>

        <div
          className='row '
          style={{
            marginLeft: "1%",
            justifyContent: "center",
            marginBottom: "3rem",
          }}
        >
          {panelAdmin.length == 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <span className='judgement-main-screen-subheader'>
                Please wait for communication from our team to meet the judges
                in the next filtration phase.
              </span>
            </div>
          ) : (
            panelAdmin.map((r) =>
              r.type == "admin" ? (
                <div className='col-md-5 mr-5 col-sm-12 judges-main-screen-card mt-5 p-2'>
                  <span className='judgement-main-screan-card-header'>
                    _VOIS DevOps Hackathon {r.panelType}
                  </span>
                  <span className='judgement-main-screan-card-subheader'>
                    Welcome to judges area
                  </span>
                  <span className='judgement-main-screan-card-subheader mb-3'>
                    {r.name}
                  </span>

                  {r.panelType != "evaluation-panel" ? (
                    <button
                      className='judgement-main-screan-card-joinEvaluatebtn mb-3'
                      onClick={() => {
                        history("/filtrationpaneladmin", {
                          state: { panelID: r._id },
                        });
                      }}
                    >
                      Manage Panel
                    </button>
                  ) : (
                    <button
                      className='judgement-main-screan-card-joinEvaluatebtn mb-3 '
                      onClick={() => {
                        history("/filtrationpaneladmin", {
                          state: { panelID: r._id },
                        });
                      }}
                    >
                      Manage Panel
                    </button>
                  )}
                </div>
              ) : r.type == "judge" ? (
                <div className='col-md-5 mr-5 col-sm-12 judges-main-screen-card mt-5 p-2'>
                  <span className='judgement-main-screan-card-header'>
                    _VOIS DevOps Hackathon
                  </span>
                  <span className='judgement-main-screan-card-subheader'>
                    Welcome to judges area
                  </span>
                  <span className='judgement-main-screan-card-subheader mb-3'>
                    {r.filtrationPanel.name}
                  </span>
                  {r.filtrationPanel.panelType != "evaluation-panel" ? (
                    <>
                      {/* <span className='judgement-main-screan-card-subheader'>
                        {r.name}
                      </span> */}
                      {r.approvedTerms ? (
                        <button
                          className='judgement-main-screan-card-joinEvaluatebtn mb-3'
                          onClick={() => {
                            history("/filtrationpaneljudge", {
                              state: {
                                panelID: r.filtrationPanel._id,
                                groupID: r.filtrationGroup._id,
                                email: email,
                              },
                            });
                          }}
                        >
                          Evaluate
                        </button>
                      ) : (
                        <div
                          className='form-check-box mt-2'
                          style={{ padding: "0 2rem" }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={approved}
                            id='flexCheckDefault'
                            onChange={(e) =>
                              ApproveTerms(r.filtrationGroup._id)
                            }
                          />

                          <label
                            style={{ color: "white" }}
                            className='form-check-label'
                          >
                            I acknowledge that I have reviewed the T&Cs
                            available on{" "}
                            <a
                              href=''
                              onClick={getprivacyNotice}
                              style={{
                                color: "var(--text-secondary)",
                                display: "inline-block",
                              }}
                            >
                              this link{" "}
                            </a>{" "}
                            for the _VOIS entity that I belong to .
                          </label>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* <span className='judgement-main-screan-card-subheader'>
                        {r.filtrationPanel.name}
                      </span> */}

                      {r.approvedTerms ? (
                        <>
                          {" "}
                          <button
                            className='judgement-main-screan-card-joinMeetingbtn mb-3'
                            onClick={() => {
                              window.open(r.filtrationGroup.link, "_blank");
                            }}
                          >
                            Join the meeting
                          </button>
                          <button
                            className='judgement-main-screan-card-joinEvaluatebtn mb-3'
                            onClick={() => {
                              history("/filtrationpaneljudge", {
                                state: {
                                  panelID: r.filtrationPanel._id,
                                  groupID: r.filtrationGroup._id,
                                  email: email,
                                },
                              });
                            }}
                          >
                            Evaluate
                          </button>
                        </>
                      ) : (
                        <div
                          className='form-check-box mt-2'
                          style={{ padding: "0 2rem" }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={approved}
                            id='flexCheckDefault'
                            onChange={(e) =>
                              ApproveTerms(r.filtrationGroup._id)
                            }
                          />

                          <label
                            className='form-check-label'
                            style={{ color: "white" }}
                          >
                            I acknowledge that I have reviewed the T&Cs
                            available on{" "}
                            <a
                              href=''
                              onClick={getprivacyNotice}
                              style={{
                                color: "var(--text-secondary)",
                                display: "inline-block",
                              }}
                            >
                              this link{" "}
                            </a>{" "}
                            for the _VOIS entity that I belong to .
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : r.type == "user" ? (
                <div className='col-md-5 mr-5 col-sm-12 judges-main-screen-card mt-5 p-2'>
                  <span className='judgement-main-screan-card-header'>
                    _VOIS DevOps Hackathon
                  </span>
                  <span className='judgement-main-screan-card-subheader'>
                    Welcome to judges area
                  </span>

                  <>
                    <span className='judgement-main-screan-card-subheader'>
                      {r.filtrationPanel.name}
                    </span>

                    <button
                      className={
                        r.filtrationGroup.IsDisabled
                          ? "judgement-main-screan-card-joinMeetingbtn-disabled mb-3"
                          : "judgement-main-screan-card-joinMeetingbtn mb-3"
                      }
                      disabled={r.filtrationGroup.IsDisabled}
                      onClick={() => {
                        window.open(r.filtrationGroup.link, "_blank");
                      }}
                    >
                      Join the meeting
                    </button>
                  </>
                </div>
              ) : null
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default JudgementPanels;
