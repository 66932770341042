import React, { useState, useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import styles from "./CookieAcoordion.module.css";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CookiesDetails from "./CookiesDetails";

function CookieAccordion({
  title,
  description,
  index,
  usetoggle,
  onTog,
  details,
  showDetails,
  ifany,
}) {
  const [expand, setExpansion] = useState(-1);
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const myObjects = {
    Strictly_Necessary_Cookies: [
      {
        name: "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx",
        host: "https://www.googletagmanager.com/gtag/js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description: "Google Analytics",
        Duration: "0 Days",
      },
    ],
    Targeting_Cookies: [
      {
        name: "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx",
        host: "https://www.googletagmanager.com/gtag/js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description: "Google Analytics",
        Duration: "0 Days",
      },
      {
        name: "_ga_xxxxxxxxxx",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description:
          "This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assigns a randomly generated number to identify unique visitors",
        Duration: "1 Years",
      },
      {
        name: "_fbp",
        host: "https://connect.facebook.net/en_US/fbevents.js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description:
          "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers",
        Duration: "2 Months",
      },
    ],
    Performance_Cookies: [
      {
        name: "_ga",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Performance Cookies",
        type: "3rd Party",
        description:
          "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.  By default it is set to expire after 2 years, although this is customisable by website owners.",
        Duration: "2 Years",
      },
      {
        name: "_gid",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Performance Cookies",
        type: "3rd Party",
        description:
          "This cookie name is associated with Google Universal Analytics. This appears to be a new cookie and as of Spring 2017 no information is available from Google.  It appears to store and update a unique value for each page visited.",
        Duration: "1 Days",
      },
    ],
    // other properties...
  };
  const [DetailsTarget, setDetailsTarget] = useState("");
  const handleExpansion = (expandedItem) => {
    if (expand === expandedItem) {
      setExpansion(-1);
    } else {
      setExpansion(expandedItem);
    }
  };
  return (
    <Accordion>
      <Accordion.Item
        eventKey={index}
        className=' mb-4'
        onClick={() => handleExpansion(index)}
      >
        <Accordion.Header className='row'>
          <span
            className={
              styles["pull-left"] + " ml-3 " + styles["question-content"]
            }
          >
            {title}
          </span>
          <span>
            {showDetails == false ? (
              usetoggle ? (
                <span className=' pr-3'>
                  <label class='switch2'>
                    <input
                      type='checkbox'
                      checked={toggle}
                      onChange={(checked) => {
                        setToggle(!toggle);
                        onTog(!toggle);
                      }}
                    />
                    <span class='slider2 round'></span>
                  </label>
                  <label style={{ color: "white", marginLeft: "0.5rem" }}>
                    {toggle == true ? "on" : "off"}
                  </label>
                </span>
              ) : (
                <span className={styles["ripon"] + " pr-3"}>Always Active</span>
              )
            ) : null}
            {expand === index ? (
              <FontAwesomeIcon
                icon={faAngleUp}
                className={styles["pull-right"] + " mr-4"}
              />
            ) : (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={styles["pull-right"] + " mr-4"}
              />
            )}
          </span>
        </Accordion.Header>
        <Accordion.Body className={styles["accordion-body"]}>
          <p className=' ml-4'>{description}</p>
          {showDetails ? (
            <div className={styles["accordion2-div"]}>
              {title != "Strictly Necessary Cookies"
                ? myObjects[title.replace(" ", "_")].map((e, i) => {
                    console.log("r", e);
                    return (
                      <CookiesDetails
                        title={e.name}
                        description={e.description}
                        Duration={e.Duration}
                        host={e.host}
                        category={e.category}
                        type={e.type}
                        index={i}
                      ></CookiesDetails>
                    );
                  })
                : null}
            </div>
          ) : index != 0 ? (
            <a
              onClick={() => {
                details(title);
              }}
              className=' ml-4 mb-3 '
              style={{ color: "#e60000", fontWeight: "bold" }}
            >
              View Vendor Details
            </a>
          ) : null}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CookieAccordion;
