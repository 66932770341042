import React from "react";
import { useState, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import "./mainUserDetails.css";
import VerificationCode from "../../../common/verificationCode";
import Stepper from "../stepper/stepper";

import {
  getCodebyEmail,
  getCodebyMobile,
  verifyMobileCode,
  verifyEmailCode,
  verifyParticipant,
  editVerifyMobile,
  editVerifyEmail,
} from "../../../../apis/register";

import Toaster from "../../../common/Toast";
import moment from "moment";
import { values } from "lodash";

const MainUserDetails = ({ handlenext }) => {
  const [data, setData] = useContext(MainUserContext);
  const [admin, setAdmin] = useState(data.isAdmin);
  const [emailInput, setEmailInput] = useState(false);
  const [phoneInput, setPhoneInput] = useState(false);
  const [mobileCode, setMobileCode] = useState();
  const [emailCode, setEmailCode] = useState();
  const [goNext, setGoNext] = useState(false);
  const [sendemail, setSendEmail] = useState(true);
  const [sendsms, setSendSMS] = useState(true);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [msg, setMsg] = useState("");

  const [passwordStatus, setPasswordSatus] = useState(true);
  const [passwordConfirmStatus, setPasswordConfirmSatus] = useState(true);
  const displayInputOfEmail = (email) => {
    setEmailInput(true);
    getCodebyEmail(email);
  };

  const handleMobileVerified = (mobile, code) => {
    verifyMobileCode(mobile, code)
      .then((res) => {
        if (res.data == "verified") {
          setMobileVerified(true);
          setMsg("");
        }
      })
      .catch(() => {
        setMsg("the code is wrong, please try again");
        setMobileVerified(false);
      });
  };

  const handleEmailVerified = (email, code) => {
    verifyEmailCode(email, code)
      .then((res) => {
        if (res.data == "verified") {
          setEmailVerified(true);
          setMsg("");
        }
      })
      .catch(() => {
        setMsg("the code is wrong, please try again");
        setEmailVerified(false);
      });

    verifyParticipant(email)
      .then((res2) => {
        if (res2) {
          setAdmin(false);
          setData({
            ...data,
            isAdmin: true,
          });
        }
      })
      .catch(() => {
        setAdmin(true);
        setData({
          ...data,
          isAdmin: true,
        });
      });
  };

  const displayInputOfPhone = (mobile) => {
    setPhoneInput(true);
    getCodebyMobile(mobile, data.region);
  };

  const disabledInputOfEmail = (email) => {
    editVerifyEmail(email).then((res) => setEmailInput(false));
    setEmailVerified(false);
  };

  const togglePasswordStatus = () => {
    setPasswordSatus((oldStatus) => !oldStatus);
  };
  const togglePasswordConfirmStatus = () => {
    setPasswordConfirmSatus((oldStatus) => !oldStatus);
  };
  const disabledInputOfPhone = (mobile) => {
    editVerifyMobile(mobile).then((res) => setPhoneInput(false));
    setMobileVerified(false);
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .max(50, "Maximum 50 characters")
      .required("Name is required"),
    email: Yup.string()
      .min(7, "Minimum 7 characters")
      .max(150, "Maximum 150 characters")
      .required("Email is required")
      .email("Invalid email Format")
      .matches(
        "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
        " please enter a valid email "
      )
      .matches(
        "^[A-Za-z0-9._%+-]+@(?!Vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
        " please enter a valid email "
      ),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~@#$^*()_+=[\]{}|\\,.?: -\!\%\&]).{8,}$/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    passwordConfirm: Yup.string()
      .required("Password Confirmation is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password Confirmation have to be identical with the password"
      ),

    phoneNumber: Yup.string()
      .min(9, "Minimum 9 characters")
      .max(9, "Maximum 9 characters")
      .required("Phone Number is required")
      .matches("(7)[0-9]{8}", " please enter a valid phone number"),
    birthDate: Yup.string()

      .required(" Birth Date is required")

      .test("validate BirthDate", "BirthDate is Invalid", function (value) {
        return checkDate(value);
      })
      .test(
        "validate BirthDate Eligability",
        "Sorry, your registration can't be completed you're less than 18",
        function (value) {
          return checkeligability(value);
        }
      ),
  });

  const checkDate = (birthDate) => {
    if (new Date(birthDate).getTime() >= new Date().getTime()) {
      return false;
    } else return true;
  };
  const checkeligability = (birthDate) => {
    const birth = new Date(birthDate);
    const date_diff = new Date(birthDate).getTime() - new Date().getTime();
    const years = Math.abs(new Date(date_diff).getFullYear() - 1970);
    //
    if (years < 18) {
      return false;
    } else if (years == 18) {
      if (birth.getMonth() < new Date().getMonth()) {
        return true;
      }
      if (birth.getMonth() == new Date().getMonth()) {
        if (birth.getDate() < new Date().getDate()) {
          return true;
        } else if (birth.getDate() == new Date().getDate()) {
          return false;
        }
      }
    } else if (years > 18) {
      return true;
    }
  };
  return (
    <Formik
      initialValues={{
        name: data.name,
        phoneNumber: data.phoneNumber,
        email: data.email,
        password: data.password,
        passwordConfirm: data.passwordConfirm,
        uploadCv: data.uploadCv,
        CV: data.CV,
        isAdmin: data.isAdmin,
        region: data.region,
        emailVerified: data.emailVerified,
        mobileVerified: data.mobileVerified,
        birthDate: data.birthDate,
      }}
      validationSchema={SignupSchema}
      onSubmit={(values) => {
        values.isAdmin = admin;
        setData(values);
      }}
    >
      {({
        handleSubmit,
        getFieldProps,
        touched,
        values,
        errors,
        isValid,
        setFieldValue,
        handleChange,
        handleBlur,
      }) => (
        <div className='row user-details-container '>
          {msg ? <Toaster variant={"danger"} msg={msg} /> : ""}
          <div className='col-md-3 mr-5 p-0'>
            <Stepper
              next={handleSubmit}
              disabled={!isValid || !emailVerified || !mobileVerified}
              NextStep={goNext}
            />
          </div>
          <div className='col-md-8 scrolled user-details-alignment '>
            <form onSubmit={handleSubmit}>
              <div className='title'>Registration Details</div>
              <div className='main-user-form-body-container'>
                <div className=' row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name' className='label-format'>
                      Full Name<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <input
                      type='text'
                      className={
                        touched.name && errors.name
                          ? "text-box-error"
                          : "text-box"
                      }
                      id='name'
                      name='name'
                      value={values.name}
                      {...getFieldProps("name")}
                      onChange={handleChange}
                    />
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      style={
                        touched.name && errors.name
                          ? {
                              display: "block",
                              position: "absolute",
                              top: "33%",
                              right: "10%",
                            }
                          : { display: "none" }
                      }
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                        fill='#B3261E'
                      />
                    </svg>
                    <div className='hint'>
                      <li>Must be more than 5 characters.</li>
                    </div>
                    {touched.name && errors.name ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.name}
                      </div>
                    ) : null}
                  </div>

                  <div className='form-group col-md-6 '>
                    <label htmlFor='id' className='label-format'>
                      Birth Date<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.birthDate}
                      type='date'
                      className={
                        touched.birthDate && errors.birthDate
                          ? "text-box-error"
                          : "text-box"
                      }
                      id='birthDate'
                      name='birthDate'
                      {...getFieldProps("birthDate")}
                    />
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      style={
                        touched.birthDate && errors.birthDate
                          ? {
                              display: "block",
                              position: "absolute",
                              top: "33%",
                              right: "15%",
                            }
                          : { display: "none" }
                      }
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                        fill='#B3261E'
                      />
                    </svg>
                    {touched.birthDate && errors.birthDate ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.birthDate}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className=' row'>
                  <div className='form-group col-md-6 '>
                    <label htmlFor='email' className='email label-format'>
                      Email<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <div className='row ml-1'>
                      <div className='col-md-9 pl-0 col-sm-12'>
                        <input
                          type='email'
                          className={
                            touched.email &&
                            (errors.email || sendemail == false)
                              ? "text-box-error"
                              : "text-box"
                          }
                          id='email'
                          name='email'
                          value={values.email}
                          {...getFieldProps("email")}
                        />
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          style={
                            touched.email &&
                            (errors.email || sendemail == false)
                              ? {
                                  display: "block",
                                  position: "absolute",
                                  top: "24%",
                                  right: "10%",
                                }
                              : { display: "none" }
                          }
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                            fill='#B3261E'
                          />
                        </svg>
                      </div>
                      <div className='col-md-3  col-sm-12 verify-div'>
                        <button
                          type='button'
                          className='verify-btn'
                          onClick={() => {
                            setEmailInput(true);
                            getCodebyEmail(values.email)
                              .then((r) => setSendEmail(true))
                              .catch((ex) => {
                                setSendEmail(false);
                              });
                          }}
                        >
                          VERIFY
                        </button>
                      </div>
                    </div>
                    <div className='hint'>
                      <li>A verification code will be sent to your email.</li>
                    </div>
                    {touched.email && sendemail == false ? (
                      <div className='alert alert-danger reg-alert'>
                        Email Format is wrong
                      </div>
                    ) : null}
                    {touched.email && errors.email ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className='form-group  verify-form'>
                    <div className='col-md-12'>
                      {emailInput === true ? (
                        <div className=''>
                          <label
                            htmlFor='verifyEmail'
                            className='verification label-format'
                          >
                            Verification Code{" "}
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <div className='row ml-1'>
                            <VerificationCode
                              length={4}
                              onComplete={(code) => {
                                setEmailCode(code);
                                handleEmailVerified(values.email, code);
                              }}
                            />
                            {emailVerified ? (
                              <img src='/assets/check.svg'></img>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className=' row'>
                  <div className='form-group  col-md-6 '>
                    <label htmlFor='name' className='password label-format'>
                      Password<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <input
                      style={{ paddingLeft: "1rem" }}
                      type={passwordStatus === true ? "password" : "text"}
                      className={
                        touched.password && errors.password
                          ? "text-box-error"
                          : "text-box"
                      }
                      id='password'
                      name='password'
                      {...getFieldProps("password")}
                    />
                    <span
                      onClick={() => togglePasswordStatus()}
                      toggle='#password-field'
                      className={
                        passwordStatus
                          ? "fa fa-fw fa-eye field-icon toggle-password"
                          : "fa fa-fw fa-eye-slash field-icon toggle-password"
                      }
                    ></span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      style={
                        touched.password && errors.password
                          ? {
                              display: "block",
                              position: "relative",
                              bottom: "20%",
                              left: "82%",
                            }
                          : { display: "none" }
                      }
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                        fill='#B3261E'
                      />
                    </svg>
                    {touched.password && errors.password ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name' className='password label-format'>
                      Password Confirmation<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>
                    <input
                      style={{ paddingLeft: "1rem" }}
                      type={
                        passwordConfirmStatus === true ? "password" : "text"
                      }
                      className={
                        touched.passwordConfirm && errors.passwordConfirm
                          ? "text-box-error"
                          : "text-box"
                      }
                      id='passwordconfirm'
                      name='passwordconfirm'
                      {...getFieldProps("passwordConfirm")}
                    />
                    <span
                      onClick={() => togglePasswordConfirmStatus()}
                      toggle='#password-field'
                      className={
                        passwordConfirmStatus
                          ? "fa fa-fw fa-eye field-icon toggle-password"
                          : "fa fa-fw fa-eye-slash field-icon toggle-password"
                      }
                    ></span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      style={
                        touched.passwordConfirm && errors.passwordConfirm
                          ? {
                              display: "block",
                              position: "relative",
                              bottom: "20%",
                              left: "82%",
                            }
                          : { display: "none" }
                      }
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                        fill='#B3261E'
                      />
                    </svg>
                    {touched.passwordConfirm && errors.passwordConfirm ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.passwordConfirm}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className=' row '>
                  <div className='form-group  col-md-6 '>
                    <label
                      htmlFor='phoneNumber'
                      className='mobile label-format'
                    >
                      Mobile Number<span className='required'> *</span>
                      <span className='required ml-1'> (required)</span>
                    </label>

                    <div>
                      <div className='row'>
                        <div className='col-md-3 col-sm-3'>
                          <input
                            className='text-box'
                            placeholder='+40'
                            disabled
                          />
                        </div>
                        <div className='col-md-6  col-sm-9'>
                          <input
                            placeholder='e.g : 701231234'
                            type='tel'
                            className={
                              touched.phoneNumber &&
                              (errors.phoneNumber || sendsms == false)
                                ? "text-box-error"
                                : "text-box"
                            }
                            id='phoneNumber'
                            value={values.phoneNumber}
                            name='phoneNumber'
                            {...getFieldProps("phoneNumber")}
                          />
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            style={
                              touched.phoneNumber &&
                              (errors.phoneNumber || sendsms == false)
                                ? {
                                    display: "block",
                                    position: "absolute",
                                    top: "25%",
                                    right: "10%",
                                  }
                                : { display: "none" }
                            }
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                              fill='#B3261E'
                            />
                          </svg>
                        </div>
                        <div className='col-md-2 col-sm-2 pl-3  verify-mobile-div'>
                          <button
                            type='button'
                            className={
                              (touched.phoneNumber &&
                                (sendsms == false || errors.phoneNumber)) ||
                              !touched.phoneNumber
                                ? "verify-btn-disabled"
                                : "verify-btn"
                            }
                            disabled={
                              (touched.phoneNumber &&
                                (sendsms == false || errors.phoneNumber)) ||
                              !touched.phoneNumber
                                ? true
                                : false
                            }
                            onClick={() => {
                              setPhoneInput(true);
                              getCodebyMobile(values.phoneNumber, "romania")
                                .then((r) => {
                                  setSendSMS(true);
                                })
                                .catch((ex) => {
                                  setSendSMS(false);
                                });
                            }}
                          >
                            VERIFY
                          </button>
                        </div>
                      </div>
                      <div className='hint'>
                        <li>
                          A verification code will be sent to your mobile.
                        </li>
                        <li>
                          Please enter a valid mobile number starting with 7 .
                        </li>
                      </div>
                      {touched.phoneNumber && sendsms == false ? (
                        <div className='alert alert-danger reg-alert'>
                          Mobile Number Format is wrong
                        </div>
                      ) : null}

                      {touched.phoneNumber && errors.phoneNumber ? (
                        <div className='alert alert-danger reg-alert'>
                          {errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='form-group  verify-form'>
                    <div className='col-md-12'>
                      {phoneInput === true ? (
                        <div className=''>
                          <label
                            htmlFor='verifyEmail'
                            className='verification label-format'
                          >
                            Verification Code{" "}
                            <span className='required'> *</span>
                            <span className='required ml-1'> (required)</span>
                          </label>
                          <div className='row ml-1'>
                            <VerificationCode
                              length={4}
                              onComplete={(code) => {
                                setMobileVerified(code);
                                handleMobileVerified(values.phoneNumber, code);
                              }}
                            />
                            {mobileVerified ? (
                              <img src='/assets/check.svg'></img>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='btn-next-container' style={{ float: "right" }}>
                <button
                  // type='submit'
                  onClick={() => {
                    setGoNext(true);
                  }}
                  type='button'
                  disabled={!isValid || !emailVerified || !mobileVerified}
                  className={
                    !isValid || !emailVerified || !mobileVerified
                      ? "next-btn-disabled"
                      : "next-btn-egypt"
                  }
                >
                  NEXT
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default MainUserDetails;
