import React, { useState } from 'react';

const StepsContext = React.createContext([() => {}]);

const StepsProvider = ({ children }) => {
  const [Steps, setSteps] = useState(1);
  // console.log("stepss", Steps);

  const changeSteps = (step) => {
    setSteps(step);
  };
  return (
    <StepsContext.Provider value={[Steps, changeSteps]}>
      {children}
    </StepsContext.Provider>
  );
};

export { StepsProvider, StepsContext };
