import React from "react";
import { useNavigate } from "react-router-dom";
import LoggedNavBar from "../common/navBar/loggedNav";
import SideMenu from "../common/sideMenu/sideMenu";
import "./photoboothListing.css";
function PhotoboothListing() {
  const history = useNavigate();
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='breakroomLising-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className='col-md-12 col-xs-12'
            >
              <img src='/assets/Selfie-pana.svg'></img>
              <p className='photobooth-title mt-5'>PhotoBooth</p>
              <span className='photobooth-desc mt-2'>
                Take A Photo With Our Frame To
              </span>
              <span className='photobooth-desc'>
                Get The Spirit Of Connection
              </span>

              <div
                style={{ display: "flex", flexDirection: "column" }}
                className='mt-2'
              >
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    font: "normal normal normal 24px/28px Vodafone Rg",
                    borderRadius: "10px",
                    padding: "0.5rem 3.5rem",
                    border: "2px solid #FFFFFF",
                    margin: "1rem 0",
                  }}
                  onClick={() => {
                    history("/photobooth");
                  }}
                >
                  Take Photo
                </button>
                <button
                  className='mosaicwall-btn'
                  onClick={() => {
                    history("/mosaicwall");
                  }}
                >
                  MOSAIC WALL
                </button>
              </div>
              {/* <button
                className='ml-2 mt-2'
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  font: "normal normal normal 24px/28px Vodafone Rg",
                  borderRadius: "10px",
                  padding: "0.5rem 3.5rem",
                  border: "2px solid #FFFFFF",
                }}
                onClick={() => {
                  history("/mosaicwall2");
                }}
              >
                Mosaic Wall
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoboothListing;
