import React from "react";
import styles from "./FifthSection.module.css";
function FifthSection() {
  return (
    <div className={styles["fifth-section-div"]}>
      <div className={styles["fifth-page-main-content"]}>
        <div className={styles["frame-top-design"]}>
          <div className={styles["lefttopframe"]}></div>
          <div className={styles["righttopframe"]}></div>
        </div>
        <div className={styles["bg"]}>
          <span className={styles["fifth-section-main-title"] + " col-md-12"}>
            Join from home
          </span>

          <div className={styles["fifth-page-main-content-body"]}>
            {" "}
            <div className={styles["main-div"]}>
              <span className={styles["secondsection-description"]}>
                The hackathon is 100% online; you can join from the comfort of
                your own home.
                <br /> Make sure you have good internet connectivity to support
                you throughout the entire experience and to be able to join all
                sessions!
              </span>
            </div>
            <div className={styles["video-div"]}>
              <img
                src='/assets/robot.png'
                className={styles["sectionfive-video"]}
              ></img>
            </div>
          </div>
        </div>
        <div className={styles["frame-bottom-design"]}>
          <div className={styles["leftbottomframe"]}></div>
          <div className={styles["rightbottomframe"]}></div>
        </div>
      </div>
    </div>
  );
}

export default FifthSection;
