import React from "react";
import LoggedNavBar from "../../common/navBar/loggedNav";
import SideMenu from "../../common/sideMenu/sideMenu";
import axios from "axios";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import domtoimage from "dom-to-image";
import "./photobooth.css";

import auth from "../../../services/authService";
function Photobooth() {
  const history = useNavigate();
  const [loadBytes, setLoadBytes] = React.useState(true);
  let videoStream;
  let file;
  const location = useLocation();
  useEffect(() => {
    let width = 245,
      height = 245,
      filter = "none",
      streaming = false;

    const video = document.getElementById("video");
    const photoButton = document.getElementById("photo-button");

    const photoFilter = document.getElementById("photo-filter");
    const clearButton = document.getElementById("clear-button");
    const canvas = document.getElementById("canvas");
    const photos = document.getElementById("photos");

    // getting the stream from the webcam and import it into the video element
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then((stream) => {
        // link to the video source
        videoStream = stream;
        video.srcObject = stream;

        video.play();
      })
      .catch((err) => {});

    // Play video when ready
    video.addEventListener(
      "canplay",
      function (e) {
        if (!streaming) {
          // Set video / canvas height
          //   height = video.videoHeight / (video.videoWidth / width);

          video.setAttribute("width", width);
          video.setAttribute("height", height);
          canvas.setAttribute("width", width);
          canvas.setAttribute("height", height);

          streaming = true;
        }
      },
      false
    );

    // Photo button event
    photoButton.addEventListener(
      "click",
      function (e) {
        takePicture();

        e.preventDefault();
      },
      false
    );

    // Filter event
    photoFilter.addEventListener("change", function (e) {
      // Set filter to chosen option
      filter = e.target.value;
      // Set filter to video
      video.style.filter = filter;

      e.preventDefault();
    });

    // Clear event
    clearButton.addEventListener("click", function (e) {
      // Clear photos
      photos.innerHTML = "";
      // Change filter back to none
      filter = "none";
      // Set video filter
      video.style.filter = filter;
      // Reset select list
      photoFilter.selectedIndex = 0;
    });

    // Take picture from canvas
    function takePicture() {
      setLoadBytes(false);
      // Create canvas
      const context = canvas.getContext("2d");
      if (width && height) {
        // set canvas props
        canvas.width = width;
        canvas.height = height;

        // Draw an image of the video on the canvas
        context.drawImage(video, 0, 0, width, height);

        // Create image from the canvas
        const imgUrl = canvas.toDataURL("image/png");
        const frameImage = document.createElement("Div");
        frameImage.style.backgroundImage = `url(/assets/Photobooth_frame_noLogo-svg.png)`;

        domtoimage
          .toBlob(frameImage)
          .then(function (blob) {
            btn.href = URL.createObjectURL(blob);
            btn.download = `MIHHackathon${Date.now()}.png`;
            setLoadBytes(true);
          })
          .catch((err) => console.log(err));
        frameImage.style.backgroundRepeat = "no-repeat";
        frameImage.style.width = "15rem";
        frameImage.style.height = "18rem";
        frameImage.style.backgroundSize = "contain";
        axios.post(`${process.env.REACT_APP_SERVER_URL}mosaicWall`, {
          imageBytes: imgUrl,
          userName: auth.getCurrentUser().preferred_username.split(".")[0],
        });
        // create frame container DOM
        const frameContainer = document.createElement("DIV");
        frameContainer.setAttribute(
          "class",
          "frame-Container align-items-center col-xl-4 col-lg-6 col-md-12 col-s-12 mt-5 mb-5"
        );
        frameContainer.style.display = "flex";
        frameContainer.style.flexDirection = "column";
        frameContainer.style.justifyContent = "center";

        // create h2 element DOM
        const photoBoothName = document.createElement("h3");
        photoBoothName.style.color = "#e60000";
        photoBoothName.style.position = "relative";
        photoBoothName.style.font = "normal normal bold 24px/24px Vodafone Rg";
        photoBoothName.style.left = "14%";
        photoBoothName.style.top = "4%";
        photoBoothName.style.fontWeight = "bolder";
        photoBoothName.style.textTransform = "uppercase";
        photoBoothName.innerHTML =
          auth.getCurrentUser().preferred_username.split(".")[0] +
          " " +
          auth.getCurrentUser().preferred_username.split(".")[1].split("@")[0];

        // Create img element DOM

        const img = document.createElement("img");

        // Set img src
        img.setAttribute("src", imgUrl);
        img.style.width = "159px";
        img.style.height = "159px";
        img.style.margin = "2.2rem 2.4rem 1.5rem";
        img.style.borderRadius = "50%";
        // img.style.border = "white 5px solid";
        // Set image filter
        img.style.filter = filter;

        frameImage.appendChild(img);
        frameImage.appendChild(photoBoothName);

        const btnDiv = document.createElement("DIV");
        btnDiv.setAttribute("class", "mt-2 ");
        const btn = document.createElement("a");
        btn.setAttribute("class", "btn  btn-danger");

        btn.innerHTML = "Download";

        photos.appendChild(frameContainer);
        // Add image to photos
        frameContainer.appendChild(frameImage);

        frameContainer.appendChild(btnDiv);

        btnDiv.appendChild(btn);
      }
    }
    return function () {
      if (videoStream != undefined)
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
    };
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container'
            style={{ margin: "0", padding: "0" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
              className='col-md-12 col-xs-12  '
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div class='btn-group dropleft'>
                  <select
                    name=''
                    className='magic-btn asc-photobooth-button mr-1'
                    id='photo-filter'
                  >
                    <option value='none'>Normal</option>
                    <option value='grayscale(100%)'>Grayscale</option>
                    <option value='sepia(100%)'>Sepia</option>
                    <option value='invert(100%)'>Invert</option>
                    <option value='hue-rotate(90deg)'>Hue</option>
                    <option value='blur(10px)'>Blur</option>
                    <option value='contrast(200%)'>Contrast</option>
                  </select>
                </div>

                <div className='asc-mainframe asc-photobooth'>
                  <img
                    src='/assets/glb/newmodel/photobooth_noFrame_nologo2.svg'
                    className='asc-frame-bg'
                  ></img>
                  <img
                    src='/assets/photoboothFrame2.png'
                    className='asc-frame'
                  ></img>
                  <video
                    id='video'
                    width='225'
                    height='225'
                    className='asc-video'
                  >
                    Stream not available..
                  </video>
                  <h1 className='asc-framename'>
                    {auth.getCurrentUser().preferred_username.split(".")[0] +
                      " " +
                      auth
                        .getCurrentUser()
                        .preferred_username.split(".")[1]
                        .split("@")[0]}
                  </h1>
                  {/* <div
                    className='logo-image'
                    style={{
                      position: "absolute",
                      marginTop: "109%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src='/assets/vois-black.svg'
                      className='mr-3'
                      alt=''
                      width='80'
                      height='30'
                    ></img>
                    <img
                      src='/assets/black-Logo.svg'
                      alt=''
                      width='150'
                      height='30'
                    ></img>
                  </div> */}
                </div>
                <i className='clear-btn fa fa-trash' id='clear-button'></i>
              </div>
              <div className='controls-container row  d-flex justify-content-center  asc-photobooth-button-container'>
                <button
                  id='photo-button'
                  disabled={loadBytes == true ? false : true}
                  className='  asc-photobooth-button'
                >
                  Take photo
                </button>

                {/* <select
                  name=''
                  className='  asc-photobooth-button mr-1'
                  id='photo-filter'
                >
                  <option value='none'>Normal</option>
                  <option value='grayscale(100%)'>Grayscale</option>
                  <option value='sepia(100%)'>Sepia</option>
                  <option value='invert(100%)'>Invert</option>
                  <option value='hue-rotate(90deg)'>Hue</option>
                  <option value='blur(10px)'>Blur</option>
                  <option value='contrast(200%)'>Contrast</option>
                </select> */}

                {/* <button id='clear-button' className=' asc-photobooth-button'>
                  Clear
                </button>
                 */}
                <button
                  className=' asc-photobooth-button mt-2'
                  onClick={() => {
                    history("/mosaicwall");
                  }}
                >
                  Show mosaic wall
                </button>
                {/* <button
                  className=' asc-photobooth-button mt-2'
                  onClick={() => {
                    history("/mosaicwall2");
                  }}
                >
                  Show mosaic wall
                </button> */}
              </div>
            </div>

            <canvas id='canvas'></canvas>
            <div className='col-md-12 col-xs-6'>
              <div className='video-bottom-container'>
                <div id='photos' className='row'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Photobooth;
