import React, { useState, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./stepper.css";
import { StepsContext } from "../../../../contexts/StepsContext";
import { useEffect } from "react";

const Stepper = ({
  next,
  disabled,
  back,
  setRecabtcha,
  NextStep,
  BackStep,
}) => {
  const [currentStep, setCurrentStep] = useContext(StepsContext);
  const [verifyStep, setVerifyStep] = useState("recaptcha");
  const [termsCondition, setTermsCondition] = useState(false);
  const [steps, setSteps] = useState([
    {
      id: 1,
      page: "Registration Details",
    },
    {
      id: 2,
      page: "Consent",
    },
    {
      id: 3,
      page: "Submit Your Pitch",
    },
  ]);
  useEffect(() => {
    if (NextStep) handleNextBtn();
    if (BackStep) handleBackBtn();
  }, [NextStep, BackStep]);
  const handleNextBtn = () => {
    if (currentStep < steps.length) {
      next();
      setCurrentStep((oldStep) => oldStep + 1);
    } else next();
  };

  const handleBackBtn = () => {
    if (currentStep !== 1) {
      setCurrentStep((oldStep) => oldStep - 1);
    }
  };

  const getStepClass = (stepId) => {
    let stepClass =
      currentStep === stepId
        ? "nav-link active"
        : currentStep > stepId
        ? "nav-link completed"
        : "nav-link";
    return stepClass;
  };

  const onChangeTerms = (value) => {
    setTermsCondition(value);
    setVerifyStep("recaptcha");
  };

  const onChangeRecaptcha = (value) => {
    //
    setRecabtcha(value);
    setVerifyStep("submit");
  };

  return (
    <div className='reg-step-container '>
      <nav className={`nav step-pills flex-column  stepper-container`}>
        {steps.map((step) => {
          return (
            <div key={step.id} className='step'>
              <span
                className={
                  step.id === steps.length
                    ? getStepClass(step.id) + " last"
                    : getStepClass(step.id)
                }
              >
                <span className='step-id'>{step.id}</span>
              </span>
              <span className='step-text'>{step.page}</span>
            </div>
          );
        })}
      </nav>

      {/* {currentStep === 4 && (
        <>
          {verifyStep === "terms" && (
            <div className='row mt-4 mb-2 input-group-prepend'>
              <div className='col'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='defaultCheck1'
                    onChange={(e) => onChangeTerms(e.target.checked)}
                  />
                  <label className='form-check-label' htmlFor='defaultCheck1'>
                    I have read and agree to the{" "}
                    <span className='terms-text'>Terms and privacy policy</span>
                    .
                  </label>
                </div>
              </div>
            </div>
          )}
          {verifyStep === "recaptcha" && (
            <div
              className='reCaptcha-container'
              style={{ height: "55px", width: "144px" }}
            >
              <ReCAPTCHA
                sitekey='6LdKQdAdAAAAAN9whOooFSUcAY1z01jMOBDJjMkU'
                theme='dark'
                onChange={(value) => onChangeRecaptcha(value)}
              />
            </div>
          )}
        </>
      )}
      <div className='step-container-btns'>
        <div
          className='back-btn-container'
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          style={{ visibility: currentStep !== 1 ? "visible" : "hidden" }}
        >
          <img src='/assets/back-arrow.svg'></img>
          <button
            type='button'
            className='back-btn'
            onClick={() => handleBackBtn()}
          >
            BACK
          </button>
        </div>
        {currentStep === 4 ? (
          verifyStep === "submit" ? (
            <button
              type='button'
              disabled={disabled === true}
              className={disabled ? "next-btn-disabled" : "next-btn"}
              onClick={(e) => {
                e.stopPropagation();
                handleNextBtn();
              }}
            >
              Submit
            </button>
          ) : null
        ) : (
          <button
            // type='submit'
            onClick={() => handleNextBtn()}
            type='button'
            disabled={disabled === true}
            className={disabled ? "next-btn-disabled" : "next-btn"}
          >
            NEXT
          </button>
        )}
      </div> */}
    </div>
  );
};

export default Stepper;
