// ProtectedRoute.js
import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const history = useNavigate();
  const token = localStorage.getItem("token"); // Replace this with your authentication logic
  const location = useLocation();
  if (!token && window.location.pathname !== "/entrance") {
    return <Navigate replace to='/login' />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
