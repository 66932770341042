import React from "react";
import styles from "./FirstSection.module.css";
import HomeNav from "./HomeNav";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
function FirstSection() {
  const history = useNavigate();
  const rendererUpper = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span className='timing text-center w-100 mb-5 text-danger'>
          Submission form closed, don't forget to apply the next year.{" "}
        </span>
      );
    } else {
      // Render a countdown
      return (
        <div
          className={
            styles["fullcounter-div"] +
            " p-0 col-12 d-flex flex-row  justify-content-left align-items-center DeadlineBoxesContainer-small"
          }
        >
          <div
            className={
              styles["eachcounter-div"] +
              "  d-flex flex-column justify-content-center align-items-center  "
            }
          >
            <div
              className={
                styles["sectionDeadlineDateBox-small"] +
                " countdown-number-title-small"
              }
            >
              {days}
            </div>
            <span className={styles["countdown-number-sub-small"]}>Days</span>
          </div>

          <div
            className={
              styles["eachcounter-div"] +
              "  d-flex flex-column justify-content-center align-items-center "
            }
          >
            <div
              className={
                styles["sectionDeadlineDateBox-small"] +
                " countdown-number-title-small"
              }
            >
              {hours}
            </div>
            <span className={styles["countdown-number-sub-small"]}>Hours</span>
          </div>

          <div
            className={
              styles["eachcounter-div"] +
              "  d-flex flex-column justify-content-center align-items-center "
            }
          >
            <div
              className={
                styles["sectionDeadlineDateBox-small"] +
                " countdown-number-title-small"
              }
            >
              {minutes}
            </div>
            <span className={styles["countdown-number-sub-small"]}>
              Minutes
            </span>
          </div>

          <div
            className={
              "  offset-r-2 d-flex flex-column justify-content-center align-items-center "
            }
          >
            <div
              className={
                styles["sectionDeadlineDateBox-small"] +
                " countdown-number-title-small"
              }
            >
              {seconds}
            </div>
            <span className={styles["countdown-number-sub-small"]}>
              Seconds
            </span>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <div className={styles["video-container"]}>
        <HomeNav></HomeNav>
      </div>
      <div className={styles["first-section-div"]}>
        <div className={styles["slogan-div"]}></div>
        <div className={styles["first-section-div-content"]}>
          <button
            className={styles["register-big-btn"]}
            onClick={() => {
              history("/region");
            }}
          >
            Register
          </button>
        </div>
      </div>
    </>
  );
}

export default FirstSection;
