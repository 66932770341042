import React from "react";
import styles from "./Home2.module.css";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import SeventhSection from "./SeventhSection";
function Home2() {
  const settings = {
    infinite: true,
    slidesToShow: isMobile ? 3 : 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    speed: 1000,
  };
  return (
    <div className={styles["main-bg"] + " container-fluid p-0"}>
      <FirstSection></FirstSection>
      <SecondSection></SecondSection>
      <div className={styles["moving-logo"]}>
        <Slider {...settings} className={styles["slick-ss"]}>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
          <div className={styles["sloganone-div"]}></div>
        </Slider>
      </div>
      {/* <ThirdSection></ThirdSection> */}
      <FourthSection></FourthSection>
      <FifthSection></FifthSection>
      <SixthSection></SixthSection>

      {/* <SeventhSection></SeventhSection> */}
    </div>
  );
}

export default Home2;
