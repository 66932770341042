import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import useInputValidation from "../../../hooks/use-input";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const apiUri = process.env.REACT_APP_SERVER_URL + "usermanagement/";

const AddUser = (props) => {
  const {
    reset: resetEmail,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    value: email,
    hasError: emailHasError,
    isValid: emailIsValid,
  } = useInputValidation((email) => {
    if (validateEmail(email)) return true;
    else return false;
  });

  const emailClasses = emailHasError
    ? "form-control border border-danger"
    : "form-control";

  let formIsValid = false;

  if (emailIsValid) {
    formIsValid = true;
  }
  const addNewUserHandler = async (event) => {
    event.preventDefault();
    if (!emailIsValid) {
      return;
    }

    const user = { email: email };
    const postUser = await axios.post(apiUri + "Users", { email });

    if (postUser.status === 200) {
      toast("User Added Successfully!", { autoClose: 3000 });
      props.updatedUsers(user);
      resetEmail();
    } else {
      toast("Failed To Add User!", { autoClose: 3000 });
    }
  };
  return (
    <form
      className="col-md-11 ml-5 text-center"
      style={{ textAlign: "center" }}
      onSubmit={addNewUserHandler}
    >
      <div className="input-group mt-5 mb-3">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              @
            </span>
          </div>
          <input
            id="email"
            type="email"
            className={emailClasses}
            placeholder="Email"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
        </div>
        {emailHasError && (
          <strong className="text-danger">Please Enter Valid Email</strong>
        )}
      </div>
      <div className="form-group">
        <button
          style={{ backgroundColor: "#F79058" }}
          className="btn btn-outline-dark button-login btn-sm mt-3 mb-3"
          disabled={!formIsValid}
        >
          Add New User
        </button>
      </div>
    </form>
  );
};

export default AddUser;
