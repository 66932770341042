import { useNavigate, useLocation } from "react-router-dom";
import SuperAdminNavBar from "./SuperAdminNavBar";
import axios from "axios";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import ReactPaginate from "react-paginate";
import "./projectspreview.css";
import styles from "./superadminProjectsinnerPage.module.css";
function ProjectsPreview() {
  const history = useNavigate();
  let location = useLocation();
  const [loadBytes, setLoadBytes] = React.useState(false);

  const [region, setregion] = useState(
    location.state.region != "all" ? location.state.region : "all"
  );
  const [searchText, setSearchText] = useState("");
  const [status, setstatus] = React.useState("all");
  const [projects, setprojects] = useState([]);
  const [exported, setexported] = useState([]);
  const [projectsCount, setprojectsCount] = useState(0);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [projectID, setprojectID] = useState("");
  const downloadExcel = (dataObj) => {
    const data = dataObj.map((record) => {
      let obj = {};
      for (let key in record) {
        if (key == "non_Registered") {
          obj["Non Registered"] = JSON.parse(record[key])

            .map((el) => el.email)
            .toString();
        } else if (key == "prefiltration_comments") {
          obj["Prefiltration Comments"] = JSON.parse(record[key])

            .map((el) => el.comment)
            .toString();
        } else if (key == "name") {
          obj["Participant Name"] = record[key];
        } else if (_.startCase(key) == "Created At") {
          obj["Member Created At"] = record[key].toString();
        } else if (_.startCase(key) == "Updated At") {
          obj["Member Updated At"] = record[key].toString();
        } else if (_.startCase(key) == "Status") {
          obj["Member Status"] = record[key].toString();
        } else if (key == "project") {
          for (let key2 in record[key]) {
            if (_.startCase(key2) != "Id" && _.startCase(key2) != "V") {
              if (_.startCase(key2) == "Name") {
                obj["Project Name"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Created At") {
                obj["Project Created At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Updated At") {
                obj["Project Updated At"] = record["project"][key2].toString();
              } else if (_.startCase(key2) == "Status") {
                obj["Project Status"] = record["project"][key2].toString();
              } else {
                obj[_.startCase(key2)] = record["project"][key2];
              }
            }

            obj[_.startCase(key2)] = record["project"][key2].toString();
          }
        } else {
          obj[_.startCase(key)] = record[key];
        }
      }

      return obj;
    });
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // var staffId = +localStorage.getItem('StaffId');

    /* save to file */
    const fileregion = region != undefined ? region : "all";
    XLSX.writeFile(wb, "Projects_" + fileregion + " " + new Date() + ".xlsx");
  };
  const deleteProject = (id) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}project/superadmindelete`, {
        id,
      })
      .then((res) => {
        getProjects();
      });
  };
  const getProjects = () => {
    setprojects([]);
    setexported([]);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getforsuperadminpaginated`,
        {
          region,
          searchText,
          status,
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setprojects(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region,
          status,
        }
      )
      .then((res) => {
        setprojectsCount(res.data.result);
      });
  };
  const handlePaginationClick = (e) => {
    setprojects([]);
    setexported([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getforsuperadminpaginated`,
        {
          region,
          searchText,
          status,
          page: e.selected,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setprojects(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };
  useEffect(() => {
    setregion(location.state.region != "all" ? location.state.region : "all");
    getProjects();
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, [region, location.state, status]);
  const exportprojects = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getforsuperadminexport`,
        { region, status }
      )
      .then((res) => {
        setexported(res.data);
        downloadExcel(res.data);
      });
  };
  const handlesearch = (e) => {
    setLoadBytes(false);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getforsuperadminpaginated`,
        {
          region,
          searchText: e,
          status,
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setprojects(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(0);
        setLoadBytes(true);
        console.log(res.data);
      });
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <div className='container-fluid p-0'>
        <div className=' nav-button-padding'></div>
        <div className='row' style={{ minHeight: "50vh" }}>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={" col-md-10"}>
            <div className={styles["main-container"] + " row mt-5"}>
              <div className='col-md-12'>
                <button
                  className='btn btn-outline-dark float-right '
                  style={{
                    marginRight: "6%",
                    marginTop: "2rem",
                    marginBottom: "1.5rem",
                  }}
                  onClick={() => {
                    exportprojects();
                  }}
                >
                  <span className='fas fa-file-download mr-2'></span> Export
                  Projects
                </button>
                <h3
                  style={{
                    color: "#007c92",
                    marginLeft: "3%",
                    marginTop: "2rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Total # of Projects : {projectsCount}
                </h3>
              </div>
              <div className='main-postKeynotes-page-bg '>
                <div className='main-postKeynotes-page-header'>
                  <button
                    className='btn-selcted'
                    onClick={(el) => {
                      var buttons =
                        document.getElementsByClassName("btn-selcted");

                      for (var i = 0; i < buttons.length; i++)
                        buttons[i].classList = "main-postKeynotes-page-button";
                      el.target.classList = "btn-selcted";
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                      setprojects([]);
                      setstatus("all");
                    }}
                  >
                    All
                  </button>
                  <button
                    className='main-postKeynotes-page-button'
                    onClick={(el) => {
                      var buttons =
                        document.getElementsByClassName("btn-selcted");

                      for (var i = 0; i < buttons.length; i++)
                        buttons[i].classList = "main-postKeynotes-page-button";
                      el.target.classList = "btn-selcted";
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                      setprojects([]);
                      setstatus("approved");
                    }}
                  >
                    Approved
                  </button>
                  <button
                    className='main-postKeynotes-page-button '
                    onClick={(el) => {
                      var buttons =
                        document.getElementsByClassName("btn-selcted");

                      for (var i = 0; i < buttons.length; i++)
                        buttons[i].classList = "main-postKeynotes-page-button";
                      el.target.classList = "btn-selcted";
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                      setprojects([]);
                      setstatus("pending");
                    }}
                  >
                    Pending
                  </button>
                  <button
                    className='main-postKeynotes-page-button '
                    onClick={(el) => {
                      var buttons =
                        document.getElementsByClassName("btn-selcted");

                      for (var i = 0; i < buttons.length; i++)
                        buttons[i].classList = "main-postKeynotes-page-button";
                      el.target.classList = "btn-selcted";
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                      setprojects([]);
                      setstatus("rejected");
                    }}
                  >
                    Rejected
                  </button>
                  <div
                    style={{
                      position: "absolute",
                      right: "4%",
                    }}
                  >
                    <input
                      className='mr-2'
                      type='text'
                      style={{ borderRadius: "10px" }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      value={searchText}
                    />
                    <button
                      onClick={() => handlesearch(searchText)}
                      className='fa fa-search'
                    ></button>
                  </div>
                </div>
                {loadBytes ? (
                  <div
                    className='main-postKeynotes-page-body row'
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <table className='table t-width'>
                      <thead>
                        <tr>
                          <th scope='col' style={{ width: "20%" }}>
                            Name
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Status
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            # of Parts
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Data Completed
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Has Prefiltration Comments
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Has Mixed SL
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Has 2% +
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Has 5% +
                          </th>
                          <th scope='col' style={{ width: "20%" }}>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map((el) => {
                          return (
                            <tr>
                              <td>{el.name}</td>
                              <td>{el.status}</td>
                              <td>{el.teamMembersCount}</td>
                              <td>
                                {el.datacheck ? (
                                  <span
                                    className='fa fa-check'
                                    style={{ color: "green" }}
                                  ></span>
                                ) : (
                                  <span style={{ color: "#e60000" }}>x</span>
                                )}
                              </td>
                              <td>
                                {el.prefiltration_comments ? (
                                  <span
                                    className='fa fa-check'
                                    style={{ color: "green" }}
                                  ></span>
                                ) : (
                                  <span style={{ color: "#e60000" }}>x</span>
                                )}
                              </td>
                              <td>
                                {el.fromMixedSL ? (
                                  <span
                                    className='fa fa-check'
                                    style={{ color: "green" }}
                                  ></span>
                                ) : (
                                  <span style={{ color: "#e60000" }}>x</span>
                                )}
                              </td>
                              <td>
                                {el.isTwoPercent ? (
                                  <span
                                    className='fa fa-check'
                                    style={{ color: "green" }}
                                  ></span>
                                ) : (
                                  <span style={{ color: "#e60000" }}>x</span>
                                )}
                              </td>
                              <td>
                                {el.isFivePercent ? (
                                  <span
                                    className='fa fa-check'
                                    style={{ color: "green" }}
                                  ></span>
                                ) : (
                                  <span style={{ color: "#e60000" }}>x</span>
                                )}
                              </td>
                              <td>
                                <span
                                  className='fa fa-eye mr-2'
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    history("/superadmin/projectDetails", {
                                      state: { id: el._id },
                                    });
                                  }}
                                ></span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  className='fa fa-trash'
                                  onClick={() => {
                                    deleteProject(el._id);
                                  }}
                                ></span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <ReactPaginate
                          previousLabel={"prev"}
                          nextLabel={"next"}
                          pageCount={Math.ceil(totalCount / pageSize)}
                          onPageChange={handlePaginationClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                      </tfoot>
                    </table>
                  </div>
                ) : (
                  <div
                    className='main-postKeynotes-page-body row'
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <div className='text-center'>
                      <div className='spinner-grow text-primary' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div
                        className='spinner-grow text-secondary'
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-success' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-danger' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-warning' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-info' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-light' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                      <div className='spinner-grow text-dark' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPreview;
