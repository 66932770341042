import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SuperAdminContext } from "../../contexts/superadminContext";
import SuperAdminNavBar from "./SuperAdminNavBar";
import styles from "./superadminHome.module.css";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function SuperadminHome() {
  const [superAdmin, changeDetails] = useContext(SuperAdminContext);
  const [members, setMembers] = useState([]);
  const [counters, setcounters] = useState({});
  const [projectCounters, setProjectCounters] = useState();
  const [region, setregion] = useState("");
  let location = useLocation();
  const [generatedLinksData, setgeneratedLinksData] = useState();
  const forceUpdate = useForceUpdate();
  const [generalData, setgeneralData] = useState();
  const [registrationData, setregistrationData] = useState();
  const [registrationProjectData, setregistrationProjectData] = useState();
  const [egyptCounter, setEgyptCounter] = useState(0);
  const [indiaCounter, setIndiaCounter] = useState(0);
  const [romaniaCounter, setRomaniaCounter] = useState(0);
  useEffect(() => {
    changeDetails({
      region: "all",
    });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "egypt",
        }
      )
      .then((res) => {
        setEgyptCounter(res.data.result);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "india",
        }
      )
      .then((res) => {
        setIndiaCounter(res.data.result);
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}project/getprojectcountforregion`,
        {
          region: "romania",
        }
      )
      .then((res) => {
        setRomaniaCounter(res.data.result);
      });
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/members`, {})
      .then((res) => {
        console.log(res.data);
        setMembers(res.data);
      });
    getdashBoard();
    getdashBoardParticipants();
    getdashBoardLink();
    getdashBoardgeneral();
    getdashBoardRegister();
    getdashBoardProjects();
  }, []);
  const getdashBoardProjects = () => {
    setregistrationProjectData([]);
    let chart = am4core.create("chartdiv2", am4charts.PieChart3D);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/project`, {})
      .then((res) => {
        setregistrationProjectData(res.data);

        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el._id,
            litres: el.count,
            color: getRandomItem(),
          };
        });

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        chart.innerRadius = am4core.percent(50);
        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;

        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.value}";
        pieSeries.slices.template.events.on(
          "hit",
          function (ev) {
            history("/superadmin/project", {
              state: {
                region: ev.target.dataItem.category,
              },
            });
          },
          this
        );
      });
  };
  const getdashBoardRegister = () => {
    setregistrationData([]);
    let chart = am4core.create("chartdiv", am4charts.PieChart3D);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/register`, {
        region: region,
      })
      .then((res) => {
        setregistrationData(res.data);

        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el.region,
            litres: el.count,
            color: getRandomItem(),
          };
        });

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;

        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.value}";
        pieSeries.slices.template.events.on(
          "hit",
          function (ev) {
            history("/superadmin/project", {
              state: {
                region: ev.target.dataItem.category,
              },
            });
          },
          this
        );
      });
  };
  const exportprojectToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      registrationProjectData.map((el) => {
        return { projectType: el._id[0], hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-projectTypes-Stats" + new Date() + ".xlsx");
  };
  const getdashBoard = () => {
    setcounters({});
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard`, {
        region: "all",
      })
      .then((res) => {
        setcounters(res.data);

        let chart = am4core.create("projects", am4charts.XYChart);

        chart.paddingRight = 20;
        chart.data = [
          {
            country: "Approved",
            color: "#0096AD",
            visits: res.data.approvedProjects,
          },
          {
            country: "Pending",
            color: "#EB6100",
            visits: res.data.pendingProjects,
          },
          {
            country: "Rejected",
            color: "#B7BF10",
            visits: res.data.rejectedProjects,
          },
        ];
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add(
          "dy",
          function (dy, target) {
            if (target.dataItem && target.dataItem.index & (2 == 2)) {
              return dy + 25;
            }
            return dy;
          }
        );

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "visits";
        series.dataFields.categoryX = "country";
        series.name = "Visits";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.adapter.add("fill", function (fill, target) {
          return target.dataItem.dataContext["color"];
        });
        series.columns.template.adapter.add("stroke", function (fill, target) {
          return target.dataItem.dataContext["color"];
        });

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        forceUpdate();
      });
  };
  const getdashBoardParticipants = () => {
    setcounters({});
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard`, {
        region: "all",
      })
      .then((res) => {
        setcounters(res.data);

        let chart = am4core.create("participantsafter", am4charts.XYChart);

        chart.paddingRight = 20;
        chart.data = [
          {
            country: "Approved",
            color: "#0096AD",
            visits: res.data.approvedTeammembers,
          },
          {
            country: "Pending",
            color: "#EB6100",
            visits: res.data.pendingTeammembers,
          },
          {
            country: "Rejected",
            color: "#B7BF10",
            visits: res.data.rejectedTeammembers,
          },
        ];
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add(
          "dy",
          function (dy, target) {
            if (target.dataItem && target.dataItem.index & (2 == 2)) {
              return dy + 25;
            }
            return dy;
          }
        );

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "visits";
        series.dataFields.categoryX = "country";
        series.name = "Visits";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.adapter.add("fill", function (fill, target) {
          return target.dataItem.dataContext["color"];
        });
        series.columns.template.adapter.add("stroke", function (fill, target) {
          return target.dataItem.dataContext["color"];
        });

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        forceUpdate();
      });
  };
  const history = useNavigate();
  const exportgeneratedLinks = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      generatedLinksData.map((el) => {
        return { SL: el._id, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-ServiceLines-Stats" + new Date() + ".xlsx");
  };
  const getdashBoardLink = () => {
    setgeneratedLinksData([]);
    let chart = am4core.create("generatedLinkschartdiv", am4charts.PieChart3D);

    axios.get(`${process.env.REACT_APP_SERVER_URL}invetations/`).then((res) => {
      setgeneratedLinksData(res.data);

      chart.paddingRight = 20;
      chart.data = res.data.map((el) => {
        return {
          country: el._id,
          litres: el.count,
          color: getRandomItem(),
        };
      });

      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      chart.innerRadius = am4core.percent(50);
      // Disable tooltips
      pieSeries.slices.template.tooltipText = "";
      pieSeries.slices.template.propertyFields.fill = "color";
      chart.legend = new am4charts.Legend();
      chart.legend.maxHeight = 150;
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.text = "{value.value}";
    });
  };
  function getRandomItem() {
    let arr = ["#0096AD", "#EB6100", "#B7BF10", "#DEDEDE"];
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
  }
  const exportgeneralToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      generalData.map((el) => {
        return { page: el.link, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Hits-Stats" + new Date() + ".xlsx");
  };
  const getdashBoardgeneral = () => {
    setgeneralData([]);

    let chart = am4core.create("generalchartdiv", am4charts.PieChart);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}dashboard/general`)
      .then((res) => {
        setgeneralData(res.data.sort((a, b) => b.count - a.count));
        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el.link,
            litres: el.count,
            color: getRandomItem(),
          };
        });
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        chart.innerRadius = am4core.percent(50);
        // Disable tooltips
        pieSeries.slices.template.tooltipText = "";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.legend = new am4charts.Legend();
        chart.legend.maxHeight = 150;
        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.value}";
      });
  };
  const exportRegistrationToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      registrationData.map((el) => {
        return { type: el._id, hits: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Registration-Stats" + new Date() + ".xlsx");
  };
  return (
    <div>
      <div className='container-fluid p-0' style={{ overflow: "hidden" }}>
        <div className='row '>
          <div className='col-md-2'>
            <SuperAdminNavBar></SuperAdminNavBar>
          </div>
          <div className={styles["main-container"] + " col-md-10"}>
            <div className='row mt-3'>
              <span className={styles["title-header"] + " col-md-12 mb-2"}>
                Registration By Country
              </span>
              <div className={styles["region-box"] + " col-md-2 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span
                    className={styles["regionTitle"]}
                    onClick={() => {
                      changeDetails({
                        region: "egypt",
                      });
                      localStorage.setItem("region", "egypt");
                      history("/superadmin/regionalhome");
                    }}
                  >
                    Egypt
                  </span>
                  <img
                    src='/assets/flags/Egypt.png'
                    className={styles["regionimg"]}
                  ></img>
                </div>
                <span className={styles["count"]}>{egyptCounter}</span>
              </div>
              <div className={styles["region-box"] + " col-md-2 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span
                    className={styles["regionTitle"]}
                    onClick={() => {
                      changeDetails({
                        region: "romania",
                      });
                      localStorage.setItem("region", "romania");
                      history("/superadmin/regionalhome");
                    }}
                  >
                    Romania
                  </span>
                  <img
                    src='/assets/flags/romania.png'
                    className={styles["regionimg"]}
                  ></img>
                </div>
                <span className={styles["count"]}>{romaniaCounter}</span>
              </div>
              <div className={styles["region-box"] + " col-md-2 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span
                    className={styles["regionTitle"]}
                    onClick={() => {
                      changeDetails({
                        region: "india",
                      });
                      localStorage.setItem("region", "india");
                      history("/superadmin/regionalhome");
                    }}
                  >
                    India
                  </span>
                  <img
                    src='/assets/flags/India.png'
                    className={styles["regionimg"]}
                  ></img>
                </div>
                <span className={styles["count"]}>{indiaCounter}</span>
              </div>

              <span className={styles["title-header"] + " col-md-12 mb-2"}>
                Before First Panel
              </span>
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span className={styles["regionTitle"]}>Projects</span>
                </div>
                <span className={styles["count"]}>
                  {counters.totalProjects}
                </span>
              </div>
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span className={styles["regionTitle"]}>Team Members</span>
                </div>
                <span className={styles["count"]}>
                  {counters.totalRegisteredTeammembers}
                </span>
              </div>
              <div className={styles["region-box"] + " col-md-3 ml-2 mb-2"}>
                <div className={styles["region-box-header"] + " mb-3"}>
                  <span className={styles["regionTitle"]}>
                    Non-Submitted Team Members
                  </span>
                </div>
                <span className={styles["count"]}>
                  {counters.totalnonRegisteredTeammembers}
                </span>
              </div>
              <span className={styles["title-header"] + " col-md-12 mb-2"}>
                After First Panel
              </span>
              <div
                className={styles["region-box-chart"] + " col-md-5 ml-2 mb-2"}
                id='projects'
              ></div>
              <div
                className={styles["region-box-chart"] + " col-md-5 ml-5 mb-2"}
                id='participantsafter'
              ></div>
              <span className={styles["title-header"] + " col-md-12 mb-2"}>
                Insights
              </span>

              <div
                className={styles["region-box-chart"] + " col-md-5 ml-2 mb-2"}
              >
                <span className={styles["card2-boxes-title"]}>HITS/PAGE</span>
                <div id='generalchartdiv' className={styles["chart"]}></div>
                <button
                  className={styles["button-style"]}
                  onClick={() => {
                    exportgeneralToExcel();
                  }}
                >
                  Export to excel
                </button>
              </div>
              <div
                className={styles["region-box-chart"] + " col-md-5 ml-5 mb-2"}
              >
                <span className={styles["card2-boxes-title"]}>
                  Registration by region
                </span>
                <div id='chartdiv' className={styles["chart"]}></div>

                <button
                  className={styles["button-style"]}
                  onClick={() => {
                    exportRegistrationToExcel();
                  }}
                >
                  Export to excel
                </button>
              </div>

              {/* <div
                onClick={() => {
                  changeDetails({
                    region: "egypt",
                  });
                  localStorage.setItem("region", "egypt");
                  history("/superadmin/regionalhome");
                }}
                className='col-md-6 col-xs-12 transition-asc-img'
                style={{
                  background:
                    "transparent url('/assets/egypt.jpg') 0% 0% no-repeat padding-box",
                  backgroundSize: "100% 100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    textShadow:
                      "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                  }}
                >
                  Egypt
                </h1>
              </div>
              <div
                className='col-md-6 col-xs-12 transition-asc-img'
                onClick={() => {
                  changeDetails({
                    region: "india",
                  });
                  localStorage.setItem("region", "india");
                  history("/superadmin/regionalhome");
                }}
                style={{
                  background:
                    "transparent url('/assets/india.jpg') 0% 0% no-repeat padding-box",
                  backgroundSize: "100% 100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    textShadow:
                      "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                  }}
                >
                  India
                </h1>
              </div>
              <div
                className='col-md-12 col-xs-12 transition-asc-img'
                style={{
                  background:
                    "transparent url('/assets/romania.jpeg') 0% 0% no-repeat padding-box",
                  backgroundSize: "100% 100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  changeDetails({
                    region: "romania",
                  });
                  localStorage.setItem("region", "romania");
                  history("/superadmin/regionalhome");
                }}
              >
                <h1
                  style={{
                    color: "white",
                    textShadow:
                      "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                  }}
                >
                  Romania
                </h1>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className='col-md-6 col-xs-12 p-0 transition-asc-img'>
            <div
              className=''
              style={{
                height: "100%",
                background:
                  "transparent url('/assets/hungary.jpg') 0% 0% no-repeat padding-box",
                backgroundSize: "100% 100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                changeDetails({
                  region: "hungary",
                });
                localStorage.setItem("region", "hungary");
                history("/superadmin/regionalhome");
              }}
            >
              <h1
                style={{
                  color: "white",
                  textShadow:
                    "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                }}
              >
                Hungary
              </h1>
            </div>
          </div>
        </div> */}

        {/* <img
          className='logoContainer mb-2'
          alt='login-background'
          src='/assets/ic-logo-navbar.png'
        ></img> */}
      </div>
    </div>
  );
}

export default SuperadminHome;
