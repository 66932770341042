import React, { useState, useEffect, useRef } from "react";
import LoggedNavBar from "../../common/navBar/loggedNav";
import "./coachesRooms.css";
import { getCurrentUser } from "./../../../services/authService";
import { getallRooms, setRoom } from "../../../apis/coach";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Toaster from "../../../components/common/Toast";
import SideMenu from "../../../components/common/sideMenu/sideMenu";
import { useNavigate } from "react-router-dom";

const CoachesRooms = () => {
  const [panelRooms, setPanelRooms] = useState([]);
  const [msg, setMsg] = useState("");
  const [coach, setCoach] = useState(false);
  const [sorted, setSort] = useState("A");
  useEffect(() => {
    getallRooms().then((res) => {
      setPanelRooms(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      console.log(res.data);
    });
  }, []);

  const handlecheck = (panelID, roomID) => {
    let tempRooms = [];
    panelRooms.map((i) => {
      if (i.id == panelID) {
        i.rooms.map((j) => {
          if (j.id == roomID) {
            j.isDisabled = !j.isDisabled;
            setRoom({ id: roomID, status: j.isDisabled })
              .then((res) => {
                if (res.data == "room visability setted successfully")
                  setMsg("");
              })
              .catch(() => setMsg("something went wrong, please try again"));
          }
        });
      }
      tempRooms.push(i);
      setPanelRooms(tempRooms);
    });
  };
  const searchref = useRef();
  const history = useNavigate();
  const search = () => {
    console.log(searchref.current.value);
    if (searchref.current.value == "") {
      getallRooms().then((res) => {
        setPanelRooms(res.data);
      });
    } else {
      getallRooms().then((res) => {
        const panels = res.data.filter((r) =>
          r.name.toLowerCase().startsWith(searchref.current.value.toLowerCase())
        );
        console.log(panels);
        setPanelRooms(panels);
      });
    }
  };
  const sort = () => {
    const pan = panelRooms;
    if (sorted == "A") {
      setPanelRooms(pan.sort((a, b) => b.name.localeCompare(a.name)));
      console.log(pan.sort((a, b) => b.name - a.name));
      setSort("D");
    } else if (sorted == "D") {
      setPanelRooms(pan.sort((a, b) => a.name.localeCompare(b.name)));
      setSort("A");
    }
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall2' />
        <div className='container'>
          <p className='header-support'>COACHES ROOM</p>
          <div className='coaches-redLine'></div>
          <div className='room-container-subheader'>
            <input
              type='text'
              className='searchbox-room'
              ref={searchref}
              onKeyUp={search}
            ></input>
            <button className='searchbox-room-btn' onClick={search}>
              <i className='fa fa-search searchbox-room-icon-btn'></i>{" "}
            </button>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <button className='room-sort-btn' onClick={sort}>
                SORT
              </button>
            </div>
          </div>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            {panelRooms.map((panelRoom) => {
              return (
                <div className='room' key={panelRoom.id}>
                  <div className='room-container-header'>
                    <span className='room-welcome'>{panelRoom.name}</span>
                  </div>

                  <div className='links-div'>
                    {panelRoom.rooms.map((room, i) => {
                      return (
                        <div key={room.id} className='btns-div'>
                          <div className='internal-btns-div'>
                            <p className='internal-btns-header'>
                              ROOM{" "}
                              {i == 0
                                ? "one"
                                : i == 1
                                ? "two"
                                : i == 2
                                ? "three"
                                : i == 3
                                ? "four"
                                : "five"}
                            </p>
                            {room.owner ==
                            getCurrentUser().preferred_username ? (
                              <label class='switch'>
                                <input
                                  type='checkbox'
                                  checked={!room.isDisabled}
                                  onChange={() =>
                                    handlecheck(panelRoom.id, room.id)
                                  }
                                />
                                <span class='slider round'></span>
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <button
                            disabled={room.isDisabled}
                            onClick={() => {
                              window.open(room.link, "_blank");
                            }}
                            className='join'
                          >
                            Join the meeting
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CoachesRooms;
