import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RevokeUser from "./RevokeUser";
import { useNavigate } from "react-router-dom";
import { setImpersonateJWT } from "../../../services/authService";

const apiUri = process.env.REACT_APP_SERVER_URL + "usermanagement/";

const User = (props) => {
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  const revokeUser = async () => {
    setShowModal(true);
  };

  const getUserEmail = (email) => {
    setImpersonateJWT(email);
    history("/entrance");
  };

  const showDeleteModalHandler = () => {
    setShowModal(false);
  };

  const resetPassword = async () => {
    const updateUser = await axios.put(apiUri + "Users", { email: props.user });

    if (updateUser.status === 200) {
      toast("Password Reset", { autoClose: 5000 });
    } else {
      toast("Password Reset Failed!", { autoClose: 5000 });
    }
  };

  return (
    <React.Fragment>
      {showModal && (
        <RevokeUser
          fetchUsers={props.fetchUsers}
          selectedUser={props.user}
          showDeleteModalHandler={showDeleteModalHandler}
        />
      )}
      <tr>
        <td>{props.user}</td>
        <td>
          <button className='btn btn-info' onClick={resetPassword}>
            Reset Password
          </button>
        </td>
        <td>
          <button className='btn btn-danger' onClick={revokeUser}>
            Revoke User
          </button>
        </td>
        <td>
          <button
            className='btn btn-danger'
            onClick={() => {
              getUserEmail(props.user);
            }}
          >
            Impersonate User
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default User;
