import React from "react";
import styles from "./SixthSection.module.css";
function SixthSection() {
  return (
    <div className={styles["sixth-section-div"]}>
      <div className={styles["div-container"]}>
        <div className={styles["single-div"]}>
          <div className={styles["title"]}>
            1<sup>st</sup>
          </div>
          <div className={styles["card-content"]}>
            <span className={styles["card-content-title"]}>
              Create your team
            </span>
            <span className={styles["card-content-body"]}>
              We encourage you to create a team of up to 3 members with
              different skill sets to develop your solution, but you can also
              compete individually. You will need at least one team member who
              can pitch the team’s idea in English - the primary language of the
              competition.
            </span>
          </div>
        </div>
        <div className={styles["single-div"]}>
          <div className={styles["title"]}>
            2<sup>nd</sup>
          </div>
          <div className={styles["card-content"]}>
            <span className={styles["card-content-title"]}>
              REGISTER & SUBMIT YOUR PITCH
            </span>
            <span className={styles["card-content-body"]}>
              Your solution should reflect how companies can adapt to the
              rapidly changing business landscape by moving forward, testing new
              ideas, spreading fresh thinking, driving innovations and improving
              processes. 
            </span>
          </div>
        </div>
        <div className={styles["single-div"] + " m-0"}>
          <div className={styles["title"]}>
            3<sup>rd</sup>
          </div>
          <div className={styles["card-content"]}>
            <span className={styles["card-content-title"]}>
              STAY TUNED FOR YOUR INVITATION
            </span>
            <span className={styles["card-content-body"]}>
              The most innovative, impactful, and technically feasible
              solutions from every competing region will be selected to join the
              competition.
              <br /> We will send a confirmation e-mail to the chosen teams.
              <br /> Watch out for this e-mail to know whether you are among
              them!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SixthSection;
