import React, { useState } from "react";
import Modal from "./../Modal/Modal";

const AnnouncementModal = ({
  showOrHideModalHandler,
  announcementObject,
  addNewAnnouncementHandler,
  updateAnnouncementHandler,
}) => {
  const [announcementTitle, setAnnouncementTitle] = useState(
    announcementObject.title || ""
  );

  const [announcementBody, setAnnouncementBody] = useState(
    announcementObject.body || ""
  );

  const changeTitleHandler = (event) => {
    setAnnouncementTitle(event.target.value);
  };

  const changeBodyHandler = (event) => {
    setAnnouncementBody(event.target.value);
  };

  const submitHandler = () => {
    if (!announcementObject._id) {
      const newAnnouncementObject = {
        title: announcementTitle,
        body: announcementBody,
        region: localStorage.getItem("region"),
      };

      addNewAnnouncementHandler(newAnnouncementObject);
      showOrHideModalHandler();
    } else {
      const modifiedAnnouncement = {
        id: announcementObject._id,
        title: announcementTitle,
        body: announcementBody,
      };

      updateAnnouncementHandler(modifiedAnnouncement);
      showOrHideModalHandler();
    }
  };

  return (
    <div>
      <Modal showDeleteModalHandler={showOrHideModalHandler}>
        {announcementObject._id ? (
          <h2 className="text-center mt-3 mb-3">Modify announcement</h2>
        ) : (
          <h2 className="text-center mt-3 mb-3">Add new announcement</h2>
        )}

        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              Title
            </span>
          </div>
          <textarea
            type="text"
            className="form-control rounded input-lg"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            onChange={changeTitleHandler}
            value={announcementTitle}
          ></textarea>
        </div>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">Announcement</span>
          </div>
          <textarea
            className="form-control rounded"
            value={announcementBody}
            onChange={changeBodyHandler}
          ></textarea>
        </div>

        <div className="mt-4">
          <button className="btn btn-primary mr-3" onClick={submitHandler}>
            Submit
          </button>
          <button className="btn btn-danger" onClick={showOrHideModalHandler}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AnnouncementModal;
