import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { MainUserContext } from "../../../../contexts/mainUserContext";
import "./mainUserDetails.css";
import VerificationCode from "../../../common/verificationCode";
import Stepper from "../stepper/stepper";
import moment from "moment";
import {
  getCodebyEmail,
  getCodebyMobile,
  verifyMobileCode,
  verifyEmailCode,
  verifyParticipant,
  editVerifyMobile,
  editVerifyEmail,
} from "../../../../apis/register";

import Toaster from "../../../common/Toast";
import { values } from "lodash";
import { GuardianContext } from "../../../../contexts/guardianContext";

const MainUserDetails = () => {
  const [guardianDetails, changeDetails] = useContext(GuardianContext);
  const [data, setData] = useContext(MainUserContext);
  const [admin, setAdmin] = useState(data.isAdmin);
  const [emailInput, setEmailInput] = useState(false);
  const [phoneInput, setPhoneInput] = useState(false);
  const [mobileCode, setMobileCode] = useState();
  const [emailCode, setEmailCode] = useState();
  const [sendemail, setSendEmail] = useState(true);
  const [sendsms, setSendSMS] = useState(true);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [msg, setMsg] = useState("");
  const [cvError, SetCvErrorMessage] = React.useState("");
  const [passwordStatus, setPasswordSatus] = useState(true);
  const [passwordConfirmStatus, setPasswordConfirmSatus] = useState(true);
  const [goNext, setGoNext] = useState(false);
  const displayInputOfEmail = (email) => {
    setEmailInput(true);
    getCodebyEmail(email);
  };

  const handleMobileVerified = (mobile, code) => {
    verifyMobileCode(mobile, code)
      .then((res) => {
        if (res.data == "verified") {
          setMobileVerified(true);
          setMsg("");
        }
      })
      .catch(() => {
        setMsg("the code is wrong, please try again");
        setMobileVerified(false);
      });
  };

  const handleEmailVerified = (email, code) => {
    verifyEmailCode(email, code)
      .then((res) => {
        if (res.data == "verified") {
          setEmailVerified(true);
          setMsg("");
        }
      })
      .catch(() => {
        setMsg("the code is wrong, please try again");
        setEmailVerified(false);
      });

    verifyParticipant(email)
      .then((res2) => {
        if (res2) setAdmin(false);
      })
      .catch(() => {
        setAdmin(true);
      });
  };

  const displayInputOfPhone = (mobile) => {
    setPhoneInput(true);
    getCodebyMobile(mobile, data.region);
  };

  const disabledInputOfEmail = (email) => {
    editVerifyEmail(email).then((res) => setEmailInput(false));
    setEmailVerified(false);
  };

  const togglePasswordStatus = () => {
    setPasswordSatus((oldStatus) => !oldStatus);
  };
  const togglePasswordConfirmStatus = () => {
    setPasswordConfirmSatus((oldStatus) => !oldStatus);
  };

  const disabledInputOfPhone = (mobile) => {
    editVerifyMobile(mobile).then((res) => setPhoneInput(false));
    setMobileVerified(false);
  };
  const checkIdDate = (value) => {
    let result =
      value.substr(1, 2) && value.substr(3, 2) < 13 && value.substr(5, 2) < 32;
    return result;
  };

  const calculateAge = (values) => {
    const nationalIDYear =
      values.id.toString().charAt(1) == "0" ||
      values.id.toString().charAt(1) == "1" ||
      values.id.toString().charAt(1) == "2"
        ? "20" + values.id.toString().substring(1, 3)
        : "19" + values.id.toString().substring(1, 3);
    console.log(nationalIDYear);
    // let age = moment(
    //   `${values.id.toString().substring(3, 5)}/${values.id
    //     .toString()
    //     .substring(5, 7)}/${nationalIDYear}`,
    //   "MM/DD/YYYY"
    // ).from(moment());
    const birth = new Date(
      `${values.id.toString().substring(3, 5)}/${values.id
        .toString()
        .substring(5, 7)}/${nationalIDYear}`
    );
    const date_diff =
      new Date(
        `${values.id.toString().substring(3, 5)}/${values.id
          .toString()
          .substring(5, 7)}/${nationalIDYear}`
      ).getTime() - new Date().getTime();
    const years = Math.abs(new Date(date_diff).getFullYear() - 1970);
    //
    if (years < 18) {
      return false;
    } else if (years == 18) {
      if (birth.getMonth() < new Date().getMonth()) {
        return true;
      }
      if (birth.getMonth() == new Date().getMonth()) {
        if (birth.getDate() < new Date().getDate()) {
          return true;
        } else if (birth.getDate() == new Date().getDate()) {
          return false;
        }
      }
    } else if (years > 18) {
      return true;
    }
  };
  const {
    touched,
    values,
    handleChange,
    errors,
    isValid,
    handleSubmit,
    handleBlur,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(5, "Minimum 5 characters")
        .max(50, "Maximum 50 characters")
        .required("Name is required"),
      email: Yup.string()
        .min(7, "Minimum 7 characters")
        .max(150, "Maximum 150 characters")
        .required("Email is required")
        .email("Invalid email Format")
        .matches(
          "^[A-Za-z0-9._%+-]+@(?!vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
          " please enter a valid email "
        )
        .matches(
          "^[A-Za-z0-9._%+-]+@(?!Vodafone.com)[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
          " please enter a valid email "
        ),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~@#$^*()_+=[\]{}|\\,.?: -\!\%\&]).{8,}$/,
          // /^(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_=+])[A-Za-z\d`~!@#$%^&*()-_=+]{8,}$/,
          "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      passwordConfirm: Yup.string()
        .required("Password Confirmation is required")
        .oneOf(
          [Yup.ref("password"), null],
          "Password Confirmation have to be identical with the password"
        ),
      phoneNumber: Yup.string()
        .min(10, "Minimum 10 characters")
        .max(10, "Maximum 10 characters")
        .required("Phone Number is required")
        .matches("(1)[0-9]{9}", " please enter a valid phone number"),
      nameid: Yup.string()
        .min(5, "Minimum 5 characters")
        .max(50, "Maximum 50 characters")
        .matches(
          /[ء-ي]+/,
          "يرجى ادخال الاسم باللغة العربية كما هو موضح ببطاقة الرقم القومى"
        )
        .required("name is required"),
      id: Yup.string()
        .min(14, "Must be 14 number id")
        .max(14, "Must be 14 number id")
        .required(" National ID is required")
        .matches(/^[0-9]+$/, "This is not a National ID number")
        .test(
          "validate National ID",
          "National ID is invalid",
          function (value) {
            return checkIdDate(values.id);
          }
        )
        .test(
          "validate National ID2",
          "You Have to be more than 18 to be able to complete registration",
          function (value) {
            return calculateAge(values);
          }
        ),
    }),
    initialValues: {
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      uploadCv: data.uploadCv,
      CV: data.CV,
      isAdmin: data.isAdmin,
      region: data.region,
      emailVerified: data.emailVerified,
      mobileVerified: data.mobileVerified,
      nameid: guardianDetails.nameid,
      id: guardianDetails.id,
    },
    onSubmit: (values) => {
      values.isAdmin = admin;
      setData(values);
      changeDetails({
        ...guardianDetails,
        id: values.id,
        nameid: values.nameid,
      });
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      id='mainuserForm'
      className='row user-details-container'
    >
      <div className='row user-details-container '>
        {msg ? <Toaster variant={"danger"} msg={msg} /> : ""}
        <div className='col-md-3 mr-5 '>
          <Stepper
            next={handleSubmit}
            disabled={!isValid || !emailVerified || !mobileVerified}
            NextStep={goNext}
          />
        </div>
        <div className='col-md-8 scrolled user-details-alignment '>
          <form onSubmit={handleSubmit}>
            <div className='title'>Registration Details</div>
            <div className='main-user-form-body-container '>
              <div className='row'>
                <div className='form-group input-width-100 col-md-6'>
                  <label htmlFor='name' className='full-name label-format'>
                    Full Name<span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    type='text'
                    className={
                      touched.name && errors.name
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='name'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    {...getFieldProps("name")}
                  />
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.name && errors.name
                        ? {
                            display: "block",
                            position: "absolute",
                            top: "33%",
                            right: "10%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  <div className='hint'>
                    <li>Must be more than 5 characters.</li>
                  </div>
                  {touched.name && errors.name ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.name}
                    </div>
                  ) : null}
                </div>
                <div className='form-group input-width-100 col-md-6 '>
                  <label htmlFor='nameid' className=' label-format'>
                    Full Name in Arabic
                    <span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nameid}
                    type='text'
                    className={
                      touched.nameid && errors.nameid
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='nameid'
                    name='nameid'
                    {...getFieldProps("nameid")}
                  />
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.nameid && errors.nameid
                        ? {
                            display: "block",
                            position: "absolute",
                            top: "33%",
                            right: "10%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  <div className='hint '>
                    <li>Must be as written in Arabic as your national ID.</li>
                  </div>
                  {touched.nameid && errors.nameid ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.nameid}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='row'>
                <div className='form-group input-width-100 col-md-6 '>
                  <label htmlFor='id' className='national-id label-format'>
                    National ID <span className='required'>*</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.id}
                    type='text'
                    className={
                      touched.id && errors.id ? "text-box-error" : "text-box"
                    }
                    id='id'
                    name='id'
                    {...getFieldProps("id")}
                  />
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.id && errors.id
                        ? {
                            display: "block",
                            position: "absolute",
                            top: "39%",
                            right: "10%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  {touched.id && errors.id ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.id}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className=' row'>
                <div className='form-group col-md-6 '>
                  <label htmlFor='email' className='email label-format'>
                    Email<span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <div className='row ml-1'>
                    <div className='col-md-9 pl-0 col-sm-12'>
                      <input
                        type='email'
                        className={
                          touched.email && (errors.email || sendemail == false)
                            ? "text-box-error"
                            : "text-box"
                        }
                        id='email'
                        name='email'
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        {...getFieldProps("email")}
                      />
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        style={
                          touched.email && (errors.email || sendemail == false)
                            ? {
                                display: "block",
                                position: "absolute",
                                top: "24%",
                                right: "10%",
                              }
                            : { display: "none" }
                        }
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                          fill='#B3261E'
                        />
                      </svg>
                    </div>
                    <div className='col-md-3  col-sm-12 verify-div'>
                      <button
                        type='button'
                        className='verify-btn'
                        onClick={() => {
                          setEmailInput(true);
                          getCodebyEmail(values.email)
                            .then((r) => setSendEmail(true))
                            .catch((ex) => {
                              setSendEmail(false);
                            });
                        }}
                      >
                        VERIFY
                      </button>
                    </div>
                  </div>
                  <div className='hint'>
                    <li>A verification code will be sent to your email.</li>
                  </div>
                  {touched.email && sendemail == false ? (
                    <div className='alert alert-danger reg-alert'>
                      Email Format is wrong
                    </div>
                  ) : null}
                  {touched.email && errors.email ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                <div className='form-group  verify-form'>
                  <div className='col-md-12'>
                    {emailInput === true ? (
                      <div className=''>
                        <label
                          htmlFor='verifyEmail'
                          className='verification label-format'
                        >
                          Verification Code <span className='required'> *</span>
                          <span className='required ml-1'> (required)</span>
                        </label>
                        <div className='row ml-1'>
                          <VerificationCode
                            length={4}
                            onComplete={(code) => {
                              setEmailCode(code);
                              handleEmailVerified(values.email, code);
                            }}
                          />
                          {emailVerified ? (
                            <img src='/assets/check.svg'></img>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className=' row'>
                <div className='form-group  col-md-6 '>
                  <label htmlFor='name' className='password label-format'>
                    Password<span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    style={{ paddingLeft: "1rem" }}
                    type={passwordStatus === true ? "password" : "text"}
                    className={
                      touched.password && errors.password
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='password'
                    name='password'
                    {...getFieldProps("password")}
                  />

                  <span
                    onClick={() => togglePasswordStatus()}
                    toggle='#password-field'
                    className={
                      passwordStatus
                        ? "fa fa-fw fa-eye field-icon toggle-password"
                        : "fa fa-fw fa-eye-slash field-icon toggle-password"
                    }
                  ></span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.password && errors.password
                        ? {
                            display: "block",
                            position: "relative",
                            bottom: "20%",
                            left: "82%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  {touched.password && errors.password ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.password}
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='name' className='password label-format'>
                    Password Confirmation<span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>
                  <input
                    style={{ paddingLeft: "1rem" }}
                    type={passwordConfirmStatus === true ? "password" : "text"}
                    className={
                      touched.passwordConfirm && errors.passwordConfirm
                        ? "text-box-error"
                        : "text-box"
                    }
                    id='passwordconfirm'
                    name='passwordconfirm'
                    {...getFieldProps("passwordConfirm")}
                  />

                  <span
                    onClick={() => togglePasswordConfirmStatus()}
                    toggle='#password-field'
                    className={
                      passwordConfirmStatus
                        ? "fa fa-fw fa-eye field-icon toggle-password"
                        : "fa fa-fw fa-eye-slash field-icon toggle-password"
                    }
                  ></span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    style={
                      touched.passwordConfirm && errors.passwordConfirm
                        ? {
                            display: "block",
                            position: "relative",
                            bottom: "20%",
                            left: "82%",
                          }
                        : { display: "none" }
                    }
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                      fill='#B3261E'
                    />
                  </svg>
                  {touched.passwordConfirm && errors.passwordConfirm ? (
                    <div className='alert alert-danger reg-alert'>
                      {errors.passwordConfirm}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className=' row '>
                <div className='form-group  col-md-6 '>
                  <label htmlFor='phoneNumber' className='mobile label-format'>
                    Mobile Number<span className='required'> *</span>
                    <span className='required ml-1'> (required)</span>
                  </label>

                  <div>
                    <div className='row'>
                      <div className='col-md-3 col-sm-3'>
                        <input
                          className='text-box'
                          placeholder='+20'
                          disabled
                        />
                      </div>
                      <div className='col-md-6  col-sm-9'>
                        <input
                          placeholder='e.g : 1012312345'
                          type='tel'
                          className={
                            touched.phoneNumber &&
                            (errors.phoneNumber || sendsms == false)
                              ? "text-box-error"
                              : "text-box"
                          }
                          id='phoneNumber'
                          value={values.phoneNumber}
                          name='phoneNumber'
                          {...getFieldProps("phoneNumber")}
                        />
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          style={
                            touched.phoneNumber &&
                            (errors.phoneNumber || sendsms == false)
                              ? {
                                  display: "block",
                                  position: "absolute",
                                  top: "25%",
                                  right: "10%",
                                }
                              : { display: "none" }
                          }
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z'
                            fill='#B3261E'
                          />
                        </svg>
                      </div>
                      <div className='col-md-2 col-sm-2 pl-3  verify-mobile-div'>
                        <button
                          type='button'
                          className={
                            (touched.phoneNumber &&
                              (sendsms == false || errors.phoneNumber)) ||
                            !touched.phoneNumber
                              ? "verify-btn-disabled"
                              : "verify-btn"
                          }
                          disabled={
                            (touched.phoneNumber &&
                              (sendsms == false || errors.phoneNumber)) ||
                            !touched.phoneNumber
                              ? true
                              : false
                          }
                          onClick={() => {
                            setPhoneInput(true);
                            getCodebyMobile(values.phoneNumber, "egypt")
                              .then((r) => {
                                setSendSMS(true);
                              })
                              .catch((ex) => {
                                setSendSMS(false);
                              });
                          }}
                        >
                          VERIFY
                        </button>
                      </div>
                    </div>
                    <div className='hint'>
                      <li>A verification code will be sent to your mobile.</li>
                      <li>
                        Please enter a valid mobile number neglecting the first
                        0.
                      </li>
                    </div>
                    {touched.phoneNumber && sendsms == false ? (
                      <div className='alert alert-danger reg-alert'>
                        Mobile Number Format is wrong
                      </div>
                    ) : null}

                    {touched.phoneNumber && errors.phoneNumber ? (
                      <div className='alert alert-danger reg-alert'>
                        {errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='form-group  verify-form'>
                  <div className='col-md-12'>
                    {phoneInput === true ? (
                      <div className=''>
                        <label
                          htmlFor='verifyEmail'
                          className='verification label-format'
                        >
                          Verification Code <span className='required'> *</span>
                          <span className='required ml-1'> (required)</span>
                        </label>
                        <div className='row ml-1'>
                          <VerificationCode
                            length={4}
                            onComplete={(code) => {
                              setMobileVerified(code);
                              handleMobileVerified(values.phoneNumber, code);
                            }}
                          />
                          {mobileVerified ? (
                            <img src='/assets/check.svg'></img>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className=' input-width-100'>
                <label htmlFor='resume' className='resume label-format'>
                  Resume
                </label>
                <div className='image-res-upload'>
                  <label htmlFor='file-input'>
                    <div className='file-res-input-container'>
                      <img
                        src='/assets/upload.svg'
                        style={{ cursor: "pointer" }}
                      />
                      {values.CV != "" ? (
                        <span className='file-res-input-container-text'>
                          Change Your Resume
                        </span>
                      ) : (
                        <span className='file-res-input-container-text'>
                          Upload Your Resume
                        </span>
                      )}
                    </div>
                  </label>
                </div>
                {cvError != "" ? (
                  <div className='alert alert-danger reg-alert'>{cvError}</div>
                ) : null}
                <input
                  style={{ display: "none" }}
                  id='file-input'
                  size={100 * 1024 * 1024}
                  className='CVUploader'
                  type='file'
                  name='uploadCv'
                  accept='application/pdf'
                  onChange={(e) => {
                    if (e.target.files[0].type !== "application/pdf") {
                      SetCvErrorMessage(
                        "The uploaded Document should be in pdf format"
                      );
                      return;
                    } else {
                      SetCvErrorMessage("");

                      var files = e.target.files,
                        f = files[0];
                      var reader = new FileReader();
                      reader.readAsDataURL(f);
                      reader.onload = function () {
                        setFieldValue("CV", reader.result);
                        setFieldValue("uploadCv", e.target.value);
                      };
                    }
                  }}
                />
              </div>
            </div>

            <div className='btn-next-container' style={{ float: "right" }}>
              <button
                // type='submit'
                onClick={() => {
                  setGoNext(true);
                }}
                type='button'
                disabled={
                  !isValid ||
                  !emailVerified ||
                  !mobileVerified ||
                  (touched.CV && cvError != "")
                }
                className={
                  !isValid ||
                  !emailVerified ||
                  !mobileVerified ||
                  (touched.CV && cvError != "")
                    ? "next-btn-disabled"
                    : "next-btn-egypt"
                }
              >
                NEXT
              </button>
            </div>
          </form>
        </div>
      </div>
    </form>
  );
};
export default MainUserDetails;
