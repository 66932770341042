import React from "react";
import { useState } from "react";
import styles from "./FourthSection.module.css";
import { isMobile } from "react-device-detect";
function FourthSection() {
  const [steps, setSteps] = useState([
    {
      day: "17",

      month: "May",
      step: "Registration starts",
      // description: "At 09:00 AM GMT.",
    },
    {
      day: "31",

      month: "May",
      step: "REGISTRATION CLOSES",
      // description: "At 6:00 PM GMT.",
    },
    {
      day: "7",

      month: "Jun",
      step: "Announcement of selected teams",
      // description: "To join the National Round.",
    },
    {
      day: "07",
      month: "Jul",
      step: "Rankings Anouncement​",
      // description: "Live event & Jurying of solutions & Results announcement.​",
    },
  ]);
  return (
    <div className={styles["fourth-section-div"]}>
      <div className={styles["fourth-page-main-content"]}>
        <span className={styles["fourth-section-main-title"] + " col-md-12"}>
          Your hackathon Timeline
        </span>

        <div className='row p-0'>
          <div className={styles["video-div"] + " col-md-3 col-sm-0"}>
            <div className={styles["vois-logo"]}>
              _VO<span className={styles["vois-logo-bold"]}>IS</span>
            </div>
            <video
              autoPlay
              muted
              loop
              src='/assets/landing.m4v'
              className={styles["sectiontwo-video"]}
            ></video>
          </div>
          <div
            className={
              styles["step-container"] + " text-center col-md-6 col-sm-12"
            }
          >
            <nav className={styles["nav-pills"] + " nav flex-column"}>
              {steps.map((step, index) => {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div key={step.day} className={styles["circle"]}>
                        {isMobile ? (
                          <svg
                            className={styles["circle-img"]}
                            xmlns='http://www.w3.org/2000/svg'
                            width='86'
                            height='86'
                            viewBox='0 0 86 86'
                            fill='none'
                          >
                            <path
                              d='M38.6595 4.24264C41.0027 1.8995 44.8017 1.8995 47.1448 4.24264L81.5617 38.6595C83.9049 41.0027 83.9049 44.8017 81.5617 47.1448L47.1448 81.5617C44.8017 83.9049 41.0027 83.9049 38.6595 81.5617L4.24264 47.1448C1.8995 44.8017 1.8995 41.0027 4.24264 38.6595L38.6595 4.24264Z'
                              fill='#E60000'
                            />
                            <path
                              d='M29.0412 83.0412V78.9588H24.9588V83.0412H29.0412ZM1.95876 55.9588V60.0412H6.04124V55.9588H1.95876ZM27.3536 80.6464L4.35355 57.6464L3.64645 58.3536L26.6464 81.3536L27.3536 80.6464Z'
                              fill='#E60000'
                            />
                            <path
                              d='M84.0412 30.0412V25.9588H79.9588V30.0412H84.0412ZM56.9588 2.95876V7.04124H61.0412V2.95876H56.9588ZM82.3536 27.6464L59.3536 4.64645L58.6464 5.35355L81.6464 28.3536L82.3536 27.6464Z'
                              fill='#E60000'
                            />
                            <path
                              d='M28.0412 2.95876L23.9588 2.95876L23.9588 7.04124L28.0412 7.04124L28.0412 2.95876ZM0.958759 30.0412L5.04124 30.0412L5.04124 25.9588L0.958758 25.9588L0.958759 30.0412ZM25.6464 4.64645L2.64645 27.6464L3.35355 28.3536L26.3536 5.35355L25.6464 4.64645Z'
                              fill='#E60000'
                            />
                            <path
                              d='M84.0412 55.9588L79.9588 55.9588L79.9588 60.0412L84.0412 60.0412L84.0412 55.9588ZM56.9588 83.0412L61.0412 83.0412L61.0412 78.9588L56.9588 78.9588L56.9588 83.0412ZM81.6464 57.6464L58.6464 80.6464L59.3536 81.3536L82.3536 58.3536L81.6464 57.6464Z'
                              fill='#E60000'
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='87'
                            height='86'
                            viewBox='0 0 87 86'
                            fill='none'
                            className={styles["circle-img"]}
                          >
                            <path
                              d='M39.6595 4.24264C42.0027 1.8995 45.8017 1.8995 48.1448 4.24264L82.5617 38.6595C84.9049 41.0027 84.9049 44.8017 82.5617 47.1448L48.1448 81.5617C45.8017 83.9049 42.0027 83.9049 39.6595 81.5617L5.24264 47.1448C2.8995 44.8017 2.8995 41.0027 5.24264 38.6595L39.6595 4.24264Z'
                              fill='#E60000'
                            />
                            <path
                              d='M30.0412 83.0412V78.9588H25.9588V83.0412H30.0412ZM2.95876 55.9588V60.0412H7.04124V55.9588H2.95876ZM28.3536 80.6464L5.35355 57.6464L4.64645 58.3536L27.6464 81.3536L28.3536 80.6464Z'
                              fill='#E60000'
                            />
                            <path
                              d='M86.0412 29.0412V24.9588H81.9588V29.0412H86.0412ZM58.9588 1.95876V6.04124H63.0412V1.95876H58.9588ZM84.3536 26.6464L61.3536 3.64645L60.6464 4.35355L83.6464 27.3536L84.3536 26.6464Z'
                              fill='#E60000'
                            />
                            <path
                              d='M28.0412 2.95876L23.9588 2.95876L23.9588 7.04124L28.0412 7.04124L28.0412 2.95876ZM0.958759 30.0412L5.04124 30.0412L5.04124 25.9588L0.958758 25.9588L0.958759 30.0412ZM25.6464 4.64645L2.64645 27.6464L3.35355 28.3536L26.3536 5.35355L25.6464 4.64645Z'
                              fill='#E60000'
                            />
                            <path
                              d='M85.0412 55.9588L80.9588 55.9588L80.9588 60.0412L85.0412 60.0412L85.0412 55.9588ZM57.9588 83.0412L62.0412 83.0412L62.0412 78.9588L57.9588 78.9588L57.9588 83.0412ZM82.6464 57.6464L59.6464 80.6464L60.3536 81.3536L83.3536 58.3536L82.6464 57.6464Z'
                              fill='#E60000'
                            />
                          </svg>
                        )}

                        <div className={styles["circle-text"]}>
                          <div className={styles["day"]}>
                            <p className='p-0 m-0'>{step.day}</p>{" "}
                            <sup>{step.sup}</sup>
                          </div>
                          <p>{step.month}</p>
                        </div>
                      </div>

                      {index === steps.length - 1 ? (
                        ""
                      ) : (
                        <div className={styles["line"]}></div>
                      )}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "17.2px",
                        alignItems: "flex-start",
                      }}
                      className={styles["step-desc-container"]}
                    >
                      <span
                        className={styles["step-text"]}
                        dangerouslySetInnerHTML={{ __html: step.step }}
                      ></span>
                      <span className={styles["step-desc"]}>
                        {step.points ? (
                          <ul className={styles["desc-list"]}>
                            {step.points.map((r) => (
                              <li dangerouslySetInnerHTML={{ __html: r }}></li>
                            ))}
                          </ul>
                        ) : (
                          step.description
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </nav>
          </div>
          <div className='col-md-3 col-sm-0'></div>
        </div>
      </div>
    </div>
  );
}

export default FourthSection;
