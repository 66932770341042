import React from "react";

import onlineIcon from "../../../../assets/online.png";
// import closeIcon from "../../icons/closeIcon.png";
import { useNavigate } from "react-router-dom";
import "./InfoBar.css";

const InfoBar = ({ room }) => {
  let history = useNavigate();
  return (
    <div className='asc-infoBar'>
      <div className='leftInnerContainer'>
        <img className='onlineIcon' src={onlineIcon} alt='online icon' />
        <h3 className='chat-container-name'>{room}</h3>
      </div>
    </div>
  );
};

export default InfoBar;
