import React, { useEffect, useState } from "react";
import "./mosaicWall.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { base64StringToBlob } from "blob-util";
import MosaicExample from "./mosaicExample";
import LoggedNavBar from "../../common/navBar/loggedNav";
import SideMenu from "../../common/sideMenu/sideMenu";

function Mosaicwall2() {
  const [imageLinks, setImageLinks] = React.useState([]);
  const [imageBytes, setImageBytes] = React.useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [imagesfinalbytes, setimagesfinalbytes] = useState([]);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}mosaicWall/`).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          imageLinks.push(res.data[i]);

          axios.get(`${res.data[i]}`).then((res) => {
            imageBytes.push(res.data);
            const blob = base64StringToBlob(
              res.data.split(",")[1],
              "image/png"
            );

            let url = URL.createObjectURL(blob);
            let uris = imagesfinalbytes;
            uris.push(url);
            setimagesfinalbytes(uris);
            if (imageLinks.length === imageBytes.length) {
              setLoadBytes(true);
            }
          });
        }
      }
    });
  }, []);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className='coaches-pageContainer '>
        <LoggedNavBar to='hall' />
        <div className='container'>
          <div
            className='row asc-cards-flip-container '
            style={{ margin: "0", padding: "0" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
              className='col-md-12 col-xs-12  mt-5'
            >
              <div
                style={{
                  position: "relative",
                  left: "4px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                {loadBytes === true ? (
                  <>
                    <div
                      className='row'
                      id='mosaicContainer'
                      style={{ height: "80vh" }}
                    >
                      <MosaicExample
                        images={imagesfinalbytes}
                        source='/assets/mosaicwall-bg.svg'
                      />

                      {/* {imageContainer()} */}
                    </div>
                  </>
                ) : (
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mosaicwall2;
