import React from "react";
import styles from "./SecondSection.module.css";
import { isMobile } from "react-device-detect";
function SecondSection() {
  return (
    <div className={styles["second-section-div"]}>
      <div className={styles["second-page-main-content"]}>
        {isMobile ? null : (
          <span className={styles["second-section-main-title"] + " col-md-12"}>
            What is in it for you?
          </span>
        )}

        <div className={styles["second-page-main-content-body"]}>
          {" "}
          <div className={styles["video-div"]}>
            <img
              src='/assets/whats-in-it.jpg'
              className={styles["sectiontwo-video"]}
            ></img>
          </div>
          {isMobile ? (
            <span
              className={styles["second-section-main-title"] + " col-md-12"}
            >
              What is in it for you?
            </span>
          ) : null}
          <div className={styles["main-div"]}>
            <span className={styles["secondsection-description"]}>
              Take your skills and expertise to the next level and have fun
              creating breakthrough solutions whilst being coached and inspired
              by our expert Technology leaders. You will experiment, learn fast,
              grow your professional network, boost your personal brand, get
              global exposure and earn cash prizes! 
            </span>
            <section className={styles["separator"]}>
              {isMobile ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='324'
                  height='29'
                  viewBox='0 0 324 29'
                  fill='none'
                >
                  <path d='M324 28H164L141.5 0.5H106H0' stroke='#E60000' />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 763 37'
                  fill='none'
                  className={styles["line"]}
                >
                  <path d='M763 36H348L311.5 0.5H106H0' stroke='#E60000' />
                </svg>
              )}

              <span className={styles["prizes-title"]}>PRIZES</span>
            </section>
            {/* <span className={styles["prizes-text"]}>
              In our hackathon, brilliance deserves recognition. We've lined up
              an array of captivating prizes that await those who dare to
              innovate, those who dream big and code even bigger.
            </span> */}
            <div className={styles["badge-div"]}>
              <div className={styles["one-badge-div"]}>
                <img
                  src='/assets/badges/badgeone.svg'
                  className={styles["one-badge-img"]}
                ></img>
                <div className={styles["one-badge-div-details"]}>
                  <div className={styles["one-badge-div-title"]}>
                    EGP 30,000
                  </div>
                  <div className={styles["one-badge-div-subtitle"]}>
                    1<sup>st</sup> Place
                  </div>
                </div>
              </div>
              <div className={styles["one-badge-div"]}>
                <img
                  src='/assets/badges/badgetwo.svg'
                  className={styles["one-badge-img"]}
                ></img>
                <div className={styles["one-badge-div-details"]}>
                  <div className={styles["one-badge-div-title"]}>
                    EGP 20,000
                  </div>
                  <div className={styles["one-badge-div-subtitle"]}>
                    2<sup>nd</sup> Place
                  </div>
                </div>
              </div>
              <div className={styles["one-badge-div"]}>
                <img
                  src='/assets/badges/badgethree.svg'
                  className={styles["one-badge-img"]}
                ></img>
                <div className={styles["one-badge-div-details"]}>
                  <div className={styles["one-badge-div-title"]}>
                    EGP 10,000
                  </div>
                  <div className={styles["one-badge-div-subtitle"]}>
                    3<sup>rd</sup> Place
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
